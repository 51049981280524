import React from 'react';
import { Button, Typography, Icon, Input, Drawer, Spin, Row, Col } from 'antd';
import moment from 'moment';
// import query_string from 'query-string';
//* view components
import { NumericKeyboard }   from 'numeric-keyboard/dist/numeric_keyboard.react';
import NumericKeyboardLayout from '../../../snippets/NumericKeyboardLayout';
import NumericInput          from '../../../snippets/NumericInput';
import TokenList           from '../../../snippets/drawer_contents/TokenList';
import TokenAndBalanceList from '../../../snippets/drawer_contents/TokenAndBalanceList'
//* utils
import checkers   from '../../../utils/checkers';
import eos_wallet from '../../../utils/eos_wallet';
//* resources
import lang from '../../../lang/lang';
//* global variants
import config from '../../../config';


// const status_localize = {
//   KR: {
//     'START': '승인 대기',
//     'EXCHANGABLE': 'EXCHANGABLE',
//     'PAY_CONFIRMED': '승인',
//     'OK': '완료',
//     'CANCELED': '취소',
//     'FAILED': '실패'
//   }
// }

class Token2TokenTab extends React.PureComponent {
  static defaultProps = {
  }

  is_mounted_ = false;
  state = {
    is_loading: false,
    // data: [],
    visible_keyboard_drawer: false,
    visible_from_token_list_drawer: false,
    visible_to_token_list_drawer: false,
    
  };
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;
    // this.setstate({ is_loading: true });

    // this.setstate({
    //   is_loading: false,
    //   data: await this._refreshData(this.props.year, this.props.month),
    //  });
  }

  componentWillUnmount() { this.is_mounted_ = false; }
  
  // async componentWillReceiveProps(nextProps) {
  //   const is_same_year  = this.props.year  === nextProps.year;
  // }
  // shouldComponentUpdate (nextProps, nextState) {
  //   return true;
  // }

  render() {
    return (
      <div>
        {/* input ***********************/}
        <div> 
          <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
            <div style={{ flex: "1 1 0px" }}>
              <NumericInput
                className="numeric-input-borderless"
                style={{
                  width: '100%',
                  textAlign: 'center'
                }}
                size="large"
                value={eos_wallet.formatCommaNumber(this.props.value_to_exchange, false)}
                value_max={this.props.value_max}
                onChange={this.props.onChangeValueToExchange}
                onFocus={e => {
                  if (checkers.is_mobile === false) {
                    if (process.env.NODE_ENV === 'development')
                      console.log("pc");
                    return;
                  }
                  if (process.env.NODE_ENV === 'development')
                    console.log("mobile");
                  this.setstate({ visible_keyboard_drawer: true })
                }}
                onBlur={e => {
                  if (checkers.is_mobile === false) {
                    this.props.previewResult();
                    return;
                  }
                }}
                maxLength={13}
                limit_decimal_point={this.props.limit_decimal_point}
                placeholder="" comma="true" />
              <Drawer
                placement="bottom"
                closable={false}
                onClose={e => {
                  let value_to_exchange = this.props.value_to_exchange;
                  if (value_to_exchange[value_to_exchange.length -1] === '.')
                    value_to_exchange = value_to_exchange.substring(0, value_to_exchange.length -1);
                  this.props.onChangeValueToExchange(value_to_exchange);
                  this.setstate({ visible_keyboard_drawer: false });
                  this.props.previewResult();
                }}
                visible={this.state.visible_keyboard_drawer}
                height="bottom">
                {/* <div style={{ paddingBottom: "0.25rem" }}>
                  <Row type="flex" justify="center" gutter={2}>
                    <Col span={5}><Button type="link" style={{ color: '#777', width: "100%", cursor: "pointer", textAlign: "center", padding: "0" }} onClick={e => this.props._hAddValue(100000)}>{lang.get('add_value/add100thousand')}</Button></Col>
                    <Col span={5}><Button type="link" style={{ color: '#777', width: "100%", cursor: "pointer", textAlign: "center", padding: "0" }} onClick={e => this.props._hAddValue(50000)}>{lang.get('add_value/add50thousand')}</Button></Col>
                    <Col span={5}><Button type="link" style={{ color: '#777', width: "100%", cursor: "pointer", textAlign: "center", padding: "0" }} onClick={e => this.props._hAddValue(10000)}>{lang.get('add_value/add10thousand')}</Button></Col>
                    <Col span={5}><Button type="link" style={{ color: '#777', width: "100%", cursor: "pointer", textAlign: "center", padding: "0" }} onClick={e => this.props.onChangeValueToExchange('0')}>{lang.get('add_value/clear')}</Button></Col>
                  </Row>
                </div> */}
                <NumericKeyboard layout={NumericKeyboardLayout.value} entertext={lang.get('exchange/token_to_token/btn_enter_number')} onPress={value => {
                  if (value === 'enter') {
                    this.setstate({ visible_keyboard_drawer: false });
                  }
                  this.props.onPressExchangeValue(value);
                }} />
              </Drawer>                
            </div>
            <div style={{
              position: "absolute",
              right: "0.5rem",
              // flex: "0 1 auto",
              fontSize: "1rem",
              fontWeight: "700",
              textAlign: "left",
              paddingRight: "0.5rem",
              background: "#fff",
            }}>
              <Typography.Text>{this.props.token_from_symbol}</Typography.Text>
            </div>
          </div>

          {/* {(checkers.is_mobile === false)
            ? ( <div style={{ padding: "0.5rem 0.5rem 1rem"}}>
                  <Row type="flex" justify="end">
                    <Col>
                      <div>
                        <Button onClick={e => this.props._hAddValue(100000)} style={{ marginRight: "5px" }}>{lang.get('add_value/add100thousand')}</Button>
                        <Button onClick={e => this.props._hAddValue(50000)} style={{ marginRight: "5px" }}>{lang.get('add_value/add50thousand')}</Button>
                        <Button onClick={e => this.props._hAddValue(10000)} style={{ marginRight: "5px" }}>{lang.get('add_value/add10thousand')}</Button>
                        <Button onClick={e => this.props.onChangeValueToExchange('0')}>{lang.get('add_value/clear')}</Button>
                      </div>
                    </Col>
                  </Row>
                </div>
            ) : null
          } */}
        </div>

        <div style={{ padding: "0.5rem" }}>

          {/* input exchange location */}
          <div style={{
            borderRadius: "0.6em", padding: "1.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
            display: 'flex', justifyContent: "space-between", alignItems: "center",
            fontSize: "1rem", fontWeight: "700", color: "RGB(191, 191, 191)"
          }}>
            <div><Typography.Text>{lang.get('exchange/token_to_token/select_exchanger/title')}</Typography.Text></div>
            {(this.props.selected_exchanger.id && !this.props.is_loading)
              ? ( <div>
                    <Button type="link" onClick={this.props.onClickRedirectToSelectExchanger} style={{ padding: "0", fontSize: "1rem", fontWeight: "700" }}>
                      <Typography.Text>{this.props.selected_exchanger.name}</Typography.Text>
                      <Icon type="unordered-list" style={{ paddingLeft: "0.5rem", color: "rgb(2, 122, 255)" }} />
                    </Button>
                  </div>)
              : ( <div>
                    <Button type="link" onClick={this.props.onClickRedirectToSelectExchanger} style={{ padding: "0", fontSize: "1rem", fontWeight: "700" }}>
                      <Typography.Text>{lang.get('exchange/token_to_token/select_exchanger/not_selected')}</Typography.Text>
                      <Icon type="unordered-list" style={{ paddingLeft: "0.5rem", color: "rgb(2, 122, 255)" }} />
                    </Button>
                  </div>)
            }
          </div>


          <div style={{ borderRadius: "0.6em", padding: "1.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)"}}>
            <div style={{ fontSize: "1rem", fontWeight: "700", color: "RGB(191, 191, 191)" }}>
              <Typography.Text>{lang.get('exchange/token_to_token/select_token/title')}</Typography.Text>
            </div>
            <div style={{ color: "RGB(191, 191, 191)", marginTop: "0.5rem", display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ fontSize: "1rem", fontWeight: "700" }}>
                <Typography.Text>{(this.props.token_from_symbol) ? this.props.token_from_symbol : lang.get('exchange/token_to_token/select_token/not_selected')}</Typography.Text>
                <Button type="link" onClick={e => this.setstate({ visible_token_from_list_drawer: true })} style={{ padding: "0" }}>
                  <Icon type="unordered-list" style={{ paddingLeft: "0.5rem", color: "rgb(2, 122, 255)" }} />
                </Button>                
                <Drawer
                  title={<div style={{ fontWeight: "700" }}><Typography.Text>{lang.get('drawer/token_list/title')}</Typography.Text></div>}
                  placement="bottom"
                  closable={true}
                  onClose={e => this.setstate({ visible_token_from_list_drawer: false})}
                  visible={this.state.visible_token_from_list_drawer}
                  height="bottom"
                  bodyStyle={{ paddingTop: "0" }}
                >
                  <div style={{ height: "100%", maxWidth: "480px", marginLeft: "auto", marginRight: "auto" }}>        
                    <div style={{ position: "relative", height: "calc(50vh - 5rem)"}}>
                      <div style={{ top: "0", bottom: "0", left: "0", right: "0", position: "absolute",  overflow: "auto", paddingTop: "0.5rem"}}>
                        <TokenAndBalanceList
                          data={this.props.user_accounts}
                          except_tokens={[config.major_token_symbol]}
                          onClickCurrency={async value => {
                            this.setstate({
                              visible_token_from_list_drawer: false
                            });
                            await this.props.onClickFromToken(value);
                            this.props.previewResult();
                          }}
                        />
                      </div>
                    </div>
                  </div>
              </Drawer>
                
              </div>
              <div style={{ fontSize: "1rem", fontWeight: "700" }}>
                <Icon type="arrow-right" />
              </div>
              <div style={{ fontSize: "1rem", fontWeight: "700" }}>
                <Typography.Text>{(this.props.token_to_symbol) ? this.props.token_to_symbol : lang.get('exchange/token_to_token/select_token/not_selected')}</Typography.Text>
                <Button type="link" onClick={e => this.setstate({ visible_token_to_list_drawer: true })} style={{ padding: "0" }}>
                  <Icon type="unordered-list" style={{ paddingLeft: "0.5rem", color: "rgb(2, 122, 255)" }} />
                </Button>                
                <Drawer
                  title={<div style={{ fontWeight: "700" }}><Typography.Text>{lang.get('drawer/token_list/title')}</Typography.Text></div>}
                  placement="bottom"
                  closable={true}
                  onClose={e => this.setstate({ visible_token_to_list_drawer: false })}
                  visible={this.state.visible_token_to_list_drawer}
                  height="bottom"
                  bodyStyle={{ paddingTop: "0" }}
                >
                  <div style={{ height: "100%" }}>
                    <div style={{ position: "relative", height: "calc(50vh - 5rem)" }}>
                      <div style={{ top: "0", bottom: "0", left: "0", right: "0", position: "absolute", overflow: "auto" }}>
                        <TokenList except_token_symbols={[this.props.token_from_symbol]} onClickToken={async value => {
                          this.setstate({ visible_token_to_list_drawer: false });
                          await this.props.onClickToToken(value.token_symbol);
                          this.props.previewResult();
                        }} />
                      </div>
                    </div>
                  </div>
                </Drawer>
              </div>
            </div>
          </div>

          {(this.props.is_result_caculating)
            ? ( <div style={{
                  position: "relative", width: "100%", height: "100px", borderRadius: "0.6em", padding: "1.5rem 1rem", margin: "0.5rem auto 0"
                  , boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)", textAlign: "center" }}>
                  <Spin tip={lang.get('exchange/token_to_token/result_caculating/loading_text')} />
                </div>                          
              )
            : null
          }
          {(this.props.token_to_add && this.props.result && !this.props.is_result_caculating)
          ? ( <div>
                <div style={{ borderRadius: "0.6em", padding: "1.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)"}}>
                  <Row type="flex" justify="space-between" align="middle">
                    <Col span={20}>
                      <div style={{ fontWeight: "700" }}>{lang.get('exchange/token_to_token/result_caculating/expected_token_to_add')}</div>
                      <div style={{ fontSize: "1.2rem", fontWeight: "700", color: "red" }}>
                        {`${eos_wallet.formatCommaNumber(this.props.token_to_add)} `}
                        <span style={{ color: "RGB(150, 150, 150)", fontSize: "0.75rem" }}>{this.props.token_to_symbol}</span>
                      </div>
                      <div style={{ fontWeight: "700", marginTop: "1rem" }}>{lang.get('exchange/token_to_token/result_caculating/expected_balance')}</div>
                      <div style={{ fontSize: "1.2rem", fontWeight: "700", color: "red" }}>
                        {`${eos_wallet.formatCommaNumber(this.props.result)} `}
                        <span style={{ color: "RGB(150, 150, 150)", fontSize: "0.75rem" }}>{this.props.token_to_symbol}</span>
                      </div>
                      <div style={{ fontWeight: "700", marginTop: "1rem" }}>{lang.get('exchange/token_to_token/result_caculating/expected_commission')}</div>
                      <div style={{ fontSize: "1.2rem", fontWeight: "700", color: "red" }}>
                        {`${eos_wallet.formatCommaNumber(this.props.commission)} `}
                        <span style={{ color: "RGB(150, 150, 150)", fontSize: "0.75rem" }}>{this.props.token_from_symbol}</span>
                      </div>
                    </Col>
                    <Col span={4}>
                      <div>
                        <Button type="link" onClick={e => this.props.redirectTo('wallet/record', { tab_key: '3' })}>
                          <Icon type="right" style={{ color: "RGB(75, 75, 75)" }} />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <div style={{ fontSize: "0.8rem", color: "red", marginTop: "0.5rem" }}>
                    {lang.get('exchange/token_to_token/result_caculating/warning_message', {date: moment(new Date()).format('YYYY.MM.DD HH:mm:ss')} )}
                  </div>
                </div>
              </div> )
          : null}


          <div style={{ borderRadius: "0.6em", padding: "1.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)"}}>
            <div>
              <Icon type="profile" />
              <span style={{ paddingLeft: "0.5rem", color: "rgb(135, 135, 135)" }}>{lang.get('exchange/token_to_token/input_memo/title')}</span>
            </div>
            <div style={{ marginTop: "0.4rem" }}>
              <Input.TextArea maxLength="255" autosize={{minRows: 1, maxRows: 4}} value={this.props.log} onChange={this.props.onChangeMemo}
                style={{ width: "100%", height: "100%" }} /> 
            </div>
          </div>

          <div style={{ padding: "0.5rem 1rem", margin: "0.5rem auto 0" }}>
            {lang.get('exchange/token_to_token/commission_info_text', { commission_rate: this.props.commission_rate })}
          </div>
        </div>
      </div>
    );
  }
}

export default Token2TokenTab;