import React from 'react';
import { Button, Typography, Icon, Row, Col } from 'antd';
// import query_string from 'query-string';
//* view components
import Loading from '../../../snippets/Loading';
//* utils
import common     from '../../../utils/common';
import eos_wallet from '../../../utils/eos_wallet';
import state_util from '../../../utils/state_util';
import storage_util from '../../../utils/storage_util';
//* resources
import lang from '../../../lang/lang';




class Result extends React.Component {
  is_mounted_ = false;
  state = {
    is_loading: false,
    commission_amount: '-',
    commission_rate: 0,
    exchange_type: '', // BY_BANK_ACCOUNT , 
    exchanger: {name: '', bankaccounts: [{bank_account: '', bank: { name: ''}}]},
    money_amount: '-',
    money_symbol: '',
    token_amount: '-',
    token_symbol: '',
    from_token_amount: '-',
    from_token_symbol: '',
    to_token_amount: '-',
    to_token_symbol: '',
    user_bankaccount: {bank_account: '', owner_name: '', bank: { name: ''}},
    result_type: '',
  }

  constructor(props) { super(props);
    // console.log(this.props.location.state);
    // if (!this.props.location.state)
    //   return this.props.history.push('/');

    if (sessionStorage.getItem(state_util.ENUM.exchange_state) === null) {
      return this.props.history.push('/');
    } else {
      if (storage_util.loadFromSession(state_util.ENUM.exchange_state).its_result !== true) {
        return this.props.history.push('/');
      }
    }

    // const query = query_string.parse(this.props.location.search);
    // if (query === 'tokentotoken') {
    //   this.state = {
    //     ...this.state,
    //   }  
    // } else if (query === 'token_to_money') {
    //   this.state = {
    //     ...this.state,
    //   }
    // }
    // const { value_transferred } = this.props.location.state;    
    
  }
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    if (await common.isLoggedIn() === false) {
      return;
    }

    let stored_state = storage_util.loadFromSession(state_util.ENUM.exchange_state, void 0);
    state_util.clearEveryStates();

    this.setstate({
      is_loading: false,      
      header_text: await eos_wallet.getHeaderTokenText() || '',
      ...stored_state,
    });
  }
  componentWillUnmount() { this.is_mounted_ = false; }

  render () {
    if (common.get().token === void 0) {
      return null;
    }

    return (
      <Loading is_loading={this.state.is_loading} overall={true} style={{ display: "table", tableLayout: "fixed", width: "100%", height: "100%" }}>
       
        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>

            {/** HEADER *****/}
            <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  onClick={e => this.props.history.push('/wallet')}
                  style={{
                    flex: "1 1 0px", borderRadius: "4px", padding: "0.2rem 1rem"
                  , display: "block", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"
                  , fontSize: "1rem", color: "#fff", background: "rgb(51, 129, 252)", cursor: 'pointer'
                }} >
                {this.state.header_text}&nbsp;
              </div>
                <div style={{ flex: "0 0 auto", padding: "0 0 0 0.5rem" }}>
                  <Button key="extra" type="link" style={{ padding: "0", pointerEvents: "none", height: "2.5rem" }}>
                    <Icon type="plus" style={{ fontSize: "1.5rem", color: "rgba(255, 255, 255, 0)" }} />
                  </Button>
                </div>
              </div>

              <Typography.Title level={3} style={{ display: 'flex', alignItems: 'center', marginBottom: "0", marginTop: "1.5rem" }}>
                <Typography.Text>{lang.get('exchange/result/title')}</Typography.Text>
              </Typography.Title>
            </div>
            {/***** HEADER END **/}

          </div>
        </div>

        <div style={{ display: "table-row", height: "4%" }}></div>

        <div style={{ display: "table-row" }}> {/* content */}
          <div style={{ display: "table-cell" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div style={{ top: "0", bottom: "0", left: "0", right: "0", position: "absolute", overflow: "auto"}}>

                <div style={{ padding: "0.5rem" }}>

                  <div style={{
                      borderRadius: "0.6em", padding: "0.5rem 1rem", margin: "0 auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                      display: 'flex', justifyContent: "space-between", alignItems: "center",
                      fontSize: "1rem", fontWeight: "700",
                    }}>
                    <div><Typography.Text>{lang.get('exchange/result/token_from')}</Typography.Text></div>
                    {(this.state.result_type === 'token_to_money')
                      ? ( <div style={{ fontWeight: "700" }}>
                            {`${eos_wallet.formatCommaNumber(this.state.token_amount)} ${this.state.token_symbol}`}
                          </div>
                        ) : null
                    }
                    {(this.state.result_type === 'token_to_token')
                      ? ( <div style={{ fontWeight: "700" }}>
                            {`${eos_wallet.formatCommaNumber(this.state.from_token_amount)} ${this.state.from_token_symbol}`}
                          </div>
                        ) : null
                    }
                  </div>

                  <div style={{
                      borderRadius: "0.6em", padding: "0.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                      display: 'flex', justifyContent: "space-between", alignItems: "center",
                      fontSize: "1rem", fontWeight: "700",
                    }}>
                    <div><Typography.Text>{lang.get('exchange/result/commission_amount')}</Typography.Text></div>
                    {(this.state.result_type === 'token_to_money')
                      ? ( <div style={{ fontWeight: "700" }}>
                            {`${eos_wallet.formatCommaNumber(this.state.commission_amount)} ${this.state.token_symbol}`}
                          </div>
                        ) : null
                    }
                    {(this.state.result_type === 'token_to_token')
                      ? ( <div style={{ fontWeight: "700" }}>
                            {`${eos_wallet.formatCommaNumber(this.state.commission_amount)} ${this.state.from_token_symbol}`}
                          </div>
                        ) : null
                    }
                  </div>


                  {(this.state.result_type === 'token_to_money')
                    ? ( <div style={{
                          borderRadius: "0.6em", padding: "0.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                          display: 'flex', justifyContent: "space-between", alignItems: "center",
                          fontSize: "1rem", fontWeight: "700",
                        }}>
                          <div><Typography.Text>{lang.get('exchange/result/money_to')}</Typography.Text></div>
                          <div style={{ fontWeight: "700" }}>
                            {`${eos_wallet.formatCommaNumber(this.state.money_amount)} ${this.state.money_symbol}`}
                          </div>
                        </div>
                      ) : null
                  }
                  {(this.state.result_type === 'token_to_token')
                    ? ( <div style={{
                          borderRadius: "0.6em", padding: "0.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                          display: 'flex', justifyContent: "space-between", alignItems: "center",
                          fontSize: "1rem", fontWeight: "700",
                        }}>
                          <div><Typography.Text>{lang.get('exchange/result/token_to')}</Typography.Text></div>
                          <div style={{ fontWeight: "700" }}>
                            {`${eos_wallet.formatCommaNumber(this.state.to_token_amount)} ${this.state.to_token_symbol}`}
                          </div>
                        </div>
                      ) : null
                  }


                  <div style={{ borderRadius: "0.6em", padding: "0.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)" }}>
                    <div style={{ fontSize: "1rem", fontWeight: "700" }}>
                      <Typography.Text>{lang.get('exchange/result/exchanger/title')}</Typography.Text>
                    </div>
                    {(this.state.result_type === 'token_to_money')
                      ? ( <div style={{ padding: "0.5rem 0", fontSize: "0.8rem" }}>
                            <Row>
                              <Col xs={6}>{lang.get('exchange/result/exchanger/name')}</Col>
                              <Col xs={18}>{this.state.exchanger.name}</Col>
                            </Row>
                            <Row>
                              <Col xs={6}>{lang.get('exchange/result/exchanger/bank')}</Col>
                              <Col xs={18}>{(((this.state.exchanger.bankaccounts || {})[0] || {}).bank || {}).name}</Col>
                            </Row>
                            <Row>
                              <Col xs={6}>{lang.get('exchange/result/exchanger/account')}</Col>
                              <Col xs={18}>{((this.state.exchanger.bankaccounts || {})[0] || {}).bank_account}</Col>
                            </Row>
                          </div>
                        ) : null
                    }
                    {(this.state.result_type === 'token_to_token')
                      ? ( <div style={{ padding: "0.5rem 0", fontSize: "0.8rem" }}>
                            <Row>
                              <Col xs={6}>{lang.get('exchange/result/exchanger/name')}</Col>
                              <Col xs={18}>{this.state.exchanger.name}</Col>
                            </Row>
                          </div>
                        ) : null
                    }
                  </div>

                  {(this.state.result_type === 'token_to_money') && ((((this.state || {}).user_bankaccount || {}).bank || {}).name)
                    ? ( <div style={{ borderRadius: "0.6em", padding: "0.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)" }}>
                          <div style={{ padding: "0.5rem 0", fontSize: "0.8rem" }}>
                            <Row>
                              <Col xs={6}>{lang.get('exchange/result/user/name')}</Col>
                              <Col xs={18}>{((this.state || {}).user_bankaccount || {}).owner_name}</Col>
                            </Row>
                            <Row style={{ marginTop: "0.5rem"}}>
                              <Col xs={6}>{lang.get('exchange/result/user/account')}</Col>
                              <Col xs={18}>{`${(((this.state || {}).user_bankaccount || {}).bank || {}).name} ${((this.state || {}).user_bankaccount || {}).bank_account} `}</Col>
                            </Row>
                          </div>
                        </div>
                      ) : null
                  }

                </div>
              </div>
            </div>
          </div>
        </div>


        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell", padding: "0.5rem 1rem 1rem"}}>
            <Button size="large" type="danger" style={{ width: "100%", fontWeight: "700", color: "#fff", background: "rgb(255, 92, 92)" }} onClick={e => this.props.history.push('/exchange')}>
              {lang.get('exchange/result/btn_confirm')}
            </Button>
          </div>
        </div>
    </Loading>)
  }        
}

export default Result;