import React from "react";

import common from "../../../utils/common";
import eos_wallet from "../../../utils/eos_wallet";
import storage_util from "../../../utils/storage_util";
import lang from "../../../lang/lang";
import {
  Button,
  Typography,
  Icon,
  Input,
  Select,
  Drawer,
  Row,
  Col,
} from "antd";
import checkers from "../../../utils/checkers";
// import native_util from '../../../utils/native_util';
import server_api from "../../../utils/server_api";
import moment from "moment";
import "moment/locale/ko";

class Exchange2 extends React.Component {
  is_mounted_ = false;
  state = {
    list: [],
    isLoading: false,
  };

  constructor(props) {
    super(props);
    // console.log(this.props.location.state);
    // if (!this.props.location.state)
    //   return this.props.history.push('/');

    // const { value_transferred } = this.props.location.state;
    this.state = Object.assign(
      {},
      this.state,
      storage_util.loadFromLocal("push_options")
    );

    // console.log(storage_util.loadFromLocal('push_options'));
    // console.log(this.state);
  }
  setstate = (partial_state) =>
    this.is_mounted_ === true && this.setState(partial_state);

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    if ((await common.isLoggedIn()) === false) {
      return;
    }

    this._fetchRequestList();

    this.setstate({
      is_loading: false,
      header_text: (await eos_wallet.getHeaderTokenText()) || "",
    });
    // 알림 체크
  }
  componentWillUnmount() {
    this.is_mounted_ = false;
  }

  //   amount: "0"
  // createdAt: "2021-07-14T03:38:25.000Z"
  // id: 7
  // memo: ""
  // state: "REQUEST"
  // targetToken: "HIM"
  // token: "HIM"
  // updatedAt: "2021-07-14T03:38:25.000Z"
  // user_id: 12
  async _fetchRequestList() {
    try {
      const r = (await server_api.getUsersCoinExchanges()).items;

      let l = [];
      r.forEach((e) => {
        let ee = "Request";
        if (e.state === "SENDED") {
          ee = "Withdraw";
        } else if (e.state === "FAIL") {
          ee = "Fail";
        } else if (e.state === "DEPOSIT") {
          ee = "Deposit";
        }
        l.push(
          <div
            style={{
              padding: "0.5rem 1rem 0",
              display: "table",
              width: "100%",
              borderBottom: "1px solid gray",
            }}
          >
            <div
              style={{ display: "table-cell", width: "15%", fontSize: "11px" }}
            >
              {ee}
            </div>
            <div style={{ display: "table-cell", width: "10%" }}>
              {e.amount}
            </div>
            <div style={{ display: "table-cell", width: "55%" }}>
              Memo:{" "}
              {lang.getLang() === "en"
                ? e.memo
                    .replace("게임포인트를 변환", "Game point")
                    .replace("자원 부족", "error")
                    .replace("에서 입금", "to deposit")
                    .replace("으로 환전", "to exchange")
                    .replace("에서 입금", "to deposit")
                : e.memo}
              <br />{" "}
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "150px",
                  color: "blue",
                }}
              >
                {lang.getLang() === "en"
                  ? e.transaction
                      .replace("게임포인트를 변환", "Game point")
                      .replace("자원 부족", "error")
                      .replace("에서 입금", "to deposit")
                      .replace("으로 환전", "to exchange")
                      .replace("에서 입금", "to deposit")
                  : e.transaction}
              </div>
            </div>
            <div style={{ display: "table-cell", width: "20%" }}>
              {moment(e.updatedAt || 0).format("YY-MM-DD HH:mm:ss")}
            </div>
          </div>
        );
      });
      this.setState({ list: l });
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }
      return void 0;
    }
  }

  render() {
    if (common.get().token === void 0) {
      return null;
    }

    return (
      <div style={{ display: "table", width: "100%", height: "100%" }}>
        {/* Header */}
        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>
            {/** HEADER *****/}
            <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  onClick={(e) => this.props.history.push("/wallet")}
                  style={{
                    flex: "1 1 0px",
                    borderRadius: "4px",
                    padding: "0.2rem 1rem",
                    display: "block",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontSize: "1rem",
                    color: "#fff",
                    background: "rgb(51, 129, 252)",
                    cursor: "pointer",
                  }}
                >
                  {this.state.header_text}&nbsp;
                </div>
                <div style={{ flex: "0 0 auto", padding: "0 0 0 0.5rem" }}>
                  <Button
                    type="link"
                    style={{ padding: "0", height: "2.5rem" }}
                    onClick={(e) => this.props.history.push("/all")}
                  >
                    <Icon
                      type="close"
                      style={{ fontSize: "1.6rem", color: "#000" }}
                    />
                  </Button>
                </div>
              </div>

              <Typography.Title
                level={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0",
                  marginTop: "1.5rem",
                }}
              >
                <Typography.Text>
                  {lang.get("more/withdraw_history")}
                </Typography.Text>
              </Typography.Title>
            </div>
            {/***** HEADER END **/}
          </div>
        </div>

        <div style={{ display: "table-row" }}>
          {" "}
          {/* content */}
          <div style={{ display: "table-cell" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div
                style={{
                  top: "0",
                  bottom: "0",
                  left: "0",
                  right: "0",
                  position: "absolute",
                  overflow: "auto",
                }}
              >
                <div style={{ padding: "1rem 0.5rem" }}></div>
                <div
                  style={{
                    padding: "1rem 0.5rem",
                    overflowY: "scroll",
                    height: "85%",
                  }}
                >
                  {this.state.list}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Exchange2;
