
const is_mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);


export default {
  isInThesePaths (paths = []) {
    const cur_path_arr = (window.location.pathname).split('/');
    const current_path = `/${cur_path_arr[1]}`;
    return (paths.indexOf(current_path) >= 0);
  },

  notInThesePaths (paths = []) {
    const cur_path_arr = (window.location.pathname).split('/');
    const current_path = `/${cur_path_arr[1]}`;
    return (paths.indexOf(current_path) < 0);
  },

  
  is_mobile
}