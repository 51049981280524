import React, { PureComponent } from "react";

import "./nyobkeypad.less";
import eos_wallet from "../utils/eos_wallet";

//* basic layout templets
const keypad_layout_number = [
  [
    { value: "1", content: "1" },
    { value: "2", content: "2" },
    { value: "3", content: "3" },
  ],
  [
    { value: "4", content: "4" },
    { value: "5", content: "5" },
    { value: "6", content: "6" },
  ],
  [
    { value: "7", content: "7" },
    { value: "8", content: "8" },
    { value: "9", content: "9" },
  ],
  [
    { value: "del", content: "삭제" },
    { value: "0", content: "0" },
  ],
];

class NyobKeypad extends PureComponent {
  static defaultProps = {
    onClickKey: (value) =>
      console.log(
        `the onClickKey() is not connected. current key is ${value}.`
      ),
    cols: 6,
    data: keypad_layout_number, //* basic templet
    tableStyle: {},
    tbodyStyle: {},
    trStyle: {},
    tdStyle: {},
  };

  is_mounted_ = false;
  state = {
    cell_width: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      cell_width: 100 / this.props.cols,
    };
  }
  setstate = (partial_state) =>
    this.is_mounted_ === true && this.setState(partial_state);

  componentDidMount() {
    this.is_mounted_ = true;
  }
  componentWillUnmount() {
    this.is_mounted_ = false;
  }
  // async componentWillReceiveProps(nextProps) {
  //   const is_same_year  = this.props.year  === nextProps.year;
  // }
  // shouldComponentUpdate (nextProps, nextState) {
  //   return true;
  // }

  render() {
    return (
      <table
        style={Object.assign(
          { tableLayout: "fixed", width: "100%", userSelect: "none" },
          this.props.tableStyle
        )}
      >
        <tbody>{this._rRows()}</tbody>
      </table>
    );
  }

  _rRows() {
    const { data } = this.props;
    return data.map((datum, i) => (
      <tr key={i} style={Object.assign({}, this.props.trStyle)}>
        {this._rCols(datum)}
      </tr>
    ));
  }

  _rCols(data) {
    let style = Object.assign(
      {
        cursor: "pointer",
        width: `${this.state.cell_width}%`,
        textAlign: "center",
      },
      this.props.tdStyle
    );

    return data.map((datum, i) => {
      return (
        <td
          className="nyobkey"
          key={i}
          colSpan={datum.colspan}
          rowSpan={datum.rowspan}
          data-value={datum.value}
          style={Object.assign({}, style, datum.style)}
          onClick={this._hClickCell.bind(this)}
        >
          {datum.content}
        </td>
      );
    });
  }

  async _hClickCell(e) {
    this.props.onClickKey(e.target.dataset.value);

    const it = e.target;

    var circle = document.createElement("div");
    it.appendChild(circle);

    var d = Math.max(it.clientWidth, it.clientHeight);
    const offset = eos_wallet.cumulativeOffset(it);

    circle.style.width = circle.style.height = d / 2 + "px";
    circle.style.left = e.clientX - offset.left - d / 2 / 2 + "px";
    circle.style.top = e.clientY - offset.top - d / 2 / 2 + "px";

    circle.classList.add("ripple");

    setTimeout(() => {
      circle.remove();
    }, 3000);
  }
}

function formatNumber(value) {
  value += "";
  if (value.indexOf(",") >= 0)
    // formatted number
    return value;

  const list = value.split(".");
  const prefix = list[0].charAt(0) === "-" ? "-" : "";
  let num = prefix ? list[0].slice(1) : list[0];
  let result = "";
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
}

function unFormatNumber(value) {
  return value.replace(/,/g, "");
}

export {
  //* utility functions
  formatNumber,
  unFormatNumber,

  //* basic layout templets
  keypad_layout_number,
};
export default NyobKeypad;
