import React from 'react';
import { Button, Typography, Icon, Select, Modal } from 'antd';
import query_string from 'query-string';
//* view components
import GoogleMap from './snippets/GoogleMap';
//* utils
import common     from '../../../../utils/common';
import eos_wallet from '../../../../utils/eos_wallet';
import server_api from '../../../../utils/server_api';
//* resources
import lang from '../../../../lang/lang';




class LocationMap extends React.Component {
  state = {
    header_text: '',
    delay: 100,
    result: 'no result',
    data_exchangers: [],
    selected_exchanger: {},
    google_map_offset: 146,
  }

  constructor(props) { super(props);
    // console.log(this.props.location.state);
    // if (!this.props.location.state)
    //  return this.props.history.push('/');
    this.state = {
      ...this.state,      
      selected_exchanger: (props.location.state || {}).selected_exchanger || this.state.selected_exchanger,
    }


    this.ref_header_ = React.createRef();
    this.ref_input_  = React.createRef();
  }
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount () {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    if (await common.isLoggedIn() === false) {
      return;
    }

    const google_map_offset = this._getHeightOfGoogleMap();
    
    const data_exchangers = await this._fetchExchangers();

    this.setstate({
      is_loading: false,
      header_text: await eos_wallet.getHeaderTokenText() || '',
      data_exchangers,
      google_map_offset,
    });
  }
  componentWillUnmount () {
    this.is_mounted_ = false;
  }

  render () {
    if (common.get().token === void 0) {
      return null;
    }
    return (
      <div style={{ display: "table", tableLayout: "fixed", width: "100%", height: "100%" }}>
        {/* Header */}
        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>
            {/** HEADER *****/}
            <div id="location_map_header" ref={this.ref_header_} style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  onClick={e => this.props.history.push('/wallet')}
                  style={{
                        flex: "1 1 0px", borderRadius: "4px", padding: "0.2rem 1rem"
                      , display: "block", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"
                      , fontSize: "1rem", color: "#fff", background: "rgb(51, 129, 252)", cursor: 'pointer'
                    }} >
                    {this.state.header_text}&nbsp;
                </div>
                <div style={{ flex: "0 0 auto", padding: "0 0 0 0.5rem" }}>
                  <Button type="link" style={{ padding: "0", pointerEvents: "none", height: "2.5rem" }}>
                    <Icon type="plus" style={{ fontSize: "1.5rem", color: "rgba(255, 255, 255, 0)" }} />
                  </Button>
                </div>
              </div>
              <div>
                <Typography.Title level={3} style={{ display: 'flex', alignItems: 'center', marginBottom: "0", marginTop: "1.5rem" }}>
                  <Button size="small" type="link" onClick={e => this.props.history.push({
                    pathname: '/all/location',
                    search: window.location.search,
                    state: this.props.location.state
                  })} style={{ paddingLeft: "0" }}>
                    <Icon type="arrow-left" style={{ fontSize: "1rem", color: "RGB(75, 75, 75)" }} />
                  </Button>
                  <Typography.Text>{lang.get('all/location/map/title')}</Typography.Text>
                </Typography.Title>
              </div>              
            </div>
            
          </div>
        </div>

        <div style={{ display: "table-row", height: "2%" }} />

        <div style={{ display: "table-row", height: "0" }}> {/* content */}
          <div style={{ display: "table-cell" }}>
            <div id="location_map_input" ref={this.ref_input_} style={{ padding: "0 0.5rem 0.5rem" }}>
              {/* Input Location */}
              <div className="exchange-location-search"
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: "1px solid rgb(150, 150, 150)", borderRadius: "16px", padding: "0 0.5rem" }}>
                <div>
                  <Button type="link" style={{ padding: "0 0.2rem" }} onClick={e => console.log('clicked')}>
                    <Icon style={{ fontSize: "1rem" }} type="search" />
                  </Button>
                </div>
                <div style={{ flex: "1 1 0" }}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    onChange={(value) => this._hSearch(value)}
                    // onSelect={e => console.log('select')}
                    // onFocus={(e) => console.log('focus')}
                    // onBlur={(e) => console.log('blur')}
                    // onSearch={(e) => console.log('search')}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    defaultValue={(this.state.selected_exchanger || {}).name}
                  >
                    {this._rSearchList()}
                  </Select>
                </div>
                <div>
                  <Button type="link" style={{ padding: "0 0.2rem"}} onClick={e => this.setstate({ selected_exchanger: {}})}>
                    <Icon style={{ fontSize: "1.2em" }} type="environment" theme="filled" />
                  </Button>
                  <Button type="link" style={{ padding: "0 0.2rem"}} onClick={e => this.props.history.push({
                    pathname: '/all/location/inputQRLocation',
                    search: this.props.location.search,
                    state: {
                      ...this.props.location.state,
                      selected_exchanger: this.state.selected_exchanger
                    }
                  })}>
                    <Icon style={{ fontSize: "1.2em" }} type="scan" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div style={{ display: "table-row", height: "100%" }}> {/* content */}
          <div style={{ display: "table-cell" }}>        
            <div style={{ background: "grey", position: "ralative", borderTop: "1px solid RGB(192, 192, 192)" }}>
              <GoogleMap
                style={{ height: `calc(${98}% - ${this.state.google_map_offset}px)` }}
                data={this.state.data_exchangers}
                selected_exchanger={this.state.selected_exchanger}
                destination={{ lat: 37.542006, lng: 126.996336 }}
                onClickMarker={this._hSaveLocationAndRedirect.bind(this)} />         
            </div>     
         </div>  
        </div>
      </div>
    )
  }


  _rSearchList () {
    const { data_exchangers } = this.state;

    return data_exchangers.map((exchanger, i) => (<Select.Option key={i} value={exchanger.id}>{exchanger.name}</Select.Option>));
  }






  //* transactions
  async _hSearch (exchanger_id) {
    this.setstate({ is_loading: true });
    try {      
      const res_item = (await server_api.readExchangersSafe({
        where: {
          id: exchanger_id,
          is_archived: false,
        },
        include: [{
          association: 'bankaccounts',
          where: {
            is_archived: false,
          },
          required: true,
          // include: [{ association: 'bank' }]
        }]        
      })).items[0];

      if (!res_item) {
        Modal.error({
          title: lang.get('all/location/map/modal_case1/title'),
          content: lang.get('all/location/map/modal_case1/content'),
          okText: lang.get('modal/confirm_text'),
        })
        return this.setstate({
          is_loading: false,
        });
      }

      const selected_exchanger = {
        id: res_item.id,
        name: res_item.name,
        address: res_item.address,
        phone_numbers: res_item.phone_numbers,
        latitude: res_item.latitude,
        longitude: res_item.longitude,
      }

      this.setstate({
        is_loading: false,
        selected_exchanger
      })
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        return console.error(e);
      }

      return this.setstate({
        is_loading: false,
      });
    }
  }


  async _hSaveLocationAndRedirect (exchanger_id) {    
    try {
      this.setstate({ is_loading: true });
      await server_api.updateUser(
        common.get().id,
        {
          registered_exchanger_id: exchanger_id
        }        
      );


      const previous_path = query_string.parse(this.props.location.search).return
      if (previous_path) {
        this.props.history.push({
          pathname: previous_path
        });
      }
      else {
        this.props.history.push({
          pathname: '/all/location'
        });
      }      
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        return console.error(e);
      }

      Modal.error({
        title: lang.get('all/location/map/modal_case2/title'),
        content: lang.get('all/location/map/modal_case2/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      this.setstate({ is_loading: false });
    }
  }


  async _fetchExchangers () {
    try {
      const data_exchangers = (await server_api.readExchangersSafe({
        where: {          
          is_archived: false,
        },
        include: [{
          association: 'bankaccounts',
          where: {
            is_archived: false,
          },
          required: true,
          // include: [{ association: 'bank' }]
        }]
      })).items

      return data_exchangers;
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.log(e);
      }

      return [];
    }
  }


  //* utils  
  _getHeightOfGoogleMap () {
    if (this.is_mounted_ !== true) {
      return;
    }
    // const height_header_ge = document.getElementById('location_map_header').clientHeight;
    // const height_input_ge = document.getElementById('location_map_input').clientHeight;

    // more react way
    const height_header = this.ref_header_.current.clientHeight;
    const height_input  = this.ref_input_.current.clientHeight;

    return height_header + height_input;
  }
}
                
export default LocationMap;