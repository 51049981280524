//* modules
import React from "react";
import * as clipboard from "clipboard-polyfill/build/clipboard-polyfill.promise";
import { Button, Typography, Icon, Row, Col, Input, Drawer, Modal } from "antd";
//* view components
import InputOtpPc from "../../../snippets/InputOtpPc";
import Loading from "../../../snippets/Loading";
import { NumericKeyboard } from "numeric-keyboard/dist/numeric_keyboard.react";
import NumericKeyboardLayout from "../../../snippets/NumericKeyboardLayout";
//* utils
import checkers from "../../../utils/checkers";
import common from "../../../utils/common";
import eos_wallet from "../../../utils/eos_wallet";
import native_util from "../../../utils/native_util";
import server_api from "../../../utils/server_api";
import state_util from "../../../utils/state_util";
import storage_util from "../../../utils/storage_util";
//* resources
import lang from "../../../lang/lang";

class SelectWallet extends React.Component {
  is_mounted_ = false;
  state = {
    is_loading: false,
    is_submitting: false,
    header_text: "",
    token: "",
    error_msg: "",
    transfer_type: "BY_LOGIN_ID",
    login_id: "",
    eos_account: "",
    password: "",
    log: "",
    value_to_transfer: "",
    visible_input_otp_drawer: false,
    visible_input_otp_pc_modal: false,
  };

  constructor(props) {
    super(props);
    // console.log(this.props.state);
    // if (!this.props.location.state)
    //   return this.props.history.push('/');

    if (sessionStorage.getItem(state_util.ENUM.transfer_state) === null) {
      return this.props.history.push("/");
    }

    // this.state = {
    //   ...this.state,
    //   // value_to_transfer : (props.location.state || {}).value_to_transfer  || this.state.value_to_transfer ,
    //   // token             : (props.location.state || {}).token              || this.state.token             ,
    //   // log               : (props.location.state || {}).log                || this.state.log               ,
    //   // login_id          : (props.location.state || {}).login_id           || this.state.login_id          ,
    //   // eos_account       : (props.location.state || {}).eos_account        || this.state.eos_account       ,
    //   // transfer_type     : (props.location.state || {}).transfer_type      || this.state.transfer_type     ,
    // }
  }
  setstate = (partial_state) =>
    this.is_mounted_ === true && this.setState(partial_state);

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    if ((await common.isLoggedIn()) === false) {
      return;
    }

    const stored_state = storage_util.loadFromSession(
      state_util.ENUM.transfer_state,
      {},
      true
    );
    state_util.clearEveryStates();

    this.setstate({
      is_loading: false,
      header_text: await eos_wallet.getHeaderTokenText(),
      ...stored_state, // value_to_transfer, token
    });
  }

  componentWillUnmount() {
    this.is_mounted_ = false;
  }

  render() {
    if (common.get().token === void 0) {
      return null;
    }

    return (
      <Loading
        is_loading={this.state.is_loading || this.state.is_submitting}
        overall={true}
        style={{
          display: "table",
          tableLayout: "fixed",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>
            {/** HEADER *****/}
            <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  onClick={(e) => this.props.history.push("/wallet")}
                  style={{
                    flex: "1 1 0px",
                    borderRadius: "4px",
                    padding: "0.2rem 1rem",
                    display: "block",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontSize: "1rem",
                    color: "#fff",
                    background: "rgb(51, 129, 252)",
                    cursor: "pointer",
                  }}
                >
                  {this.state.header_text}&nbsp;
                </div>
                <div style={{ flex: "0 0 auto", padding: "0 0 0 0.5rem" }}>
                  <Button
                    key="extra"
                    type="link"
                    style={{ padding: "0", height: "2.5rem" }}
                    onClick={async (e) => {
                      if (native_util.hasNativeApi()) {
                        const data = await native_util.pushQRReaderView();
                        if (this.state.transfer_type === "BY_LOGIN_ID")
                          this.setState({ login_id: data });
                        else if (this.state.transfer_type === "BY_EOS_ACCOUNT")
                          this.setState({ eos_account: data });
                        return;
                      }

                      storage_util.saveToSession(
                        state_util.ENUM.transfer_state,
                        {
                          value_to_transfer: Number(
                            this.state.value_to_transfer
                          ).toFixed(2),
                          token: this.state.token,
                          log: this.state.log,
                          login_id: this.state.login_id,
                          eos_account: this.state.eos_account,
                          transfer_type: this.state.transfer_type,
                        }
                      );
                      this.props.history.push({
                        pathname: "/transfer/selectwallet/inputqrkey",
                        // state: { }
                      });
                    }}
                  >
                    <Icon
                      type="scan"
                      style={{ fontSize: "2rem", color: "RGB(75, 75, 75)" }}
                    />
                  </Button>
                </div>
              </div>

              <Typography.Title
                level={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0",
                  marginTop: "1.5rem",
                }}
              >
                <Button
                  size="small"
                  type="link"
                  onClick={(e) => this.props.history.push("/transfer")}
                  style={{ paddingLeft: "0" }}
                >
                  <Icon
                    type="left"
                    theme="outlined"
                    style={{ color: "RGB(75, 75, 75)" }}
                  />
                </Button>
                <Typography.Text>
                  {`${eos_wallet.formatCommaNumber(
                    this.state.value_to_transfer
                  )} ${this.state.token} ${lang.get(
                    "transfer/select_wallet/title"
                  )}`}
                </Typography.Text>
              </Typography.Title>
            </div>
            {/***** HEADER END **/}
          </div>
        </div>

        <div style={{ display: "table-row", height: `${4}%` }}></div>

        <div style={{ display: "table-row" }}>
          {" "}
          {/* content */}
          <div style={{ display: "table-cell" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div
                style={{
                  top: "0",
                  bottom: "0",
                  left: "0",
                  right: "0",
                  position: "absolute",
                  overflow: "auto",
                  padding: "0.5rem",
                }}
              >
                <div
                  style={{
                    borderRadius: "0.6em",
                    padding: "1rem",
                    margin: "1rem auto 0",
                    boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                  }}
                >
                  <div
                    style={{
                      color: "RGB(191, 191, 191)",
                      marginTop: "0.5rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ fontSize: "1rem", fontWeight: "700" }}>
                      <Typography.Text>
                        {lang.get("transfer/select_wallet/select_type/title")}
                      </Typography.Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "0.8rem",
                      }}
                    >
                      <span
                        style={
                          this.state.transfer_type === "BY_LOGIN_ID"
                            ? {
                                display: "inline-block",
                                verticalAlign: "middle",
                                cursor: "pointer",
                                userSelect: "none",
                                padding: "0.2rem 0.4rem",
                                borderRadius: "4px",
                                color: "#fff",
                                background: "rgb(51, 129, 252)",
                              }
                            : {
                                display: "inline-block",
                                verticalAlign: "middle",
                                cursor: "pointer",
                                userSelect: "none",
                                padding: "0.2rem 0.4rem",
                                borderRadius: "4px",
                                color: "rgb(85,89,99)",
                                background: "rgb(240,240,240)",
                              }
                        }
                        onClick={(e) =>
                          this.setstate({
                            transfer_type: "BY_LOGIN_ID",
                            eos_account: "",
                          })
                        }
                      >
                        {lang.get(
                          "transfer/select_wallet/select_type/option_1"
                        )}
                      </span>
                      <span style={{ width: "0.5rem" }} />
                      <span
                        style={
                          this.state.transfer_type === "BY_EOS_ACCOUNT"
                            ? {
                                display: "inline-block",
                                verticalAlign: "middle",
                                cursor: "pointer",
                                userSelect: "none",
                                padding: "0.2rem 0.4rem",
                                borderRadius: "4px",
                                color: "#fff",
                                background: "rgb(51, 129, 252)",
                              }
                            : {
                                display: "inline-block",
                                verticalAlign: "middle",
                                cursor: "pointer",
                                userSelect: "none",
                                padding: "0.2rem 0.4rem",
                                borderRadius: "4px",
                                color: "rgb(85,89,99)",
                                background: "rgb(240,240,240)",
                              }
                        }
                        onClick={(e) =>
                          this.setstate({
                            transfer_type: "BY_EOS_ACCOUNT",
                            login_id: "",
                          })
                        }
                      >
                        {lang.get(
                          "transfer/select_wallet/select_type/option_2"
                        )}
                      </span>
                    </div>
                  </div>

                  <div style={{ height: "1rem" }} />

                  {this.state.transfer_type === "BY_LOGIN_ID" ? (
                    <div>
                      <div>
                        <Icon type="user" />
                        <span
                          style={{
                            paddingLeft: "0.5rem",
                            color: "rgb(135, 135, 135)",
                          }}
                        >
                          ID
                        </span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: "1 1 0px", paddingLeft: "1.2rem" }}>
                          <input
                            value={this.state.login_id}
                            onChange={(e) =>
                              this.setstate({ login_id: e.target.value.trim() })
                            }
                            placeholder={lang.get(
                              "transfer/select_wallet/input_id/placeholder"
                            )}
                            style={{
                              width: "100%",
                              height: "100%",
                              border: "none",
                              borderBottom: "1px solid RGB(200, 200, 200)",
                              boxShadow: "none",
                              outline: "none",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            flex: "0 1 auto",
                            fontSize: "1rem",
                            fontWeight: "700",
                            textAlign: "right",
                            paddingLeft: "0.5rem",
                          }}
                        >
                          <Button
                            size="small"
                            style={{
                              background: "rgb(240, 240, 240)",
                              fontSize: "0.8rem",
                            }}
                            onClick={(e) => this._hPasteKey(e)}
                          >
                            {lang.get(
                              "transfer/select_wallet/input_id/btn_paste"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {this.state.transfer_type === "BY_EOS_ACCOUNT" ? (
                    <div>
                      <div>
                        <Icon type="user" />
                        <span
                          style={{
                            paddingLeft: "0.5rem",
                            color: "rgb(135, 135, 135)",
                          }}
                        >
                          {lang.get(
                            "transfer/select_wallet/input_eos_account/title"
                          )}
                        </span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: "1 1 0px", paddingLeft: "1.2rem" }}>
                          <input
                            value={this.state.eos_account}
                            onChange={(e) =>
                              this.setstate({
                                eos_account: e.target.value.trim(),
                              })
                            }
                            placeholder={lang.get(
                              "transfer/select_wallet/input_eos_account/placeholder"
                            )}
                            style={{
                              width: "100%",
                              height: "100%",
                              border: "none",
                              borderBottom: "1px solid RGB(200, 200, 200)",
                              boxShadow: "none",
                              outline: "none",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            flex: "0 1 auto",
                            fontSize: "1rem",
                            fontWeight: "700",
                            textAlign: "right",
                            paddingLeft: "0.5rem",
                          }}
                        >
                          <Button
                            size="small"
                            style={{
                              background: "rgb(240, 240, 240)",
                              fontSize: "0.8rem",
                            }}
                            onClick={(e) => this._hPasteKey(e)}
                          >
                            {lang.get(
                              "transfer/select_wallet/input_eos_account/btn_paste"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div style={{ marginTop: "1rem" }}>
                    <div>
                      <Icon type="profile" />
                      <span
                        style={{
                          paddingLeft: "0.5rem",
                          color: "rgb(135, 135, 135)",
                        }}
                      >
                        {lang.get("transfer/select_wallet/input_memo/title")}
                      </span>
                    </div>
                    <div style={{ paddingLeft: "1.2rem", marginTop: "0.4rem" }}>
                      <Input.TextArea
                        maxLength="255"
                        autosize={{ minRows: 2, maxRows: 4 }}
                        value={this.state.log}
                        onChange={(e) => this.setstate({ log: e.target.value })}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </div>
                </div>

                {/* BY_LOGIN_ID */}
                {this.state.transfer_type === "BY_LOGIN_ID" ? (
                  <div
                    style={{
                      borderRadius: "0.6em",
                      padding: "1rem",
                      margin: "1rem auto 0",
                      boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    }}
                  >
                    <div>
                      <Icon type="file-done" />
                      <span
                        style={{
                          paddingLeft: "0.5rem",
                          color: "rgb(135, 135, 135)",
                        }}
                      >
                        {lang.get(
                          "transfer/select_wallet/recent_login_id_list/title"
                        )}
                      </span>
                    </div>
                    {this.readLocalLoginIdList()["recent_login_ids"].length >
                    0 ? (
                      <div
                        style={{ paddingLeft: "1.2rem", marginTop: "0.2rem" }}
                      >
                        {this._rLocalLoginIDList("recent_login_ids")}
                      </div>
                    ) : (
                      <div
                        style={{
                          marginTop: "0.2rem",
                          textAlign: "center",
                          color: "RGB(160, 160, 160)",
                        }}
                      >
                        {lang.get(
                          "transfer/select_wallet/recent_login_id_list/no_item_text"
                        )}
                      </div>
                    )}
                  </div>
                ) : null}

                {this.state.transfer_type === "BY_LOGIN_ID" ? (
                  <div
                    style={{
                      borderRadius: "0.6em",
                      padding: "1rem",
                      margin: "1rem auto 0",
                      boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    }}
                  >
                    <div>
                      <Icon type="file-done" />
                      <span
                        style={{
                          paddingLeft: "0.5rem",
                          color: "rgb(135, 135, 135)",
                        }}
                      >
                        {lang.get(
                          "transfer/select_wallet/favorite_login_id_list/title"
                        )}
                      </span>
                    </div>
                    {this.readLocalLoginIdList()["favorite_login_ids"].length >
                    0 ? (
                      <div
                        style={{ paddingLeft: "1.2rem", marginTop: "0.2rem" }}
                      >
                        {this._rLocalLoginIDList("favorite_login_ids")}
                      </div>
                    ) : (
                      <div
                        style={{
                          marginTop: "0.2rem",
                          textAlign: "center",
                          color: "RGB(160, 160, 160)",
                        }}
                      >
                        {lang.get(
                          "transfer/select_wallet/favorite_login_id_list/no_item_text"
                        )}
                      </div>
                    )}
                  </div>
                ) : null}

                {/* BY_EOS_ACCOUNT */}
                {this.state.transfer_type === "BY_EOS_ACCOUNT" ? (
                  <div
                    style={{
                      borderRadius: "0.6em",
                      padding: "1rem",
                      margin: "1rem auto 0",
                      boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    }}
                  >
                    <div>
                      <Icon type="file-done" />
                      <span
                        style={{
                          paddingLeft: "0.5rem",
                          color: "rgb(135, 135, 135)",
                        }}
                      >
                        {lang.get(
                          "transfer/select_wallet/recent_eos_account_list/title"
                        )}
                      </span>
                    </div>
                    {this.readLocalEOSAccountList()["recent_eos_accounts"]
                      .length > 0 ? (
                      <div
                        style={{ paddingLeft: "1.2rem", marginTop: "0.2rem" }}
                      >
                        {this._rLocalEOSAccountList("recent_eos_accounts")}
                      </div>
                    ) : (
                      <div
                        style={{
                          marginTop: "0.2rem",
                          textAlign: "center",
                          color: "RGB(160, 160, 160)",
                        }}
                      >
                        {lang.get(
                          "transfer/select_wallet/recent_eos_account_list/no_item_text"
                        )}
                      </div>
                    )}
                  </div>
                ) : null}

                {this.state.transfer_type === "BY_EOS_ACCOUNT" ? (
                  <div
                    style={{
                      borderRadius: "0.6em",
                      padding: "1rem",
                      margin: "1rem auto 0",
                      boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    }}
                  >
                    <div>
                      <Icon type="file-done" />
                      <span
                        style={{
                          paddingLeft: "0.5rem",
                          color: "rgb(135, 135, 135)",
                        }}
                      >
                        {lang.get(
                          "transfer/select_wallet/favorite_eos_account_list/title"
                        )}
                      </span>
                    </div>
                    {this.readLocalEOSAccountList()["favorite_eos_accounts"]
                      .length > 0 ? (
                      <div
                        style={{ paddingLeft: "1.2rem", marginTop: "0.2rem" }}
                      >
                        {this._rLocalEOSAccountList("favorite_eos_accounts")}
                      </div>
                    ) : (
                      <div
                        style={{
                          marginTop: "0.2rem",
                          textAlign: "center",
                          color: "RGB(160, 160, 160)",
                        }}
                      >
                        {lang.get(
                          "transfer/select_wallet/favorite_eos_account_list/no_item_text"
                        )}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* Buttons */}
        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>
            <div style={{ padding: "0.5rem 1rem 1rem" }}>
              {this.state.error_msg.length > 0 ? (
                <div
                  style={{
                    padding: "0.5rem 0.5rem",
                    fontSize: "0.9rem",
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  {this.state.error_msg}
                </div>
              ) : null}
              <div>
                <Row gutter={16}>
                  <Col span={12}>
                    <Button
                      size="large"
                      style={{ width: "100%" }}
                      onClick={(e) => this.props.history.push("/transfer")}
                    >
                      {lang.get("transfer/select_wallet/btn_cancel")}
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      size="large"
                      type="danger"
                      style={{
                        width: "100%",
                        fontWeight: "700",
                        color: "#fff",
                        background: "rgb(255, 92, 92)",
                      }}
                      onClick={this._hPreSubmit.bind(this)}
                    >
                      {lang.get("transfer/select_wallet/btn_submit")}
                    </Button>
                  </Col>
                </Row>
              </div>

              <Drawer
                title={
                  <div style={{ fontWeight: "700" }}>
                    <Typography.Text>
                      {lang.get("drawer/input_otp/title")}
                    </Typography.Text>
                  </div>
                }
                placement="bottom"
                closable={true}
                onClose={(e) =>
                  this.setstate({
                    visible_input_otp_drawer: false,
                    password: "",
                    error_msg: "",
                  })
                }
                visible={this.state.visible_input_otp_drawer}
                height="bottom"
              >
                <div style={{ height: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "1rem 1rem 1.5rem",
                    }}
                  >
                    {this._rPassword()}
                  </div>
                  <NumericKeyboard
                    layout={NumericKeyboardLayout.OTP}
                    onPress={this._hNumericKeyboard.bind(this)}
                    entertext={lang.get(
                      "transfer/select_wallet/btn_otp_submit"
                    )}
                  />
                </div>
              </Drawer>
              <Modal
                title={
                  <div style={{ fontWeight: "700" }}>
                    <Typography.Text>
                      {lang.get("drawer/input_otp/title")}
                    </Typography.Text>
                  </div>
                }
                footer={null}
                visible={this.state.visible_input_otp_pc_modal}
                onClose={(e) => this.setstate({ password: "" })}
                onCancel={(e) =>
                  this.setstate({ visible_input_otp_pc_modal: false })
                }
                width="320px"
                centered
              >
                <InputOtpPc
                  password={this.state.password}
                  onChange={(v) => this.setstate({ password: v })}
                  button_text={lang.get(
                    "transfer/select_wallet/btn_otp_submit"
                  )}
                  onSubmit={this._hSubmit.bind(this)}
                />
              </Modal>
            </div>
          </div>
        </div>
      </Loading>
    );
  }

  _rLocalLoginIDList(storage_key) {
    let list = this.readLocalLoginIdList()[storage_key];
    let { favorite_login_ids } = this.readLocalLoginIdList();

    const list_map = list.map((key, i) => {
      if (typeof key !== "string") return null;

      const is_checked = favorite_login_ids.indexOf(key) >= 0;

      return (
        <div
          key={i}
          style={{
            width: "100%",
            textAlign: "left",
            cursor: "pointer",
            padding: "0.2rem 0.5rem",
          }}
        >
          <span onClick={(e) => this.setstate({ login_id: key })}>{key}</span>
          <span style={{ paddingLeft: "0.5rem" }}>
            <input
              type="checkbox"
              id={`recentkey_${key}`}
              name={`recentkey_${key}`}
              checked={is_checked}
              hidden
              onChange={(e) => this.switchFavoriteLoginId(key)}
            />
            <label htmlFor={`recentkey_${key}`}>
              {is_checked ? (
                <Icon
                  type="star"
                  theme="filled"
                  style={{ color: "rgb(255, 191, 0)" }}
                />
              ) : (
                <Icon type="star" theme="outlined" />
              )}
            </label>
          </span>
        </div>
      );
    });

    return list_map;
  }

  _rLocalEOSAccountList(storage_key) {
    let list = this.readLocalEOSAccountList()[storage_key];
    let { favorite_eos_accounts } = this.readLocalEOSAccountList();

    const list_map = list.map((key, i) => {
      if (typeof key !== "string") return null;

      const is_checked = favorite_eos_accounts.indexOf(key) >= 0;

      return (
        <div
          key={i}
          style={{
            width: "100%",
            textAlign: "left",
            cursor: "pointer",
            padding: "0.2rem 0.5rem",
          }}
        >
          <span onClick={(e) => this.setstate({ eos_account: key })}>
            {key}
          </span>
          <span style={{ paddingLeft: "0.5rem" }}>
            <input
              type="checkbox"
              id={`recentkey_${key}`}
              name={`recentkey_${key}`}
              checked={is_checked}
              hidden
              onChange={(e) => this.switchFavoriteEOSAccount(key)}
            />
            <label htmlFor={`recentkey_${key}`}>
              {is_checked ? (
                <Icon
                  type="star"
                  theme="filled"
                  style={{ color: "rgb(255, 191, 0)" }}
                />
              ) : (
                <Icon type="star" theme="outlined" />
              )}
            </label>
          </span>
        </div>
      );
    });

    return list_map;
  }

  _rPassword() {
    let pw_box = [];
    let { password } = this.state;
    const pw_length = (password || "").length;

    // const svg_circle =(
    //   <svg height="100" width="100">
    //     <circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" />
    //   </svg>)

    for (let i = 0; i < 6; ++i) {
      if (i < pw_length)
        pw_box.push(
          <Col key={i} span={4} style={{ textAlign: "center" }}>
            ●
          </Col>
        );
      else
        pw_box.push(
          <Col
            key={i}
            span={4}
            style={{ textAlign: "center", color: "RGBA(192, 192, 192, 0.5)" }}
          >
            ●
          </Col>
        );
    }

    return <Row style={{ width: "14rem", borderRadius: "4px" }}>{pw_box}</Row>;
  }

  async _hPasteKey(e) {
    switch (this.state.transfer_type) {
      case "BY_LOGIN_ID":
        if (native_util.hasNativeApi())
          this.setState({ login_id: await native_util.getClipboardText() });
        else
          clipboard
            .readText()
            .then((v) => this.setstate({ login_id: v }), console.error);
        break;
      case "BY_EOS_ACCOUNT":
        if (native_util.hasNativeApi())
          this.setState({ eos_account: await native_util.getClipboardText() });
        else
          clipboard
            .readText()
            .then((v) => this.setstate({ eos_account: v }), console.error);
        break;
      default:
        if (process.env.NODE_ENV === "development")
          console.error("exception case");
    }
  }

  async _hPreSubmit() {
    if (this.state.is_submitting === true) {
      return;
    }
    switch (this.state.transfer_type) {
      case "BY_LOGIN_ID":
        await this._hPreSubmitLoginID();
        break;
      case "BY_EOS_ACCOUNT":
        await this._hPreSubmitEOSAccount();
        break;
      default:
        if (process.env.NODE_ENV === "development")
          console.error("exception case");
    }
  }

  async _hPreSubmitLoginID() {
    if (this.state.login_id === common.get().login_id) {
      Modal.error({
        title: lang.get("transfer/select_wallet/modal_case1/title"),
        content: lang.get("transfer/select_wallet/modal_case1/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setState({ is_submitting: false });
    }

    if (this.state.is_submitting === true) {
      return;
    }
    this.setState({ is_submitting: true });
    if (eos_wallet.validate.checkBlank(this.state.login_id)) {
      Modal.error({
        title: lang.get("transfer/select_wallet/modal_case2/title"),
        content: lang.get("transfer/select_wallet/modal_case2/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setState({ is_submitting: false });
    }

    if (checkers.is_mobile === true) {
      return this.setstate({
        visible_input_otp_drawer: true,
        is_submitting: false,
      });
    } else {
      return this.setstate({
        visible_input_otp_pc_modal: true,
        is_submitting: false,
      });
    }
  }

  async _hPreSubmitEOSAccount() {
    this.setState({ is_submitting: true });

    if (eos_wallet.validate.checkBlank(this.state.eos_account)) {
      Modal.error({
        title: lang.get("transfer/select_wallet/modal_case3/title"),
        content: lang.get("transfer/select_wallet/modal_case3/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setState({ is_submitting: false });
    }

    if (checkers.is_mobile === true) {
      return this.setstate({
        visible_input_otp_drawer: true,
        is_submitting: false,
      });
    } else {
      return this.setstate({
        visible_input_otp_pc_modal: true,
        is_submitting: false,
      });
    }
  }

  async _hSubmit() {
    if (this.state.is_submitting === true || this.state.is_loading === true) {
      return;
    }

    this.setstate({
      is_submitting: true,
      visible_input_otp_drawer: false,
      visible_input_otp_pc_modal: false,
    });

    switch (this.state.transfer_type) {
      case "BY_LOGIN_ID":
        await this._hSubmitLoginID();
        break;
      case "BY_EOS_ACCOUNT":
        await this._hSubmitEOSAccount();
        break;
      default:
        if (process.env.NODE_ENV === "development")
          console.error("exception case");
    }
  }

  async _hSubmitLoginID() {
    const { login_id, value_to_transfer, token, log, password } = this.state;

    let res_login_id = {};
    try {
      res_login_id = (
        await server_api.readUsersSafe({
          where: {
            login_id: this.state.login_id,
          },
        })
      ).items[0];

      if (!res_login_id) {
        Modal.error({
          title: lang.get("transfer/select_wallet/modal_case5/title"),
          content: lang.get("transfer/select_wallet/modal_case5/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });
        return this.setState({
          is_submitting: false,
          password: "",
        });
      }
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }
      Modal.error({
        title: lang.get("transfer/select_wallet/modal_case6/title"),
        content: lang.get("transfer/select_wallet/modal_case6/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setState({
        is_submitting: false,
        password: "",
      });
    }

    try {
      const params = {
        receiver_type: this.state.transfer_type,
        to_user_id: res_login_id.id,
        amount: Number(value_to_transfer).toFixed(2),
        token_symbol: token,
        log,
        otp_code: password,
      };
      console.log(params);
      const res_transfer = await server_api.createUsersTransfer(params);

      if (!res_transfer) {
        Modal.error({
          title: lang.get("transfer/select_wallet/modal_case7/title"),
          content: lang.get("transfer/select_wallet/modal_case7/content"),
          centered: true,
        });

        return this.setstate({
          is_submitting: false,
          password: "",
        });
      }

      //* success process
      this.saveRecentLoginIdList(login_id);

      await eos_wallet.setHeaderTokenText();

      storage_util.saveToSession(state_util.ENUM.transfer_state, {
        value_transferred: Number(value_to_transfer).toFixed(2),
        token: token,
        receiver_login_id: login_id,
        transfer_type: this.state.transfer_type,
        its_result: true,
      });

      // await new Promise(resolve => setTimeout(resolve, 100)); // block bug of a click event
      return this.props.history.push({
        pathname: "/transfer/result",
      });
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      if (
        (e.result || {}).message === "opt-code not matched. please try again."
      ) {
        Modal.error({
          title: lang.get("transfer/select_wallet/modal_case4/title"),
          content: lang.get("transfer/select_wallet/modal_case4/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });

        return this.setstate({
          is_submitting: false,
          password: "",
        });
      }

      if (((e.result || {}).message || "") === "from and to are same") {
        Modal.error({
          title: lang.get("transfer/select_wallet/modal_case12/title"),
          content: lang.get("transfer/select_wallet/modal_case12/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });
        return this.setstate({
          is_submitting: false,
          password: "",
        });
      }

      if (
        ((e.result || {}).message || "").indexOf(
          "not enough tradable balance for"
        ) > -1
      ) {
        Modal.error({
          title: lang.get("transfer/select_wallet/modal_case13/title"),
          content: lang.get("transfer/select_wallet/modal_case13/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });
        return this.setstate({
          is_submitting: false,
          password: "",
        });
      }

      Modal.error({
        title: lang.get("transfer/select_wallet/modal_case8/title"),
        content: lang.get("transfer/select_wallet/modal_case8/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setstate({
        is_submitting: false,
        password: "",
      });
    }
  }

  async _hSubmitEOSAccount() {
    const { eos_account, value_to_transfer, token, log, password } = this.state;

    try {
      const params = {
        receiver_type: this.state.transfer_type,
        to_eos_account: eos_account,
        amount: value_to_transfer,
        token_symbol: token,
        log,
        otp_code: password,
      };
      const res_transfer = await server_api.createUsersTransfer(params);

      if (!res_transfer) {
        Modal.error({
          title: lang.get("transfer/select_wallet/modal_case9/title"),
          content: lang.get("transfer/select_wallet/modal_case9/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });

        return this.setstate({
          is_submitting: false,
          password: "",
        });
      }

      //* success process
      this.saveRecentEOSAccountList(eos_account);

      await eos_wallet.setHeaderTokenText();

      storage_util.saveToSession(state_util.ENUM.transfer_state, {
        value_transferred: value_to_transfer,
        token: token,
        receiver_eos_account: eos_account,
        transfer_type: this.state.transfer_type,
        its_result: true,
      });

      // await new Promise(resolve => setTimeout(resolve, 100)); // block bug of a click event
      return this.props.history.push({
        pathname: "/transfer/result",
      });
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      if (
        (e.result || {}).message === "opt-code not matched. please try again."
      ) {
        Modal.error({
          title: lang.get("transfer/select_wallet/modal_case4/title"),
          content: lang.get("transfer/select_wallet/modal_case4/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });

        return this.setstate({
          is_submitting: false,
          password: "",
        });
      }

      if (
        ((e.result || {}).details || [])[0] ===
        "assertion failure with message: to account does not exist"
      ) {
        Modal.error({
          title: lang.get("transfer/select_wallet/modal_case10/title"),
          content: lang.get("transfer/select_wallet/modal_case10/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });
        return this.setstate({
          is_submitting: false,
          password: "",
        });
      }

      if (((e.result || {}).message || "") === "from and to are same") {
        Modal.error({
          title: lang.get("transfer/select_wallet/modal_case12/title"),
          content: lang.get("transfer/select_wallet/modal_case12/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });
        return this.setstate({
          is_submitting: false,
          password: "",
        });
      }

      if (
        ((e.result || {}).message || "").indexOf(
          "not enough tradable balance for"
        ) > -1
      ) {
        Modal.error({
          title: lang.get("transfer/select_wallet/modal_case13/title"),
          content: lang.get("transfer/select_wallet/modal_case13/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });
        return this.setstate({
          is_submitting: false,
          password: "",
        });
      }

      Modal.error({
        title: lang.get("transfer/select_wallet/modal_case11/title"),
        content: lang.get("transfer/select_wallet/modal_case11/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setstate({
        is_submitting: false,
        password: "",
      });
    }
  }

  async _hNumericKeyboard(key) {
    let { password } = this.state;

    if (!password) password = "";

    switch (key) {
      case "esc":
        return this.setstate({
          error_msg: "",
          password: "",
          visible_input_otp_drawer: false,
        });
      case "del":
        return this.setstate({
          error_msg: "",
          password: password.slice(0, -1),
        });
      case "enter":
        return await this._hSubmit();
      default:
        if (password.length < 6)
          return this.setstate({
            error_msg: "",
            password: password.concat(key),
          });
    }
  }

  /* utils */
  //* login_id
  readLocalLoginIdList() {
    let recent_login_ids = [];
    let favorite_login_ids = [];

    const recent_keys_str = localStorage.getItem("recent_login_ids");
    const favorite_keys_str = localStorage.getItem("favorite_login_ids");

    if (recent_keys_str) recent_login_ids = JSON.parse(recent_keys_str);

    if (favorite_keys_str) favorite_login_ids = JSON.parse(favorite_keys_str);

    if (Array.isArray(recent_login_ids) === false) {
      localStorage.removeItem("recent_login_ids");
      recent_login_ids = [];
    }

    if (Array.isArray(favorite_login_ids) === false) {
      localStorage.removeItem("favorite_login_ids");
      favorite_login_ids = [];
    }
    if (typeof recent_login_ids[0] !== "string") {
      localStorage.removeItem("recent_login_ids");
      recent_login_ids = [];
    }

    if (typeof favorite_login_ids[0] !== "string") {
      localStorage.removeItem("favorite_login_ids");
      favorite_login_ids = [];
    }

    return { recent_login_ids, favorite_login_ids };
  }

  saveRecentLoginIdList(login_id) {
    const max_length = 5;
    let { recent_login_ids } = this.readLocalLoginIdList();

    if (!Array.isArray(recent_login_ids)) recent_login_ids = [];

    const selected_login_id_idx = recent_login_ids.indexOf(login_id);
    if (selected_login_id_idx >= 0) {
      recent_login_ids.splice(selected_login_id_idx, 1);
    }
    recent_login_ids.unshift(login_id);

    const current_length = recent_login_ids.length;
    if (current_length > max_length) {
      recent_login_ids.splice(max_length, current_length);
    }

    localStorage.setItem("recent_login_ids", JSON.stringify(recent_login_ids));
  }

  switchFavoriteLoginId(login_id) {
    const max_length = 100;
    let { favorite_login_ids } = this.readLocalLoginIdList();

    if (!Array.isArray(favorite_login_ids)) favorite_login_ids = [];

    const selected_login_id_idx = favorite_login_ids.indexOf(login_id);
    if (selected_login_id_idx >= 0) {
      favorite_login_ids.splice(selected_login_id_idx, 1);
    } else {
      favorite_login_ids.unshift(login_id);
    }

    const current_length = favorite_login_ids.length;
    if (current_length > max_length) {
      favorite_login_ids.splice(max_length, current_length);
    }

    localStorage.setItem(
      "favorite_login_ids",
      JSON.stringify(favorite_login_ids)
    );

    this.forceUpdate();
  }

  //* eos_account
  readLocalEOSAccountList() {
    let recent_eos_accounts = [];
    let favorite_eos_accounts = [];

    const recent_keys_str = localStorage.getItem("recent_eos_accounts");
    const favorite_keys_str = localStorage.getItem("favorite_eos_accounts");

    if (recent_keys_str) recent_eos_accounts = JSON.parse(recent_keys_str);

    if (favorite_keys_str)
      favorite_eos_accounts = JSON.parse(favorite_keys_str);

    if (Array.isArray(recent_eos_accounts) === false) {
      localStorage.removeItem("recent_eos_accounts");
      recent_eos_accounts = [];
    }

    if (Array.isArray(favorite_eos_accounts) === false) {
      localStorage.removeItem("favorite_eos_accounts");
      favorite_eos_accounts = [];
    }
    if (typeof recent_eos_accounts[0] !== "string") {
      localStorage.removeItem("recent_eos_accounts");
      recent_eos_accounts = [];
    }

    if (typeof favorite_eos_accounts[0] !== "string") {
      localStorage.removeItem("favorite_eos_accounts");
      favorite_eos_accounts = [];
    }

    return { recent_eos_accounts, favorite_eos_accounts };
  }

  saveRecentEOSAccountList(eos_account) {
    const max_length = 5;
    let { recent_eos_accounts } = this.readLocalEOSAccountList();

    if (!Array.isArray(recent_eos_accounts)) recent_eos_accounts = [];

    const selected_eos_account_idx = recent_eos_accounts.indexOf(eos_account);
    if (selected_eos_account_idx >= 0) {
      recent_eos_accounts.splice(selected_eos_account_idx, 1);
    }
    recent_eos_accounts.unshift(eos_account);

    const current_length = recent_eos_accounts.length;
    if (current_length > max_length) {
      recent_eos_accounts.splice(max_length, current_length);
    }

    localStorage.setItem(
      "recent_eos_accounts",
      JSON.stringify(recent_eos_accounts)
    );
  }

  switchFavoriteEOSAccount(eos_account) {
    const max_length = 100;
    let { favorite_eos_accounts } = this.readLocalEOSAccountList();

    if (!Array.isArray(favorite_eos_accounts)) favorite_eos_accounts = [];

    const selected_eos_account_idx = favorite_eos_accounts.indexOf(eos_account);
    if (selected_eos_account_idx >= 0) {
      favorite_eos_accounts.splice(selected_eos_account_idx, 1);
    } else {
      favorite_eos_accounts.unshift(eos_account);
    }

    const current_length = favorite_eos_accounts.length;
    if (current_length > max_length) {
      favorite_eos_accounts.splice(max_length, current_length);
    }

    localStorage.setItem(
      "favorite_eos_accounts",
      JSON.stringify(favorite_eos_accounts)
    );

    this.forceUpdate();
  }
}

export default SelectWallet;
