import React from 'react';
import * as clipboard from "clipboard-polyfill/build/clipboard-polyfill.promise"
import { Button, Typography, Icon, Input, Modal, Drawer, Row, Col } from 'antd';
import { NumericKeyboard } from 'numeric-keyboard/dist/numeric_keyboard.react';
//* view components
import Loading from '../../../snippets/Loading';
import NumericKeyboardLayout from '../../../snippets/NumericKeyboardLayout';
// import InputOtpPc from '../../../snippets/InputOtpPc';
//* utils
import checkers    from '../../../utils/checkers'
import common      from '../../../utils/common';
import eos_wallet  from '../../../utils/eos_wallet';
import native_util from '../../../utils/native_util';
import server_api  from '../../../utils/server_api';
//* resources
import lang from '../../../lang/lang';




class Verification extends React.Component {
  is_mounted_ = false;
  state = {
    is_loading: false,
    google_otp_state: 1,
    google_otp_key: '-',
    otp_code: '',
    visible_input_otp_drawer: false,
    visible_input_otp_pc_modal: false,
  }

  constructor(props) { super(props);
    // console.log(this.props.location.state);
    // if (!this.props.location.state)
    //   return this.props.history.push('/');

    // const { value_transferred } = this.props.location.state;    
    this.state = {
      ...this.state,
    }
  }
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;    
    this.setstate({ is_loading: true });
    if (await common.isLoggedIn() === false) {
      return;
    }

    const google_otp_key = await this._fetchGoogleOTPKey();

    this.setstate({
      is_loading: false,      
      header_text: await eos_wallet.getHeaderTokenText() || '',
      google_otp_key
    });
  }
  componentWillUnmount() { this.is_mounted_ = false; }

  render () {
    if (common.get().token === void 0) {
      return null;
    }

    return (
      <Loading is_loading={this.state.is_loading} overall={true} style={{ display: "table", tableLayout: "fixed", width: "100%", height: "100%" }}>

        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>

            {/** HEADER *****/}
            <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  onClick={e => this.props.history.push('/wallet')}
                  style={{
                      flex: "1 1 0px", borderRadius: "4px", padding: "0.2rem 1rem"
                    , display: "block", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"
                    , fontSize: "1rem", color: "#fff", background: "rgb(51, 129, 252)", cursor: 'pointer'
                  }} >
                  {this.state.header_text}&nbsp;
                </div>  
                <div style={{ flex: "0 0 auto", padding: "0 0 0 0.5rem" }}>
                  <Button type="link" style={{ padding: "0", height: "2.5rem" }} onClick={e => this.props.history.push('/all')}>
                    <Icon type="close" style={{ fontSize: "1.6rem", color: "#000" }} />
                  </Button>
                </div>
              </div>

              <Typography.Title level={3} style={{ display: 'flex', alignItems: 'center', marginBottom: "0", marginTop: "1.5rem" }}>
                <Typography.Text>{lang.get('all/verification/title')}</Typography.Text>
              </Typography.Title>

              <div style={{ marginTop: '0.5rem', color: 'RGB(156, 0, 0)', fontWeight: "700" }}>
                {lang.get('all/verification/info_message')}
              </div>
            </div>
            {/***** HEADER END **/}

          </div>
        </div>

        <div style={{ display: "table-row", height: "4%" }} />

        <div style={{ display: "table-row" }}> {/* content */}
          <div style={{ display: "table-cell" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div style={{ top: "0", bottom: "0", left: "0", right: "0", position: "absolute",
                overflow: "auto"}}>
                {(this.state.google_otp_state === 1)
                  ? ( <GoogleOTP
                        valueGoogleOtpKey={this.state.google_otp_key}
                        onChangeGoogleOTPKey={e => this.setstate({ google_otp_key: e.target.value })}
                        onClickCopy={e => {
                          if (native_util.hasNativeApi())
                            native_util.setClipboardText(this.state.google_otp_key);
                          else
                            clipboard.writeText(this.state.google_otp_key);
                        }}
                        valueCertificateKey={this.state.otp_code}
                        onChangeCertificationKey={e => this.setstate({ otp_code: e.target.value })}
                        onClickSubmit={this._submit.bind(this)}
                        showMobileOTP={e => this.setstate({ visible_input_otp_drawer: true })}
                        showPCOTP={e => this.setstate({ visible_input_otp_pc_modal: true })}
                      />)
                  : ( <NormalState onClickGoogleOTP={e => this.setstate({ title: lang.get('all/verifiation/title'), google_otp_state: 1 })} />)
                }
                
                <div>
                  <Drawer
                    title={<div style={{ fontWeight: "700" }}><Typography.Text>{lang.get('drawer/input_otp/title')}</Typography.Text></div>}
                    placement="bottom"
                    closable={true}
                    onClose={e => this.setstate({
                      visible_input_otp_drawer: false,
                      password: '',
                      error_msg: ''
                    })}
                    visible={this.state.visible_input_otp_drawer}
                    height="bottom"
                  >
                    <div style={{ height: "100%"}}>
                      <div style={{ display: "flex", justifyContent: "center", padding: "1rem 1rem 1.5rem" }}>
                        {this._rPassword()}
                      </div>
                      <NumericKeyboard layout={NumericKeyboardLayout.OTP} onPress={this._hOTPKeyboard.bind(this)} entertext={lang.get('all/verification/btn_otp_submit')} />
                    </div>
                  </Drawer>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "table-row", height: "0" }}> 
          <div style={{ display: "table-cell", padding: "0.5rem" }}>
            <Button type="primary" size="large" style={{ width: "100%" }} onClick={e => this.props.history.push('/all')}>{lang.get('all/verification/btn_verified')}</Button>
          </div>
        </div>
      </Loading>
    )
  }

  
  _rPassword () {
    let pw_box = [];
    let { otp_code } = this.state;
    const pw_length = (otp_code || '').length;

    // const svg_circle =(
    //   <svg height="100" width="100">
    //     <circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" />
    //   </svg>)

    for(let i = 0; i < 6; ++i) {
      if (i < pw_length)
        pw_box.push(
          <Col key={i} span={4} style={{ textAlign: "center" }}>
            ●
          </Col>
        )
      else
        pw_box.push(
          <Col key={i} span={4} style={{ textAlign: "center", color: "RGBA(192, 192, 192, 0.5)"}}>
            ●
          </Col>
        )
    }

    return (
      <Row style={{ width: "14rem", borderRadius: "4px" }}>
        {pw_box}
      </Row>);    
  }


  async _hOTPKeyboard (key) {
    let { otp_code } = this.state;
    
    if (!otp_code)
      otp_code = '';

    switch (key) {
      case 'esc' :
        return this.setstate({
          error_msg: '',
          otp_code: '',
          visible_input_otp_drawer: false
        });
      case 'del' :
        return this.setstate({          
          error_msg: '',
          otp_code: otp_code.slice(0, -1)
        });
      case 'enter' :
        return this.setstate({          
          visible_input_otp_drawer: false,
          visible_input_otp_pc_modal: false,
        })
      default :
        if (otp_code.length < 6)
          return this.setstate({            
            error_msg: '',
            otp_code: otp_code.concat(key)
          });
    }
  }



  async _fetchGoogleOTPKey () {
    try {
      const res_user_item = (await server_api.fetchUsers({
        where: {
          id: common.get().id,  
        },
        attributes: ['otp_key'],        
      })).items[0];

      return res_user_item.otp_key;
    }
    catch (e) {
      console.error(e);
      return '-';
    }
  }


  async _submit () {
    const { otp_code } = this.state;

    if (otp_code.trim() === '') {
      Modal.error({
        title: lang.get('all/verification/modal_case2/title'),
        content: lang.get('all/verification/modal_case2/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return;
    }

    this.setstate({ is_loading: true });

    try {
      await server_api.checkOTP({
        otp_code
      });      

      Modal.success({
        title: lang.get('all/verification/modal_case1/title'),
        content: lang.get('all/verification/modal_case1/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true,
      });

      return this.setstate({
        is_loading: false,
        otp_code: '',
      });
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.log(e);
      }

      if (e.result.message === 'opt-code not matched. please try again.') {
        Modal.error({
          title: lang.get('all/verification/modal_case3/title'),
          content: lang.get('all/verification/modal_case3/content'),          
          okText: lang.get('modal/confirm_text'),
          centered: true,
        });
      }

      return this.setstate({
        is_loading: false,
        otp_code: '',
      });
    }
  }
}





const NormalState = (props) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
      <Button size="large" type="primary" onClick={props.onClickGoogleOTP}>{lang.get('all/verification/btn_start_verify')}</Button>
    </div>
  )
}


const GoogleOTP = (props) => {
  return (
    <div style={{ padding: "0.5rem"}}>
      <div style={{ borderRadius: "0.6em", padding: "1.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)"}}>
        <div>{lang.get('all/verification/info_step0')}</div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "1rem" }}>
          <div>{lang.get('all/verification/info_step1')}</div>
          <div><Button size="small" style={{ borderColor: "RGB(38, 115, 220)", color: "RGB(38, 115, 220)" }} onClick={e => window.open('https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2', '_blank')}>
            {lang.get('all/verification/btn_download')}
          </Button></div>
        </div>
        <div style={{ marginTop: "1rem" }}>{lang.get('all/verification/info_step2')}</div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "1rem" }}>
          <div style={{ paddingLeft: "0.25rem", letterSpacing: "0rem", fontSize: "1.25rem", fontWeight: "700", color: "RGB(51, 129, 252)"}}>{props.valueGoogleOtpKey}</div>
          <div><Button size="small" style={{ borderColor: "RGB(38, 115, 220)", color: "RGB(38, 115, 220)" }} onClick={props.onClickCopy}>
            {lang.get('all/verification/btn_copy')}
          </Button></div>
        </div>
      </div>

      <div style={{ borderRadius: "0.6em", padding: "1.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)"}}>
        <div>{lang.get('all/verification/info_step3')}</div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "1rem" }}>
          <div>
            <Input type="password" value={props.valueCertificateKey} onChange={props.onChangeCertificationKey}
              onFocus={e => {
                if (checkers.is_mobile === false) {
                  if (process.env.NODE_ENV === 'development')
                    console.log("pc");
                  return;
                }
                if (process.env.NODE_ENV === 'development')
                  console.log("mobile");
                props.showMobileOTP(e);
              }} />
          </div>
          <div style={{ paddingLeft: "0.5rem" }}><Button size="small" style={{ borderColor: "RGB(38, 115, 220)", color: "RGB(38, 115, 220)" }} onClick={props.onClickSubmit}>
            {lang.get('all/verification/btn_verify')}
          </Button></div>
        </div>
      </div>
    </div>
  )
}


// const style = {
//   google_otp_sub_title: { marginTop: "1rem", color: "RGB(0, 0, 0)", fontSize: "0.9rem", fontWeight: "700" },
//   input_border_bottom_only: { width: "100%", height: "100%", border: "none", borderBottom: "1px solid RGB(68, 114, 196)", boxShadow: "none", outline: "none" },
//   input_border_bottom_only_err: { width: "100%", height: "100%", border: "none", borderBottom: "1px solid red", boxShadow: "none", outline: "none" }
// }

export default Verification;