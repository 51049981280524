import React from 'react';
import TermsKR from './lang/TermsKR';


const default_country_code = 'KR';
const country_code_country_terms_map = {
  'KR' : <TermsKR />
}



class Terms extends React.PureComponent {
  is_mounted_ = false;

  constructor(props) { super(props);    
    this.state = {
      country_code: this.props.country_code
    }
  }
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));
 
  componentDidMount() {
    this.is_mounted_ = true;
  }
  componentWillUnmount() { this.is_mounted_ = false; }


  componentWillReceiveProps (nextProps) {
    if (nextProps.country_code !== this.state.country_code) {
      this.setstate({ country_code: nextProps.country_code });
    }
  }
  

  render () {
    return country_code_country_terms_map[this.state.country_code] || country_code_country_terms_map[default_country_code];
  }
}


export default Terms