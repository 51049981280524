import React from "react";
import { Input, Button, Icon, Select, Checkbox, Modal, Typography } from "antd";
//* view components
import Loading from "../../snippets/Loading";
import Terms from "./registration/Terms";
//* utils
import cache from "../../utils/cache";
import checkers from "../../utils/checkers";
import common from "../../utils/common";
import eos_wallet from "../../utils/eos_wallet";
import server_api from "../../utils/server_api";
//* resources
import logo from "../../images/logos/LogoIcon@3x.png";
import lang from "../../lang/lang";
import en from "../../images/en.png";
import kr from "../../images/kr.png";
class Registration extends React.Component {
  is_mounted_ = false;
  state = {
    is_loading: false,
    data_codes: [],
    login_id: "",
    password: "",
    password_confirm: "",
    email: "",
    country_code: "",
    error_message: "",
    focused_offsetTop: void 0,
    terms_checked: false,
    visible_terms_modal: false,
    checked_id: "",
  };

  constructor(props) {
    super(props);

    this._onResize = this._onResize.bind(this);
  }
  setstate = (partial_state) =>
    this.is_mounted_ === true && this.setState(partial_state);

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });

    this.setstate({
      is_loading: false,
      data_codes: await this._cacheCodes(),
    });
    window.addEventListener("resize", this._onResize);
  }
  componentWillUnmount() {
    this.is_mounted_ = false;
    window.removeEventListener("resize", this._onResize);
  }
  _onResize() {
    if (checkers.is_mobile === false) {
      return;
    }
    if (!this.state.focused_offsetTop) {
      return;
    }

    if (this.is_mounted_)
      document.getElementById("scrollbox").scrollTop =
        this.state.focused_offsetTop;
  }

  async _checkId() {
    // alert(this.state.login_id);
    this.setState({ is_loading: true });
    if (!this.state.login_id || this.state.login_id.length < 3) {
      Modal.error({
        title: lang.get("id_search/modal_case6/title"),
        content: lang.get("id_search/modal_case6/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      this.setState({ is_loading: false });
    } else {
      let res = await server_api.checkId({ id: this.state.login_id });
      console.log(res);
      console.log(res.message);
      if (res.message === "ok") {
        Modal.success({
          title: lang.get("id_search/modal_case5/title"),
          content: lang.get("id_search/modal_case5/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });
        this.setState({ checked_id: this.state.login_id, is_loading: false });
      } else {
        Modal.error({
          title: lang.get("id_search/modal_case6/title"),
          content: lang.get("id_search/modal_case6/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });
        this.setState({ is_loading: false });
      }
    }
  }

  render() {
    return (
      <Loading
        is_loading={this.state.is_loading}
        has_footer={false}
        overall={true}
        style={{ width: "100%", height: "100%", display: "table" }}
      >
        <div style={{ display: "table-row", height: "100%" }}>
          <div
            style={{
              display: "table-cell",
              position: "relative",
            }}
          >
            <div
              id="scrollbox"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                overflow: "auto",
              }}
            >
              {/* <div id="scrollbox"> */}
              <div style={{ height: "5%" }} />
              <div
                style={{
                  width: "80%",
                  margin: "0 auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={logo}
                  alt=""
                  style={{
                    width: "2.2rem",
                    maxWidth: "99px",
                    height: "auto",
                    maxHeight: "99px",
                  }}
                />
                <span
                  style={{
                    fontSize: "2.9rem",
                    marginLeft: "0.5rem",
                    color: "rgb(130, 130, 130)",
                  }}
                >
                  GGP Wallet
                </span>
              </div>
              <div
                style={{
                  width: "80%",
                  margin: "0 auto",
                  fontSize: "1.4rem",
                  fontWeight: "700",
                }}
              >
                Signup
              </div>
              <div style={{ height: "5%" }} />
              <div
                style={{
                  width: "70%",
                  minWidth: "288px",
                  margin: "0 auto",
                }}
              >
                <NyobInput
                  type="text"
                  labelText={lang.get("registration/id")}
                  maxLength={40}
                  onChangeInput={(e) =>
                    this.setstate({ login_id: e.target.value })
                  }
                  style={{ color: "rgba(0,0,0,.45)", fontSize: "1.5rem" }}
                  setOffset={(v) => this.setstate({ focused_offsetTop: v })}
                  suffix={
                    <div>
                      <Button
                        type="primary"
                        size="small"
                        style={{
                          // width: "50px",
                          fontWeight: "700",
                          color: "#fff",
                          fontSize: "10px",
                        }}
                        onClick={this._checkId.bind(this)}
                      >
                        {lang.get("registration/btn_ck")}
                      </Button>
                    </div>
                  }
                />
                <NyobInput
                  type="password"
                  labelText={lang.get("registration/password")}
                  maxLength={40}
                  onChangeInput={(e) =>
                    this.setstate({ password: e.target.value })
                  }
                  style={{ color: "rgba(0,0,0,.45)", fontSize: "1.5rem" }}
                  setOffset={(v) => this.setstate({ focused_offsetTop: v })}
                  suffix={
                    <Icon
                      type="lock"
                      style={{ color: "rgba(0,0,0,.45)", fontSize: "1.5rem" }}
                    />
                  }
                />
                <NyobInput
                  type="password"
                  labelText={lang.get("registration/password_confirm")}
                  maxLength={40}
                  onChangeInput={(e) =>
                    this.setstate({ password_confirm: e.target.value })
                  }
                  style={{ color: "rgba(0,0,0,.45)", fontSize: "1.5rem" }}
                  setOffset={(v) => this.setstate({ focused_offsetTop: v })}
                  suffix={
                    <Icon
                      type="lock"
                      style={{ color: "rgba(0,0,0,.45)", fontSize: "1.5rem" }}
                    />
                  }
                />
                <div
                  style={{
                    fontSize: "0.7rem",
                    color: "RGB(190, 190, 190)",
                    textAlign: "center",
                    paddingBottom: "1rem",
                  }}
                >
                  {lang.get("registration/password_comment")}
                </div>
                <NyobInput
                  type="email"
                  labelText={lang.get("registration/email")}
                  maxLength={255}
                  onChangeInput={(e) =>
                    this.setstate({ email: e.target.value })
                  }
                  style={{ color: "rgba(0,0,0,.45)", fontSize: "1.5rem" }}
                  setOffset={(v) => this.setstate({ focused_offsetTop: v })}
                  suffix={
                    <Icon
                      type="mail"
                      style={{ color: "rgba(0,0,0,.45)", fontSize: "1.5rem" }}
                    />
                  }
                />
                {/* <div style={{ position: "relative", marginTop: "0.5rem" }}>
                  <label
                    style={{
                      marginLeft: "0.7rem",
                      marginBottom: "0",
                      color: "rgb(191, 191, 191)",
                    }}
                  >
                    {lang.get("registration/country")}
                  </label>
                  <Select
                    id="country_selecter"
                    defaultValue=""
                    style={{ width: "100%", marginTop: "0.2rem" }}
                    onChange={(v) => this.setstate({ country_code: v })}
                  >
                    {this._rCountryOptions()}
                  </Select>
                </div> */}

                <div style={{ paddingTop: "0.5rem" }}>
                  <Checkbox
                    checked={this.state.terms_checked}
                    style={{ fontSize: "0.75rem" }}
                    onClick={this._hTermsCheckBox.bind(this)}
                  >
                    {lang.get("registration/check_box_terms")}
                  </Checkbox>
                  <Modal
                    title={
                      <div style={{ fontWeight: "700" }}>
                        <Typography.Text>
                          {lang.get("registration/modal_title_terms")}
                        </Typography.Text>
                      </div>
                    }
                    // footer={null}
                    visible={this.state.visible_terms_modal}
                    onOk={(e) =>
                      this.setState({
                        terms_checked: true,
                        visible_terms_modal: false,
                      })
                    }
                    onCancel={(e) =>
                      this.setstate({
                        terms_checked: false,
                        visible_terms_modal: false,
                      })
                    }
                    okText={lang.get("registration/btn_term_modal_agree")}
                    cancelText={lang.get("registration/btn_term_modal_cancel")}
                    centered
                  >
                    <Terms country_code={this.state.country_code} />
                  </Modal>
                </div>

                {this.state.error_message ? (
                  <div
                    style={{
                      marginTop: "0.5rem",
                      textAlign: "center",
                      color: "rgb(200, 50, 50)",
                    }}
                  >
                    {this.state.error_message}
                  </div>
                ) : null}
                <div
                  style={{
                    width: "100px",
                    marginTop: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    onClick={() => {
                      lang.setLang("en");
                      common.updateLocalCountryCode("EN");
                      window.location.reload();
                    }}
                    style={{ width: "30px", cursor: "pointer" }}
                    src={en}
                  />
                  <img
                    onClick={() => {
                      lang.setLang("kr");
                      common.updateLocalCountryCode("KR");
                      window.location.reload();
                    }}
                    style={{ width: "30px", cursor: "pointer" }}
                    src={kr}
                  />
                </div>
                <div style={{ paddingBottom: "50%" }} />
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "table-row" }}>
          <div
            style={{
              display: "table-cell",
              padding: "0 1rem 0",
            }}
          >
            <div>
              <Button
                type="danger"
                size="large"
                style={{
                  width: "100%",
                  fontWeight: "700",
                  color: "#fff",
                  background: "rgb(255, 92, 92)",
                }}
                onClick={this._hRegistrate.bind(this)}
              >
                {lang.get("registration/btn_register")}
              </Button>
            </div>
            <div style={{ padding: "0.5rem 0" }}>
              <Button
                type="link"
                size="small"
                style={{ width: "100%" }}
                onClick={(e) => this.props.history.push("/login")}
              >
                {lang.get("registration/btn_login")}
              </Button>
            </div>
          </div>
          <div style={{ paddingTop: "20px" }} />
        </div>
      </Loading>
    );
  }

  _rCountryOptions() {
    if (!this.state.data_codes) {
      return null;
    }
    if (this.state.data_codes.length === 0) {
      return null;
    }

    const list = this.state.data_codes.reduce((p, c, i, a) => {
      if (c.country_code === "") {
        return p;
      }
      if (c.country_code === "EU") {
        return p;
      } else {
        p.push(
          <Select.Option key={c.country_code}>
            {lang.get(`country_codes/${c.country_code}`)}
          </Select.Option>
        );
      }
      return p;
    }, []);

    return list;
  }

  _hTermsCheckBox(e) {
    if (this.state.terms_checked) {
      return this.setstate({ terms_checked: false });
    }

    this.setstate({ visible_terms_modal: true });
  }

  async _hRegistrate(e) {
    const validate_result = await this.validate();
    if (validate_result !== true) {
      return;
    }
    this.setstate({ is_loading: true });
    try {
      const res_user_resgistration_items = (
        await server_api.createUser({
          login_id: this.state.login_id.trim(),
          password: this.state.password.trim(),
          email: this.state.email.trim(),
          country_code: "en",
          code_for_new_password: null,
        })
      ).items;

      if (res_user_resgistration_items.length > 0) {
        const res_login = await common.login(
          "USER",
          this.state.login_id,
          this.state.password
        );
        if (res_login.status === true) {
          return window.location.replace("/all/verification");
        } else {
          return this.setstate({
            error_message: lang.get("registration/error_case1"),
            is_loading: false,
          });
        }
      } else {
        return this.setstate({
          error_message: lang.get("registration/error_case2"),
          is_loading: false,
        });
      }
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      if ((e.result || {}).message === "users_login_id must be unique") {
        return this.setstate({
          error_message: lang.get("registration/error_case11"),
          is_loading: false,
        });
      }

      if ((e.result || {}).message === "users_email must be unique") {
        return this.setstate({
          error_message: lang.get("registration/error_case12"),
          is_loading: false,
        });
      }

      return this.setstate({
        error_message: lang.get("registration/error_case3"),
        is_loading: false,
      });
    }
  }

  validate() {
    // const regExp_not_login_id = /[^A-Za-z0-9]/;
    const regExp_email =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    var regex_not_password = /[^A-Za-z\d~@#$%^&*+=`|{}:;!,.?'"()[\]\-_\\/<>]/;
    var regex_password =
      /^.*(?=^.{8,30}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[`~@#$%^&*+=|{}:;!,.?'"()[\]\-_\\/<>]).*$/;

    if (!this.state.login_id.trim()) {
      return this.setstate({
        error_message: lang.get("registration/error_case4"),
      });
    }

    if (this.state.login_id !== this.state.checked_id) {
      return this.setstate({ error_message: lang.get("registration/alert3") });
    }
    // if (regExp_not_login_id.test(this.state.login_id).trim()) {
    //   return this.setstate({ error_message: lang.get('registration/error_case13') });
    // }

    if (!this.state.password.trim()) {
      return this.setstate({
        error_message: lang.get("registration/error_case5"),
      });
    }

    if (this.state.password.trim() !== this.state.password_confirm.trim()) {
      return this.setstate({
        error_message: lang.get("registration/error_case6"),
      });
    }

    if (!regExp_email.test(this.state.email.trim())) {
      return this.setstate({
        error_message: lang.get("registration/error_case7"),
      });
    }

    if (regex_not_password.test(this.state.password.trim())) {
      return this.setstate({
        error_message: lang.get("registration/error_case9"),
      });
    }

    if (!regex_password.test(this.state.password.trim())) {
      return this.setstate({
        error_message: lang.get("registration/error_case9"),
      });
    }

    // if (this.state.country_code.trim() === "") {
    //   return this.setstate({
    //     error_message: lang.get("registration/error_case10"),
    //   });
    // }

    if (this.state.terms_checked === false) {
      return this.setstate({
        error_message: lang.get("registration/error_case14"),
      });
    }

    return true;
  }

  async _cacheCodes() {
    try {
      // const res_items = (await cache.codes.get()).items;
      const res_items = [{ country_code: "KR" }];

      return res_items;
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        return console.error(e);
      }
      return [];
    }
  }
}

const NyobInput = ({
  labelText = "label",
  type = "text",
  onChangeInput = (e) => console.log("not binded"),
  maxLength = 255,
  suffix,
  setOffset = () => console.log("not binded"),
}) => {
  let style = {};
  const default_style = {
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderRadius: "0",
    boxShadow: "none",
    paddingRight: "2.25rem",
  };
  if (suffix) style = Object.assign({}, default_style);

  return (
    <div style={{ position: "relative", marginTop: "0.5rem" }}>
      <label
        style={{
          marginLeft: "0.7rem",
          marginBottom: "0",
          color: "rgb(191, 191, 191)",
        }}
      >
        {labelText}
      </label>
      <Input
        type={type}
        style={style}
        maxLength={maxLength}
        onChange={(e) => onChangeInput(e)}
        defaultValue=""
        onFocus={(e) => {
          if (checkers.is_mobile === false) {
            return;
          }
          setOffset(eos_wallet.cumulativeOffset(e.target).top - 60);
        }}
        // onBlur={e => {
        //   if (checkers.is_mobile === false) {
        //     return;
        //   }
        //   setOffset(void 0);
        // }}
      />
      <div style={{ position: "absolute", top: "50%", right: "2%" }}>
        {suffix}
      </div>
    </div>
  );
};

export default Registration;
