import React from 'react';
import { Input } from 'antd';

import '../styles/numeric_input.less';



//TODO ???? 0??? ?? ??
class NumericInput extends React.Component {
  static defaultProps = {
    onChange: (e) => {}, //! mustbe : value => this.setstate{{ something_key: value }}
    value: '',           //! mustbe : this.state.something_key
    value_max: '9000000000000000',
    placeholder: 'set placeholder',
    maxLength: 25,
    comma: undefined,    //! set "true" for formatted number
    onBlur: (e) => {},
    onFocus: (e) => {},
    allowClear: undefined,
    limit_decimal_point: 2,
  }

  onChange = e => {
    let new_value = (e.target.value).trim();
    
    //* 공백에서 '.' 입력 → '0.'으로 시작
    if (new_value === ".")
      new_value = "0.";


    //* 0 이후 .이 아닌 문자를 입력 → 0 제거후 해당 문자로 시작
    if ((new_value.length === 2) && (new_value.charAt(0) === '0') && (new_value.charAt(1) !== "."))
      new_value = new_value.substring(1);


    //* ',' 포맷 제거
    new_value = unFormatNumber(new_value);


    //* regex 생성
    // const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/; //음수 허용
    const { limit_decimal_point } = this.props;
    const reg = new RegExp('^([1-9][0-9]*)[.]?([0-9]{0,' + limit_decimal_point + '})?$|^0[.]?([0-9]{0,' + limit_decimal_point + '})?$', 'g');

    //* 변형된 입력값 리턴 + 최대치 최종 체크
    if ((!Number.isNaN(new_value) && reg.test(new_value)) || new_value === '') {
      if (new_value > Number(this.props.value_max))
        new_value = this.props.value_max; 
      if (new_value === '')
        new_value = '0';

      return this.props.onChange(new_value);
    }
  }

  onFocus = () => {
    const { value, onFocus } = this.props;

    if (value === '0') {
      this.props.onChange('');
    }

    if (onFocus) {
      onFocus();
    }
  }


  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    let { value, onBlur, onChange } = this.props;
    if (value.charAt(value.length - 1) === '.' || value === '-') {
      if (this.props.comma) {
        onChange(unFormatNumber(value.slice(0, -1)));
      }
    }
    if (value === '')
      onChange('0');

    if (onBlur) {
      onBlur();
    }
  }

  render() {
    return (
      <Input
        size="large"
        inputMode="numeric"
        placeholder={this.props.placeholder}
        maxLength={this.props.maxLength}
        allowClear={this.props.allowClear}
        disabled={this.props.disabled}        
        {...this.props}
        onFocus={this.onFocus}
        onChange={this.onChange}
        onBlur={this.onBlur}
      />
    );
  }
}

function formatNumber (value) {
  value += '';
  if (value.indexOf(',') >= 0) // formatted number
    return value;

  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  let decimal;
  if (list[1] === void 0) {
    decimal = '';
  }
  else if (list[1] === '') {
    decimal = '.';
  }
  else {
    decimal = `.${list[1]}`;
  }

  return `${prefix}${result}${decimal}`;
}

function unFormatNumber (value) {
  return value.replace(/,/g, '')
}


export { formatNumber, unFormatNumber};
export default NumericInput;
