import React from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'antd';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
//* utils
import common      from '../../../../../utils/common';
import native_util from '../../../../../utils/native_util';
import server_api  from '../../../../../utils/server_api';
//* resources
import lang from '../../../../../lang/lang';


/*
Amharic	am
Arabic	ar
Basque	eu
Bengali	bn
English (UK)	en-GB
Portuguese (Brazil)	pt-BR
Bulgarian	bg
Catalan	ca
Cherokee	chr
Croatian	hr
Czech	cs
Danish	da
Dutch	nl
English (US)	en
Estonian	et
Filipino	fil
Finnish	fi
French	fr
German	de
Greek	el
Gujarati	gu
Hebrew	iw
Hindi	hi
Hungarian	hu
Icelandic	is
Indonesian	id
Italian	it
Japanese	ja
Kannada	kn
Korean	ko
Latvian	lv
Lithuanian	lt
Malay	ms
Malayalam	ml
Marathi	mr
Norwegian	no
Polish	pl
Portuguese (Portugal)	pt-PT
Romanian	ro
Russian	ru
Serbian	sr
Chinese (PRC)	zh-CN
Slovak	sk
Slovenian	sl
Spanish	es
Swahili	sw
Swedish	sv
Tamil	ta
Telugu	te
Thai	th
Chinese (Taiwan)	zh-TW
Turkish	tr
Urdu	ur
Ukrainian	uk
Vietnamese	vi
Welsh	cy
 */

const lang_from_country_code = {
  'KR': 'ko',
  'US': 'en',
  'CN': 'zh-CN',
}



class GoogleMapContainer extends React.Component {
  is_mounted_ = false;
  state = {
    current_location: void 0,
    selectedPlace: {
      name: 'selectedPlace'
    },
    data: [
      // { latitude: 47.5524695, longitude: -122.0425407 }
    ],
    showingInfoWindow: false,
    activeMarker: {
      name: ''
    },
  }

  constructor(props) { super(props);

    this.state = {
      ...this.state,
      selected_exchanger: props.selected_exchanger || this.state.selected_exchanger,
      data              : props.data               || this.state.data              ,
    }
  }
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));


  async componentDidMount () {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    
    //* current location
    let current_location = void 0;
    if (native_util.hasNativeApi() === true) {
      try {
        const [lat, lng] = await native_util.getCurrentLocation();
        current_location = { lat, lng };
      }catch (e) {
        console.log('permission error', e);
      }
    }else if ("geolocation" in navigator) {
      /* geolocation is available */
      current_location = await new Promise(resolve => navigator.geolocation.getCurrentPosition(function (position) {
        resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
      }, function (error) {
        if (error.code === error.PERMISSION_DENIED)
          console.log('permission error', error);
        
        resolve(void 0);
      }));
    }

    const res_registered_exchanger_item = await this._fetchRegisterdExchanger();

    this.setstate({
      is_loading: false,
      current_location,
      registered_exchanger_id: res_registered_exchanger_item.registered_exchanger_id
    })

  }
  componentWillUnmount () {
    this.is_mounted_ = false;
  }  

  async componentWillReceiveProps(nextProps) {
    if ((this.state.selected_exchanger || {}).selected_time !== (nextProps.selected_exchanger || {}).selected_time)
      this.setstate({
        selected_exchanger: {}
      });

    this.setstate({ is_loading: true });
    //* current location
    let current_location = void 0;
    if ("geolocation" in navigator) {
      /* geolocation is available */
      current_location = await new Promise(resolve => navigator.geolocation.getCurrentPosition(function (position) {
        resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
      }, function (error) {
        if (error.code === error.PERMISSION_DENIED)
          console.log('permission error');
        
        resolve(void 0);
      }));
    }

    this.setstate({
      is_loading: false,
      current_location,
      data: nextProps.data,
      selected_exchanger: nextProps.selected_exchanger
    });
  }

  
  render() {
    let center = void 0;
    if ((this.state.selected_exchanger || {}).latitude) {
      const location = this.state.selected_exchanger;
      center = { lat: location.latitude, lng: location.longitude };
    } else if (this.state.current_location) {
      center = this.state.current_location;
    } else if (this.state.data.length > 0) {
      const location = this.state.data[0];
      center = { lat: location.latitude, lng: location.longitude };
    } else {
      center = null;
    }
    // let bounds = this._getBounds();

    let info_window_button = null;

    if (this.state.activeMarker.title !== '-1') {
      info_window_button = ( <div style={{ marginTop: "0.5rem", width: "100%"}}>
                                <Button size="small" style={{ width: "100%" }} onClick={e => this.props.onClickMarker(this.state.activeMarker.title)}>
                                  {lang.get('all/location/map/google_map/btn_register')}
                                </Button>
                              </div>)
    }
    if ((this.state.selectedPlace || {}).exchangerId === this.state.registered_exchanger_id) {
      info_window_button = ( <div style={{ marginTop: "0.5rem", width: "100%"}}>
                                <Button size="small" style={{ width: "100%" }} onClick={e => this.props.onClickMarker(this.state.activeMarker.title)} disabled>
                                  {lang.get('all/location/map/google_map/btn_registered')}
                                </Button> 
                             </div>)
    }

    return (
      <Map
        google={this.props.google}
        zoom={(center)? 13: 0}
        style={this.props.style}
        // initialCenter={center}
        center={center}
        // bounds={bounds}
        onClick={this.onMapClicked.bind(this)}> 
        {(this.state.current_location)
          ? (<Marker key="current_location" id="current_location" position={this.state.current_location} icon={{ url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png" }}
            onClick={this.onMarkerClick.bind(this)} title="-1" name={lang.get('all/location/map/google_map/current_position')} />)
          : null
        }
        {this._rMarkers()}
        <InfoWindowEx
          onClose={this.onInfoWindowClose.bind(this)}
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          >
          <div>
            <div style={{ fontWeight: "700", fontSize: "1rem" }}>{this.state.activeMarker.name}</div>
            {info_window_button}
          </div>
        </InfoWindowEx>
      </Map>
    );
  }

  _rMarkers = () => {
    return (this.state.data).map((datum, i) => {
      let icon_url = '';
      if (datum.id === this.state.registered_exchanger_id) {
        icon_url = "https://maps.google.com/mapfiles/ms/icons/red-dot.png";
      }
      else {
        icon_url = "https://maps.google.com/mapfiles/ms/icons/red.png";
      }
      return (
        <Marker key={i} id={i} position={{ lat: datum.latitude, lng: datum.longitude }} icon={{ url: icon_url }}
          onClick={this.onMarkerClick.bind(this)} title={String(datum.id)} name={String(datum.name)} exchangerId={datum.id} />
      )
    })
  }

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setstate({
        showingInfoWindow: false,
        activeMarker: {
          name: ''
        }
      })
    }
  };


  onMarkerClick (props, marker, e) {
    this.setstate({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }
  

  onInfoWindowClose (e) {
    // console.log(e);
  }

  _getBounds () {
    let { data } = this.state;
    if (data.length === 0)
      return null;

    let bounds = new this.props.google.maps.LatLngBounds();
    for (var i = 0; i < data.length; i++) {
      bounds.extend({lat: Number(data[i].latitude), lng: Number(data[i].longitude) });
    }

    return bounds;
  }

  // _getSurroundingLocations (current_location, raw_data, range) {
  //   raw_data.forEach((datum, i) => {
  //     let diff_x = Math.pow(Number(datum.longitude - current_location.lng), 2);
  //     let diff_y = Math.pow(Number(datum.latitude  - current_location.lat), 2);

  //     console.log(diff_x + diff_y);
  //   })
  // }



  async _fetchRegisterdExchanger () {
    try {
      const res_item = (await server_api.fetchUsers({
        where: {
          id: common.get().id
        },
        attributes: ['registered_exchanger_id']
      })).items[0];

      return res_item;
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      return void 0;      
    }
    
  }
}

class InfoWindowEx extends React.Component {
  constructor(props) {
    super(props);
    this.infoWindowRef = React.createRef();
    this.contentElement = document.createElement(`div`);
  }

  componentDidUpdate(prevProps) {
    if (this.props.children !== prevProps.children) {
      ReactDOM.render(
        React.Children.only(this.props.children),
        this.contentElement
      );
      this.infoWindowRef.current.infowindow.setContent(this.contentElement);
    }
  }

  render() {
    return <InfoWindow ref={this.infoWindowRef} {...this.props} />;
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAso3xwVld_EIhvdsm8mwOJLtHF2cJkgO4',
  language: lang_from_country_code[common.get().country_code] || 'en',
})(GoogleMapContainer);