import React from "react";
import { Button, Typography, Icon, Row, Col, Input, Modal, Select } from "antd";
//* view components
import Loading from "../../../snippets/Loading";
//* utils
import cache from "../../../utils/cache";
import common from "../../../utils/common";
import eos_wallet from "../../../utils/eos_wallet";
import server_api from "../../../utils/server_api";
//* resources
import lang, { MultiLanguage } from "../../../lang/lang";

class Profile extends React.Component {
  is_mounted_ = false;
  state = {
    data: [],
    data_codes: [],
    header_text: "",
    // otp_status: false,
    new_email: "",
    new_password: "",
    code_for_new_password: "",
    country_code: common.get().country_code,
    editable_email: false,
    editable_password: false,
  };

  constructor(props) {
    super(props);
    // console.log(this.props.location.state);
    // if (!this.props.location.state)
    //   return this.props.history.push('/');

    // const { value_transferred } = this.props.location.state;
    this.state = {
      ...this.state,
      is_loading: true,
    };
  }
  setstate = (partial_state) =>
    this.is_mounted_ === true && this.setState(partial_state);

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    if ((await common.isLoggedIn()) === false) {
      return;
    }

    const data_codes = await this._cacheCodes();
    const user_data = await this._fetchUser();

    this.setstate({
      is_loading: false,
      header_text: (await eos_wallet.getHeaderTokenText()) || "",
      data_codes,
      ...user_data,
    });
  }
  componentWillUnmount() {
    this.is_mounted_ = false;
  }

  render() {
    if (common.get().token === void 0) {
      return null;
    }

    return (
      <Loading
        is_loading={this.state.is_loading}
        opacity="0.7"
        style={{
          display: "table",
          tableLayout: "fixed",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>
            {/** HEADER *****/}
            <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  onClick={(e) => this.props.history.push("/wallet")}
                  style={{
                    flex: "1 1 0px",
                    borderRadius: "4px",
                    padding: "0.2rem 1rem",
                    display: "block",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontSize: "1rem",
                    color: "#fff",
                    background: "rgb(51, 129, 252)",
                    cursor: "pointer",
                  }}
                >
                  {this.state.header_text}&nbsp;
                </div>
                <div style={{ flex: "0 0 auto", padding: "0 0 0 0.5rem" }}>
                  <Button
                    type="link"
                    onClick={(e) => this.props.history.push("/all")}
                    style={{ padding: "0", height: "2.5rem" }}
                  >
                    <Icon
                      type="close"
                      style={{ fontSize: "1.6rem", color: "rgb(75, 75, 75)" }}
                    />
                  </Button>
                </div>
              </div>

              <Typography.Title
                level={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0",
                  marginTop: "1.5rem",
                }}
              >
                <Typography.Text>
                  {lang.get("all/profile/title")}
                </Typography.Text>
              </Typography.Title>
            </div>
            {/***** HEADER END **/}
          </div>
        </div>

        <div style={{ display: "table-row", height: "2%" }} />

        <div style={{ display: "table-row", height: "100%" }}>
          {" "}
          {/* content */}
          <div style={{ display: "table-cell" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div
                style={{
                  top: "0",
                  bottom: "0",
                  left: "0",
                  right: "0",
                  position: "absolute",
                  overflow: "auto",
                }}
              >
                <div style={{ padding: "0.5rem" }}>
                  <div
                    style={{
                      fontSize: "0.9rem",
                      borderRadius: "0.6em",
                      padding: "1.5rem 1rem",
                      margin: "0.5rem auto 0",
                      boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    }}
                  >
                    <Row type="flex" align="middle">
                      <Col span={6} style={{ fontWeight: "700" }}>
                        {lang.get("all/profile/user_id")}
                      </Col>
                      <Col span={18}>
                        <div style={{ color: "rgb(150, 150, 150)" }}>
                          {common.get().login_id}
                        </div>
                      </Col>
                    </Row>

                    <div style={{ fontWeight: "700", marginTop: "1rem" }}>
                      {lang.get("all/profile/email")}
                    </div>
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      style={{ marginTop: "0.3rem" }}
                    >
                      <Col span={20}>
                        <div style={{ paddingLeft: "0.5rem" }}>
                          {this.state.email || "　"}
                        </div>
                      </Col>
                      <Col>
                        {!this.state.editable_email ? (
                          <div>
                            <Button
                              size="small"
                              onClick={(e) =>
                                this.setstate({
                                  editable_email: !this.state.editable_email,
                                })
                              }
                              style={{ fontSize: "0.8rem" }}
                            >
                              {lang.get("all/profile/btn_modify")}
                            </Button>
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    {this.state.editable_email ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "0.5rem",
                        }}
                      >
                        <div style={{ flex: "1 1 auto" }}>
                          <Input
                            style={{ width: "100%" }}
                            placeholder={lang.get(
                              "all/profile/new_email_placeholder"
                            )}
                            size="small"
                            value={this.state.new_email}
                            onChange={(e) =>
                              this.setstate({ new_email: e.target.value })
                            }
                          />
                        </div>
                        <div style={{ flex: "0 1 0px" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div style={{ paddingLeft: "0.2rem" }}>
                              <Button
                                size="small"
                                onClick={(e) => this._submitEmail()}
                                style={{ fontSize: "0.8rem" }}
                              >
                                {lang.get("all/profile/btn_modify")}
                              </Button>
                            </div>
                            <div style={{ paddingLeft: "0.2rem" }}>
                              <Button
                                size="small"
                                onClick={(e) =>
                                  this.setstate({
                                    editable_email: false,
                                    new_email: "",
                                  })
                                }
                                style={{ fontSize: "0.8rem" }}
                              >
                                {lang.get("all/profile/btn_cancel")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div
                    style={{
                      fontSize: "0.9rem",
                      borderRadius: "0.6em",
                      padding: "1.5rem 1rem",
                      margin: "0.5rem auto 0",
                      boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    }}
                  >
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      style={{ marginTop: "0.3rem" }}
                    >
                      <Col>
                        <div style={{ fontWeight: "700" }}>
                          {lang.get("all/profile/password")}
                        </div>
                        <div
                          style={{
                            fontSize: "0.75rem",
                            color: "rgb(150, 150, 150)",
                          }}
                        >
                          {lang.get("all/profile/password_condition")}
                        </div>
                      </Col>
                      <Col>
                        {!this.state.editable_password ? (
                          <div>
                            <Button
                              onClick={(e) =>
                                this.setstate({
                                  editable_password: !this.state
                                    .editable_password,
                                })
                              }
                              style={{ fontSize: "0.8rem" }}
                            >
                              {lang.get("all/profile/btn_modify")}
                            </Button>
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    {this.state.editable_password ? (
                      <div>
                        <div style={{ marginTop: "0.5rem" }}>
                          <div>
                            {lang.get("all/profile/change_password_guide1")}
                          </div>
                          <div style={{ marginTop: "0.25rem" }}>
                            <Button
                              style={{ width: "100%", fontWeight: "700" }}
                              onClick={this._submitCodeForNewPassword.bind(
                                this
                              )}
                            >
                              {lang.get("all/profile/btn_send_code")}
                            </Button>
                          </div>
                        </div>

                        <div style={{ marginTop: "0.75rem" }}>
                          <div>
                            {lang.get("all/profile/change_password_guide2")}
                          </div>
                          <div style={{ marginTop: "0.25rem" }}>
                            <Input
                              type="password"
                              placeholder={lang.get(
                                "all/profile/code_for_new_password_placeholder"
                              )}
                              value={this.state.code_for_new_password}
                              onChange={(e) =>
                                this.setstate({
                                  code_for_new_password: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div style={{ marginTop: "0.25rem" }}>
                            <Input
                              type="password"
                              placeholder={lang.get(
                                "all/profile/new_password_placeholder"
                              )}
                              value={this.state.new_password}
                              onChange={(e) =>
                                this.setstate({ new_password: e.target.value })
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginTop: "0.5rem",
                          }}
                        >
                          <div>
                            <Button
                              onClick={(e) => this._submitPassword()}
                              style={{ fontSize: "0.8rem" }}
                            >
                              {lang.get("all/profile/btn_modify")}
                            </Button>
                          </div>
                          <div style={{ paddingLeft: "0.3rem" }}>
                            <Button
                              onClick={(e) =>
                                this.setstate({
                                  editable_password: false,
                                  code_for_new_password: "",
                                  new_password: "",
                                })
                              }
                              style={{ fontSize: "0.8rem" }}
                            >
                              {lang.get("all/profile/btn_cancel")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div
                    style={{
                      fontSize: "0.9rem",
                      borderRadius: "0.6em",
                      padding: "1.5rem 1rem",
                      margin: "0.5rem auto 0",
                      boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    }}
                  >
                    <div style={{ fontWeight: "700" }}>
                      {lang.get("all/profile/country")}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "0.25rem",
                      }}
                    >
                      <div style={{ flex: "1 1 auto" }}>
                        <Select
                          id="country_selecter"
                          value={lang.get(
                            `country_codes/${this.state.country_code}`
                          )}
                          style={{ width: "100%" }}
                          onChange={(v) => this.setstate({ country_code: v })}
                        >
                          {this._rCountryOptions()}
                        </Select>
                      </div>
                      <div style={{ flex: "0 1 0px", paddingLeft: "0.5rem" }}>
                        <Button
                          onClick={this._submitCountry.bind(this)}
                          style={{ fontSize: "0.8rem" }}
                        >
                          {lang.get("all/profile/btn_replace")}
                        </Button>
                      </div>
                    </div>
                  </div>

                  {/* <div style={{ fontSize: "0.9rem", borderRadius: "0.6em", padding: "1.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)" }}>
                  <Row type="flex" align="middle">
                    <Col span={9} style={{ fontWeight: "700" }}>
                      {lang.get('all/profile/otp_status')}
                    </Col>
                    <Col span={15} style={{ textAlign: "right"}}>
                      {(this.state.otp_status)
                        ? ( <Button disabled style={{ fontSize: "0.8rem" }}>{lang.get('all/profile/otp_btn_verified')}</Button> )
                        : ( <Button style={{ fontSize: "0.8rem" }} onClick={e => this.props.history.push('/all/verification')}>{lang.get('all/profile/otp_btn_verify')}</Button> )
                      }
                      
                    </Col>
                  </Row>
                </div> */}

                  {/* <div style={{ fontWeight: "700", borderRadius: "0.6em", padding: "1.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)", }}>
                    <div>
                      <Button type="link" onClick={e => alert('개발 중')}
                        style={{ width: "100%", padding: "0", display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: "0.9rem", fontWeight: "700", color: "rgb(150, 150, 150)" }}
                      >
                        <div>
                          <Typography.Text>{lang.get('all/profile/export_private_key')}</Typography.Text>
                        </div>
                        <div>
                          <Icon type="right" />
                        </div>
                      </Button>
                    </div>

                    <div style={{ marginTop: "0.5rem" }}>
                      <Button type="link" onClick={e => alert('개발 중')}
                        style={{ width: "100%", padding: "0", display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: "0.9rem", fontWeight: "700", color: "rgb(150, 150, 150)" }}
                      >
                        <div>
                          <Typography.Text>{lang.get('all/profile/export_keystore')}</Typography.Text>
                        </div>
                        <div>
                          <Icon type="right" />
                        </div>
                      </Button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell", padding: "0.5rem 1rem 1rem" }}>
            <Button
              size="large"
              type="danger"
              style={{
                width: "100%",
                fontWeight: "700",
                color: "#fff",
                background: "rgb(255, 92, 92)",
              }}
              onClick={(e) => {
                common.logout();
                window.location.reload();
              }}
            >
              {lang.get("all/profile/btn_logout")}
            </Button>
          </div>
        </div>
      </Loading>
    );
  }

  _rCountryOptions() {
    if (this.state.data_codes.length === 0) {
      return null;
    }

    const list = this.state.data_codes.reduce((p, c, i, a) => {
      if (c.country_code === "") {
        return p;
      }
      if (c.country_code === "EU") {
        return p;
      } else {
        p.push(
          <Select.Option key={c.country_code}>
            {lang.get(`country_codes/${c.country_code}`)}
          </Select.Option>
        );
      }
      return p;
    }, []);

    return list;
  }

  //* transactions
  async _cacheCodes() {
    try {
      // const res_items = (await cache.codes.get()).items;
      const res_items = [{ country_code: "KR" }];

      return res_items;
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        return console.error(e);
      }
      return [];
    }
  }

  async _fetchUser() {
    try {
      const res_user_item = (
        await server_api.fetchUsers({
          where: {
            id: common.get().id,
          },
          attributes: ["email"],
        })
      ).items[0];

      return res_user_item;
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      return void 0;
    }
  }

  async _submitEmail() {
    if (this.state.is_loading === true) return;

    const regExp_email = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    if (!regExp_email.test(this.state.new_email.trim())) {
      return Modal.error({
        title: lang.get("all/profile/modal_case1/title"),
        content: lang.get("all/profile/modal_case1/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
    }

    try {
      this.setState({ is_loading: true });
      await server_api.updateUser(common.get().id, {
        email: this.state.new_email,
      });

      Modal.success({
        title: lang.get("all/profile/modal_case2/title"),
        centered: true,
        okText: lang.get("modal/confirm_text"),
      });
      return this.setstate({
        is_loading: false,
        editable_email: false,
        email: this.state.new_email,
      });
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      if ((e.result || {}).message === "users_email must be unique") {
        Modal.error({
          title: lang.get("all/profile/modal_case10/title"),
          content: lang.get("all/profile/modal_case10/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });

        return this.setstate({
          is_loading: false,
        });
      }

      Modal.error({
        title: lang.get("all/profile/modal_case3/title"),
        content: lang.get("all/profile/modal_case3/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setState({ is_loading: false });
    }
  }

  async _submitCodeForNewPassword(e) {
    if (this.state.is_loading === true) {
      return;
    }
    this.setstate({ is_loading: true });

    try {
      const params = {
        login_id: common.get().login_id,
        email: this.state.email,
      };

      const res = await server_api.sendCodeForNewPassword(params);

      if (res.message === "email has been sent")
        Modal.success({
          title: lang.get("all/profile/modal_case8/title"),
          content: lang.get("all/profile/modal_case8/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });

      return this.setstate({ is_loading: false });
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      Modal.error({
        title: lang.get("all/profile/modal_case9/title"),
        content: lang.get("all/profile/modal_case9/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });

      this.setstate({ is_loading: false });
    }
  }

  async _submitPassword() {
    if (this.state.is_loading === true) {
      return;
    }

    if (typeof this.state.new_password !== "string") {
      return;
    }

    var regex_not_password = /[^A-Za-z\d~@#$%^&*+=`|{}:;!,.?'"()[\]\-_\\/<>]/;
    var regex_password = /^.*(?=^.{8,30}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[`~@#$%^&*+=|{}:;!,.?'"()[\]\-_\\/<>]).*$/;
    if (
      !regex_password.test(this.state.new_password.trim()) ||
      regex_not_password.test(this.state.new_password.trim())
    ) {
      return Modal.error({
        title: lang.get("all/profile/modal_case5/title"),
        content: lang.get("all/profile/modal_case5/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
    }

    try {
      this.setState({ is_loading: true });

      await server_api.updateUsersPassword({
        login_id: common.get().login_id,
        code_for_new_password: this.state.code_for_new_password,
        new_password: this.state.new_password,
      });

      Modal.success({
        title: lang.get("all/profile/modal_case6/title"),
        centered: true,
        okText: lang.get("modal/confirm_text"),
      });

      return this.setstate({
        is_loading: false,
        editable_password: false,
        code_for_new_password: "",
        new_password: "",
      });
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      if ((e.result || {}).message === "incorrect code") {
        await Modal.error({
          title: lang.get("all/profile/modal_case12/title"),
          content: lang.get("all/profile/modal_case12/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });

        return this.setState({
          is_loading: false,
        });
      }

      Modal.error({
        title: lang.get("all/profile/modal_case7/title"),
        content: lang.get("all/profile/modal_case7/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });

      return this.setState({
        is_loading: false,
      });
    }
  }

  async _submitCountry() {
    if (this.state.is_loading) {
      return;
    }

    if (this.state.country_code.trim() === "") {
      Modal.error({
        title: lang.get("all/profile/modal_case11/title"),
        content: lang.get("all/profile/modal_case11/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });

      return;
    }

    this.setstate({ is_loading: true });
    try {
      await server_api.updateUser(common.get().id, {
        country_code: this.state.country_code,
      });

      common.updateLocalCountryCode(this.state.country_code);
      const country_lang = MultiLanguage.getLangFromCountryCode(
        this.state.country_code
      );
      lang.setLang(country_lang);

      return window.location.reload();
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      return this.setstate({ is_loading: false });
    }
  }
}

export default Profile;
