

let is_inited_ = false;

/*eslint-disable no-undef*/
export default {

  hasNativeApi () {
    return (window['NativeQRReader'])? true: false;
  },

  init () {
    if (!window['NativeQRReader'])
      return;
    
    console.log = this.print;
    is_inited_ = true;
  },

  async pushQRReaderView () {
    if (!is_inited_ || !window['NativeQRReader'])
      return;

    return new Promise((resolve, reject) => {
      window.onQRData = async (data) => {
        if (!data || data === 'null')
          resolve(null);
        else
          resolve(data);
      }

      NativeQRReader.postMessage('window.onQRData');
    });
  },
  
  toast (msg) {
    if (!is_inited_ || !window['NativeToaster'])
      return;

    NativeToaster.postMessage(msg);
  },

  hideKeyboard () {
    if (!is_inited_ || !window['NativeHideKeyboard'])
      return;

    NativeHideKeyboard.postMessage('');
  },

  async getCurrentLocation () {
    if (!is_inited_ || !window['NativeGetCurrentLocation'])
      return;

    return new Promise((resolve, reject) => {
      window.onGetLocationData = async (latitude, longitude, error) => {
        if (error || !latitude || !longitude)
          return reject(error);
        
        resolve([latitude, longitude]);
      }

      NativeGetCurrentLocation.postMessage('window.onGetLocationData');
    });
  },

  print (obj) {
    if (!is_inited_ || !window['NativePrint'])
      return;
    
    NativePrint.postMessage(JSON.stringify(obj));
  },

  setFcmSubscribe (params) {
    if (!is_inited_ || !window['NativeSetFcmSubscribe'])
      return;
    
    NativeSetFcmSubscribe.postMessage(JSON.stringify(params));
  },

  async getFcmToken () {
    if (!is_inited_ || !window['NativeGetFcmToken'])
      return;

    return new Promise((resolve, reject) => {
      window.onGetFcmToken = async (token) => {
        resolve(token);
      }

      NativeGetFcmToken.postMessage('window.onGetFcmToken');
    });
  },

  async getClipboardText () {
    if (!is_inited_ || !window['NativeGetClipboardText'])
      return;

    return new Promise((resolve, reject) => {
      window.onGetClipBoardText = async (text) => {
        resolve(text);
      }

      NativeGetClipboardText.postMessage('window.onGetClipBoardText');
    });
  },

  async setClipboardText (text) {
    if (!is_inited_ || !window['NativeSetClipboardText'])
      return;

    return new Promise((resolve, reject) => {
      window.onSetClipBoardText = async () => {
        resolve();
      }

      NativeSetClipboardText.postMessage(JSON.stringify({
        text,
        resolve: 'window.onSetClipBoardText',
      }));
    });
  },

  async downloadImage (img_str) {
    if (!is_inited_ || !window['NativeDownloadImage'])
      return;
    
    NativeDownloadImage.postMessage(img_str);
  }

};
/*eslint-enable no-undef*/