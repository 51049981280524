import React from 'react';

import { Collapse, Spin } from 'antd';
const { Panel } = Collapse;

const Header = (props) => (
  <div style={{ display: "flex", alignItems: "center"}}>
    <div style={{ flex: "0 0 1.5rem", height: "1.5rem", borderRadius: "50%",
      color: "RGB(255, 255, 255)", background: "RGB(122, 148, 195)", textAlign: "center"
    }}>
      Q
    </div>
    <div style={{ flex: "0 1 auto", marginLeft: "0.5rem", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", height: "1.5rem" }}>
      {props.children}
    </div>
  </div>
)

const Text = (props) => (
  <p style={{ fontSize: "0.8rem" }}>
    {props.content}
  </p>
);

class FaqList extends React.Component {
  static defaultProps = {
  }

  is_mounted_ = false;
  state = {
    is_loading: false,
    data: []
  };  
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount () {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });

    

    this.setstate({
      is_loading: false,
      data: this.props.data
     });
  }

  componentWillUnmount() { this.is_mounted_ = false; }
  
  async componentWillReceiveProps (nextProps) {
    // const is_same_year  = this.props.year  === nextProps.year;
    this.setstate({ data: nextProps.data });
  }

  render () {
    if (this.state.data.length > 0)
      return (
        <Collapse bordered={false} expandIconPosition="right">
          {this._rPanels()}
        </Collapse>
      )

    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50px", textAlign: "center"}}>
        <Spin />
      </div>
    )
  }

  _rPanels () {
    return (this.state.data).map((datum, i) => (
      <Panel
        key={i}header={<Header>{datum.title}</Header>}>
        {this._rContents(datum.contents)}
      </Panel>
    ));
  }

  _rContents (data) {
    return data.map((datum, i) => {
      const splited_data = (datum.content).split('\n');
      
      return splited_data.map((datum_t1, i_t1) => {
        return (<Text key={i_t1} content={datum_t1} />);
      });
    });
  }
}

export default FaqList

