module.exports = {
  en: {
    //* transfer ------------------------------------------------
    transfer: {
      title: "transfer",
      btn_submit: "submit",
      error_header_text: "Please enter the amount",

      modal_case1: {
        title: "Transfer request failure",
        content: (params) =>
          `Please transfer more amount than ${params.value}.`,
      },
      modal_case2: {
        title: "Transfer request failure",
        content:
          "At present you don't have a holding token. Would you like to charge it now?",
      },
      modal_case3: {
        title: "Transfer request failure",
        content: "You don't have enough token.",
      },
      modal_case5: {
        title: "Transfer request failure",
        content: "There is modified data. Log in again and try it please.",
      },
      modal_case4: {
        title: "shortage of balance",
        content:
          "At present you don't have holding tokens. Would you like to charge it now?",
      },
      modal_case6: {
        title: "Transfer request failure",
        content: "Please specify a token.",
      },
    },

    "transfer/select_wallet": {
      title: "transfer",
      select_type: {
        title: "transfer target",
        option_1: "Pay APP ID",
        option_2: "Wallet Address",
      },
      input_id: {
        placeholder: "Please enter the ID of the recipient",
        btn_paste: "Paste",
      },
      input_eos_account: {
        title: "Wallet Address",
        placeholder: "Please enter the wallet address",
        btn_paste: "Paste",
      },
      input_memo: {
        title: "Message",
      },
      recent_login_id_list: {
        title: "recent history",
        no_item_text: "There is no recent transfer history",
      },
      favorite_login_id_list: {
        title: "Favoreite",
        no_item_text: "No favorite ID is found.",
      },
      recent_eos_account_list: {
        title: "recent history",
        no_item_text: "No recent transfer history is found",
      },
      favorite_eos_account_list: {
        title: "Favorite",
        no_item_text: "No favorite account is found",
      },
      btn_cancel: "Cancel",
      btn_submit: "Submit",
      btn_otp_submit: "Submit",

      modal_case1: {
        title: "transfer request failure",
        content: "You can't transfer with your ID",
      },
      modal_case2: {
        title: "transfer request failure",
        content: "Enter the ID of the recipient",
      },
      modal_case3: {
        title: "transfer request failure",
        content: "Enter the wallet address of the exchange",
      },
      modal_case4: {
        title: "Transfer failure",
        content: "OTP Authentication Failure.",
      },
      modal_case5: {
        title: "transfer request failure",
        content: "This ID does not exist",
      },
      modal_case6: {
        title: "transfer request failure",
        content: "ID verification failure",
      },
      modal_case7: {
        title: "transfer request failure",
        content: "Transfer failure",
      },
      modal_case8: {
        title: "Transfer failure",
        content: "Failed to transfer",
      },
      modal_case9: {
        title: "Transfer failure",
        content: "Failed to transfer",
      },
      modal_case10: {
        title: "Transfer failure",
        content: "This account doesn’t exist",
      },
      modal_case11: {
        title: "Transfer failure",
        content: "Failed to transfer",
      },
      modal_case12: {
        title: "Transfer failure",
        content: "You cannot transfer to yourself",
      },
      modal_case13: {
        title: "Transfer failure",
        content: "Balance is short",
      },
    },

    "transfer/input_qr_key": {
      guide_text: "Scan the QR code or upload the QR code image",

      modal_case1: {
        title: "operating restriction",
        content:
          "Camera operation failed. Please check your device and permissions.",
      },
    },

    "transfer/result": {
      title: "Transfer Completed",
      value_transferred: "Transfer Amount",
      value_of_balance: "Holding Amount",
      to_user_info: {
        id: "Recipient ID",
      },
      to_eos_account_info: {
        account: "Wallet Address of the Exchange",
      },
      btn_confirm: "Confirm",
      btn_another_transfer: "Additional Transfer",
    },

    //* filling -------------------------------------------------
    filling: {
      title: "Charge",
      input_number: {
        btn_enter_number: "Enter",
      },
      select_exchanger: {
        title: "Branch Selection",
        not_selected: "Undesignated",
      },
      select_currency_token: {
        title_currency: "Currency",
        title_token: "Coin",
        not_selected: "Undesignated",
      },
      result_caculating: {
        loading_text: "Calculating the expected results....",
        expected_token_to_add: "Expected charge amount",
        expected_balance: "Expected Balance after charging",
        warning_message: (params) => {
          return `※ The balance after charging is (${params.date}), the expected amount based on the current server exchange rate, and may vary depending on the exchange rate at the time of the actual charge.`;
        },
      },
      select_bank_account: {
        title: "Deposit account",
        not_selected: "Select your account",
      },
      input_memo: {
        title: "Message",
      },
      btn_submit: "Charging",
      btn_otp_submit: "Charge",

      modal_case1: {
        title: "Expected charging result request failed",
        content: `Due to communication error, it failed to estimate charging result. Please try again later. `,
      },
      modal_case2: {
        title: "Charge request failed",
        content: "Check expected charge amount please",
      },
      modal_case3: {
        title: "Charge request failed",
        content: "Enter the amount",
      },
      modal_case4: {
        title: "Charge request failed",
        content: (params) => `Enter more amount than ${params.value_min}`,
      },
      modal_case5: {
        title: "Charge request failed",
        content: "Select the Currency",
      },
      modal_case6: {
        title: "Charge request failed",
        content: "Register a branch",
      },
      modal_case7: {
        title: "Charge request failed",
        content: "Select an account",
      },
      modal_case8: {
        title: "Charge request failed",
        content: "Select the coin",
      },
      modal_case9: {
        title: "Charge request failed",
        content: "Check the expected charge amount",
      },
      modal_case10: {
        title: "Charge request failed",
        content: "Insufficient token to be charged",
      },
      modal_case11: {
        title: "Charge request failed",
        content:
          "OTP Authentication number is not correct. Check it again please.",
      },
      modal_case12: {
        title: "Charge failure",
        content: "Server error. Charge request failed",
      },
      modal_case13: {
        title: "Charge failure",
        content: "Charge request registration failed",
      },
      modal_case14: {
        title: "Charge request failed",
        content:
          "Changes in the branch's account information make it impossible to charge to that branch. Please try again in a few minutes, or designate a branch again.",
      },
      modal_case15: {
        title: "Charge request failed",
        content:
          "Select a currency again. The currency is not in service at this branch.",
      },
    },

    "filling/result": {
      title: "Charge request completed",
      money_amount: "Requested amount",
      token_amount: "Charge coin",
      exchanger: {
        title: "Branch information",
        name: "Branch name",
        bank: "Bank",
        account: "Account number",
        btn_copy: "Copy",
        info_text: "Upon the deposit to * , charge will proceed.",
      },
      user: {
        name: "account owner",
        account: "account number",
      },
      btn_confirm: "confirm",
    },

    //* exchange -------------------------------------------------
    exchange: {
      tabs: {
        title_token_to_money: "Currency Exchange",
        title_token_to_token: "Coin Exchange",
      },
      btn_submit: "Exchange",
      btn_otp_submit: "Exchange",

      modal_case1: {
        title: "Do you want to change the registered branch?",
        content:
          "Currency exchange is available at the specific country’s branch .",
      },
      modal_case2: {
        title: "Exchange request failed",
        content: "Enter the amount.",
      },
      modal_case3: {
        title: "Exchange request failed",
        content: (params) => `Enter more amount than ${params.min_value}.`,
      },
      modal_case4: {
        title: "Exchange request failed",
        content: "Register the branch.",
      },
      modal_case5: {
        title: "Exchange request failed",
        content: "The branch and the country of the coin do not correspond.",
      },
      modal_case6: {
        title: "Exchange request failed",
        content: "Holding token is insufficient",
      },
      modal_case7: {
        title: "Exchange request failed",
        content: "Failed to confirm the holding token.",
      },
      modal_case8: {
        title: "Exchange request failed",
        content: "Select the deposit account.",
      },
      modal_case9: {
        title: "Exchange request failed",
        content: "Enter the amount.",
      },
      modal_case10: {
        title: "Exchange request failed",
        content: (params) => `Enter more amount than ${params.min_value}.`,
      },
      modal_case11: {
        title: "Exchange request failed",
        content: "Select the branch.",
      },
      modal_case12: {
        title: "Exchange request failed",
        content: "Holding token is insufficient",
      },
      modal_case13: {
        title: "Exchange request failed",
        content: "Failed to confirm the holding token.",
      },
      // 'modal_case14' : {
      //   'title': 'Exchange request failed',
      //   'content': 'Coins to be exchanged are insufficient.',
      // },
      modal_case15: {
        title: "Exchange request failed",
        content: "Error occurred while checking expected exchange result.",
      },
      modal_case16: {
        title: "Exchange request failed",
        content: "OTP Authentication number is incorrect. Check again please.",
      },
      modal_case17: {
        title: "Exchange request failed",
        content: "Error occurred while checking exchange request.",
      },
      modal_case18: {
        title: "Exchange request failed",
        content: "Communication error occurred.",
      },
      modal_case19: {
        title: "Exchange request failed",
        content: " Error occurred while checking exchange request.",
      },
      modal_case20: {
        title: "Exchange request failed",
        content: " Communication error occurred.",
      },
      modal_case21: {
        title: "Insufficient Balance",
        content: "You don’t have any token. Do you want to charge now?",
      },
      modal_case22: {
        title: "Exchange request failed",
        content: "Please specify both token and currency.",
      },
      modal_case23: {
        title: "Exchange request failed",
        content: "Please specify all the tokens.",
      },
      modal_case24: {
        title: "failed to request expected exchange result",
        content: `Due to the server error, it has failed to estimate exchange result. Try again later`,
      },
      modal_case25: {
        title: "Exchange request failed",
        content: "Check the estimated amount of exchange.",
      },
      modal_case26: {
        title: "Exchange request failed",
        content:
          "Exchange at the branch office is not possible due to changes in account information. Please try again later, or designate a branch again.",
      },
      modal_case27: {
        title: "Exchange request failed",
        content: (params) => `Enter less amount than ${params.max_value}.`,
      },
    },

    "exchange/money_to_token": {
      commission_info_text1: (params) =>
        `※ Currently, the same kind of currency exchange fee is ${params.commission_rate}%.`,
      commission_info_text2: (params) =>
        `※ Currently, the different kind of money exchange fee is ${params.commission_rate}%.`,
    },

    "exchange/token_to_money": {
      btn_enter_number: "Enter",
      select_exchanger: {
        title: "Select a branch",
        not_selected: "Undesignated",
      },
      select_token: {
        title: "Select coin",
        not_selected: "Undesignated",
      },
      select_exchange_type: {
        title: "Deposit mode",
        by_offline: "Branch mode",
        by_bank_account: "Account transfer",
      },
      select_bank_account: {
        title: "Deposit account",
        not_selected: "Select the account",
      },
      input_memo: {
        title: "Message",
      },
      commission_info_text: (params) =>
        `※ Currently, currency exchange fee is ${params.commission_rate}%.`,
    },

    "exchange/token_to_token": {
      btn_enter_number: "Enter",
      select_exchanger: {
        title: "Select branch",
        not_selected: "Undesignated",
      },
      select_token: {
        title: "Select coin",
        not_selected: "Undesignated",
      },
      result_caculating: {
        loading_text: "Calculating the expected results....",
        expected_token_to_add: "Expected amount of exchange",
        expected_balance: "Expected balance after the exchange",
        expected_commission: "Expected fee",
        warning_message: (params) => {
          return `※ The balance after exchange ({params.date}) is the expected amount based on the current server exchange rate, which can vary depending on the exchange rate at the time of actual exchange.`;
        },
      },
      input_memo: {
        title: "Message",
      },
      commission_info_text: (params) =>
        `※ Currently, coin exchange fee is ${params.commission_rate}%.`,
    },

    "exchange/result": {
      title: "Exchange request completed",
      token_from: "Request coin",
      commission_amount: "fee",
      money_to: "Exchange currency",
      token_to: "Exchange coin",
      exchanger: {
        title: "Branch information",
        name: "Branch name",
        bank: "Bank",
        account: "Account number",
        btn_copy: "Copy",
        info_text:
          "* Charging will proceed when deposit is completed with the above account.",
      },
      user: {
        name: "account owner",
        account: "Account number",
      },
      btn_confirm: "Confim",
    },

    //* wallet -------------------------------------------------
    wallet: {
      title: "Wallet",
      qr_viewer: {
        title: "My QR code",
        modal_detail: {
          title: "QR code",
          info_text: "Show me the QR code!",
          copied_message: "Copied",
          btn_copy_login_id: "Copy ID",
          btn_copy_eos_account: "Copy wallet address",
          btn_download_qr: "Save QR code",
        },
      },
      wallet_major: (params) => `${params.major_token_symbol} holding amount`,
      wallet_selected: (params) =>
        `${params.selected_token_symbol} holding amount`,
    },

    "wallet/record": {
      title: "View history",
      transfer_tab: "Transfer history",
      filling_tab: "Charge history",
      exchange_tab: "Exchange history",
    },

    "wallet/record/transfer": {
      no_data_text: "No transfer history is found.",
      status: {
        pre_transfer: "Waiting",
        on_transfering: "Transfering",
        ok: "Completed",
        failed: "Failed",
      },
      select: {
        all: "All",
        to: "Withdrawal",
        from: "Deposit",
      },
      modal_detail: {
        title: "Transfer history",
        sender_id: "Sender ID",
        sender_id_exchanger: "Sender Exchange",
        receiver_id: "Receiver ID",
        receiver_exchanger: "Receiver Exchange",
        token_amount: "Amount",
        log: "Message",
        date: "Transfer date",
        status: "Status",
      },
    },

    "wallet/record/filling": {
      no_data_text: "No charge history is found.",
      status: {
        start: "Waiting",
        exchangable: "Able to transact",
        pay_confirmed: "Approved",
        ok: "Completed",
        canceled: "Canceled",
        failed: "failed",
      },
      from_symbol: ({ currency_from }) => `${currency_from} Charge`,
      modal_detail: {
        title: "Charge history",
        exchanger_name: "Branch",
        exchanger_bank_account: "Account",
        money_amount: "Requested amount",
        token_amount: "Amount",
        date: "Transfer date",
        status: "Status",
        reason: "Reason",
      },
    },

    "wallet/record/exchange": {
      no_data_text: "No exchange history is found.",
      status: {
        start: "Waiting",
        exchangable: "Able to transact",
        pay_confirmed: "Approved",
        ok: "Completed",
        canceled: "Canceled",
        failed: "Failed",
      },
      select: {
        money: "Coin-Currency Exchange",
        token: "Coin-Coin Exchange",
      },
      from_symbol: ({ token_from }) => `${token_from} Exchange`,
      modal_t2m_detail: {
        title: "Exchange history",
        exchanger_name: "Branch",
        from_token: "Requested amount",
        to_money: "Exchange amount",
        commission: "Fee",
        date: "Transfer date",
        status: "Status",
        reason: "Reason",
      },
      modal_t2t_detail: {
        title: "Exchange history",
        exchanger_name: "Branch",
        from_token: "Requested amount",
        to_token: "Exchange amount",
        commission: "Fee",
        date: "Transfer date",
        status: "Status",
        reason: "Reason",
      },
    },

    //* all ----------------------------------------------------
    all: {
      title: "All",
      profile_section: {
        title: "Profile",
        profile: "Profile",
      },
      service_section: {
        title: "Service",
        wallet: "My Wallet",
        coupon: "Coupon",
        history: "Transaction History",
        exchanger: "Find Branch",
        exchange_rate_info: " Exchange rate information",
        verification: "Verifivation and Security",
        setting: "Setting",
      },
    },

    "all/notice": {
      title: "Notice",
      load_more: "Load More",
    },

    "all/faq": {
      title: "How may I help you?",
      subtitle: "FAQ",
    },

    "all/profile": {
      title: "Profile",
      user_id: "User ID",
      email: "Email",
      new_email_placeholder: "Enter new email please",
      password: "Password change",
      password_condition:
        "8 or more digits including special characters, English, and numbers",
      change_password_guide1:
        "1. Please send the Temporary Code to the email you set",
      btn_send_code: "Send",
      change_password_guide2:
        "2. Enter the received Temporary Code and the new Password. ",
      code_for_new_password_placeholder: "Enter Temporary Code",
      new_password_placeholder: "Enter a new password",
      country: "Country",
      // 'otp_status': 'OTP Authentication Status',
      // 'otp_btn_verified': 'Verified',
      // 'otp_btn_verify': 'Verify',
      export_private_key: " Send personal key",
      export_keystore: "Send Keystore",

      btn_modify: "Modify",
      btn_replace: "Replace",
      btn_cancel: "Cancel",
      btn_logout: "Log out",

      modal_case1: {
        title: "Failed to modify",
        content: "Please Check Email Form.",
      },
      modal_case2: {
        title: "Modified.",
      },
      modal_case3: {
        title: "Failed to modify",
        content: "Communication Error. Try Again Later.",
      },
      modal_case5: {
        title: "Failed to modify",
        content:
          "Mix with 8 to 30 digits including special characters, English, and numbers.",
      },
      modal_case6: {
        title: "Modified.",
      },
      modal_case7: {
        title: "Failed to Modify",
        content: "Communication Error. Try Again Later.",
      },
      modal_case8: {
        title: "Transfer request completed",
        content: "Please Check the Code in the Mail.",
      },
      modal_case9: {
        title: "Transfer Request Failed",
        content: "Server Error. Try Again.",
      },
      modal_case10: {
        title: "Failed to Modify",
        content: "exist Email.",
      },
      modal_case11: {
        title: "Failed to Modify",
        content: "Designate a country.",
      },
      modal_case12: {
        title: "Failed to Modify",
        content: "Incorrect Code.",
      },
    },

    "all/exchange_infomation": {
      title: "Exchange Rate Information",
      time_info: ({ date }) => `Exchange rate standard time: ${date}`,
    },

    "all/verification": {
      title: "OTP Authentication",
      info_message:
        " Enter OTP Authentication Number When transferring/charging/Exchanging.",
      btn_verified: "Authentication Completed",
      btn_start_verify: "OTP Authentication",
      btn_verify: "Authentication Confirmed",
      btn_otp_submit: "Enter",

      info_step0:
        "After OTP Authentication, Transaction and Registration are available.",
      info_step1: "1. Download Google OTP.",
      btn_download: "Download",
      info_step2: "2. Please copy and register the following personal keys..",
      btn_copy: "Copy",
      info_step3: " Enter Google Authentication Number with 6 digits.",

      modal_case1: {
        title: "Authentication Success",
        content: "OTP Registration is completed.",
      },
      modal_case2: {
        title: "Authentication Failed",
        content: "Enter Authentication Number.",
      },
      modal_case3: {
        title: "Authentication Failed",
        content: "Incorrect Authentication Number. Try Again Please.",
      },
    },

    "all/location": {
      title: "Exchange Branch",
      no_exchanger: "Register the Branch!",
      near_exchangers: "List of branches nearby",
      no_list_data: "No Data",
      no_list_data_location: "No search result for the nearby branch",

      btn_find_way: "Find Ways",
      btn_find_location: "Find Location",
      btn_change_location: "Modify",
      modal_case1: {
        title: "Communication error occurred while registering.",
      },
      modal_case2: {
        title: " There isn’t the branch or an account with that branch.",
      },
      modal_case3: {
        title: "Functional Limit.",
        content:
          "Some functions are not working due to the limit on the authorization. Please Check Location Authorization.",
      },
    },

    "all/location/map": {
      title: "Search branch location",

      google_map: {
        current_position: "Current Location",
        btn_register: "Register",
        btn_registered: "Registered Branch",
      },

      modal_case1: {
        title: "Error has occurred",
        content:
          "That branch doesn’t exist or has been changed. Re-log in and try again please.",
      },
      modal_case2: {
        title: "Branch registration failed",
        content: "Communication Error has occurred. Try again later please.",
      },
    },

    "all/location/input_qr": {
      guide_text: "Scan the QR code or upload the QR code image",
      reading_qr: "recognizing...",

      modal_case1: {
        title: "Input Failed",
        content: "The branch does not exist.",
      },
      modal_case2: {
        title: "Input Failed",
        content: "Failed to confirm due to communication error.",
      },
      modal_case3: {
        title: "Restrict operation",
        content:
          " Camera drive failed. Please check the instrument and permissions.",
      },
    },

    "filling/commission": {
      commission_info_text1: (params) =>
        `※ Currently, the same kind of currency charge fee is ${params.commission_rate}.`,
      commission_info_text2: (params) =>
        `※ The charge for charging other kinds of currency is currently ${params.commission_rate}%.`,
    },

    "coupon/commission": {
      commission_info_text1: (params) =>
        `※ Currently, the purchase fee for the same kind of coupon is ${params.commission_rate}%.`,
      commission_info_text2: (params) =>
        `※ Currently, the different kind Coupon purchase fee is ${params.commission_rate}%.`,
    },

    "all/setting": {
      title: "Setting",
      notify_sound: "Notifying Sound",
      no_sound: "Not Set",

      notify_transfer: "Notify Deposit/Withdrawal",
      notify_filling_exchange: "Notify Charge/Exchange",
      notify_popup: "Popup Notification",
    },

    "all/coupon": {
      select_buy_type: {
        title: "Purchase type",
        by_currency: "Currency",
        by_crypto: "Coin",
      },
      commission_coupon_text: (params) =>
        `※ Currently, Coupon purchase fee is ${params.commission_rate}%.`,
      result: {
        title: "Coupon application completed",
        name: "Applied Coupon",
        money_amount: "Purchase amount",
        token_amount: "Purchase Coupon",
        info_text:
          "* Coupon purchase will proceed when deposit is completed with the above account.",
      },
      modal_detail: {
        title: " Coupon Type",
        status: "Status",
        type: "Purchase Type",
        code: "Coupon Code",
        remain: "Remaining amount",
        reason: "Reason",
        date: "Date",
      },
      record: {
        gift: "Sent Gift",
        receive: "Received Gift",
        gift_from: "Sender",
        gift_to: "Receiver",
        memo: "Message",
        time: "Time",
        send_date: "Sent Date",
        recv_date: "Received Date",
        type: "Type",
        date: "Date",
        no_data_text: "There is no coupon.",
        recv: "Received Coupon",
        send: "Sent Coupon",
        buy: "Purchased Coupon",
        status: {
          start: "Waiting for approval",
          exchangable: "Able to Transact",
          pay_confirmed: "Confirmed",
          ok: "Completed",
          canceled: "Cancel",
          failed: "Failed",
          closed: "Closed Coupon",
          clear: "Used",
        },
      },
      t_buy: "Purchased Coupon",
      t_send: "Sent Coupon",
      t_recv: "Received Coupon",
      t_pay: "Pay by Coupon",
      t_wallet: "Sent to the Wallet",
      to_wallet: "Send to my Wallet",
      title: "Coupon",
      my: "My Coupon",
      coupon_log: "CouponHistory",
      type: "History Type",
      exchange_bank: "Account",
      my_buy: "Buy",
      my_able: "Available",
      my_total: "Total History",
      my_recv: "Received Gift",
      my_send: "Sent Gift",
      my_wallet: "Wallet Change History",
      my_pay: "Payment History",
      my_cancel: "Cancellation History",
      my_remain: "Remaining amount",
      pay_id: "Pay ID",
      buy: "Buy",
      gift: "Gift",
      gift_box: "Gift box",
      pay: "Pay",
      select_currency: "Select Currency",
      select_coupon: "Select Coupon",
      gift_to: " Pay App ID to receive gift",
      pay_account: "Receiver Wallet Address",
      pay_amount: "Amount",
      total_amount: "Total Amount",
      not_selected: "Undesignated",
      success: "Completed. You can check it in [My Coupon History].",
      modal_case1: {
        title: "Purchase Failed",
        content: " Select Coupon.",
      },
      result_caculating: {
        loading_text: "Calculating estimated amount...",
        expected_price: "Coupon amount",
        expected_fee: "Fee",
        expected_total_price: "Total Purchase Amount",
        warning_message: (params) => {
          return `※ The balance after purchase is the expected amount based on (${params.date}) the current server exchange rate and may vary depending on the exchange rate at the time of actual charge.`;
        },
      },
      modal_case2: {
        title: "Request Failed",
        content: "Please Check Expected Purchase Amount",
      },
      modal_case3: {
        title: "Request Failed",
        content: "Enter the amount",
      },
      modal_case4: {
        title: "Request Failed",
        content: (params) => `Enter more than ${params.value_min}`,
      },
      modal_case5: {
        title: "Request Failed",
        content: "Select Currency",
      },
      modal_case6: {
        title: "Request Failed",
        content: "Register branch",
      },
      modal_case7: {
        title: "Request Failed",
        content: "Select account",
      },
      modal_case8: {
        title: "Request Failed",
        content: "Select Coupon",
      },

      modal_case11: {
        title: "Request Failed",
        content:
          "OTP Authentication Number is incorrect. Please check it again.",
      },
      modal_case12: {
        title: "Request Failed",
        content: "Server error. Failed to request a purchase",
      },
      modal_case13: {
        title: "Request Failed",
        content: "Failed to purchase coupon",
      },
      modal_case14: {
        title: "Request Failed",
        content:
          "Due to changes in the account information of the branch, purchase to that branch is not possible. Please try again later, or designate a branch again.",
      },
      modal_case15: {
        title: "Request Failed",
        content:
          "Select a currency again. This currency is not in service at this branch.",
      },
      modal_case16: {
        title: "Request Failed",
        content:
          "You don’t have a Pay App ID to receive gift, or it is incorrect.",
      },
      modal_case17: {
        title: "Request Failed",
        content: "Select coupon to pay",
      },
      modal_case18: {
        title: "Request Failed",
        content: " Enter the ID of the receiver.",
      },
      modal_case19: {
        title: "Request Failed",
        content: "Enter the amount to pay.",
      },
      modal_case20: {
        title: "Insufficient Balance",
        content: "The balance of the coin you select is insufficient.",
      },
    },

    registration: {
      id: "ID",
      password: "Password",
      password_confirm: "Password check",
      password_comment:
        "* Enter a password with 8 or more digits combination of English, numbers, and special characters.",
      email: "Email",
      country: "Country",

      check_box_terms: " I accept the Terms and Conditions and Privacy Policy.",
      modal_title_terms: " Terms and Conditions of Use and Privacy Policy ",
      btn_term_modal_agree: "Agree",
      btn_term_modal_cancel: "Cancel",

      btn_register: "Sign up",
      btn_ck: "Duplicate check",
      btn_login: "Log in",
      alert1: " This ID is available.",
      alert2: " This ID is already in use.",
      alert3: " Please confirm ID redundancy.",
      error_case1: "There is no matching ID or password.",
      error_case2:
        " Member registration failed. Please contact the administrator.",
      error_case3: "Communication error. Try Again in a moment.",
      error_case4: "Enter ID",
      error_case5: "Enter Password",
      error_case6: " Password does not match ",
      error_case7: "Please check the email again",
      error_case9:
        "Please mix at least eight English characters, numbers, and special characters",
      error_case10: "Please specify your country",
      error_case11: "This ID already exists.",
      error_case12: " This email already exists.",
      // 'error_case13': 'Please use only English and numeric characters for ID.',
      error_case14:
        " Please accept the Terms and Conditions and Privacy Policy.",
    },

    login: {
      id: "ID",
      password: "Password",

      btn_register: "Sign up",
      btn_login: "Log in",
      btn_id_search: "ID",
      btn_pw_search: "Find Password ",

      error_case1: "Enter ID",
      error_case2: "Enter Password",
      error_case3: " There is no matching ID or password.",
      error_case4: "Communication error. Try Again in a moment.",
    },

    id_search: {
      id: "ID",
      email: "Email",
      email_guide: "Your ID will be sent to your registered email",
      btn_id_search: "Send",
      btn_login: "Log in",
      btn_register: "Sign up",

      modal_case1: {
        title: " Transmission request completed ",
        content: "Please check your ID in the email.",
      },
      modal_case2: {
        title: "Transfer Request Failed",
        content: " Server error. Try Again after a while.",
      },
      modal_case3: {
        title: "Transfer Request Failed",
        content: "Enter Email.",
      },
      modal_case4: {
        title: "Transfer Request Failed",
        content: "This is not the right email format.",
      },
      modal_case5: {
        title: "Confirm",
        content: " This ID is available.",
      },
      modal_case6: {
        title: "Confirm",
        content: " This ID is not available.",
      },
    },

    etc: {
      alert1: "* The depositor and account information must match.",
      alert2: " The amount has been used up.",
    },
    pw_search: {
      guide1: " Enter your ID and email and press the 'Send' button ",
      guide2:
        "Please enter the Temporary Code, ID and new Password received by email and press the 'Password Setup' button",
      id: "ID",
      email: "Email",
      code_for_new_password: "Temporary Code",
      btn_send_code: "Send",
      new_password: "New Password",
      btn_pw_search: "Password Set up",
      btn_login: "Log in",
      btn_register: "Sign up",

      modal_case1: {
        title: "Setup Failed",
        content:
          " Mix 8-30 characters of English, numbers, and special characters.",
      },
      modal_case2: {
        title: "Setup is on.",
      },
      modal_case3: {
        title: "Setup Failed",
        content: "Communication Error. Try again later.",
      },
      modal_case4: {
        title: "transfer request completed",
        content: "Please check the code in the mail.",
      },
      modal_case5: {
        title: "Transfer Request Failed",
        content: "Server error. Try Again after a while.",
      },
      modal_case6: {
        title: "Transfer Request Failed",
        content: "Enter ID.",
      },
      modal_case7: {
        title: "Transfer Request Failed",
        content: "Enter Email.",
      },
      modal_case8: {
        title: "Transfer Request Failed",
        content: "Enter correct email format",
      },
      modal_case9: {
        title: "Setup Failed",
        content: "Enter Temporary Code.",
      },
      modal_case10: {
        title: "Setup Failed",
        content: "Password를 Enter.",
      },
      modal_case11: {
        title: "Setup Failed",
        content: " This code is invalid.",
      },
      modal_case12: {
        title: "Failed",
        content: " This ID or email does not exist.",
      },
    },
    more: {
      withdraw_out: "External wallet withdrawal",
      withdraw_history: "External withdraw history",
      withdraw_amount: "Withdraw amount",
      withdraw_address: "Enter external wallet address",
      withdraw_memo: "withdraw memo",
      withdraw_exchange: "If it is an exchange, enter code.",
      error1: "Please enter the correct quantity.",
      erroe2: "Please change the coin to be transferred.",
      error3: "Enter the 12-digit external wallet memo.",
    },
  },
};
