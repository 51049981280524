

const lifetime = 5 * 60 * 1000;


const ENUM = {
	transfer_state: 'transfer_state',
	filling_state : 'filling_state',
	exchange_state: 'exchange_state',
}

function clearEveryStates() {
	for (let v of Object.values(ENUM)) {
		sessionStorage.removeItem(v);
	}
}


function isDirty(stored_state = {}) {
	if (!stored_state.validated_time) {
		return false;
	}

	const now = Date.now();
	if (now - stored_state.validated_time > lifetime) {
		return true;
	}

	return false;
}



export default {
  ENUM,
	clearEveryStates,
	isDirty,
}