import React from 'react';
import * as clipboard from "clipboard-polyfill/build/clipboard-polyfill.promise"
import { Button, Typography, Icon, Row, Col } from 'antd';

//* view components
import Loading from '../../../snippets/Loading';
//* utils
import common       from '../../../utils/common';
import eos_wallet   from '../../../utils/eos_wallet';
import native_util  from '../../../utils/native_util';
import state_util   from '../../../utils/state_util';
import storage_util from '../../../utils/storage_util';
//* resources
import lang from '../../../lang/lang';




class Result extends React.Component {
  is_mounted_ = false;
  state = {
    is_loading: true,
    header_text: '',
    money_amount: '',
    money_currency_symbol: '',
    token_amount: '',
    token_symbol: '',
    exchange_location: {
      name: '',
      bank: '',
      account: '',
    },
    user_bankaccount: {
      owner_name: '',
      bank_name: '',
      bank_account: '',
    },
  }

  constructor(props) { super(props);
    // console.log(this.props.location.state);
    // if (!this.props.location.state)
    //   return this.props.history.push('/');

    if (sessionStorage.getItem(state_util.ENUM.filling_state) === null) {
      return this.props.history.push('/');
    } else {
      if (storage_util.loadFromSession(state_util.ENUM.filling_state).its_result !== true) {
        return this.props.history.push('/');
      }
    }

    // this.state = {
    //   ...this.state,
    // }
  }
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true});
    if (await common.isLoggedIn() === false) {
      return;
    }

    let stored_state = storage_util.loadFromSession(state_util.ENUM.filling_state, {}, true);
    state_util.clearEveryStates();

    this.setstate({
      is_loading: false,
      header_text: await eos_wallet.getHeaderTokenText() || '',
      ...stored_state,
    })
  }
  componentWillUnmount() { this.is_mounted_ = false; }

  render () {
    if (common.get().token === void 0) {
      return null;
    }

    return (
      <Loading is_loading={this.state.is_loading} overall={true} style={{ display: "table", tableLayout: "fixed", width: "100%", height: "100%" }}>

        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>

            {/** HEADER *****/}
            <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  onClick={e => this.props.history.push('/wallet')}
                  style={{
                    flex: "1 1 0px", borderRadius: "4px", padding: "0.2rem 1rem"
                  , display: "block", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"
                  , fontSize: "1rem", color: "#fff", background: "rgb(51, 129, 252)", cursor: 'pointer'
                }} >
                {this.state.header_text}&nbsp;
              </div>
              <div style={{ flex: "0 0 auto", padding: "0 0 0 0.5rem"}}>
                  <Button key="extra" type="link" style={{ padding: "0", pointerEvents: "none", height: "2.5rem" }}>
                    <Icon type="plus" style={{ fontSize: "1.5rem", color: "rgba(255, 255, 255, 0)" }} />
                  </Button>
                </div>
              </div>

              <Typography.Title level={3} style={{ display: 'flex', alignItems: 'center', marginBottom: "0", marginTop: "1.5rem" }}>
                <Typography.Text>{lang.get('filling/result/title')}</Typography.Text>
              </Typography.Title>
            </div>
            {/***** HEADER END **/}

          </div>
        </div>

        <div style={{ display: "table-row", height: "4%" }}></div>
        
        <div style={{ display: "table-row" }}> {/* content */}
          <div style={{ display: "table-cell" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div style={{ top: "0", bottom: "0", left: "0", right: "0", position: "absolute", overflow: "auto"}}>
                <div style={{ padding: "0.5rem" }}>

                  <div style={{
                      borderRadius: "0.6em", padding: "0.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                      display: 'flex', justifyContent: "space-between", alignItems: "center",
                      fontSize: "1rem", fontWeight: "700",
                    }}>
                    <div><Typography.Text>{lang.get('filling/result/money_amount')}</Typography.Text></div>
                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                      <div style={{ flex: "1 1 0px" }}>
                        <div style={{ display: "table", verticlaAlign: "middle",
                          width: '100%', paddingRight: "0.5rem", textAlign: 'right', fontWeight: "700" }}>
                          {eos_wallet.formatCommaNumber(this.state.money_amount)}
                        </div>
                      </div>
                      <div style={{ flex: "0 1 auto", fontSize: "1rem", fontWeight: "700", textAlign: "left" }}>
                        <Typography.Text>{this.state.money_currency_symbol}</Typography.Text>
                      </div>
                    </div>
                  </div>

                  <div style={{
                      borderRadius: "0.6em", padding: "0.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                      display: 'flex', justifyContent: "space-between", alignItems: "center",
                      fontSize: "1rem", fontWeight: "700",
                    }}>
                    <div><Typography.Text>{lang.get('filling/result/token_amount')}</Typography.Text></div>
                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                      <div style={{ flex: "1 1 0px" }}>
                        <div style={{ display: "table", verticlaAlign: "middle",
                          width: '100%', paddingRight: "0.5rem", textAlign: 'right', fontWeight: "700" }}>
                          {eos_wallet.formatCommaNumber(this.state.token_amount)}
                        </div>
                      </div>
                      <div style={{ flex: "0 1 auto", fontSize: "1rem", fontWeight: "700", textAlign: "left" }}>
                        <Typography.Text>{this.state.token_symbol}</Typography.Text>
                      </div>
                    </div>
                  </div>

                  {(this.state.is_loading) ? null
                    : ( <div style={{ borderRadius: "0.6em", padding: "0.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)" }}>
                          <div style={{ fontSize: "1rem", fontWeight: "700" }}>
                            <Typography.Text>{lang.get('filling/result/exchanger/title')}</Typography.Text>
                          </div>
                          <div style={{ padding: "0.5rem 0", fontSize: "0.8rem" }}>
                            <Row>
                              <Col xs={6}>{lang.get('filling/result/exchanger/name')}</Col>
                              <Col xs={18}>{this.state.exchange_location.name}</Col>
                            </Row>
                            <Row>
                              <Col xs={6}>{lang.get('filling/result/exchanger/bank')}</Col>
                              <Col xs={18}>{this.state.exchange_location.bank}</Col>
                            </Row>
                            <Row>
                              <Col xs={6}>{lang.get('filling/result/exchanger/account')}</Col>
                              <Col xs={18}>{this.state.exchange_location.account}<Button size="small" style={{ marginLeft: "0.4rem"}}
                                onClick={e => {
                                  if (native_util.hasNativeApi())
                                    native_util.setClipboardText(this.state.exchange_location.account);
                                  else
                                    clipboard.writeText(this.state.exchange_location.account);
                                }}>
                                  {lang.get('filling/result/exchanger/btn_copy')}
                                </Button>
                              </Col>
                            </Row>
                            <div style={{ marginTop: "0.5rem", fontSize: "0.6rem", fontWeight: "400", color: "RGB(180, 179, 195)", textAlign: "right"}}>
                              {lang.get('filling/result/exchanger/info_text')}
                            </div>
                          </div>
                        </div>
                      )
                  }
                  

                  {(this.state.is_loading) ? null
                    : ( <div style={{ borderRadius: "0.6em", padding: "0.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)" }}>
                          <div style={{ padding: "0.5rem 0", fontSize: "0.8rem" }}>
                            <Row>
                              <Col xs={6}>{lang.get('filling/result/user/name')}</Col>
                              <Col xs={18}>{this.state.user_bankaccount.owner_name}</Col>
                            </Row>
                            <Row style={{ marginTop: "0.5rem"}}>
                              <Col xs={6}>{lang.get('filling/result/user/account')}</Col>
                              <Col xs={18}>{`${this.state.user_bankaccount.bank_name} ${this.state.user_bankaccount.bank_account}`}</Col>
                            </Row>
                          </div>
                        </div>
                      )
                  }                 

                </div>
              </div>
            </div>
          </div>
        </div>


        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell", padding: "1rem 1rem"}}>
            <Button size="large" type="danger" style={{ width: "100%", fontWeight: "700", color: "#fff", background: "rgb(255, 92, 92)" }} onClick={e => this.props.history.push('/filling')}>
              {lang.get('filling/result/btn_confirm')}
            </Button>
          </div>
        </div>
    </Loading>)
  }
}

export default Result;