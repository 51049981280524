import React from 'react';

import { List, Row, Col } from 'antd';

// import InfiniteScroll from 'react-infinite-scroller';
// import '../../styles/infinity_lists.less';

import Loading from '../../snippets/Loading';

import s3         from '../../utils/s3'        ;
import server_api from '../../utils/server_api';


import img_no_country from '../../images/no_country.png';



class TokenList extends React.Component {
  static defaultProps = {
    onClickCurrency: e => {},
    except_token_symbols: [],
    except_no_currency_tokens: true,
  }

  is_mounted_ = false;
  state = {
    data: [],
    loading: false,
    hasMore: true,
  };
  
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;
      
    let raw_data = this.props.data || (await server_api.fetchTokens()).items || [];
    let data;
    if (this.props.except_no_currency_tokens === false) {
      data = raw_data.filter(datum => ([...this.props.except_token_symbols].indexOf(datum.token_symbol) < 0));
    }
    else {
      data = raw_data.filter(datum => ([...this.props.except_token_symbols].indexOf(datum.token_symbol) < 0) && (datum.currency_symbol));
    }
      
    this.setstate({
      raw_data,
      data,
    });
  }
  componentWillUnmount() { this.is_mounted_ = false; }

  async componentWillReceiveProps (nextProps) {
    if (nextProps) {
      const { raw_data } = this.state;
      let data;
      if (nextProps.except_no_currency_tokens === false) {
        data = raw_data.filter(datum => ([...this.props.except_token_symbols].indexOf(datum.token_symbol) < 0));
      } else {
        data = raw_data.filter(datum => ([...this.props.except_token_symbols].indexOf(datum.token_symbol) < 0) && (datum.currency_symbol));
      }

      this.setstate({
        data
      })
    }
    // const is_same_year = this.props.year === nextProps.year;
  }
  // shouldComponentUpdate (nextProps, nextState) {
  //   console.log(nextProps);
  //   return true;
  // }

  // handleInfiniteOnLoad = () => {
  //   let { data } = this.state;
  //   this.setstate({
  //     loading: true,
  //   });
  //   if (data.length > 14) {
  //     message.warning('List loaded all');
  //     this.setstate({
  //       hasMore: false,
  //       loading: false,
  //     });
  //     return;
  //   }
  //   this.fetchData(res => {
  //     data = data.concat(res.results);
  //     this.setstate({
  //       data,
  //       loading: false,
  //     });
  //   });
  // };

  render() {
    if (this.state.data.length === 0) {
      return <Loading overall={false} is_loading={true} style={{ width: "100%", height: "100%" }} />
    }

    return (
      // <InfiniteScroll
      //   initialLoad={false}
      //   pageStart={0}
      //   loadMore={this.handleInfiniteOnLoad}
      //   hasMore={!this.state.loading && this.state.hasMore}
      //   useWindow={false}
      // >
          <List
            dataSource={this.state.data}
            renderItem={(token, i) => {
              let img_url = `${s3.server_address}/${s3.flag_image_path}/${token.country_code}.png`;

              return (<List.Item key={'cur' + i} style={{ padding: "0.4rem 0", border: "none", cursor: "pointer" }} onClick={this.onClickToken.bind(this)} data-token={token.token_symbol}>
                <Row type="flex" justify="center" align="middle" style={{ pointerEvents: "none", width: "100%" }}>
                  <Col span={7}>
                    <img alt="" src={img_url} onError={(e) => { e.target.onerror = null; e.target.src = img_no_country }} style={{ width: "2rem" }} />
                  </Col>
                  <Col span={7}>
                    {token.token_symbol}
                  </Col>
                </Row>
              </List.Item>)
        }} >
          {/* {this.state.loading && this.state.hasMore && (
            <div className="infinity-loading-container">
              <Spin />
            </div>
          )} */}
        </List>
      // </InfiniteScroll>
    );
  }

  onClickToken (e) {
    const { data } = this.state;

    const selected_token = (data.filter(datum => datum.token_symbol === e.target.dataset.token))[0];

    this.props.onClickToken(selected_token);
  }
}


export default TokenList;