import React from 'react';

import 'three-dots';
import '../styles/loading.less';


class Loading extends React.Component {
  static defaultProps = {
    is_loading: true,
    overall: true,
    style: {},
    children: <div></div>
  }

  render () {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        {(this.props.is_loading === false || this.props.overall === true)
          ? ( <div style={this.props.style}>
                {this.props.children || null}
              </div>)
          : null
        }
        {(this.props.is_loading === true && !this.props.overall)
          ? ( <div style={Object.assign({}, (this.props.style || {}), { display: "flex", justifyContent: "center", alignItems: "center"})}>
                <div style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", background: `RGBA(0, 0, 0, ${this.props.opacity || "0.0"})` }}>
                  <div className="dot-flashing" style={{ position: "absolute", top: "50%", left: "50%" }}/>
                </div>
              </div>
            )
          : null
        }
        {(this.props.is_loading === true && this.props.overall === true)
          ? ( <div
                style={{
                  position: "absolute", top: "0",
                  display: "flex", justifyContent: "center", alignItems: "center",
                  height: "100%", width: "100%", zIndex: "999"
                }}>
                <div style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", background: `RGBA(0, 0, 0, ${this.props.opacity || "0.5"})` }}>
                  <div className="dot-flashing" style={{ position: "absolute", top: "50%", left: "50%" }}/>
                </div>
              </div>          
            )
          : null
        }  
      </div>      
    )
  }
}

export default Loading;