import React from 'react';
import { Button, Typography, Icon } from 'antd';
//* view component
import Loading from '../../../snippets/Loading';
import MinorHeader from '../../../snippets/MinorHeader';
import FaqList from './notice/FaQList';
//* utils
import common     from '../../../utils/common';
import eos_wallet from '../../../utils/eos_wallet';
import server_api from '../../../utils/server_api';
//* resources
import lang from '../../../lang/lang';



class Templet extends React.Component {
  is_mounted_ = false;
  state = {
    is_loading: false,
    header_text: '',
    data: []
  }

  constructor(props) { super(props);
    // console.log(this.props.location.state);
    // if (!this.props.location.state)
    //   return this.props.history.push('/');

    // const { value_transferred } = this.props.location.state;    
    this.state = {
      ...this.state,
    }
  }
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    if (await common.isLoggedIn() === false) {
      return;
    }

    const res_items = (await server_api.fetchBoards({
      where: {
        name: 'faq'
      },
      include: [
        {
          association: 'posts',
          include: [{ association: 'contents' }]
        }
      ]
    })).items;

    this.setstate({
      is_loading: false,
      header_text: await eos_wallet.getHeaderTokenText() || '',
      data: res_items[0].posts
    });
  }
  componentWillUnmount() { this.is_mounted_ = false; }

  render () {
    if (common.get().token === void 0) {
      return null;
    }
    return (
      <Loading is_loading={this.state.is_loading} overall={true} style={{ display: "table", tableLayout: "fixed", width: "100%", height: "100%" }}>

        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>

            {/** HEADER *****/}
            <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  onClick={e => this.props.history.push('/wallet')}
                  style={{
                      flex: "1 1 0px", borderRadius: "4px", padding: "0.2rem 1rem"
                    , display: "block", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"
                    , fontSize: "1rem", color: "#fff", background: "rgb(51, 129, 252)", cursor: 'pointer'
                  }} >
                  {this.state.header_text}&nbsp;
                </div>
                <div style={{ flex: "0 0 auto", padding: "0 0 0 0.5rem" }}>
                  <Button key="to_faq" type="link" style={{ padding: "0", height: "2.5rem" }}
                    onClick={e => this.props.history.push({ pathname: "/all/faq", })}>
                    <Icon type="bulb" style={{ fontSize: "1.6rem", color: "rgb(75, 75, 75)" }} />
                  </Button>
                </div>
                <div style={{ flex: "0 0 auto", paddingLeft: "0.25rem", paddingRight: "0" }}>
                  <Button key="to_notice" type="link" style={{ padding: "0", height: "2.5rem" }}
                    onClick={e => this.props.history.push({ pathname: "/all/notice", })}>
                    <Icon type="notification" style={{ fontSize: "1.6rem", color: "rgb(75, 75, 75)" }} />
                  </Button>
                </div>
              </div>

              <Typography.Title level={3} style={{ display: 'flex', alignItems: 'center', marginBottom: "0", marginTop: "1.5rem" }}>
                <Button size="small" type="link" onClick={e => this.props.history.push('/all')} style={{ paddingLeft: "0" }}>
                  <Icon type="arrow-left" style={{ fontSize: "1rem", color: "RGB(75, 75, 75)" }} />
                </Button>
                <Typography.Text>{lang.get('all/faq/title')}</Typography.Text>
              </Typography.Title>
            </div>
  
          </div>
        </div>

        <div style={{ display: "table-row", height: "4%" }} />


        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell", padding: "0 1rem 0rem"}}>
            <div>
              <MinorHeader title={lang.get('all/faq/subtitle')} style={{ fontSize: "0.9rem"}}/>
            </div>
          </div>
        </div>


        <div style={{ display: "table-row" }}> {/* content */}
          <div style={{ display: "table-cell", padding: "0.5rem 1rem 1rem" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div style={{ top: "0", bottom: "0", left: "0", right: "0", position: "absolute",
                overflow: "auto"}}>
                <FaqList data={this.state.data} />
              </div>
            </div>
          </div>
        </div>
    </Loading>)
  }        
}

export default Templet;