import React from "react";
//* view components
import Loading from "../../snippets/Loading";
//* utils
import cache from "../../utils/cache";
import checkers from "../../utils/checkers";
import common from "../../utils/common";
import eos_wallet from "../../utils/eos_wallet";
//* resources
import MultiLanguage from "../../lang/modules/MultiLanguage";
import lang from "../../lang/lang";
import logo from "../../images/logos/LogoIcon@3x.png";

import { Input, Button, Icon } from "antd";
import en from "../../images/en.png";
import kr from "../../images/kr.png";
class Login extends React.Component {
  is_mounted_ = false;
  state = {
    is_loading: false,
    login_id: "",
    password: "",
    error_message: "",
    focused_offsetTop: void 0,
  };

  constructor(props) {
    super(props);
    // this.state = {
    //   ...this.state,
    // }

    this._onResize = this._onResize.bind(this);
  }
  setstate = (partial_state) =>
    this.is_mounted_ === true && this.setState(partial_state);

  componentDidMount() {
    this.is_mounted_ = true;
    window.addEventListener("resize", this._onResize);
  }
  componentWillUnmount() {
    this.is_mounted_ = false;
    window.removeEventListener("resize", this._onResize);
  }
  _onResize() {
    if (checkers.is_mobile === false) {
      return;
    }
    if (!this.state.focused_offsetTop) {
      return;
    }

    if (this.is_mounted_)
      document.getElementById("scrollbox").scrollTop =
        this.state.focused_offsetTop;
  }

  render() {
    return (
      <Loading
        is_loading={this.state.is_loading}
        overall={true}
        style={{ width: "100%", height: "100%", display: "table" }}
      >
        <div style={{ display: "table-row", height: "100%" }}>
          <div style={{ display: "table-cell", position: "relative" }}>
            <div
              id="scrollbox"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                overflow: "auto",
              }}
            >
              <div style={{ height: "10%" }} />
              <div
                style={{
                  width: "80%",
                  margin: "0 auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={logo}
                  alt=""
                  style={{
                    width: "2.2rem",
                    maxWidth: "99px",
                    height: "auto",
                    maxHeight: "99px",
                  }}
                />
                <span
                  style={{
                    fontSize: "2.9rem",
                    marginLeft: "0.5rem",
                    color: "rgb(130, 130, 130)",
                  }}
                >
                  GGP Wallet
                </span>
              </div>
              <div
                style={{
                  width: "80%",
                  margin: "0 auto",
                  fontSize: "1.4rem",
                  fontWeight: "700",
                }}
              >
                Login
              </div>
              <div style={{ height: "20%" }} />
              <div
                style={{ width: "70%", minWidth: "288px", margin: "0 auto" }}
              >
                <NyobInput
                  type="text"
                  labelText={lang.get("login/id")}
                  maxLength={40}
                  onChangeInput={(e) =>
                    this.setstate({ login_id: e.target.value })
                  }
                  setOffset={(v) => this.setstate({ focused_offsetTop: v })}
                  defaultValue={this.state.login_id}
                  suffix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.45)", fontSize: "1.5rem" }}
                    />
                  }
                />
                <NyobInput
                  type="password"
                  labelText={lang.get("login/password")}
                  maxLength={40}
                  onChangeInput={(e) =>
                    this.setstate({ password: e.target.value })
                  }
                  setOffset={(v) => this.setstate({ focused_offsetTop: v })}
                  defaultValue={this.state.password}
                  suffix={
                    <Icon
                      type="lock"
                      style={{ color: "rgba(0,0,0,.45)", fontSize: "1.5rem" }}
                    />
                  }
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.target.blur();
                      this._hLogin(e);
                    }
                  }}
                />

                <div
                  style={{
                    width: "100px",
                    marginTop: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    onClick={() => {
                      lang.setLang("en");
                      common.updateLocalCountryCode("EN");
                      window.location.reload();
                    }}
                    style={{ width: "30px", cursor: "pointer" }}
                    src={en}
                  />
                  <img
                    onClick={() => {
                      lang.setLang("kr");
                      common.updateLocalCountryCode("KR");
                      window.location.reload();
                    }}
                    style={{ width: "30px", cursor: "pointer" }}
                    src={kr}
                  />
                </div>
                {this.state.error_message ? (
                  <div
                    style={{
                      marginTop: "0.5rem",
                      textAlign: "center",
                      color: "rgb(200, 50, 50)",
                    }}
                  >
                    {this.state.error_message}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "table-row" }}>
          <div style={{ display: "table-cell", padding: "0 1rem 0" }}>
            <div>
              <Button
                size="large"
                type="danger"
                onClick={(e) => this._hLogin(e)}
                style={{
                  width: "100%",
                  fontWeight: "700",
                  color: "#fff",
                  background: "rgb(255, 92, 92)",
                }}
              >
                {lang.get("login/btn_login")}
              </Button>
              {/* <div style={{ fontSize: "0.5rem", color: "RGB(190, 190, 190)"}}>temp role: ADMIN</div> */}
            </div>

            <div
              style={{
                paddingTop: "0.5rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ visibility: "hidden" }}>
                <Button
                  style={{ padding: "0" }}
                  type="link"
                  onClick={(e) => this.props.history.push("/idsearch")}
                >
                  {lang.get("login/btn_id_search")}
                </Button>
                <span>/</span>
                <Button
                  style={{ padding: "0" }}
                  type="link"
                  onClick={(e) => this.props.history.push("/pwsearch")}
                >
                  {lang.get("login/btn_pw_search")}
                </Button>
              </div>
              <div>
                <Button
                  style={{ padding: "0" }}
                  type="link"
                  onClick={(e) => this.props.history.push("/registration")}
                >
                  {lang.get("login/btn_register")}
                </Button>
              </div>
            </div>

            <div style={{ paddingTop: "10%" }} />
          </div>
        </div>
      </Loading>
    );
  }

  async _hSelectType(value) {
    this.setstate({ type: value });
  }

  async _hLogin(e) {
    if (this.state.is_loading === true) {
      return;
    }

    if (this.state.login_id.trim() === "") {
      return this.setstate({
        error_message: lang.get("login/error_case1"),
      });
    }

    if (this.state.password.trim() === "") {
      return this.setstate({
        error_message: lang.get("login/error_case2"),
      });
    }

    this.setstate({ is_loading: true });
    try {
      console.log("QUERT LOAGIN");
      const res_login = await common.login(
        "USER",
        this.state.login_id,
        this.state.password
      );
      console.log("object");
      console.log(res_login);
      if (res_login.status === true) {
        await this._cacheCodes();
        await this._cacheGlobalVars();

        const l = MultiLanguage.getLangFromCountryCode(common.country_code);
        lang.setLang(l);

        // window.location.replace('/');
        this.props.history.push("/");
      } else {
        this.setstate({
          error_message: lang.get("login/error_case3"),
          is_loading: false,
        });
      }
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      this.setstate({
        error_message: lang.get("login/error_case4"),
        is_loading: false,
      });
    }
  }

  async _cacheCodes() {
    try {
      await cache.codes.get();
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        return console.error(e);
      }
    }
  }

  async _cacheGlobalVars() {
    try {
      await cache.global_vars.get();
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }
    }
  }
}

const NyobInput = ({
  labelText = "label",
  type = "text",
  onChangeInput = (e) => console.log("not binded"),
  onKeyDown = (e) => {},
  maxLength = 255,
  suffix,
  setOffset = () => console.log("not binded"),
  defaultValue = "",
}) => {
  let style = {};
  const default_style = {
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderRadius: "0",
    boxShadow: "none",
  };
  if (suffix) style = Object.assign({}, default_style);

  return (
    <div style={{ position: "relative", marginTop: "0.5rem" }}>
      <label
        style={{
          marginLeft: "0.7rem",
          marginBottom: "0",
          color: "rgb(191, 191, 191)",
        }}
      >
        {labelText}
      </label>
      <Input
        type={type}
        style={style}
        maxLength={maxLength}
        onKeyDown={(e) => onKeyDown(e)}
        onChange={(e) => onChangeInput(e)}
        defaultValue={defaultValue}
        onFocus={(e) => {
          if (checkers.is_mobile === false) {
            return;
          }
          setOffset(eos_wallet.cumulativeOffset(e.target).top);
        }}
        onBlur={(e) => {
          if (checkers.is_mobile === false) {
            return;
          }
          setOffset(void 0);
        }}
      />
      <div style={{ position: "absolute", top: "50%", right: "2%" }}>
        {suffix}
      </div>
    </div>
  );
};

export default Login;
