import React from 'react';
import { Button, Collapse } from 'antd';
//* utils
import server_api from '../../../../utils/server_api';
//* resources
import lang from '../../../../lang/lang';


const count = 3;


const Text = (props) => (
  <p style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem", fontSize: "0.8rem" }}>
    {props.content}
  </p>
);

class NoticeList extends React.Component {
  is_mounted_ = false;
  state = {
    initLoading: true,
    loading: false,
    data: [],
    list: [],
  };  
  setstate = (partial_state, callback = () => {}) => (this.is_mounted_ === true && this.setState(partial_state, callback));

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ loading: true });

    const res_items = (await server_api.fetchBoards({
      where: {
        name: 'notice'
      },
      include: [
        {
          association: 'posts',
          include: [{ association: 'contents' }]
        }
      ]
    })).items;

    this.setstate({
      initLoading: false,
      pre_data: res_items[0].posts,
      data: res_items[0].posts,
    });
  }

  componentWillUnmount () { this.is_mounted_ = false; }
  // async componentWillReceiveProps (nextProps) { // const is_same_year  = this.props.year  === nextProps.year; }

  onLoadMore = async () => {
    this.setstate({
      loading: true,
      data: this.state.pre_data.concat([...new Array(count)].map(() => ({ loading: true, name: {} }))),
    });

    const res_items = (await server_api.fetchBoards({
      where: {
        name: 'notice'
      },
      include: [
        {
          association: 'posts',
          include: [{ association: 'contents' }]
        }
      ]
    })).items;

    const pre_data = this.state.pre_data.concat(res_items[0].posts);
    this.setstate(
      {
        pre_data,
        data: pre_data,
        loading: false,
      },
      () => {
        // Resetting window's offsetTop so as to display react-virtualized demo underfloor.
        // In real scene, you can using public method of react-virtualized:
        // https://stackoverflow.com/questions/46700726/how-to-use-public-method-updateposition-of-react-virtualized
        window.dispatchEvent(new Event('resize'));
      },
    );
  };

  render() {
    const { initLoading, loading } = this.state;
    const loadMore =
      !initLoading && !loading ? (
        <div
          style={{
            textAlign: 'center',
            marginTop: 12,
            height: 32,
          }}
        >
          <Button onClick={this.onLoadMore}>{lang.get('all/notice/load_more')}</Button>
        </div>
      ) : null;

    return (
      <div>
        <Collapse bordered={false} accordion>
          {this._rPanels()}
        </Collapse>
        {loadMore}
      </div>
    );
  }

  _rPanels () {
    const { data } = this.state;

    return data.map((datum, i) => (
      <Collapse.Panel key={i} header={datum.title}>
        {this._rContents(datum.contents)}
      </Collapse.Panel>
    ));
  }

  _rContents (data) {
    // const style = { fontSize: "0.8rem"};
    
    return data.map((datum, i) => {
      const splited_data = (datum.content).split('\n');
      
      return splited_data.map((datum_t1, i_t1) => {
        return (<Text key={i_t1} content={datum_t1} />);
      });
    });
  }
}

export default NoticeList;