import React from 'react';
import { Button, Icon, Modal } from 'antd';
import QrReader from 'react-qr-reader';
//* view components
import Loading from '../../../snippets/Loading';
//* utils
import common       from '../../../utils/common';
import state_util   from '../../../utils/state_util';
import storage_util from '../../../utils/storage_util';
//* resources
import lang from '../../../lang/lang';
//* styles
import '../../../styles/qr_reader.less';



class InputQRKey extends React.Component {
  state = {
    is_loading: false,
    delay: 100,
    result: 'no result'
  }

  constructor(props) { super(props);
    // if (!this.props.location.state)
    //  return this.props.history.push('/');

    if (sessionStorage.getItem(state_util.ENUM.transfer_state) === null) {
      return this.props.history.push('/');
    }

    this._hError = this._hError.bind(this);
    this._hScan = this._hScan.bind(this);
    this.openImageDialog = this.openImageDialog.bind(this);
  }
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount () {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    if (await common.isLoggedIn() === false) {
      return;
    }
    
    let stored_state = storage_util.loadFromSession(state_util.ENUM.transfer_state, {}, true);
    state_util.clearEveryStates();

    this.setstate({
      is_loading: false,
      stored_state,
    });
  }
  componentWillUnmount () {
    this.is_mounted_ = false;
  }  

  async componentWillReceiveProps(nextProps) {
    // if ((this.state.selected_exchanger || {}).selected_time !== (nextProps.selected_exchanger || {}).selected_time)
  }

  render () {
    if (common.get().token === void 0) {
      return null;
    }
    return (
      <Loading is_loading={this.state.is_loading} overall={true} style={{ display: "table", tableLayout: "fixed", width: "100%", height: "100%" }}>
        
        {/* Header */}
        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>
            <div style={{ padding: "0.5rem 1rem", display: "flex", justifyContent: "space-between", alignItems: "center", background: "rgb(0, 0, 0)"}}>
              <Button size="small" type="link" onClick={e => {                
                storage_util.saveToSession(state_util.ENUM.transfer_state, this.state.stored_state);

                this.props.history.push({
                pathname: '/transfer/selectwallet',
                // state: {
                //   ...this.props.location.state
                // }
              })}} style={{ paddingLeft: "0" }}>
                <Icon type="left" theme="outlined" style={{ fontSize: "1rem",  color: '#fff' }} />
              </Button>
              <Button key="extra_input_qr_from_file" type="link" style={{ padding: "0", color: "#fff" }} onClick={this.openImageDialog}>
                ALBUM
              </Button>
            </div>
          </div>
        </div>

        <div style={{ display: "table-row" }}> {/* content */}
          <div style={{ display: "table-cell" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div style={{ position: "absolute", top: "0", bottom: "0", left: "0", right: "0", overflow: "auto", background: "rgb(0, 0, 0)" }}>
                <div className="qr-reader">{/* //TODO mobile webcam */}
                  <QrReader
                    delay={this.state.delay}
                    style={{ width: "100%", height: "100%" }}
                    onError={this._hError}
                    onScan={this._hScan}
                    showViewFinder={true}
                  />
                  <QrReader  
                    ref="qrReader1"
                    delay={this.state.delay}
                    style={{ display: "none" }}
                    onError={this._hError}
                    onScan={this._hScan}
                    legacyMode
                  />
                </div>
                <div style={{ fontSize: "0.8rem", textAlign: "center", marginTop: "1rem", color: "#fff" }}>
                  {lang.get('transfer/input_qr_key/guide_text')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Loading>
    )
  }

  _hScan = result => {
    if (result) {
      let { stored_state } = this.state;
      
      switch ((stored_state || {}).transfer_type) {
        case 'BY_LOGIN_ID':
          stored_state.login_id = result;
          break;
        case 'BY_EOS_ACCOUNT':
          stored_state.eos_account = result;
          break;
        default:
          if (process.env.NODE_ENV === 'development')
            console.error('exception case');
      }

      storage_util.saveToSession(state_util.ENUM.transfer_state, stored_state);

      return this.props.history.push({
        pathname: "/transfer/selectwallet",
      });
    }
  }

  _hError = err => {
    if (process.env.NODE_ENV === 'development')
      console.error(err);

    if (err.code === 8) {
      Modal.error({
        title: lang.get('transfer/input_qr_key/modal_case1/title'),
        content: lang.get('transfer/input_qr_key/modal_case1/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true,
      })
    }
  }

  openImageDialog() {
    this.setstate({ legacy_mode: false });

    this.refs.qrReader1.openImageDialog();
  }
}
                
export default InputQRKey;