import React from 'react';
import { Button, Icon, Modal } from 'antd';
import QrReader from 'react-qr-reader';
//* view components
import Loading from '../../../../snippets/Loading';
//* utils
import common     from '../../../../utils/common';
import server_api from '../../../../utils/server_api';
//* resources
import lang from '../../../../lang/lang';





class InputQRLocation extends React.Component {
  is_mounted_ = false;
  state = {
    is_loading: false,
    delay: 100,
    result: 'no result',    
  }

  constructor(props) { super(props);
    // console.log(this.props.location.state);
    // if (!this.props.location.state)
    //  return this.props.history.push('/');

    this._hError = this._hError.bind(this);
    this._hScan = this._hScan.bind(this);
    this.openImageDialog = this.openImageDialog.bind(this);
  }
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));


  async componentDidMount () {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    if (await common.isLoggedIn() === false) {
      return;
    }
    
    this.setstate({
      is_loading: false,
    });
  }
  componentWillUnmount () {
    this.is_mounted_ = false;
  }  

  async componentWillReceiveProps(nextProps) {
    // if ((this.state.selected_exchanger || {}).selected_time !== (nextProps.selected_exchanger || {}).selected_time)
  }

  render () {
    if (common.get().token === void 0) {
      return null;
    }
    return (
      <Loading is_loading={this.state.is_loading} overall={true} style={{ width: "100%", height: "100%"}}>
        <div style={{ display: "table", width: "100%", height: "100%" }}>

          {/* Header */}
          <div style={{ display: "table-row", height: "0" }}>
            <div style={{ display: "table-cell" }}>
              <div style={{ padding: "0.5rem 1rem", display: "flex", justifyContent: "space-between", alignItems: "center", background: "rgb(0, 0, 0)" }}>
                <Button size="small" type="link" onClick={e => this.props.history.push({
                  pathname: "/all/location",
                  search: window.location.search,
                  state: {
                    ...this.props.location.state
                  }
                })} style={{ paddingLeft: "0" }}>
                  <Icon type="left" theme="outlined" style={{ fontSize: "1rem", color: '#fff' }} />
                </Button>
                <Button key="extra_input_qr_from_file" type="link" style={{ padding: "0", color: "#fff" }} onClick={this.openImageDialog}>
                  <Icon type="picture" style={{ fontSize: "2rem" }} />
                </Button>
              </div>
            </div>
          </div>
          
          
          <div style={{ display: "table-row" }}> {/* content */}
            <div style={{ display: "table-cell" }}>
              <div style={{ position: "relative", height: "100%" }}>
                <div style={{ position: "absolute", top: "0", bottom: "0", left: "0", right: "0", overflow: "auto", background: "rgb(0, 0, 0)" }}>
                  <div className="qr-reader">
                    <QrReader
                      delay={this.state.delay}
                      style={{ width: "100%", height: "100%" }}
                      onError={this._hError}
                      onScan={this._hScan}
                      showViewFinder={true}
                    />
                    <QrReader  
                      ref="qrReader1"
                      delay={this.state.delay}
                      style={{ display: "none" }}
                      onError={this._hError}
                      onScan={this._hScan}
                      legacyMode
                    />
                  </div>
                  <div style={{ fontSize: "0.8rem", textAlign: "center", marginTop: "1rem", color: "#fff" }}>
                    {lang.get('all/location/input_qr/guide_text')}
                  </div>



                  {/* 요청시 복구 (검색기능)
                  <div id="exchange-location-input" style={{ marginTop: "0.5rem", padding: "0 1.5rem"}}>
                    <Input
                      size="large"
                      prefix={
                        <Button size="large" type="link" style={{ padding: "0 0.2rem" }} onClick={e => console.log('clicked')}>
                          <Icon style={{ fontSize: "1.2em" }} type="search" />
                        </Button>}
                      suffix={
                        <div>
                          <Button type="link" style={{ padding: "0 0.2rem"}} onClick={e => this.props.history.push({
                            pathname: '/all/location/map',
                            search: this.props.location.search,
                            state: {
                              ...this.props.location.state,
                            }
                          })}>
                            <Icon style={{ fontSize: "1.2em" }} type="environment" theme="filled" />
                          </Button>
                          <Button type="link" style={{ padding: "0 0.2rem"}} onClick={e => this.props.history.push({
                            pathname: '/all/location/inputQRLocation',
                            search: this.props.location.search,
                            state: {
                              ...this.props.location.state,
                            }
                          })}>
                            <Icon style={{ fontSize: "1.2em" }} type="scan" />
                          </Button>
                        </div>
                      }
                    />
                  </div>
                  */}
                </div>
              </div>
            </div>
          </div>
        </div>

        
        {this.state.is_loading
          ? (<div style={{ width: "100%", height: "100%", position: "absolute", top: "0", left: "0", zIndex: "999" }}>
              <div style={{ fontSize: "1rem", color: "#fff", fontWeight: "700", borderRadius: "0.6rem", width: "80%", padding: "1.5rem",
                position: "absolute", top: "50%", left: "50%", transform: `translate(${-50}%, ${-50}%)`, background: "rgb(50, 50, 50, 50%)" }}>
                <div style={{ textAlign: "center", userSelect: "none"}}>
                  <Icon type="loading" style={{ fontSize: 24 }} spin />
                </div>
                <div style={{ textAlign: "center", userSelect: "none", marginTop: "0.5rem"}}>
                  {lang.get('all/location/input_qr/reading_qr')}
                </div>
              </div>
            </div>)
            : null
          }
      </Loading>
    )
  }

  _hScan = async result => {
    if (result) {
      try {
        this.setstate({ is_loading: true });

        const res_selected_exchangers = (await server_api.readExchangersSafe({
          where: {
            login_id: result,
            is_archived: false,
          },
          include: [{
            association: 'bankaccounts',
            where: {
              is_archived: false,
            },
            required: true,
            // include: [{ association: 'bank' }] 
          }]
        })).items

        if (res_selected_exchangers.length >0) {
          this.props.history.push({
            pathname: "/all/location",          
            search: window.location.search,
            state: {
              ...this.props.location.state,
              selected_exchanger: res_selected_exchangers[0]
            }
          });  
        }
        else {
          Modal.error({
            title: lang.get('all/location/input_qr/modal_case1/title'),
            content: lang.get('all/location/input_qr/modal_case1/content'),
            okText: lang.get('modal/confirm_text'),
            centered: true
          });
          return this.setstate({ is_loading: false });
        }
      }
      catch (e) {
        Modal.error({
          title: lang.get('all/location/input_qr/modal_case2/title'),
          content: lang.get('all/location/input_qr/modal_case2/content'),
          okText: lang.get('modal/confirm_text'),
          centered: true
        });
        return this.setstate({ is_loading: false });
      }  
    }
  }

  _hError = err => {
    if (err.code === 8) {
      Modal.error({
        title: lang.get('all/location/input_qr/modal_case3/title'),
        content: lang.get('all/location/input_qr/modal_case3/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true,
      })
    }
    if (process.env.NODE_ENV === 'development') {
      console.error(err);
    }
  }

  openImageDialog() {
    this.setstate({ legacy_mode: false });

    this.refs.qrReader1.openImageDialog();
  }
}
                
export default InputQRLocation;