import React from 'react';
// import reqwest from 'reqwest';
// import InfiniteScroll from 'react-infinite-scroller';
import { List, Button, Popconfirm } from 'antd';
//* resources
import lang from '../../lang/lang';
//* styles
import '../../styles/infinity_lists.less';


// const fakeDataUrl = 'https://randomuser.me/api/?results=5&inc=name,gender,email,nat&noinfo'; // for infinitescroll test



class BankAccountList extends React.Component {
  static defaultProps = {
    onClickAccount: e => {}
  }

  is_mounted_ = false;
  state = {
    data: [],
    loading: false,
    hasMore: true,
  };
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));


  componentDidMount() {
    this.is_mounted_ = true;

    this.setstate({
      data: this.props.data
    })
    // this.fetchData(res => {
    //   this.setstate({
    //     // data: res.results,
    //     data: raw_data,
    //   });
    // });
  }
  componentWillUnmount() { this.is_mounted_ = false; }

  componentWillReceiveProps(nextProps) {
    this.setstate({ data: nextProps.data });  
  }



  // fetchData = callback => {
  //   reqwest({
  //     url: fakeDataUrl,
  //     type: 'json',
  //     method: 'get',
  //     contentType: 'application/json',
  //     success: res => {
  //       callback(res);
  //     },
  //   });
  // };

  // handleInfiniteOnLoad = () => {
  //   let { data } = this.state;
  //   this.setstate({
  //     loading: true,
  //   });
  //   if (data.length > 5) {
  //     message.warning('List loaded all');
  //     this.setstate({
  //       hasMore: false,
  //       loading: false,
  //     });
  //     return;
  //   }
  //   this.fetchData(res => {
  //     data = data.concat(res.results);
  //     this.setstate({
  //       data,
  //       loading: false,
  //     });
  //   });
  // };

  render() {
    return (
      // <InfiniteScroll
      //   initialLoad={false}
      //   pageStart={0}
      //   loadMore={this.handleInfiniteOnLoad}
      //   hasMore={!this.state.loading && this.state.hasMore}
      //   useWindow={false}
      // >
        <List
          dataSource={this.state.data}
          renderItem={account => (
            <List.Item key={account.id}>
              <List.Item.Meta
                title={
                  <div style={{ display: 'flex', justifyContent: "center", alignItems: "center"}}>
                    <Button size="large" type="link" style={{ width: "100%", height: "100%", textAlign: "left", fontSize: "0.9rem", cursor: "pointer", color: "black" }}
                      onClick={this.onClickAccount.bind(this)} data-account_id={account.id}>
                      <div style={{ fontWeight: "700", pointerEvents: "none"}}>{`${account.bank_name}  ${account.bank_account}`}</div>
                      <div style={{ pointerEvents: "none"}}>{(account.alias)? `${account.owner_name} (${account.alias})`: `${account.owner_name}`}</div>
                    </Button>
                    <Popconfirm
                      placement="topRight"
                      title={lang.get('drawer/bank_accounts/pop_delete_account/content')}
                      onConfirm={e => this.props.onClickDelete(account.id)}
                      okText={lang.get('drawer/bank_accounts/pop_delete_account/ok_text')}
                      cancelText={lang.get('drawer/bank_accounts/pop_delete_account/cancel_text')}
                    >
                      <Button>{lang.get('drawer/bank_accounts/btn_delete_account')}</Button>
                    </Popconfirm>                    
                  </div>}
              />
            </List.Item>
          )}
        >
          {/* {this.state.loading && this.state.hasMore && (
            <div className="infinity-loading-container">
              <Spin />
            </div>
          )} */}
        </List>
      // </InfiniteScroll>
    );
  }

  onClickAccount (e) {
    const selected_account = (this.state.data).filter(account => account.id === Number(e.target.dataset.account_id));
    
    if (selected_account.length >= 1)
      this.props.onClickAccount(selected_account[0]);
  }
}


export default BankAccountList;