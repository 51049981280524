import query_string from "query-string";

import common from "./common";
import eos_wallet from "./eos_wallet";

const current_token_for_header = "**** HIM / **** KRWH";

const api_server_address =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4001/dev"
    : `https://d717mdfbai.execute-api.ap-northeast-1.amazonaws.com/prod`; // cruds
const api_server_address2 =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4002/dev"
    : `https://jc03q7rm8d.execute-api.ap-northeast-1.amazonaws.com/prod`; // functions
const api_server_address3 =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4003/dev"
    : `https://qgmmrlyawl.execute-api.ap-northeast-1.amazonaws.com/prod`; // functions

// const api_server_address = `https://d717mdfbai.execute-api.ap-northeast-1.amazonaws.com/prod`; // cruds
// const api_server_address2 = `https://jc03q7rm8d.execute-api.ap-northeast-1.amazonaws.com/prod`; // functions
// const api_server_address3 = `https://qgmmrlyawl.execute-api.ap-northeast-1.amazonaws.com/prod`; // functions

// const api_server_address  = `http://localhost:3001`;
// const api_server_address2 = `http://localhost:3002`;

async function requestWithBody(server_url, path, method, params = {}) {
  let url = server_url + "/" + path;
  const common_data = common.get();

  const res = await fetch(url, {
    method,
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      "x-api-key": common_data.token,
    },
  });

  const res_json = await res.json();

  if (res.status === 401) {
    console.log(res_json);
    common.logout();
  } else if (Math.floor(res.status / 100) !== 2) {
    let errorMessage_ = { status: res.status, result: res_json };
    throw errorMessage_;
  }

  return res_json;
}

async function checkId(data = {}) {
  const res = await requestWithBody(
    api_server_address3,
    `api/users/getCheckId`,
    "POST",
    data
  );

  return res;
}

async function requestWithQuery(server_url, path, method, params = {}) {
  let url = server_url + "/" + path;
  const common_data = common.get();

  if (Object.keys(params).length > 0)
    url += "?" + query_string.stringify(params);

  const res = await fetch(url, {
    method,
    headers: {
      "x-api-key": common_data.token,
    },
  });

  const res_json = await res.json();
  if (res.status === 401) {
    common.logout();
    window.location.reload();
  } else if (Math.floor(res.status / 100) !== 2) {
    let errorMessage_ = { status: res.status, result: res_json };
    throw errorMessage_;
  }

  return res_json;
}

async function _fetchAll(server_url, path, options) {
  options.offset = 0;
  options.limit = 1000;

  const result = {
    items: [],
  };
  while (true) {
    const res = await requestWithQuery(server_url, path, "GET", {
      options: JSON.stringify(options),
    });
    if (res.items === undefined || res.items.length <= 0) break;

    options.offset += res.items.length;
    result.items = result.items.concat(res.items);
  }
  return result;
}

function _crudMaker(server_url, name, uppercased = undefined) {
  if (uppercased === undefined)
    uppercased = name[0].toUpperCase() + name.slice(1);
  const path = "api/" + name + "s";

  const result = {};
  result["fetch" + uppercased + "s"] = async (options = {}) => {
    const res = await requestWithQuery(server_url, path, "GET", {
      options: JSON.stringify(options),
    });
    return res;
  };

  result["fetchAll" + uppercased + "s"] = async (options = {}) => {
    const res = await _fetchAll(server_url, path, options);
    return res;
  };

  result["create" + uppercased] = async (datum, options = {}) => {
    return await requestWithBody(server_url, path, "POST", {
      params: datum,
      options,
    });
  };

  result["update" + uppercased] = async (id, datum, options = {}) => {
    return await requestWithBody(server_url, path + "/" + id, "PUT", {
      params: datum,
      options,
    });
  };

  result["delete" + uppercased] = async (id) => {
    return await requestWithQuery(server_url, path + "/" + id, "DELETE", {});
  };

  return result;
}

//******************************************************** EOS project 1907-08
async function readUsersAccount(params = {}, options = {}) {
  const common_data = common.get();
  let res_items;

  try {
    res_items = (
      await requestWithQuery(
        api_server_address2,
        `api/users/${common_data.id}/account`,
        "GET",
        { options: JSON.stringify(params) }
      )
    ).items;
    console.log(res_items);
  } catch (e) {
    if (process.env.NODE_ENV === "development") {
      throw e;
    }

    res_items = [];
  }

  if (options.type === "object") {
    const account_object = eos_wallet.getAccountsObject(res_items);
    return account_object;
  } else if (options.type === "array") {
    const account_array = eos_wallet.getAccountsArray(res_items);
    return account_array;
  } else {
    return res_items;
  }
}

async function readUsersSafe(options = {}) {
  const res = await requestWithQuery(
    api_server_address2,
    "api/users/safe",
    "GET",
    { options: JSON.stringify(options) }
  );
  return res || {};
}

async function readExchangersSafe(options = {}) {
  const res = await requestWithQuery(
    api_server_address2,
    `api/exchangers/safe`,
    "GET",
    { options: JSON.stringify(options) }
  );
  return res;
}

async function readExchangeRates(options = {}) {
  const res = await requestWithQuery(
    api_server_address2,
    `api/exchange-rate`,
    "GET",
    options
  );
  return res;
}

async function readExchangeRatesMoneyToToken(options = {}) {
  const res = await requestWithQuery(
    api_server_address2,
    `api/exchange-rate/token-amount-from-money`,
    "GET",
    options
  );
  return res;
}

// async function readExchangeRatesTokenToMoney (params = {
//   // 'token_symbol',
//   // 'token_amount',
//   // 'commission_rate' // get from global_vars
// }) {
//   const res = (await requestWithQuery(api_server_address2, `api/exchange-rate/money-amount-from-token`, 'GET', params));
//   return res;
// }

async function readExchangeRatesTokenToToken(
  params = {
    // 'from_token_symbol',
    // 'from_amount',
    // 'to_token_symbol',
    // 'commission_rate' // get from global_vars
  }
) {
  const res = await requestWithQuery(
    api_server_address2,
    `api/exchange-rate/token-amount-from-token`,
    "GET",
    params
  );
  return res;
}

async function createUser(
  params = {
    // login_id      : { type: Sequelize.STRING( 40), allowNull: false, },
    // password_hash : { type: Sequelize.STRING(100), allowNull: false, },
    //// eos_account   : { type: Sequelize.STRING( 20), allowNull: true , },
    //// eos_active_pub: { type: Sequelize.STRING( 80), allowNull: true , },
    //// eos_active_pk : { type: Sequelize.STRING( 80), allowNull: true , },
    // name          : { type: Sequelize.STRING( 40), allowNull: false, },
    // email         : { type: Sequelize.STRING(255), allowNull: false, },
    // phone_numbers : { type: Sequelize.STRING( 30), allowNull: false, },
    // country_code  : { type: Sequelize.STRING( 10), allowNull: false, },
    //// registered_exchanger_id : { type: Sequelize.INTEGER    , allowNull: true , },
  }
) {
  Object.assign(params, { registered_exchanger_id: null });
  const res = await requestWithBody(
    api_server_address2,
    `api/users/register`,
    "POST",
    params
  );

  return res;
}

async function createUsersTransfer(
  params = {
    // receiver_type : 'BY_LOGIN_ID' or 'BY_EOS_ACCOUNT',
    // to_user_id, (or to_eos_account. based on receiver_type),
    // amount,
    // token_symbol,
    // log,
  }
) {
  const common_data = common.get();
  const res = await requestWithBody(
    api_server_address2,
    `api/users/${common_data.id}/transfer`,
    "POST",
    params
  );

  return res;
}

async function createUsersMoneyToToken(
  options = {
    // exchanger_id,
    // user_bankaccount_id,
    // exchanger_bankaccount_id,
    // money_currency_symbol,
    // money_amount,
    // token_symbol,
    // log,
  }
) {
  const common_data = common.get();
  const res = await requestWithBody(
    api_server_address2,
    `api/users/${common_data.id}/money-to-token`,
    "POST",
    options
  );

  return res;
}

async function createUsersCoinExchange(
  options = {
    // exchanger_id,
    // user_bankaccount_id,
    // exchanger_bankaccount_id,
    // money_currency_symbol,
    // money_amount,
    // token_symbol,
    // log,
  }
) {
  const res = await requestWithBody(
    api_server_address2,
    `api/coin-exchanges`,
    "POST",
    options
  );

  return res;
}
async function getUsersCoinExchanges() {
  const res = await requestWithQuery(
    api_server_address2,
    `api/coin-exchanges`,
    "GET"
  );

  return res;
}

async function createUsersTokenToMoney(
  data = {
    // user_id                 : { type: Sequelize.INTEGER    , allowNull: false, },
    // exchanger_id            : { type: Sequelize.INTEGER    , allowNull: false, },
    // exchange_type           : { type: exchange_type        , allowNull: false, defaultValue: 'BY_BANK_ACCOUNT', },
    // user_bankaccount_id     : { type: Sequelize.INTEGER    , allowNull: true , },
    // exchanger_bankaccount_id: { type: Sequelize.INTEGER    , allowNull: true , },
    // token_symbol            : { type: Sequelize.STRING( 10), allowNull: false, },
    // token_amount            : { type: Sequelize.STRING( 30), allowNull: false, },
    // money_amount            : { type: Sequelize.STRING( 30), allowNull: false, },
    // commission_rate         : { type: Sequelize.DOUBLE     , allowNull: false, },
    // status                  : { type: status_type          , allowNull: false, defaultValue: 'START', },
    // log                     : { type: Sequelize.TEXT       , allowNull: false, },
    // cancel_log              : { type: Sequelize.TEXT       , allowNull: true , },
  }
) {
  const common_data = common.get();
  const res = await requestWithBody(
    api_server_address2,
    `api/users/${common_data.id}/token-to-money`,
    "POST",
    data
  );

  return res;
}

async function createUsersTokenToToken(
  data = {
    // user_id          : { type: Sequelize.INTEGER    , allowNull: false, },
    // exchanger_id     : { type: Sequelize.INTEGER    , allowNull: false, },
    // from_token_symbol: { type: Sequelize.STRING( 10), allowNull: false, },
    // from_token_amount: { type: Sequelize.STRING( 30), allowNull: false, },
    // to_token_symbol  : { type: Sequelize.STRING( 10), allowNull: false, },
    //// to_token_amount  : { type: Sequelize.STRING( 30), allowNull: false, },
    //// commission_rate  : { type: Sequelize.DOUBLE     , allowNull: false, },
    //// status           : { type: status_type          , allowNull: false, defaultValue: 'START', },
    // log              : { type: Sequelize.TEXT       , allowNull: false, },
    //// cancel_log       : { type: Sequelize.TEXT       , allowNull: true , },
  }
) {
  const common_data = common.get();
  const res = await requestWithBody(
    api_server_address2,
    `api/users/${common_data.id}/token-to-token`,
    "POST",
    data
  );

  return res;
}

async function updateUsersPassword(
  params = {
    // login_id:'test10',
    // code_for_new_password:'rxzsce',
    // new_password:'test10'
  }
) {
  const res = await requestWithBody(
    api_server_address2,
    `api/users/update-password-with-code`,
    "PUT",
    params
  );

  return res;
}

async function sendEmailOfLoginId(
  params = {
    // email:'awtn34n@gmail.com'
  }
) {
  const res = await requestWithBody(
    api_server_address2,
    `api/users/find-login-id`,
    "POST",
    params
  );

  return res;
}

async function sendCodeForNewPassword(
  params = {
    // login_id:'245abt10',
    // email:'awtn34n@gmail.com'
  }
) {
  const res = await requestWithBody(
    api_server_address2,
    `api/users/send-code-for-new-password`,
    "POST",
    params
  );

  return res;
}

async function convertCoin(
  params = {
    // fromCurrency,
    // toCurrency,
    // amount,
    // memo,
  }
) {
  const res = await requestWithBody(
    api_server_address3,
    `api/util/convert-coin`,
    "POST",
    params
  );

  return res;
}

async function checkOTP(
  params = {
    // otp_code: '132478'
  }
) {
  const id = common.get().id;

  const res = await requestWithQuery(
    api_server_address2,
    `api/users/${id}/check-otp`,
    "GET",
    params
  );

  return res;
}

export default {
  current_token_for_header,

  api_server_address,
  api_server_address2,

  ..._crudMaker(api_server_address, "administrator"),
  ..._crudMaker(api_server_address, "bank"),
  ..._crudMaker(api_server_address, "exchanger"),
  ..._crudMaker(api_server_address, "token"),
  ..._crudMaker(api_server_address, "transfer"),
  ..._crudMaker(api_server_address, "user"),
  ..._crudMaker(api_server_address, "board"),
  ..._crudMaker(
    api_server_address,
    "exchanger_bankaccount",
    "ExchangerBankAccount"
  ),
  ..._crudMaker(api_server_address, "global-var", "GlobalVar"),
  ..._crudMaker(api_server_address, "money-to-token", "MoneyToToken"),
  ..._crudMaker(api_server_address, "token-to-money", "TokenToMoney"),
  ..._crudMaker(api_server_address, "token-to-token", "TokenToToken"),
  ..._crudMaker(api_server_address, "user-bankaccount", "UserBankAccount"),
  ..._crudMaker(api_server_address, "coin-exchange"),

  readUsersSafe,
  readUsersAccount,
  readExchangersSafe,
  readExchangeRates,
  readExchangeRatesMoneyToToken,
  // readExchangeRatesTokenToMoney,
  readExchangeRatesTokenToToken,
  createUser,
  createUsersTransfer,
  createUsersMoneyToToken,
  createUsersTokenToMoney,
  createUsersTokenToToken,
  updateUsersPassword,
  checkId,
  sendEmailOfLoginId,
  sendCodeForNewPassword,
  checkOTP,
  createUsersCoinExchange,
  getUsersCoinExchanges,
  convertCoin,
};
