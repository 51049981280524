import React from 'react';


const MinorHeader = (props) => {
  const props_style = props.style;
  const default_style = { display: "flex", alignItems: "center" };

  return (
    <div style={Object.assign({}, props_style, default_style )}>
      <div style={{ flex: "0 1 auto" }}>
        {props.title || 'no Title'}
      </div>
      <div style={{ flex: "1 1 0px", height: "1px", background: "RGB(68, 114, 196)", marginLeft: "0.5rem" }} />
    </div>
  );  
}


export default MinorHeader;