import React from "react";
import { Row, Col, Modal, Typography, Select } from "antd";
import moment from "moment";
import "moment/locale/ko";
//* view components
import Loading from "../../../../snippets/Loading";
//* utils
import common from "../../../../utils/common";
import eos_wallet from "../../../../utils/eos_wallet";
import server_api from "../../../../utils/server_api";
//* styles
import "../../../../styles/infinity_lists.less";
//* resources
import lang from "../../../../lang/lang";

class TransferList extends React.PureComponent {
  no_data_messege = lang.get("wallet/record/transfer/no_data_text");
  status_localize = {
    PRE_TRANSFER: lang.get("wallet/record/transfer/status/pre_transfer"),
    ON_TRANSFERING: lang.get("wallet/record/transfer/status/on_transfering"),
    OK: lang.get("wallet/record/transfer/status/ok"),
    FAILED: lang.get("wallet/record/transfer/status/failed")
  };

  static defaultProps = {
    year: new Date().getFullYear(),
    month: new Date().getMonth()
  };

  is_mounted_ = false;
  state = {
    is_loading: false,
    data: [],
    selected_filter: "all",
    modal2Visible: false,
    selected_item: {}
  };
  setstate = (partial_state) =>
    this.is_mounted_ === true && this.setState(partial_state);

  async componentDidMount() {
    this.is_mounted_ = true;

    await this._selectFilter(
      this.state.selected_filter,
      this.props.year,
      this.props.month
    );
  }

  componentWillUnmount() {
    this.is_mounted_ = false;
  }

  async componentWillReceiveProps(nextProps) {
    const is_same_year = this.props.year === nextProps.year;
    const is_same_month = this.props.month === nextProps.month;
    if (is_same_year && is_same_month) {
      return;
    }

    await this._selectFilter(
      this.state.selected_filter,
      nextProps.year,
      nextProps.month
    );
  }

  render() {
    console.log(this.state.selected_item);
    return (
      <div style={{ display: "table", height: "100%", width: "100%" }}>
        <div style={{ display: "table-row" }}>
          <div style={{ display: "table-cell" }}>
            <div style={{ padding: "1rem 0.5rem 0" }}>
              <Select
                defaultValue="all"
                value={this.state.selected_filter}
                style={{ width: "100%" }}
                onChange={(value) =>
                  this._selectFilter(value, this.props.year, this.props.month)
                }
              >
                <Select.Option value="all">
                  {lang.get("wallet/record/transfer/select/all")}
                </Select.Option>
                <Select.Option value="to">
                  {lang.get("wallet/record/transfer/select/to")}
                </Select.Option>
                <Select.Option value="from">
                  {lang.get("wallet/record/transfer/select/from")}
                </Select.Option>
              </Select>
            </div>
          </div>
        </div>
        <div style={{ display: "table-row", height: "100%" }}>
          <div style={{ display: "table-cell", padding: "0.5rem 0" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  bottom: "0",
                  left: "0",
                  right: "0",
                  overflow: "auto",
                  padding: "0 0.5rem"
                }}
              >
                <Loading
                  is_loading={this.state.is_loading}
                  overall={false}
                  style={{ height: "100%" }}
                >
                  <NList
                    data={this.state.data}
                    onClick={this._hShowModal.bind(this)}
                    status_localize={this.status_localize}
                    no_data_messege={this.no_data_messege}
                  />
                  <Modal
                    title={
                      <div style={{ fontWeight: "700" }}>
                        <Typography.Text>
                          {lang.get(
                            "wallet/record/transfer/modal_detail/title"
                          )}
                        </Typography.Text>
                      </div>
                    }
                    centered
                    visible={this.state.modal2Visible}
                    onOk={() => this.setstate({ modal2Visible: false })}
                    onCancel={() => this.setstate({ modal2Visible: false })}
                    footer={null}
                  >
                    <table style={{ color: "RGB(50, 50, 50)" }}>
                      <tbody>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>
                            {this.state.selected_item.from_user_id
                              ? lang.get(
                                  "wallet/record/transfer/modal_detail/sender_id"
                                )
                              : lang.get(
                                  "wallet/record/transfer/modal_detail/sender_id_exchanger"
                                )}
                          </td>
                          <td
                            style={{
                              paddingLeft: "0.5rem",
                              borderLeft: "1px solid RGB(230, 230, 230)"
                            }}
                          >
                            {this.state.selected_item.from_user_id ||
                            this.state.selected_item.from_eos_account ===
                              common.get().eos_account
                              ? common.get().login_id
                              : this.state.selected_item.from_eos_account || ""}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>
                            {this.state.selected_item.to_user_id
                              ? lang.get(
                                  "wallet/record/transfer/modal_detail/receiver_id"
                                )
                              : lang.get(
                                  "wallet/record/transfer/modal_detail/receiver_exchanger"
                                )}
                          </td>
                          <td
                            style={{
                              paddingLeft: "0.5rem",
                              borderLeft: "1px solid RGB(230, 230, 230)"
                            }}
                          >
                            {this.state.selected_item.to_user_id ||
                            this.state.selected_item.to_eos_account ===
                              common.get().eos_account
                              ? common.get().login_id
                              : this.state.selected_item.to_eos_account || ""}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>
                            {lang.get(
                              "wallet/record/transfer/modal_detail/token_amount"
                            )}
                          </td>
                          <td
                            style={{
                              paddingLeft: "0.5rem",
                              borderLeft: "1px solid RGB(230, 230, 230)"
                            }}
                          >{`${eos_wallet.formatCommaNumber(
                            this.state.selected_item.amount || "0"
                          )} ${this.state.selected_item.token_symbol ||
                            ""}`}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>
                            {lang.get(
                              "wallet/record/transfer/modal_detail/log"
                            )}
                          </td>
                          <td
                            style={{
                              paddingLeft: "0.5rem",
                              borderLeft: "1px solid RGB(230, 230, 230)"
                            }}
                          >
                            {this.state.selected_item.log || ""}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>
                            {lang.get(
                              "wallet/record/transfer/modal_detail/date"
                            )}
                          </td>
                          <td
                            style={{
                              paddingLeft: "0.5rem",
                              borderLeft: "1px solid RGB(230, 230, 230)"
                            }}
                          >
                            {moment(
                              this.state.selected_item.createdAt || 0
                            ).format("YY-MM-DD HH:mm:ss")}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>
                            {lang.get(
                              "wallet/record/transfer/modal_detail/status"
                            )}
                          </td>
                          <td
                            style={{
                              paddingLeft: "0.5rem",
                              borderLeft: "1px solid RGB(230, 230, 230)"
                            }}
                          >
                            {this.status_localize[
                              this.state.selected_item.status
                            ] || ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Modal>
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async _selectFilter(value, year, month) {
    try {
      this.setstate({ is_loading: true });
      let data = (
        await server_api.fetchTransfers({
          where: {
            createdAt: {
              $gte: new Date(year, month),
              $lt: new Date(year, month + 1)
            }
          },
          attributes: [
            "amount",
            "createdAt",
            "from_eos_account",
            "from_user_id",
            "id",
            "log",
            "receiver_type",
            "status",
            "to_eos_account",
            "to_user_id",
            "token_symbol"
            // 'updatedAt',
          ],
          include: [
            { association: "from_user", attributes: ["login_id"] },
            { association: "to_user", attributes: ["login_id"] }
          ]
        })
      ).items;

      switch (value) {
        case "all":
          break;
        case "to":
          data = data.filter(
            (datum) =>
              datum.from_user_id === common.get().id ||
              datum.from_eos_account === common.get().eos_account
          );
          break;
        case "from":
          data = data.filter(
            (datum) =>
              datum.to_user_id === common.get().id ||
              datum.to_eos_account === common.get().eos_account
          );
          break;
        default:
          if (process.env.NODE_ENV === "development") {
            return console.error("exception case");
          }
          break;
      }

      data.reverse();

      this.setstate({
        is_loading: false,
        selected_filter: value,
        data
      });
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        return console.error(e);
      }

      this.setstate({
        is_loading: false,
        selected_filter: value,
        data: []
      });
    }
  }

  async _hShowModal(e) {
    let selected_item = this.state.data.filter(
      (datum) => String(datum.id) === String(e.target.dataset.id)
    );

    if (selected_item.length >= 1)
      this.setstate({
        is_loading: false,
        modal2Visible: true,
        selected_item: selected_item[0]
      });
  }
}

const NList = (props) => {
  if (props.data.length === 0) {
    return (
      <li>
        <div style={{ textAlign: "center", paddingTop: "1rem" }}>
          {props.no_data_messege}
        </div>
      </li>
    );
  }

  const user_id = common.get().login_id;
  let lis = [];
  let current_date = "";
  props.data.forEach((datum, i) => {
    const is_date_changed =
      current_date !== new Date(datum.createdAt).getDate();
    if (is_date_changed) {
      current_date = new Date(datum.createdAt).getDate();
      lis.push(
        <li key={`date_${i}`}>
          <div
            style={{
              paddingTop: "1rem",
              fontWeight: "700",
              color: "RGB(58, 99, 173)"
            }}
          >
            {moment(datum.createdAt).format("MM.DD (dd)")}
          </div>
        </li>
      );
    }

    const is_user_sender = datum.from_eos_account === common.get().eos_account;

    const style = is_user_sender
      ? { fontSize: "1rem", fontWeight: "700", color: "#a33" }
      : { fontSize: "1rem", fontWeight: "700", color: "#33a" };

    let title;
    if (datum.from_user_id === null && datum.to_user_id === null) {
      title = (
        <div
          style={{
            fontWeight: "700",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
          }}
        >
          {is_user_sender
            ? `${datum.to_eos_account}`
            : `${datum.from_eos_account}`}
        </div>
      );
    } else {
      switch (datum.receiver_type) {
        case "BY_LOGIN_ID":
          title = (
            <div
              style={{
                fontWeight: "700",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
              }}
            >
              {is_user_sender
                ? `${datum.to_user_login_id}`
                : `${datum.from_user_login_id}`}
            </div>
          );
          break;
        case "BY_EOS_ACCOUNT":
          title = (
            <div
              style={{
                fontWeight: "700",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
              }}
            >
              {is_user_sender
                ? `${datum.to_eos_account}`
                : `${datum.from_user_login_id}`}
            </div>
          );
          break;
        default:
          if (process.env.NODE_ENV === "development") {
            console.error("exception case");
          }
      }
    }

    lis.push(
      <li
        key={i}
        style={
          i !== 0 && !is_date_changed
            ? { borderTop: "1px dotted rgb(230, 230, 230)" }
            : {}
        }
      >
        <div
          style={{
            padding: "0.2rem 0",
            color: "RGB(50, 50, 50)",
            cursor: "pointer"
          }}
          onClick={props.onClick}
          data-id={datum.id}
        >
          <div style={{ pointerEvents: "none" }}>
            <Row type="flex" justify="space-between" style={{ width: "100%" }}>
              <Col span={8}>
                <div>
                  {title}
                  <div
                    style={{
                      color: "RGB(166, 166, 166)",
                      fontSize: "0.8rem",
                      fontWeight: "100"
                    }}
                  >
                    {`${new Date(datum.createdAt).toLocaleTimeString([], {
                      hour12: false,
                      hour: "2-digit",
                      minute: "2-digit"
                    })}, ${props.status_localize[datum.status]}`}
                  </div>
                </div>
              </Col>
              <Col span={16}>
                <div style={{ textAlign: "right" }}>
                  <div>
                    <span style={style}>{`${
                      is_user_sender ? "-" : ""
                    }${eos_wallet.formatCommaNumber(datum.amount)}`}</span>
                    <span
                      style={{ fontSize: "0.75rem", paddingLeft: "0.3rem" }}
                    >
                      {datum.token_symbol}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </li>
    );
  });

  lis.push(<div style={{ paddingTop: "100px" }}></div>);

  return lis;
};

export default TransferList;
