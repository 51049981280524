import React from 'react';
import { Row, Col, Modal, Typography } from 'antd';
import moment from 'moment';
import 'moment/locale/ko';
//* view components
import Loading from '../../../../snippets/Loading';

//* styles
import '../../../../styles/infinity_lists.less';
//* utils
import common     from '../../../../utils/common';
import server_api from '../../../../utils/server_api';
import eos_wallet from '../../../../utils/eos_wallet';
//* resources
import lang from '../../../../lang/lang';




class FillingList extends React.PureComponent {
  no_data_messege = lang.get('wallet/record/filling/no_data_text');
  status_localize = {
    START        : lang.get('wallet/record/filling/status/start'),
    EXCHANGABLE  : lang.get('wallet/record/filling/status/exchangable'),
    PAY_CONFIRMED: lang.get('wallet/record/filling/status/pay_confirmed'),
    OK           : lang.get('wallet/record/filling/status/ok'),
    CANCELED     : lang.get('wallet/record/filling/status/canceled'),
    FAILED       : lang.get('wallet/record/filling/status/failed'),
  }

  static defaultProps = {
    year : new Date().getFullYear(),
    month: new Date().getMonth()
  }
  
  is_mounted_ = false;
  state = {
    is_loading: false,
    data: [],
    modal2Visible: false,
    selected_item: {}
  };  
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });

    this.setstate({
      is_loading: false,
      data: await this._refreshData(this.props.year, this.props.month)
     });
  }

  componentWillUnmount() { this.is_mounted_ = false; }
  
  async componentWillReceiveProps(nextProps) {
    const is_same_year  = this.props.year  === nextProps.year;
    const is_same_month = this.props.month === nextProps.month;
    if (is_same_year && is_same_month) {
      return;
    }

    this.setstate({ is_loading: true });

    this.setstate({
      is_loading: false,
      data: await this._refreshData(nextProps.year, nextProps.month)
    });  
  }

  render() {
    return (
      <div style={{ display: "table", height: "100%", width: "100%" }}>
        <div style={{ display: "table-row", height: "100%" }}>
          <div style={{ display: "table-cell", padding: "0.5rem 0" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div style={{ position: "absolute", top: "0", bottom: "0", left: "0", right: "0", overflow: "auto" }}>
                <Loading is_loading={this.state.is_loading} overall={false} style={{ height: "100%" }}>
                  <NList data={this.state.data} onClick={this._hShowModal.bind(this)} no_data_messege={this.no_data_messege} status_localize={this.status_localize} />
                  <Modal
                    title={<div style={{ fontWeight: "700" }}><Typography.Text>{lang.get('wallet/record/filling/modal_detail/title')}</Typography.Text></div>}
                    centered
                    visible={this.state.modal2Visible}
                    onOk={() => this.setstate({ modal2Visible: false })}
                    onCancel={() => this.setstate({ modal2Visible: false })}
                    footer={null}
                  >
                    <table style={{ color: "RGB(50, 50, 50)"}}>
                      <tbody>
                        <tr>
                          <td style={{ paddingRight: "0.5rem "}}>{lang.get('wallet/record/filling/modal_detail/exchanger_name')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)"}}>{(this.state.selected_item.exchanger || {}).name || ''}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem "}}>{lang.get('wallet/record/filling/modal_detail/exchanger_bank_account')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)"}}>{`${((this.state.selected_item.exchanger_bankaccount || {}).bank || {}).name || ''} ${(this.state.selected_item.exchanger_bankaccount || {}).bank_account || ''}`}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem "}}>{lang.get('wallet/record/filling/modal_detail/money_amount')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)"}}>{`${eos_wallet.formatCommaNumber(this.state.selected_item.money_amount || '0')} ${this.state.selected_item.money_currency_symbol || ''}`}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem "}}>{lang.get('wallet/record/filling/modal_detail/token_amount')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)"}}>{`${eos_wallet.formatCommaNumber(this.state.selected_item.token_amount || '0')} ${this.state.selected_item.token_symbol || ''}`}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem "}}>{lang.get('wallet/record/filling/modal_detail/date')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)"}}>{moment(this.state.selected_item.createdAt || 0).format('YY-MM-DD HH:mm:ss')}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem "}}>{lang.get('wallet/record/filling/modal_detail/status')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)"}}>{this.status_localize[this.state.selected_item.status] || ''}</td>
                        </tr>
                        {(this.state.selected_item.status === 'FAILED' || this.state.selected_item.status === 'CANCELED')
                          ? ( <tr>
                                <td style={{ paddingRight: "0.5rem "}}>{lang.get('wallet/record/filling/modal_detail/reason')}</td>
                                <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)"}}>{this.state.selected_item.cancel_log || ''}</td>
                              </tr> )
                          : null
                        }
                      </tbody>
                    </table>
                  </Modal>
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async _hShowModal (e) {
    let selected_item = (this.state.data).filter(datum => String(datum.id) === String(e.target.dataset.id));

    if (selected_item.length >= 1)
      this.setstate({
        is_loading: false,
        modal2Visible: true,
        selected_item: selected_item[0]
      });    
  }

  async _refreshData (year, month) {
    try {
      const data = (await server_api.fetchMoneyToTokens({
        where: {
          createdAt: {
            $gte : new Date(year, month),
            $lt: new Date(year, month + 1)
          },
          user_id: common.get().id
        },
        attributes: [
          'cancel_log',
          'createdAt',
          'exchanger_bankaccount_id',
          // 'exchanger_id',
          'id',
          // 'log',
          'money_amount',
          'money_currency_symbol',
          // 'sealedAt',
          'status',
          'token_amount',
          'token_symbol',
          // 'updatedAt',
          // 'user_bankaccount_id',
          // 'user_id',
        ],
        include: [
          // { association: 'user', attributes: ['login_id'] },
          { association: 'exchanger', attributes: ['name'] },
          // { association: 'user_bankaccount' },
          { association: 'exchanger_bankaccount', attributes: ['bank_account'], include: [
            { association: 'bank', attributes: ['name'] }
          ] }
        ]
      })).items;
      data.reverse();
  
      return data;
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.log(e);
      }
      return []
    }    
  }
}


const NList = (props) => {
  if (props.data.length === 0) {
    return (
      <li>
        <div style={{ textAlign: "center" }}>{props.no_data_messege}</div>
      </li>
    )
  }

  let lis = [];
  let current_date = '';
  props.data.forEach((datum, i) => {
    const is_date_changed = current_date !== new Date(datum.createdAt).getDate();
    if (is_date_changed) {      
      current_date = new Date(datum.createdAt).getDate();
      lis.push(<li key={`date_${i}`}><div style={{ marginTop: "1rem", fontWeight: "700", color: "RGB(58, 99, 173)" }}>
        {moment(datum.createdAt).format('MM.DD (dd)')}
      </div></li>);      
    }

    lis.push(<li key={i} style={(i !== 0 && !is_date_changed)? { borderTop: "1px dotted rgb(230, 230, 230)"}: { }}>
      <div style={{ padding: "0.2rem 0", color: "RGB(50, 50, 50)", cursor: "pointer"}} onClick={props.onClick} data-id={datum.id}>
        <div style={{ pointerEvents: "none" }}>
          <Row type="flex" justify="space-between" style={{ width: "100%" }}>
            <Col span={8}>
              <div>
                <div style={{ fontWeight: "700" }}>{datum.exchanger.name}</div>
                <div style={{ color: "RGB(166, 166, 166)", fontSize: "0.75rem"}}>
                  {`${(new Date(datum.createdAt)).toLocaleTimeString([], {
                    "hour12": false,
                    "hour": "2-digit",
                    "minute": "2-digit"
                  })}, ${props.status_localize[datum.status]}`}
                </div>
              </div>
            </Col>
            <Col span={16}>
              <div style={{ textAlign: "right"}}>
                <div>
                  <span style={{ fontSize: "1rem", fontWeight: "700", color: "red"}}>{eos_wallet.formatCommaNumber(datum.token_amount)}</span>
                  <span style={{ fontSize: "0.75rem", paddingLeft: "0.3rem" }}>{`${datum.token_symbol}`}</span>                  
                </div>
                <div style={{ fontSize: "0.75rem", color: "rgb(150, 150 ,150)" }}>{lang.get('wallet/record/filling/from_symbol', { currency_from : datum.money_currency_symbol })}</div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </li>)
  });

  return lis;
}

export default FillingList;