import React from 'react';
import { Button, Typography, Icon, Row, Col, Select, ConfigProvider, Modal } from 'antd';
import query_string from 'query-string';
//* view components
import Loading from '../../../snippets/Loading';
import LocationList from './location/snippets/LocationList';
//* utils
import common      from '../../../utils/common';
import eos_wallet  from '../../../utils/eos_wallet';
import native_util from '../../../utils/native_util';
import server_api  from '../../../utils/server_api';
//* styles
import './location.less';
//* resources
import lang from '../../../lang/lang';
import img_exchange_location from '../../../images/exchange_location.png';




class Location extends React.Component {
  is_mounted_ = false;
  state = {
    is_loading: false,
    current_location: void 0,
    header_text: '',
    selected_exchanger: {
      // id: -1,
      // name: '',
      // address: '',
      // phone_numbers: '',
      // latitude: '',
      // longitude: '',
    },
    visible_location_list_drawer: false,
    exchanger_id_to_read: -1,
    data_exchangers: [],
    data_exchangers_registered: [],
  }

  constructor(props) { super(props);
    // console.log(this.props.location.state);
    // if (!this.props.location.state)
    //   return this.props.history.push('/');

    this.state = {
      ...this.state,
      selected_exchanger: (props.location.state || {}).selected_exchanger || this.state.selected_exchanger,
    }
  }
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    if (await common.isLoggedIn() === false) {
      return;
    }
    
    let current_location = void 0;
    if (native_util.hasNativeApi() === true) {
      try {
        const [lat, lng] = await native_util.getCurrentLocation();
        current_location = { lat, lng };
      }
      catch (e) {
        Modal.error({
          title: lang.get('all/location/modal_case3/title'),
          content: lang.get('all/location/modal_case3/content'),
          centered: true
        });
        if (process.env.NODE_ENV === 'development') {
          console.error('permission error');
        }
      }
    }else if ("geolocation" in navigator) {
      /* geolocation is available */
      current_location = await new Promise(resolve => navigator.geolocation.getCurrentPosition(function (position) {
        resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
      }, function (error) {
        if (error.code === error.PERMISSION_DENIED) {
          Modal.error({
            title: lang.get('all/location/modal_case3/title'),
            content: lang.get('all/location/modal_case3/content'),
            centered: true
          });
          if (process.env.NODE_ENV === 'development') {
            console.error('permission error');
          }
        }
        resolve(void 0);
      }));
    }

    const data_exchangers            = await this._fetchExchangers();
    const data_exchangers_registered = await this._fetchExchangersRegistered();

    this.setstate({
      is_loading: false,      
      header_text: await eos_wallet.getHeaderTokenText() || '',
      current_location,
      data_exchangers,
      data_exchangers_registered,
    })
  }
  componentWillUnmount() { this.is_mounted_ = false; }

  render () {
    if (common.get().token === void 0) {
      return null;
    }

    let view_exchanger = void 0;
    if ((this.state.selected_exchanger || {}).id) {
      view_exchanger = this.state.selected_exchanger
    } else if ((this.state.data_exchangers_registered || {}).id) {
      view_exchanger = this.state.data_exchangers_registered
    }

    return (
      <Loading is_loading={this.state.is_loading} overall={true} style={{ display: "table", tableLayout: "fixed", width: "100%", height: "100%" }}>

        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>

            {/** HEADER *****/}
            <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  onClick={e => this.props.history.push('/wallet')}
                  style={{
                      flex: "1 1 0px", borderRadius: "4px", padding: "0.2rem 1rem"
                    , display: "block", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"
                    , fontSize: "1rem", color: "#fff", background: "rgb(51, 129, 252)", cursor: 'pointer'
                  }} >
                  {this.state.header_text}&nbsp;
                </div>
                <div style={{ flex: "0 0 auto", padding: "0 0 0 0.5rem"}}>
                  <Button type="link" style={{ padding: "0", pointerEvents: "none", height: "2.5rem" }}>
                    <Icon type="plus" style={{ fontSize: "1.5rem", color: "rgba(255, 255, 255, 0)" }} />
                  </Button> 
                </div>
              </div>
              <div>
                <Typography.Title level={3} style={{ display: 'flex', alignItems: 'center', marginBottom: "0", marginTop: "1.5rem" }}>
                  {(query_string.parse(this.props.location.search).return)
                    ? ( <Button size="small" type="link" onClick={e => this.props.history.push({
                            pathname: query_string.parse(this.props.location.search).return,
                            state: {
                              ...this.props.location.state
                            }
                          })} style={{ paddingLeft: "0" }}>
                            <Icon type="arrow-left" theme="outlined" style={{ fontSize: "1rem", color: "RGB(75, 75, 75)" }} />
                        </Button>)
                    : null
                  }
                  <Typography.Text>{lang.get('all/location/title')}</Typography.Text>
                </Typography.Title>
              </div>              
            </div>

          </div>
        </div>

        <div style={{ display: "table-row", height: "2%" }} />

        <div style={{ display: "table-row", height: "0"}}> {/* header 2 */}
          <div style={{ display: "table-cell", padding: "0rem 1rem 0.5rem" }}>
            <div>
              {/* Input Location */}
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <div className="exchange-location-search"
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: "1px solid rgb(150, 150, 150)", borderRadius: "16px", padding: "0 0.5rem" }}>
                  <div>
                    <Button type="link" style={{ padding: "0 0.2rem" }}>
                      <Icon style={{ fontSize: "1rem", pointerEvents: 'none' }} type="search" />
                    </Button>
                  </div>
                  <div style={{ flex: "1 1 0"}}>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      onChange={(value) => this._hSearch(value)}
                      // onFocus={(e) => console.log(e)}
                      // onBlur={(e) => console.log(e)}
                      // onSearch={(e) => console.log(e)}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this._rSearchList()}
                    </Select>
                  </div>      
                  <div>
                    <Button type="link" style={{ padding: "0 0.2rem"}} onClick={e => this.props.history.push({
                      pathname: '/all/location/map',
                      search: this.props.location.search,
                      state: {
                        ...this.props.location.state,
                        selected_exchanger: this.state.selected_exchanger
                      }
                    })}>
                      <Icon style={{ fontSize: "1.2em" }} type="environment" theme="filled" />
                    </Button>
                    <Button type="link" style={{ padding: "0 0.2rem"}} onClick={async e => {
                      if (native_util.hasNativeApi()) {
                        const data = await native_util.pushQRReaderView();
                        if (!data)
                          return;

                        this.setState({ is_loading: true, });

                        let selected_exchanger;
                        try {
                          selected_exchanger = (await server_api.readExchangersSafe({
                            where: {
                              login_id: data,
                              is_archived: false,
                            },
                            include: [
                              {
                                association: 'bankaccounts',
                                where: {
                                  is_archived: false,
                                },
                                required: true,
                                include: [{ association: 'bank' }]
                              }
                            ]
                          })).items[0];
                        }
                        catch (e) {
                          if (process.env.NODE_ENV === 'development') {
                            console.error('permission error');
                          }
                        }
                                              
                        this.setState({ is_loading: false });
                        
                        if (selected_exchanger)
                          this.setState({ selected_exchanger, });
                        else
                          return native_util.toast(`no exchanger for id: ${data}`);
                        
                        return;
                      }
                      
                      this.props.history.push({
                        pathname: '/all/location/inputQRLocation',
                        search: this.props.location.search,
                        state: {
                          ...this.props.location.state,
                          selected_exchanger: this.state.selected_exchanger
                        }
                      });
                    }}>
                      <Icon style={{ fontSize: "1.2em" }} type="scan" />
                    </Button>
                  </div>            
                </div>
              </ConfigProvider>


              {/* Selected Location */}
              <div style={{ paddingTop: "1rem"}}>
                {(view_exchanger)
                  ? ( <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div style={{ width: "40%", minWidth: "100px" }}>
                          <div style={{ position: "relative", paddingTop: "100%" }}>
                            <div style={{ position: "absolute", top: "0", bottom: "0", left: "0", right: "0", padding: "2px" }}>
                              <div style={{ height: "100%", borderRadius: "50%", background: "rgb(30, 111, 220)", padding: "3px" }}>
                                <div style={{ height: "100%", borderRadius: "50%", background: "rgb(255, 255, 255)", padding: "2px"}}>
                                  <img alt="" src={img_exchange_location} style={{ width: "100%", borderRadius: "50%", objectFit: "cover" }} />
                                </div>
                              </div>
                            </div>                            
                          </div>
                        </div>
                        <div style={{ width: "100%", textAlign: "center", paddingTop: "0.5rem" }}>
                          <div style={{ fontSize: "1rem", fontWeight: "700" }}>
                            {view_exchanger.name}
                          </div>
                          <div style={{ color: "RGB(100, 100, 100)", fontSize: "0.75rem" }}>{view_exchanger.address}</div>
                          <div style={{ color: "RGB(100, 100, 100)", fontSize: "0.75rem" }}>{view_exchanger.phone_numbers}</div>
                          <div>
                            {this._rExchangerButtons(view_exchanger)}
                          </div>
                        </div>                        
                      </div>
                    )
                  : ( <div style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div style={{ width: "40%", minWidth: "100px" }}>
                          <div style={{ position: "relative", paddingTop: "100%" }}>
                            <div style={{ position: "absolute", top: "0", bottom: "0", left: "0", right: "0", padding: "2px" }}>
                              <div style={{ height: "100%", borderRadius: "50%", background: "rgb(30, 111, 220)", padding: "3px" }}>
                                <div style={{ height: "100%", borderRadius: "50%", background: "rgb(255, 255, 255)", padding: "2px"}}>
                                  <img alt="" src={img_exchange_location} style={{ width: "100%", borderRadius: "50%", objectFit: "cover" }} />
                                </div>
                              </div>
                            </div>                            
                          </div>
                        </div>
                        <div style={{ position: "absolute", bottom: "5%", margin: "0 auto" }}>
                          <div style={{ padding: "0.25rem 1rem", fontSize: "1rem", fontWeight: "700", textAlign: "center", background: "RGBA(210, 210, 210, 0.5)", borderRadius: "4px" }}>
                            {lang.get('all/location/no_exchanger')}
                          </div>
                        </div>
                      </div>
                    )
                }
              </div>

            </div>
          </div>
        </div>


        {/* Location List */}
        <div style={{ display: "table-row"}}>
          <div style={{ display: "table-cell", padding: "0.5rem" }}>
            <div style={{ position: "relative", height: "100%"}}>
              <div style={{ top: "0", bottom: "0", left: "0", right: "0", position: "absolute" }}>
                <div style={{ height: "100%", width: "100%" }}>
                  <div style={{ borderRadius: "0.6em", padding: "1rem 1rem", margin: "0 auto", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)", height: "100%", width: "100%" }}>
                    <div style={{ display: "table", height: "100%", width: "100%" }}>
                      <div style={{ display: "table-row", height: "0px" }}>
                        <div style={{ display: "table-cell" }}>
                          <div style={{ fontSize: "1rem", fontWeight: "700", marginBottom: "0.5rem" }}>
                            <Typography.Text>{lang.get('all/location/near_exchangers')}</Typography.Text>
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "table-row", height: "100%" }} >
                        <div style={{ display: "table-cell" }}>
                          <div style={{ position: "relative", height: "100%" }}>
                            <div style={{ top: "0", bottom: "0", left: "0", right: "0", position: "absolute", overflow: "auto" }}>
                              
                              <ConfigProvider renderEmpty={customizeRenderEmptyLocation}>
                                <LocationList
                                  data={this.state.data_exchangers}
                                  current_location={this.state.current_location}
                                  onClickLocation={value => {
                                    this.setstate({ selected_exchanger: value, visible_location_list_drawer: false })
                                  }} />
                              </ConfigProvider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Loading>
    )
  }



  _rExchangerButtons(view_exchanger) {
    const { data_exchangers_registered, current_location } = this.state;
   
    if (!view_exchanger.id) {
      return ( null )
    }

    let url = ``;
    if (current_location) {
      url = `https://www.google.com/maps/dir/${current_location.lat},${current_location.lng}/${view_exchanger.latitude},${view_exchanger.longitude}`
    }
    else {
      url = `https://www.google.com/maps/place/${view_exchanger.latitude},${view_exchanger.longitude}`
    }
    let buttons = [
      ( <Col key="0" span={6}>
         <Button size="small" style={{ width: "100%" }} onClick={e => window.open(url, "_blank")}>
            {(current_location)? lang.get('all/location/btn_find_way'): lang.get('all/location/btn_find_location')}
          </Button>
        </Col>
      )];

    const registered_exchanger_id = (data_exchangers_registered || {}).id;
    const view_exchanger_id = (view_exchanger || {}).id;
    const is_not_registered = (registered_exchanger_id !== view_exchanger_id);
    if (is_not_registered && !this.state.is_loading) {
      buttons.push(
        <Col key="1" span={6}>
          <Button size="small" style={{ width: "100%" }} onClick={e => this._hChangeRegisteredExchanger(view_exchanger_id)}>{lang.get('all/location/btn_change_location')}</Button>
        </Col>
      )
    }


    return (
      <Row gutter={8} type="flex" justify="center" align="middle">
        {buttons}
      </Row>
    )
  }



  _rSearchList () {
    const { data_exchangers } = this.state;

    return data_exchangers.map((exchanger, i) => (<Select.Option key={i} value={exchanger.id}>{exchanger.name}</Select.Option>));
  }

  

  async _hChangeRegisteredExchanger (exchanger_id) {    
    this.setstate({ is_loading: true });
    try {      
      await server_api.updateUser(
        common.get().id,
        {
          registered_exchanger_id: exchanger_id
        }
      );

      const data_exchangers_registered = await this._fetchExchangersRegistered();

      
      this.setstate({
        is_loading: false,
        data_exchangers_registered,
        selected_exchanger: data_exchangers_registered,
      })
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }
      
      Modal.error({
        title: lang.get('all/location/modal_case1/title'),
        okText: lang.get('modal/confirm_text')
      });
      this.setstate({
        is_loading: false,
      });
    }
  }




  async _hSearch (exchanger_id) {
    this.setstate({ is_loading: true });
    try {      
      const res_item = (await server_api.readExchangersSafe({
        where: {
          id: exchanger_id,
          is_archived: false,
        },
        include: [
          {
            association: 'bankaccounts',
            where: {
              is_archived: false,
            },
            required: true,
            // include: [{ association: 'bank' }]
          }
        ]
      })).items[0];

      if (!res_item) {
        Modal.error({
          title: lang.get('all/location/modal_case2/title'),
          okText: lang.get('modal/confirm_text')
        });
        return this.setstate({
          is_loading: false,
          selected_exchanger: {},
        });
      }

      const selected_exchanger = {
        id: res_item.id,
        name: res_item.name,
        address: res_item.address,
        phone_numbers: res_item.phone_numbers,
        latitude: res_item.latitude,
        longitude: res_item.longitude,
      }

      this.setstate({
        is_loading: false,
        selected_exchanger,
      })
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      this.setstate({
        is_loading: false
      });
    }
  }

  
  async _fetchExchangersRegistered () {    
    try {
      const res_user_item = (await server_api.fetchUsers({
        where: {
          id: common.get().id
        },
        attributes: ['registered_exchanger_id'],
        include: [
          { association: 'registered_exchanger' }]
      })).items[0];

      if (!res_user_item.registered_exchanger) {
        return {}
      }

      return {
        id: res_user_item.registered_exchanger_id,
        name: res_user_item.registered_exchanger.name,
        address: res_user_item.registered_exchanger.address,
        phone_numbers: res_user_item.registered_exchanger.phone_numbers,
        latitude: res_user_item.registered_exchanger.latitude,
        longitude: res_user_item.registered_exchanger.longitude,
      };
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }
      return {};
    }
  }

  async _fetchExchangers () {
    try {      
      const res_items = (await server_api.readExchangersSafe({
        where: {
          is_archived: false,
        },
        include: [{
          association: 'bankaccounts',
          where: {
            is_archived: false,
          },
          required: true,
          // include: [{ association: 'bank' }]
        }]
      })).items;

      return res_items;
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      return [];
    }
  }
}

const customizeRenderEmpty = () => (
  <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
    <div style={{ textAlign: 'center', padding: "1rem" }}>
      <Icon type="smile" style={{ fontSize: 20 }} />
      <div>{lang.get('all/location/no_list_data')}</div>
    </div>
  </div>
);

const customizeRenderEmptyLocation = () => (
  <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
    <div style={{ textAlign: 'center', padding: "1rem" }}>
      <Icon type="smile" style={{ fontSize: 20 }} />
      <div style={{ paddingTop: "0.25rem" }}>{lang.get('all/location/no_list_data_location')}</div>
    </div>
  </div>
);

export default Location;