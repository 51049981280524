import React from 'react';
import { List, message, Row, Col } from 'antd';
// import InfiniteScroll from 'react-infinite-scroller';
// import Loading from '../Loading';
//* utils
import caches     from '../../utils/cache'     ;
import eos_wallet from '../../utils/eos_wallet';
import s3         from '../../utils/s3'        ;
// import server_api from '../../utils/server_api';
//* variants
import config from '../../config';
//* styles
import '../../styles/infinity_lists.less';
//* resources
import img_no_country from '../../images/no_country.png';





class TokenAndBalanceList extends React.PureComponent {
  static defaultProps = {
    onClickCurrency: e => {},
    except_tokens: [],
  }

  is_mounted_ = false;
  state = {
    data: [],
    loading: false,
    hasMore: true,
  };
  
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });

    const disabled_tokens = config.disabled_token_symbols;
    const except_tokens = disabled_tokens.concat(this.props.except_tokens);
    const filtered_data = (this.props.data).filter(datum => (except_tokens).indexOf(datum.name) < 0 );
  
    // const own_tokens_array = (filtered_data).reduce((p, c, i, a) => {
    //   p.push(c.name);
    //   return p;
    // }, [])
    
    const res_tokens = (await caches.codes.get()).items;
    
    // const res_tokens = (await server_api.fetchTokens({
    //   where: {
    //     token_symbol: {
    //       $in: own_tokens_array
    //     }
    //   }
    // })).items;

    let token_country_obj = {}
    for (let v of res_tokens) {
      token_country_obj[v.token_symbol] = v.country_code;
    }


    this.setstate({
      data: filtered_data,
      token_country_obj
    });
  }
  componentWillUnmount() { this.is_mounted_ = false; }

  handleInfiniteOnLoad = () => { // for infinity scroll
    let { data } = this.state;
    this.setstate({
      loading: true,
    });
    if (data.length > 14) {
      message.warning('List loaded all');
      this.setstate({
        hasMore: false,
        loading: false,
      });
      return;
    }
    this.fetchData(res => { 
      data = data.concat(res.results);
      this.setstate({
        data,
        loading: false,
      });
    });
  };

  render() {
    if (this.state.data.length === 0) {
      return (
        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          보유한 토큰이 없습니다.
        </div>
      );
      // return <Loading overall={false} is_loading={true} style={{ width: "100%", height: "100%" }} />
    }

    return (
      // <InfiniteScroll
      //   initialLoad={false}
      //   pageStart={0}
      //   loadMore={this.handleInfiniteOnLoad}
      //   hasMore={!this.state.loading && this.state.hasMore}
      //   useWindow={false}
      // >
        <List
          // header={
          //   <Row type="flex" justify="center">
          //     <Col span={4}></Col>
          //     <Col span={4}>토큰</Col>
          //     <Col span={12} style={{ textAlign: "right"}}>보유</Col>
          //   </Row>
          // }
          dataSource={this.state.data}
          renderItem={(account, i) => {
            let img_url;
            if (account.name === config.major_token_symbol) {
              img_url = img_no_country;
            }
            else {
              img_url = `${s3.server_address}/${s3.flag_image_path}/${this.state.token_country_obj[account.name]}.png`;
            }

            return ( <List.Item key={'cur'+i} style={{ padding: "0.4rem 0", border: "none", cursor: "pointer" }} onClick={this.onClickCurrency.bind(this)} data-account_name={account.name}>
                      <Row type="flex" justify="center" align="middle" style={{ width: "100%", pointerEvents: "none", fontWeight: "700" }}>
                          <Col span={4} >
                            <img alt="" src={img_url} onError={(e) => { e.target.onerror = null; e.target.src = img_no_country }} style={{ width: "2rem", display: "inline-block" }} />
                          </Col>
                          <Col span={5} >
                            {account.name}
                          </Col>
                          <Col span={11} style={{textAlign: "right"}}>
                            {eos_wallet.formatCommaNumber(account.value)}
                          </Col>
                      </Row>
                    </List.Item> )
          }}
        >
          {/* {this.state.loading && this.state.hasMore && (
            <div className="infinity-loading-container">
              <Spin />
            </div>
          )} */}
        </List>
      // </InfiniteScroll>
    );
  }

  onClickCurrency (e) {
    this.props.onClickCurrency(e.target.dataset.account_name);
  }
}


export default TokenAndBalanceList;