import React from "react";
import { Input, Button, Icon, Modal } from "antd";

//* view componets
import Loading from "../../snippets/Loading";
//* utils
import checkers from "../../utils/checkers";
import eos_wallet from "../../utils/eos_wallet";
import server_api from "../../utils/server_api";
//* resources
import lang from "../../lang/lang";
import logo from "../../images/logos/LogoIcon@3x.png";
import en from "../../images/en.png";
import kr from "../../images/kr.png";
import common from "../../utils/common";

class PWSearch extends React.Component {
  is_mounted_ = false;
  state = {
    is_loading: false,
    login_id: "",
    email: "",
    code_for_new_password: "",
    new_password: "",
    error_message: "",
    focused_offsetTop: void 0,
  };

  // constructor (props) { super (props);
  //   this.state = {
  //     ...this.state,
  //   }

  //   this._onResize = this._onResize.bind(this);
  // }
  setstate = (partial_state) =>
    this.is_mounted_ === true && this.setState(partial_state);

  componentDidMount() {
    this.is_mounted_ = true;
    // window.addEventListener('resize', this._onResize);
  }
  componentWillUnmount() {
    this.is_mounted_ = false;
    // window.removeEventListener('resize', this._onResize);
  }
  // _onResize () {
  //   if (checkers.is_mobile === false) {
  //     return;
  //   }
  //   if (!this.state.focused_offsetTop) {
  //     return;
  //   }

  //   if (this.is_mounted_)
  //     document.getElementById('scrollbox').scrollTop = this.state.focused_offsetTop;
  // }

  render() {
    return (
      <Loading
        is_loading={this.state.is_loading}
        has_footer={false}
        overall={true}
        style={{ width: "100%", height: "100%", display: "table" }}
      >
        <div style={{ display: "table-row", height: "100%" }}>
          <div style={{ display: "table-cell", position: "relative" }}>
            {/* <div id="scrollbox" style={{ position: "absolute", width: "100%", height: "100%", overflow: "auto" }}> */}
            <div>
              <div style={{ height: "10%", minHeight: "50px" }} />
              <div
                style={{
                  width: "80%",
                  margin: "0 auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={logo}
                  alt=""
                  style={{
                    width: "2.2rem",
                    maxWidth: "99px",
                    height: "auto",
                    maxHeight: "99px",
                  }}
                />
                <span
                  style={{
                    fontSize: "2.9rem",
                    marginLeft: "0.5rem",
                    color: "rgb(130, 130, 130)",
                  }}
                >
                  GGP Wallet
                </span>
              </div>
              <div
                style={{
                  width: "80%",
                  margin: "0 auto",
                  fontSize: "1.4rem",
                  fontWeight: "700",
                }}
              >
                Password
              </div>
              <div style={{ height: "7%", minHeight: "40px" }} />
              <div
                style={{ width: "70%", minWidth: "288px", margin: "0 auto" }}
              >
                <div style={{ display: "flex" }}>
                  <div>1.&nbsp;</div>
                  <div>{lang.get("pw_search/guide1")}</div>
                </div>
                <NyobInput
                  type="text"
                  labelText={lang.get("pw_search/id")}
                  maxLength={30}
                  onChangeInput={(e) =>
                    this.setstate({ login_id: e.target.value })
                  }
                  setOffset={async (v) => {
                    await new Promise((resolve) => setTimeout(resolve, 300));
                    window.scrollTo(0, v - 80);
                  }}
                  suffix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.45)", fontSize: "1.5rem" }}
                    />
                  }
                />
                <NyobInput
                  type="email"
                  labelText={lang.get("pw_search/email")}
                  maxLength={255}
                  onChangeInput={(e) =>
                    this.setstate({ email: e.target.value })
                  }
                  setOffset={async (v) => {
                    await new Promise((resolve) => setTimeout(resolve, 300));
                    window.scrollTo(0, v - 80);
                  }}
                  suffix={
                    <Icon
                      type="mail"
                      style={{ color: "rgba(0,0,0,.45)", fontSize: "1.5rem" }}
                    />
                  }
                />
                <div style={{ marginTop: "0.5rem" }}>
                  <Button
                    style={{
                      width: "100%",
                      fontWeight: "700",
                      color: "#fff",
                      background: "rgb(64 118 212)",
                      border: "1px solid rgb(64 118 212)",
                    }}
                    onClick={this._submitCodeForNewPassword.bind(this)}
                  >
                    {lang.get("pw_search/btn_send_code")}
                  </Button>
                </div>
                <div style={{ display: "flex", marginTop: "2.5rem" }}>
                  <div>2.&nbsp;</div>
                  <div>{lang.get("pw_search/guide2")}</div>
                </div>
                <NyobInput
                  type="password"
                  labelText={lang.get("pw_search/code_for_new_password")}
                  maxLength={30}
                  onChangeInput={(e) =>
                    this.setstate({ code_for_new_password: e.target.value })
                  }
                  setOffset={async (v) => {
                    await new Promise((resolve) => setTimeout(resolve, 300));
                    window.scrollTo(0, v - 80);
                  }}
                  suffix={
                    <Icon
                      type="file-protect"
                      style={{ color: "rgba(0,0,0,.45)", fontSize: "1.5rem" }}
                    />
                  }
                />
                <NyobInput
                  type="text"
                  labelText={lang.get("pw_search/id")}
                  maxLength={30}
                  onChangeInput={(e) =>
                    this.setstate({ login_id: e.target.value })
                  }
                  setOffset={async (v) => {
                    await new Promise((resolve) => setTimeout(resolve, 300));
                    window.scrollTo(0, v - 80);
                  }}
                  suffix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.45)", fontSize: "1.5rem" }}
                    />
                  }
                />
                <NyobInput
                  type="password"
                  labelText={lang.get("pw_search/new_password")}
                  maxLength={40}
                  onChangeInput={(e) =>
                    this.setstate({ new_password: e.target.value })
                  }
                  setOffset={async (v) => {
                    await new Promise((resolve) => setTimeout(resolve, 300));
                    window.scrollTo(0, v - 80);
                  }}
                  suffix={
                    <Icon
                      type="lock"
                      style={{ color: "rgba(0,0,0,.45)", fontSize: "1.5rem" }}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "table-row" }}>
          <div style={{ display: "table-cell" }}>
            <div
              className="bottom-buttons"
              style={{ bottom: "0", padding: "0 1rem 0" }}
            >
              <div>
                <Button
                  size="large"
                  type="primary"
                  onClick={this._submitPassword.bind(this)}
                  style={{
                    width: "100%",
                    color: "#fff",
                    background: "rgb(64 118 212)",
                    border: "1px solid rgb(64 118 212)",
                  }}
                >
                  {lang.get("pw_search/btn_pw_search")}
                </Button>
              </div>
              <div
                style={{
                  width: "100px",
                  marginTop: "20px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <img
                  onClick={() => {
                    lang.setLang("en");
                    common.updateLocalCountryCode("EN");
                    window.location.reload();
                  }}
                  style={{ width: "30px", cursor: "pointer" }}
                  src={en}
                />
                <img
                  onClick={() => {
                    lang.setLang("kr");
                    common.updateLocalCountryCode("KR");
                    window.location.reload();
                  }}
                  style={{ width: "30px", cursor: "pointer" }}
                  src={kr}
                />
              </div>
              <div
                style={{
                  padding: "0.5rem 0.2rem 0",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Button
                    style={{ padding: "0" }}
                    type="link"
                    onClick={(e) => this.props.history.push("/login")}
                  >
                    {lang.get("pw_search/btn_login")}
                  </Button>
                </div>
                <div>
                  <Button
                    style={{ padding: "0" }}
                    type="link"
                    onClick={(e) => this.props.history.push("/registration")}
                  >
                    {lang.get("pw_search/btn_register")}
                  </Button>
                </div>
              </div>
            </div>

            <div style={{ paddingTop: "130px" }} />
          </div>
        </div>
      </Loading>
    );
  }

  async _submitCodeForNewPassword(e) {
    if (this.state.is_loading === true) {
      return;
    }

    if (this.state.login_id.trim() === "") {
      Modal.error({
        title: lang.get("pw_search/modal_case6/title"),
        content: lang.get("pw_search/modal_case6/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return;
    }

    if (this.state.email.trim() === "") {
      Modal.error({
        title: lang.get("pw_search/modal_case7/title"),
        content: lang.get("pw_search/modal_case7/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return;
    }

    const regExp_email =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    if (!regExp_email.test(this.state.email.trim())) {
      Modal.error({
        title: lang.get("pw_search/modal_case8/title"),
        content: lang.get("pw_search/modal_case8/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return;
    }

    this.setstate({ is_loading: true });
    try {
      const params = {
        login_id: this.state.login_id,
        email: this.state.email,
      };

      await server_api.sendCodeForNewPassword(params);

      Modal.success({
        title: lang.get("pw_search/modal_case4/title"),
        content: lang.get("pw_search/modal_case4/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });

      return this.setstate({ is_loading: false });
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }
      console.log("-------------------");
      console.log(e);
      console.log(e.result);
      console.log(e.result.message);
      if (
        e &&
        e.result &&
        e.result.message &&
        e.result.message.includes("no user")
      ) {
        Modal.error({
          title: lang.get("pw_search/modal_case12/title"),
          content: lang.get("pw_search/modal_case12/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });
      } else {
        Modal.error({
          title: lang.get("pw_search/modal_case5/title"),
          content: lang.get("pw_search/modal_case5/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });
      }

      return this.setstate({ is_loading: false });
    }
  }

  async _submitPassword() {
    if (this.state.is_loading === true) {
      return;
    }

    if (this.state.code_for_new_password.trim() === "") {
      return Modal.error({
        title: lang.get("pw_search/modal_case9/title"),
        content: lang.get("pw_search/modal_case9/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
    }

    if (this.state.login_id.trim() === "") {
      Modal.error({
        title: lang.get("pw_search/modal_case6/title"),
        content: lang.get("pw_search/modal_case6/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return;
    }

    if (typeof this.state.new_password !== "string") {
      return;
    }

    if (this.state.new_password.trim() === "") {
      return Modal.error({
        title: lang.get("pw_search/modal_case10/title"),
        content: lang.get("pw_search/modal_case10/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
    }

    var regex_not_password = /[^A-Za-z\d~@#$%^&*+=`|{}:;!,.?'"()[\]\-_\\/<>]/;
    var regex_password =
      /^.*(?=^.{8,30}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[`~@#$%^&*+=|{}:;!,.?'"()[\]\-_\\/<>]).*$/;
    if (
      !regex_password.test(this.state.new_password.trim()) ||
      regex_not_password.test(this.state.new_password.trim())
    ) {
      return Modal.error({
        title: lang.get("pw_search/modal_case1/title"),
        content: lang.get("pw_search/modal_case1/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
    }

    try {
      this.setState({ is_loading: true });
      await server_api.updateUsersPassword({
        login_id: this.state.login_id,
        code_for_new_password: this.state.code_for_new_password,
        new_password: this.state.new_password,
      });

      Modal.success({
        title: lang.get("pw_search/modal_case2/title"),
        centered: true,
        okText: lang.get("modal/confirm_text"),
        onOk: (e) => {
          Modal.destroyAll();
          return this.props.history.push("/login");
        },
      });

      return this.setstate({ is_loading: false });
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      if ((e.result || {}).message === "incorrect code") {
        Modal.error({
          title: lang.get("pw_search/modal_case11/title"),
          content: lang.get("pw_search/modal_case11/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });

        return this.setState({
          is_loading: false,
        });
      }
      if ((e.result || {}).message.includes("no user")) {
        Modal.error({
          title: lang.get("pw_search/modal_case12/title"),
          content: lang.get("pw_search/modal_case12/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });
        return this.setState({
          is_loading: false,
        });
      }

      Modal.error({
        title: lang.get("pw_search/modal_case3/title"),
        content: lang.get("pw_search/modal_case3/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });

      return this.setState({
        is_loading: false,
      });
    }
  }
}

const NyobInput = ({
  labelText = "label",
  type = "text",
  onChangeInput = (e) => console.log("not binded"),
  maxLength = 255,
  suffix,
  setOffset = () => console.log("not binded"),
}) => {
  let style = {};
  const default_style = {
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderRadius: "0",
    boxShadow: "none",
    paddingRight: "2.25rem",
  };
  if (suffix) style = Object.assign({}, default_style);

  return (
    <div style={{ position: "relative", marginTop: "0.5rem" }}>
      <label
        style={{
          marginLeft: "0.7rem",
          marginBottom: "0",
          color: "rgb(191, 191, 191)",
        }}
      >
        {labelText}
      </label>
      <Input
        type={type}
        style={style}
        maxLength={maxLength}
        onChange={(e) => onChangeInput(e)}
        defaultValue=""
        onFocus={(e) => {
          if (checkers.is_mobile === false) {
            return;
          }
          setOffset(eos_wallet.cumulativeOffset(e.target).top - 30);
        }}
        // onBlur={e => {
        //   if (checkers.is_mobile === false) {
        //     return;
        //   }
        //   setOffset(void 0);
        // }}
      />
      <div style={{ position: "absolute", top: "50%", right: "2%" }}>
        {suffix}
      </div>
    </div>
  );
};

export default PWSearch;
