module.exports = {
  en: {
    title: "HIIK PAY",

    bottom_bar: {
      transfer: "Transfer",
      filling: "Charge",
      exchange: "Exchange",
      wallet: "Wallet",
      all: "All"
    },

    modal: {
      ok_text: "Yes",
      cancel_text: "No",
      confirm_text: "Confirm"
    },

    "drawer/record_redirecter": {
      title: "View History",
      transfer_list: "View transfer history",
      filling_list: "View charge history",
      exchange_list: "View exchange history"
    },

    "drawer/token_and_balance_list": {
      title: "Select token"
    },

    "drawer/currency_list": {
      title: "Select currency"
    },

    "drawer/token_list": {
      title: "Select token"
    },

    "drawer/input_otp": {
      title: "Enter OTP"
    },

    "drawer/bank_accounts": {
      title: "Select account",
      btn_add_account: "+ Add account",
      btn_delete_account: "Delete",
      pop_delete_account: {
        content: "Will you delete the account ?",
        ok_text: "Yes",
        cancel_text: "No"
      },

      "drawer/add_account": {
        title: "Add account",
        select_country_title: "Country :",
        select_country_option_all: "All",
        select_bank_title: "Bank",
        input_account_placeholder: "Account number",
        input_owner_placeholder: "Account owner",
        input_alias_placeholder: "Nick name",
        btn_add: "Add"
      },
      no_bank_list_message: "That bank is not on the list",
      modal_case1: {
        title: "Failed to add account",
        content: "Select a bank."
      },
      modal_case2: {
        title: "Failed to add account",
        content: "Enter the account number."
      },
      modal_case3: {
        title: "Failed to add account",
        content: "Enter the correct account number."
      },
      modal_case4: {
        title: "Failed to add account",
        content: "Enter the account owner."
      },
      modal_case5: {
        title: "Failed to add account",
        content: "Enter the nick name."
      },
      modal_case6: {
        title: "Failed to add account",
        content: "Server communication error. Inquire the administrator."
      },
      modal_case7: {
        title: "Failed to add account",
        content: "Communication error. Try again later please."
      }
    },

    add_value: {
      add100thousand: "+100,000",
      add50thousand: "+50.000",
      add10thousand: "+10,000",
      clear: "Default"
    }
  }
};
