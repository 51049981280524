import React from 'react';
import { Button, Typography, Icon, Row, Col } from 'antd';
//* view components
import Loading from '../../../snippets/Loading';
//* utils
import common     from '../../../utils/common';
import eos_wallet from '../../../utils/eos_wallet';
import server_api from '../../../utils/server_api';
import storage_util from '../../../utils/storage_util';
import state_util   from '../../../utils/state_util';
//* resources
import lang from '../../../lang/lang';



class Result extends React.Component {
  is_mounted_ = false;
  state = {
    header_text: '',
    value_transferred: '',
    balance_of_token: '-',
    token: '',
    receiver_login_id : '',
    receiver_eos_account: '',
    transfer_type: 'BY_LOGIN_ID',
    error_msg: ''
  }

  constructor(props) { super(props);
    // console.log(this.props.location.state);
    // if (!this.props.location.state)
    //   return this.props.history.push('/');

    if (sessionStorage.getItem(state_util.ENUM.transfer_state) === null) {
      return this.props.history.push('/');
    } else {
      if (storage_util.loadFromSession(state_util.ENUM.transfer_state).its_result !== true) {
        return this.props.history.push('/');
      }
    }
      
    // const { value_transferred, token, receiver_login_id, receiver_eos_account, transfer_type } = this.props.location.state;    
    // this.state = {
    //   ...this.state,
    //   // value_transferred,
    //   // token,
    //   // receiver_login_id    : receiver_login_id    || '',
    //   // receiver_eos_account : receiver_eos_account || '',
    //   // transfer_type,
    // }
  }
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    if (await common.isLoggedIn() === false) {
      return;
    }

    const stored_state = storage_util.loadFromSession(state_util.ENUM.transfer_state, {}, true);
    state_util.clearEveryStates();

    this.setstate({
      is_loading: false,
      header_text: await eos_wallet.getHeaderTokenText(),
      balance_of_token: await this._refreshBalanceOfCurrency(stored_state.token),
      ...stored_state,
    })
  }
  componentWillUnmount() { this.is_mounted_ = false; }

  render () {
    if (common.get().token === void 0) {
      return null;
    }

    let { favorite_login_ids } = this.readLocalLoginIdList();
    const is_checked_login_id = (favorite_login_ids.indexOf(this.state.receiver_login_id) >= 0);

    let { favorite_eos_accounts } = this.readLocalEOSAccountList();
    const is_checked_eos_account = (favorite_eos_accounts.indexOf(this.state.receiver_eos_account) >= 0);


    return (
      <Loading is_loading={this.state.is_loading} overall={true} style={{ display: "table", tableLayout: "fixed", width: "100%", height: "100%" }}>
        {/* Header */}
        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>

            {/** HEADER *****/}
            <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  onClick={e => this.props.history.push('/wallet')}
                  style={{
                      flex: "1 1 0px", borderRadius: "4px", padding: "0.2rem 1rem"
                    , display: "block", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"
                    , fontSize: "1rem", color: "#fff", background: "rgb(51, 129, 252)", cursor: 'pointer'
                  }} >
                  {this.state.header_text}&nbsp;
                </div>
                <div style={{ flex: "0 0 auto", padding: "0 0 0 0.5rem"}}>
                  <Button key="extra" type="link" style={{ padding: "0", pointerEvents: "none", height: "2.5rem" }}>
                    <Icon type="plus" style={{ fontSize: "1.5rem", color: "rgba(255, 255, 255, 0)" }} />
                  </Button>
                </div>
              </div>

              <Typography.Title level={3} style={{ display: 'flex', alignItems: 'center', marginBottom: "0", marginTop: "1.5rem" }}>
                <Button size="small" type="link" style={{ paddingLeft: "0", pointerEvents: 'none' }}>
                  <Icon type="left" theme="outlined" style={{ color: "RGB(75, 75, 75)"}} />
                </Button>
                <Typography.Text>{lang.get('transfer/result/title')}</Typography.Text>
              </Typography.Title>
            </div>
            {/***** HEADER END **/}

          </div>
        </div>

        <div style={{ display: "table-row", height: "4%" }}></div>


        <div style={{ display: "table-row" }}> {/* content */}
          <div style={{ display: "table-cell" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div style={{ top: "0", bottom: "0", left: "0", right: "0", position: "absolute", overflow: "auto"}}>

                <div style={{ padding: "0.5rem" }}>

                  <div style={{
                    borderRadius: "0.6em", padding: "0.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    display: 'flex', justifyContent: "space-between", alignItems: "center",
                    fontSize: "1rem", fontWeight: "700",
                  }}>
                    <div><Typography.Text>{lang.get('transfer/result/value_transferred')}</Typography.Text></div>
                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                      <div style={{ flex: "1 1 0px" }}>
                        <div style={{ display: "table", verticlaAlign: "middle",
                          width: '100%', paddingRight: "0.5rem", textAlign: 'right', fontWeight: "700" }}>
                          {eos_wallet.formatCommaNumber(this.state.value_transferred)}              
                        </div>
                      </div>
                      <div style={{ flex: "0 1 auto", fontSize: "1rem", fontWeight: "700", textAlign: "left" }}>
                        <Typography.Text>{this.state.token}</Typography.Text>
                      </div>
                    </div>
                  </div>

                  <div style={{
                    borderRadius: "0.6em", padding: "0.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    display: 'flex', justifyContent: "space-between", alignItems: "center",
                    fontSize: "1rem", fontWeight: "700",
                  }}>
                    <div><Typography.Text>{lang.get('transfer/result/value_of_balance')}</Typography.Text></div>
                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                      <div style={{ flex: "1 1 0px" }}>
                        <div style={{ display: "table", verticlaAlign: "middle",
                          width: '100%', paddingRight: "0.5rem", textAlign: 'right', fontWeight: "700" }}>
                          {eos_wallet.formatCommaNumber(this.state.balance_of_token)}
                        </div>
                      </div>
                      <div style={{ flex: "0 1 auto", fontSize: "1rem", fontWeight: "700", textAlign: "left" }}>
                        <Typography.Text>{this.state.token}</Typography.Text>
                      </div>
                    </div>
                  </div>


                  {(this.state.transfer_type === 'BY_LOGIN_ID')
                    ? ( <div style={{
                          borderRadius: "0.6em", padding: "0.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                          display: 'flex', justifyContent: "space-between", alignItems: "center", fontSize: "1rem", fontWeight: "700" }}>
                          <span style={{ minWidth: "85px" }}>{lang.get('transfer/result/to_user_info/id')}</span>
                          <div>
                            <span>{this.state.receiver_login_id}</span>
                            <span style={{ paddingLeft: "0.5rem" }}>
                              <input type="checkbox" id="favorite_checker" checked={is_checked_login_id} hidden onChange={e => this.switchFavoriteLoginId(this.state.receiver_login_id)} />
                              <label htmlFor="favorite_checker">
                                {(is_checked_login_id) ? <Icon type="star" theme="filled" style={{ color: "rgb(255, 191, 0)" }} /> : <Icon type="star" theme="outlined" />}
                              </label>
                            </span>
                          </div>
                        </div>
                      )
                    : ( null )
                  }

                  {(this.state.transfer_type === 'BY_EOS_ACCOUNT')
                    ? ( <div style={{ fontSize: "1rem", fontWeight: "700", borderRadius: "0.6em", padding: "1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",}}>
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span style={{ display: "inline", width: "30%", minWidth: "110px" }}>{lang.get('transfer/result/to_eos_account_info/account')}</span>
                            <div>
                              <span>{`${this.state.receiver_eos_account}`}</span>
                              <span style={{ paddingLeft: "0.5rem" }}>
                                <input type="checkbox" id="favorite_checker" checked={is_checked_eos_account} hidden onChange={e => this.switchFavoriteEOSAccount(this.state.receiver_eos_account)} />
                                <label htmlFor="favorite_checker">
                                  {(is_checked_eos_account) ? <Icon type="star" theme="filled" style={{ color: "rgb(255, 191, 0)" }} /> : <Icon type="star" theme="outlined" />}
                                </label>
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    : ( null )
                  }
                  
                </div>
                
              </div>
            </div>
          </div>
        </div>


        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell", padding: "0.5rem 1rem 1rem"}}>
              <Row type="flex" justify="space-between" gutter={16}>
                <Col span={12}>
                  <Button size="large" style={{ width: "100%", fontWeight: "700" }} type="warning" onClick={e => this.props.history.push('/transfer')}>
                    {lang.get('transfer/result/btn_confirm')}
                  </Button>
                </Col>
                <Col span={12}>
                  <Button size="large" type="danger"
                    style={{ width: "100%", fontWeight: "700", color: "#fff", background: "rgb(255, 92, 92)" }} onClick={e => this.props.history.push('/transfer')}>
                    {lang.get('transfer/result/btn_another_transfer')}
                  </Button>
                </Col>
              </Row>
          </div>
        </div>
        
      </Loading>
    )
  }


  async _refreshBalanceOfCurrency(token) {
    try {
      const account_list = await server_api.readUsersAccount({}, { type: 'object' });
      const balance_of_token = (account_list || {})[token] || '';
      return balance_of_token;
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      return '-';
    }
    
  }




  /* utils */
  //* login_id
  readLocalLoginIdList() {
    let recent_login_ids = [];
    let favorite_login_ids = [];

    const recent_keys_str = localStorage.getItem('recent_login_ids');
    const favorite_keys_str = localStorage.getItem('favorite_login_ids');


    if (recent_keys_str)
      recent_login_ids = JSON.parse(recent_keys_str);

    if (favorite_keys_str)
      favorite_login_ids = JSON.parse(favorite_keys_str);

    if (Array.isArray(recent_login_ids) === false) {
      localStorage.removeItem('recent_login_ids')
      recent_login_ids = [];
    }

    if (Array.isArray(favorite_login_ids) === false) {
      localStorage.removeItem('favorite_login_ids')
      favorite_login_ids = [];
    }
    if (typeof recent_login_ids[0] !== 'string') {
      localStorage.removeItem('recent_login_ids')
      recent_login_ids = [];
    }

    if (typeof favorite_login_ids[0] !== 'string') {
      localStorage.removeItem('favorite_login_ids')
      favorite_login_ids = [];
    }

    return { recent_login_ids, favorite_login_ids }
  }

  saveRecentLoginIdList(login_id) {
    const max_length = 5;
    let { recent_login_ids } = this.readLocalLoginIdList();
    
    if (!Array.isArray(recent_login_ids))
      recent_login_ids = [];


    const selected_login_id_idx = recent_login_ids.indexOf(login_id);
    if (selected_login_id_idx >= 0) {
      recent_login_ids.splice(selected_login_id_idx, 1);
    }
    recent_login_ids.unshift(login_id);

    const current_length = recent_login_ids.length;
    if (current_length > max_length) {
      recent_login_ids.splice(max_length, current_length);
    }

    localStorage.setItem('recent_login_ids', JSON.stringify(recent_login_ids));
  }

  switchFavoriteLoginId (login_id) {
    const max_length = 100;
    let { favorite_login_ids } = this.readLocalLoginIdList();

    if (!Array.isArray(favorite_login_ids))
      favorite_login_ids = [];

    const selected_login_id_idx = favorite_login_ids.indexOf(login_id);
    if (selected_login_id_idx >= 0) {
      favorite_login_ids.splice(selected_login_id_idx, 1);
    }
    else {
      favorite_login_ids.unshift(login_id);
    }

    const current_length = favorite_login_ids.length;
    if (current_length > max_length) {
      favorite_login_ids.splice(max_length, current_length);
    }

    localStorage.setItem('favorite_login_ids', JSON.stringify(favorite_login_ids));

    this.forceUpdate()
  }

  //* eos_account
  readLocalEOSAccountList () {
    let recent_eos_accounts = [];
    let favorite_eos_accounts = [];

    const recent_keys_str = localStorage.getItem('recent_eos_accounts');
    const favorite_keys_str = localStorage.getItem('favorite_eos_accounts');


    if (recent_keys_str)
      recent_eos_accounts = JSON.parse(recent_keys_str);

    if (favorite_keys_str)
      favorite_eos_accounts = JSON.parse(favorite_keys_str);

    if (Array.isArray(recent_eos_accounts) === false) {
      localStorage.removeItem('recent_eos_accounts')
      recent_eos_accounts = [];
    }

    if (Array.isArray(favorite_eos_accounts) === false) {
      localStorage.removeItem('favorite_eos_accounts')
      favorite_eos_accounts = [];
    }
    if (typeof recent_eos_accounts[0] !== 'string') {
      localStorage.removeItem('recent_eos_accounts')
      recent_eos_accounts = [];
    }

    if (typeof favorite_eos_accounts[0] !== 'string') {
      localStorage.removeItem('favorite_eos_accounts')
      favorite_eos_accounts = [];
    }

    return { recent_eos_accounts, favorite_eos_accounts }
  }


  saveRecentEOSAccountList(eos_account) {
    const max_length = 5;
    let { recent_eos_accounts } = this.readLocalEOSAccountList();
    
    if (!Array.isArray(recent_eos_accounts))
      recent_eos_accounts = [];


    const selected_eos_account_idx = recent_eos_accounts.indexOf(eos_account);
    if (selected_eos_account_idx >= 0) {
      recent_eos_accounts.splice(selected_eos_account_idx, 1);
    }
    recent_eos_accounts.unshift(eos_account);

    const current_length = recent_eos_accounts.length;
    if (current_length > max_length) {
      recent_eos_accounts.splice(max_length, current_length);
    }

    localStorage.setItem('recent_eos_accounts', JSON.stringify(recent_eos_accounts));
  }


  switchFavoriteEOSAccount (eos_account) {
    const max_length = 100;
    let { favorite_eos_accounts } = this.readLocalEOSAccountList();

    if (!Array.isArray(favorite_eos_accounts))
      favorite_eos_accounts = [];

    const selected_eos_account_idx = favorite_eos_accounts.indexOf(eos_account);
    if (selected_eos_account_idx >= 0) {
      favorite_eos_accounts.splice(selected_eos_account_idx, 1);
    }
    else {
      favorite_eos_accounts.unshift(eos_account);
    }

    const current_length = favorite_eos_accounts.length;
    if (current_length > max_length) {
      favorite_eos_accounts.splice(max_length, current_length);
    }

    localStorage.setItem('favorite_eos_accounts', JSON.stringify(favorite_eos_accounts));

    this.forceUpdate();
  }
}

export default Result;