import React from 'react';

import { List, Row, Col } from 'antd';

// import InfiniteScroll from 'react-infinite-scroller';
// import '../../styles/infinity_lists.less';

import Loading from '../../snippets/Loading';

import s3 from '../../utils/s3';

import img_no_country from '../../images/no_country.png';



class CurrencyList extends React.Component {
  static defaultProps = {
    onClickCurrency: e => {}
  }

  is_mounted_ = false;
  state = {
    data: [],
    loading: false,
    hasMore: true,
  };
  
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;
    
    // const res = await server_api.readUsersAccount({}, { type: 'array' });

    // this.setstate({ data: res});

    const data = (this.props.data).filter(datum => datum.currency_symbol)

    this.setstate({ data });
  }
  componentWillUnmount() { this.is_mounted_ = false; }

  // handleInfiniteOnLoad = () => {
  //   let { data } = this.state;
  //   this.setstate({
  //     loading: true,
  //   });
  //   if (data.length > 14) {
  //     message.warning('List loaded all');
  //     this.setstate({
  //       hasMore: false,
  //       loading: false,
  //     });
  //     return;
  //   }
  //   this.fetchData(res => {
  //     data = data.concat(res.results);
  //     this.setstate({
  //       data,
  //       loading: false,
  //     });
  //   });
  // };

  render() {
    if (this.state.data.length === 0) {
      return <Loading overall={false} is_loading={true} style={{ width: "100%", height: "100%" }} />
    }

    return (
      // <InfiniteScroll
      //   initialLoad={false}
      //   pageStart={0}
      //   loadMore={this.handleInfiniteOnLoad}
      //   hasMore={!this.state.loading && this.state.hasMore}
      //   useWindow={false}
      // >
        <List
          dataSource={this.state.data}
          renderItem={(currency, i) => {
            let img_url = `${s3.server_address}/${s3.flag_image_path}/${currency.country_code}.png`;

            return (<List.Item key={'cur'+i} style={{ padding: "0.4rem 0", border: "none", cursor: "pointer" }} onClick={this.onClickCurrency.bind(this)} data-currency={currency.currency_symbol}>
                      <Row type="flex" justify="center" align="middle" style={{ pointerEvents: "none", width: "100%"}}>
                        <Col span={7}>
                          <img alt="" src={img_url} onError={(e) => { e.target.onerror = null; e.target.src = img_no_country }} style={{ width: "2rem" }} />
                        </Col>
                        <Col span={7}>
                          {currency.currency_symbol}
                        </Col>
                      </Row>
                    </List.Item>)
          }}
        >
          {/* {this.state.loading && this.state.hasMore && (
            <div className="infinity-loading-container">
              <Spin />
            </div>
          )} */}
        </List>
      // </InfiniteScroll>
    );
  }

  onClickCurrency (e) {
    this.props.onClickCurrency(e.target.dataset.currency);
  }
}


export default CurrencyList;