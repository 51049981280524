import React from "react";
import { Link } from "react-router-dom";
import { Menu, Icon } from "antd";
//* styles
import "../../styles/bottom/navigation.less";
//* resources
import lang from "../../lang/lang";

class Navigation extends React.Component {
  state = {
    current: "transfer",
  };

  handleClick = (e) => {
    console.log("click ", e);
    this.setstate({
      current: e.key,
    });
  };

  render() {
    return (
      <Menu
        id="Navigation"
        onClick={this.props.onMenuClick}
        selectedKeys={[this.props.current_menu]}
        mode="horizontal"
        style={{
          width: "100%",
          minWidth: "240px",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          borderTop: "1px solid RGB(68, 114, 196)",
        }}
      >
        <Menu.Item key="transfer" className="Menu-Item">
          <Link to="/transfer" className="Link">
            <Icon type="swap" className="Icon" />
            <div className="IconText">{lang.get("bottom_bar/transfer")}</div>
          </Link>
        </Menu.Item>
        <Menu.Item key="wallet" className="Menu-Item">
          <Link to="/wallet" className="Link">
            <Icon type="wallet" theme="filled" className="Icon" />
            <div className="IconText">{lang.get("bottom_bar/wallet")}</div>
          </Link>
        </Menu.Item>
        <Menu.Item key="record" className="Menu-Item">
          <Link to="/record" className="Link">
            <Icon type="credit-card" theme="filled" className="Icon" />
            <div className="IconText">
              {lang.get("all/service_section/history")}
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item key="all" className="Menu-Item">
          <Link to="/all" className="Link">
            <Icon type="ellipsis" className="Icon" />
            <div className="IconText">{lang.get("bottom_bar/all")}</div>
          </Link>
        </Menu.Item>
      </Menu>
    );
  }
}

export default Navigation;
