module.exports = {
  en: {
    country_codes: {
      EU: "European Union",
      GH: "Ghana",
      GA: "Gabon",
      GY: "Guyana",
      GM: "Gambia",
      GG: "Geonji Island",
      GP: "Guadlouf",
      GT: "Guatemala",
      GU: "Guam",
      GD: "Grenada",
      GR: "Greece",
      GL: "Greenland",
      GN: "Guinea",
      GW: "Guinea-Bissau",
      NA: "Namibia",
      NR: "Nauru",
      NG: "Nigeria",
      AQ: "Antarctica",
      SS: "South Sudan",
      ZA: "South Africa",
      NL: "Netherlands",
      NP: "Neapal",
      NO: "Norway",
      NF: "Norfolk Island",
      NC: "Nouvel Caledoni",
      NZ: "New Zealand",
      NU: "Niue",
      NE: " Niger",
      NI: "Nicaragua",
      KR: "South Korea",
      DK: "Denmark",
      DO: "Dominican Republic",
      DM: "Dominican Federation",
      TL: "East Timor",
      DE: "Germany",
      LA: "Laos",
      LR: "Liberia",
      LV: "Latvia",
      RU: "Russia",
      LB: "Lebanon",
      LS: "Lesotho",
      RE: "Levineon",
      RO: "Romania",
      LU: "Luxembourg",
      RW: "Rwanda",
      LY: "Libya",
      LT: "Lituania",
      LI: "Lichtenstein",
      MG: "Madagascar",
      MQ: "Martinique",
      MH: "Marcel Islands",
      YT: "Mayot",
      MO: "Macau",
      MW: "Malawi",
      MY: "Malaysia",
      ML: "Mali",
      IM: "Man Island",
      MX: "Mexico",
      MC: "Monaco",
      MA: "Morocco",
      MU: "Mauritius",
      MR: "Mauritania",
      MZ: "Mozambique",
      ME: "Montenegro",
      MS: "Montesserat",
      MD: "Moldova",
      MV: "Maldives",
      MT: "Malta",
      MN: "Mongolia",
      US: "the United States",
      UM: "United States Minor Outlying Islands",
      VI: "United States Virgin Islands",
      MM: "Myanmar",
      FM: "Federated States of Micronesia",
      VU: "Vanuatu",
      BH: "Bahrain",
      BB: "Barbados",
      VA: "State of the Vatican City",
      BS: "Bahamas",
      BD: "Bangladesh",
      BM: "Bermuda",
      BJ: "Benin",
      VE: "Venezuela",
      VN: "Vietnam",
      BE: "Belgium",
      BY: "Belarus",
      BZ: "Belize",
      BQ: "Bonner Island",
      BA: "Bosnia and Herzegovina",
      BW: "Botswana",
      BO: "Bolivia",
      BI: "Burundi",
      BF: "Burkina Faso",
      BV: "Boube Island",
      BT: "Bhutan",
      MP: "Northern Mariana Islands",
      MK: "Northern Macedonia",
      BG: "Bulgaria",
      BR: "Brazil",
      BN: "Brunei",
      WS: "Samoa",
      SA: "Saudi Arabia",
      GS: "South Georgia South Sandwich Islands",
      SM: "San Marino",
      ST: "Sao Tome and Principe",
      MF: "Saint Martin",
      BL: "Saint Barthelemy",
      PM: "Saint Pierre Miklong",
      EH: "Western Sahara",
      SN: "Senegal",
      RS: "Serbia",
      SC: "Seychelles",
      LC: "St. Lucia",
      VC: "Saint Vincent Grenadin",
      KN: "Saint Kitts Nevis",
      SH: "St. Helena",
      SO: "Somalia",
      SB: "Solomon Islands",
      SD: "Sudan",
      SR: "Suriname",
      LK: "Sri Lanka",
      SJ: "Svalbard and Jan Mayen",
      SE: "Sweden",
      CH: "Switzerland",
      ES: "Spain",
      SK: "Slovakia",
      SI: "Slovenia",
      SY: "Syria",
      SL: "Sierra Leone",
      SX: "Sint Marton",
      SG: "Singapore",
      AE: "Arab Emirates",
      AW: "Aruba",
      AM: "Armenia",
      AR: "Argentina",
      AS: "American Samore",
      IS: "Iceland",
      HT: "Haiti",
      IE: "Ireland",
      AZ: "Azerbaijan",
      AF: "Afghanistan",
      AD: "Andorra",
      AL: "Albania",
      DZ: "Algeria",
      AO: "Angola",
      AG: "Antigua and Barbuda",
      AI: "Anguilla",
      ER: "Eritrea",
      SZ: "Eswatini",
      EE: "Estonia",
      EC: "Ecuador",
      ET: "Ethiopia",
      SV: "El Salvador",
      GB: "England",
      VG: "British Virgin Islands",
      IO: "British Indian Ocean",
      YE: "Yemen",
      OM: "Oman",
      AU: "Australia",
      AT: "Austria",
      HN: "Honduras",
      AX: "Orland Islands",
      WF: "Wallis Medtuna",
      JO: "Jordan",
      UG: "Uganda",
      UY: "Uruguay",
      UZ: "Uzbekistan",
      UA: "Ukraine",
      IQ: "Iraq",
      IR: " Iran",
      IL: "Israel",
      EG: "Egypt",
      IT: "Italy",
      IN: "India",
      ID: "Indonesia",
      JP: "Japan",
      JM: "Jamaica",
      ZM: "Zambia",
      JE: "Jersey island",
      GQ: "Equatorial Guinea",
      KP: "North Korea",
      GE: "Georgia",
      CN: "China",
      CF: "Central African Republic",
      TW: "Republic of China",
      DJ: "Djibouti",
      GI: "Gibraltar",
      ZW: "Zimbabwe",
      TD: "Chad",
      CZ: "the Czech Republic",
      CL: "Chile",
      CM: "Cameroon",
      CV: "Caboverde",
      KZ: "Kazahstan",
      QA: "Qatar",
      KH: "Cambodia",
      CA: "Canada",
      KE: "Kenya",
      KY: "Cayman Islands",
      KM: "Comoros",
      CR: "Costarica",
      CC: "Cochos Islands",
      CI: "Cote d`Ivoire",
      CO: "Colombia",
      CG: "Republic of Congo",
      CD: "Democratic Republic of Congo",
      CU: "Cuba",
      KW: "Kuwait",
      CK: "Cook Islands",
      CW: "Quiraso",
      HR: "Croatia",
      CX: "Christmas Island",
      KG: "Kyrgyzstan",
      KI: "Kiribati",
      CY: "Cyprus",
      TJ: "Tajikistan",
      TZ: "Tanzania",
      TH: "Thailand",
      TC: "Turks Kakers Islands",
      TR: "Turkey",
      TG: "Togo",
      TK: "Tokelau",
      TO: "Tonga",
      TM: "Turkmenistan",
      TV: "Tuvalu",
      TN: "Tunisia",
      TT: "Trinidad and Tobago",
      PA: "Panama",
      PY: "Paraguai",
      PK: "Pakistan",
      PG: "Papua New Guinea,",
      PW: "Palau",
      PS: "Palestine",
      FO: "Faroe Islands",
      PE: "Peru",
      PT: "Portugal",
      FK: "Falkland Islands",
      PL: "Poland",
      PR: "Puerto Rico",
      FR: "France",
      GF: "French Guiana",
      TF: "French Southern and Antarctic Territory",
      PF: "French Polynesia",
      FJ: "Fiji",
      FI: "Finland",
      PH: "Philippines",
      PN: "Pitcairn Islands",
      HM: "Hurd McDonald`s Islands",
      HU: "Hungary",
      HK: "Hong Kong"
    }
  }
};
