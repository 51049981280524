import native_util from "./native_util";
import server_api from "./server_api";

export default {
  //** for account --------------
  id: null,
  login_id: null,
  eos_account: null,
  role: null,
  token: null,
  user_type: null,
  country_code: null,

  get() {
    const common_data_str = localStorage.getItem("common_data");
    if (!common_data_str) {
      return {
        id: null,
        role: null,
        token: null,
        user_type: null,
        login_id: null,
        eos_account: null,
        country_code: null,
        is_lock: null,
        lock_amount: null,
        lock_transfer_amount: null,
      };
    } else {
      return JSON.parse(common_data_str);
    }
  },

  async login(type, login_id, password) {
    try {
      console.log(server_api.api_server_address2 + "/api/auth/login");
      const res = await fetch(
        server_api.api_server_address2 + "/api/auth/login",
        {
          method: "POST",
          body: JSON.stringify({
            type,
            login_id,
            password,
          }),
        }
      );
      const res_json = await res.json();
      console.log(res_json);

      if (Math.floor(res.status / 100) !== 2) {
        this.logout();
        return {
          status: false,
          message: res_json.message,
        };
      }

      const common_data = res_json.items[0];
      common_data["login_id"] = login_id;

      localStorage.setItem("common_data", JSON.stringify(common_data));
      Object.keys(common_data).forEach((key) => (this[key] = common_data[key]));

      // update fcm token
      if (native_util.hasNativeApi()) {
        try {
          const fcm_token = await native_util.getFcmToken();
          await server_api.updateUser(common_data.id, { fcm_token });
        } catch (e) {
          console.log(e);
        }
      }

      return {
        status: true,
        message: res_json.message,
      };
    } catch (e) {
      console.log(e);
      this.logout();
      return {
        status: false,
        message: JSON.stringify(e, " ", 2),
      };
    }
  },

  logout() {
    localStorage.removeItem("common_data");
  },

  async isLoggedIn() {
    const common_data_str = localStorage.getItem("common_data");
    if (!common_data_str) {
      return false;
    }

    const common_data = JSON.parse(common_data_str);
    try {
      const res = await fetch(
        server_api.api_server_address2 + "/api/auth/check-token",
        {
          method: "POST",
          body: JSON.stringify({
            id: common_data.id,
            role: common_data.role,
            token: common_data.token,
          }),
        }
      );
      if (Math.floor(res.status / 100) === 2) {
        Object.keys(common_data).forEach(
          (key) => (this[key] = common_data[key])
        );
        return true;
      } else {
        this.logout();
        return false;
      }
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.log(e);
      }
      this.logout();
      return false;
    }
  },

  updateLocalCountryCode(new_country_code) {
    const common_data_str = this.get();
    if (!common_data_str) {
      return window.location.reload();
    }
    console.log(common_data_str);
    // this.country_code = new_country_code;
    let common_data;
    try {
      common_data = JSON.parse(common_data_str);
    } catch (error) {
      common_data = common_data_str;
    }
    console.log(common_data);
    console.log("-------------------");
    common_data.country_code = new_country_code;
    localStorage.setItem("common_data", JSON.stringify(common_data));

    return;
  },

  // setCountryCodeBeforeLogin(country_code) {
  //   const r = this.get();
  //   console.log(r);
  //   let common_data = JSON.parse(r);

  //   r.country_code = country_code;
  //   localStorage.setItem("common_data", r);
  // },
};
