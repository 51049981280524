import common from "./common";
import server_api from "./server_api";
import config from "../config";

let header_token_text = {
  // 5초
  text: void 0,
  date: void 0,
};

let country_info = {
  // 10초
  data: void 0,
  date: void 0,
};

function formatEosDemicals(number_or_string) {
  const type = typeof number_or_string;

  let stred_num = String(number_or_string).trim();
  if (stred_num.indexOf(".") > 0) {
    do {
      const current_length = stred_num.length;
      const last_index_1 = stred_num.lastIndexOf("0");
      if (last_index_1 === current_length - 1) {
        stred_num = stred_num.substring(0, last_index_1);
      }
      const last_index_2 = stred_num.lastIndexOf("0");
      if (stred_num.indexOf("0.") !== 0) {
        if (stred_num.indexOf("0") === 0) {
          stred_num = stred_num.substring(1, last_index_2);
        }
      }
    } while (
      stred_num.lastIndexOf("0") === stred_num.length - 1 ||
      (stred_num.indexOf("0.") !== 0 && stred_num.indexOf("0") === 0)
    );

    const dot_index = stred_num.lastIndexOf(".");
    if (dot_index === stred_num.length - 1) {
      stred_num = stred_num.substring(0, dot_index);
    }
  }

  if (type === "number") return Number(stred_num);
  else if (type === "string") return stred_num;
}

function getAccountsObject(account_raw_data) {
  let account_object = {};

  for (let v_str of account_raw_data) {
    const splitted = v_str.split(" ");
    account_object[splitted[1]] = splitted[0];
  }

  return account_object;
}

function getAccountsArray(account_raw_data) {
  const account_array = account_raw_data.map((item) => {
    const splitted = item.split(" ");

    return {
      name: splitted[1],
      value: parseFloat(splitted[0] || "0"),
      is_lock: splitted[2] !== "false" || false,
      lock_amount: parseFloat(splitted[3] || 0),
      lock_transfer_amount: parseFloat(splitted[4] || 0),
    };
  });

  return account_array;
}

async function getHeaderTokenText() {
  // if (header_token_text.text && header_token_text.date ) {
  //   if (new Date() - new Date(header_token_text.date) < (30 * 1000)) {
  //     return header_token_text.text
  //   }
  // }

  return await setHeaderTokenText();
}

async function setHeaderTokenText() {
  let res_user_item,
    res_users_account_items,
    major_token_symbol = config.major_token_symbol,
    major_token_value = "0";

  try {
    res_user_item = (
      await server_api.fetchUsers({
        where: { id: common.get().id },
        attributes: ["country_code"],
      })
    ).items[0];

    res_users_account_items = await server_api.readUsersAccount(
      {},
      { type: "array" }
    );

    // case 1
    // if (!res_user_item.country_code) {
    for (let item of res_users_account_items) {
      if (item.name === major_token_symbol) {
        major_token_value = formatCommaNumber(item.value);
      }
    }

    return `${major_token_value} ${major_token_symbol}`;
    // }
  } catch (e) {
    if (process.env.NODE_ENV === "development") {
      console.error(e);
    }
    return `-`;
  }

  try {
    // case 2
    const res_token_of_country_info_item = (
      await server_api.fetchTokens({
        where: {
          country_code: res_user_item.country_code,
        },
      })
    ).items[0];
    const country_token_symbol = (res_token_of_country_info_item || {})
      .token_symbol;

    if (!country_token_symbol) {
      for (let item of res_users_account_items) {
        if (item.name === major_token_symbol) {
          major_token_value = formatCommaNumber(item.value);
        }
      }

      return `${major_token_value} ${major_token_symbol}`;
    }

    // case 3
    let country_token_value = "0";
    for (let item of res_users_account_items) {
      if (item.name === major_token_symbol) {
        major_token_value = formatCommaNumber(item.value);
      }
      if (item.name === country_token_symbol) {
        country_token_value = formatCommaNumber(item.value);
      }
    }

    header_token_text = {
      text: `${major_token_value} ${major_token_symbol} / ${country_token_value} ${country_token_symbol}`,
      date: new Date(),
    };
    return `${major_token_value} ${major_token_symbol} / ${country_token_value} ${country_token_symbol}`;
  } catch (e) {
    console.log(e);
    return `${major_token_value} ${major_token_symbol}`;
  }
}

async function getCountryInfo() {
  if (country_info.data && country_info.date) {
    if (new Date() - new Date(country_info.date) < 10 * 1000) {
      return country_info.data;
    }
  }

  return await setCountryInfo();
}

async function setCountryInfo() {
  try {
    const res_token = await server_api.fetchTokens();

    console.log(res_token);

    const res_token_items = await res_token.items;

    console.log(res_token_items);

    country_info = {
      data: res_token_items,
      date: new Date(),
    };
    return res_token;
  } catch (e) {
    console.log(e);
    return [];
  }
}

const validate = {
  checkBlank,
  checkZero,
};

function checkBlank(value) {
  if (value === "") return true;
  else return false;
}
function checkZero(value) {
  if (String(value) === "0") return true;
  else return false;
}

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

//* stolen from PrototypeJS
function cumulativeOffset(element) {
  var top = 0,
    left = 0;
  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent;
  } while (element);

  return {
    top: top,
    left: left,
  };
}

function formatCommaNumber(value, doParseFloat = true) {
  if (doParseFloat === true && value !== 0 && value !== "0" && value !== "-")
    value = parseFloat(value || "0");

  value += "";
  if (value.indexOf(",") >= 0)
    // formatted number
    return value;

  const list = value.split(".");
  const prefix = list[0].charAt(0) === "-" ? "-" : "";
  let num = prefix ? list[0].slice(1) : list[0];
  let result = "";
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  let decimal;
  if (list[1] === void 0) {
    decimal = "";
  } else if (list[1] === "") {
    decimal = ".";
  } else {
    decimal = `.${list[1]}`;
  }

  return `${prefix}${result}${decimal}`;
}

function unFormatCommaNumber(value) {
  return value.replace(/,/g, "");
}

function distanceToMeter(lat1, lon1, lat2, lon2) {
  // generally used geo measurement function
  var R = 6378.137; // Radius of earth in KM
  var dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
  var dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d * 1000; // meters
}

export { header_token_text };
export default {
  setHeaderTokenText,
  getHeaderTokenText,
  getCountryInfo,
  setCountryInfo,
  getAccountsObject,
  getAccountsArray,
  formatEosDemicals,
  validate,
  cumulativeOffset,
  isEmpty,
  formatCommaNumber,
  unFormatCommaNumber,
  distanceToMeter,
};
