module.exports = {
  kr: {
    //* transfer ------------------------------------------------
    transfer: {
      title: "이체",
      btn_submit: "보내기",
      error_header_text: "금액을 입력해보세요",

      modal_case1: {
        title: "이체 요청 실패",
        content: (params) => `${params.value} 이상의 금액을 이체해주세요`,
      },
      modal_case2: {
        title: "이체 요청 실패",
        content: "현재 보유하신 토큰이 없습니다. 지금 바로 충전하시겠습니까?",
      },
      modal_case3: {
        title: "이체 요청 실패",
        content: "보유하신 토큰이 부족합니다.",
      },
      modal_case5: {
        title: "이체 요청 실패",
        content: "변동된 데이터가 있습니다. 재접속 후 시도해주세요.",
      },
      modal_case4: {
        title: "잔액 부족",
        content: "현재 보유하신 토큰이 없습니다. 지금 바로 충전하시겠습니까?",
      },
      modal_case6: {
        title: "이체 요청 실패",
        content: "토큰을 지정해주세요.",
      },
    },

    "transfer/select_wallet": {
      title: "이체",
      select_type: {
        title: "이체 대상",
        option_1: "GGP wallet 아이디",
        option_2: "지갑주소",
      },
      input_id: {
        placeholder: "받으실 분의 ID를 입력하세요",
        btn_paste: "붙여넣기",
      },
      input_eos_account: {
        title: "지갑 주소",
        placeholder: "지갑주소를 입력하세요",
        btn_paste: "붙여넣기",
      },
      input_memo: {
        title: "메세지",
      },
      recent_login_id_list: {
        title: "최근 내역",
        no_item_text: "최근 이체 내역이 없습니다",
      },
      favorite_login_id_list: {
        title: "즐겨찾기",
        no_item_text: "즐겨찾기한 ID가 없습니다",
      },
      recent_eos_account_list: {
        title: "최근 내역",
        no_item_text: "최근 이체 내역이 없습니다",
      },
      favorite_eos_account_list: {
        title: "즐겨찾기",
        no_item_text: "즐겨찾기한 계정이 없습니다",
      },
      btn_cancel: "취소",
      btn_submit: "보내기",
      btn_otp_submit: "보내기",

      modal_case1: {
        title: "이체 요청 실패",
        content: "본인의 ID로는 이체할 수 없습니다",
      },
      modal_case2: {
        title: "이체 요청 실패",
        content: "받으실 분의 ID를 입력해주세요",
      },
      modal_case3: {
        title: "이체 요청 실패",
        content: "거래소 지갑주소를 입력해주세요",
      },
      modal_case4: {
        title: "이체 실패",
        content: "OTP 인증에 실패했습니다.",
      },
      modal_case5: {
        title: "이체 요청 실패",
        content: "존재하지 않는 ID입니다",
      },
      modal_case6: {
        title: "이체 요청 실패",
        content: "ID 확인에 실패했습니다",
      },
      modal_case7: {
        title: "이체 실패",
        content: "전송에 실패했습니다",
      },
      modal_case8: {
        title: "이체 실패",
        content: "전송에 실패했습니다",
      },
      modal_case9: {
        title: "이체 실패",
        content: "전송에 실패했습니다",
      },
      modal_case10: {
        title: "이체 실패",
        content: "존재하지 않는 계정입니다",
      },
      modal_case11: {
        title: "이체 실패",
        content: "전송에 실패했습니다",
      },
      modal_case12: {
        title: "이체 실패",
        content: "본인에게는 이체할 수 없습니다",
      },
      modal_case13: {
        title: "이체 실패",
        content: "잔액이 부족합니다",
      },
    },

    "transfer/input_qr_key": {
      guide_text: "QR코드를 스캔하거나, QR코드 이미지를 업로드 해보세요",

      modal_case1: {
        title: "작동 제한",
        content: "카메라 구동에 실패했습니다. 기기 및 권한을 확인해주세요.",
      },
    },

    "transfer/result": {
      title: "이체 완료",
      value_transferred: "이체 수량",
      value_of_balance: "보유 수량",
      to_user_info: {
        id: "받는 사람 ID",
      },
      to_eos_account_info: {
        account: "지갑주소",
      },
      btn_confirm: "확인",
      btn_another_transfer: "추가 이체",
    },

    //* filling -------------------------------------------------
    filling: {
      title: "충전",
      input_number: {
        btn_enter_number: "입력",
      },
      select_exchanger: {
        title: "지점 선택",
        not_selected: "미지정",
      },
      select_currency_token: {
        title_currency: "화폐",
        title_token: "코인",
        not_selected: "미지정",
      },
      result_caculating: {
        loading_text: "예상 결과를 계산 중입니다...",
        expected_token_to_add: "충전 예상 수량",
        expected_balance: "충전 후 예상 잔액",
        warning_message: (params) => {
          return `※ 충전 후 잔액은 현재(${params.date}) 서버 환율 기준 예상 금액으로 실제 충전 시의 환율에 따라 변동될 수 있습니다.`;
        },
      },
      select_bank_account: {
        title: "입금 계좌",
        not_selected: "계좌를 선택해주세요",
      },
      input_memo: {
        title: "메세지",
      },
      btn_submit: "충전하기",
      btn_otp_submit: "충전",

      modal_case1: {
        title: "충전 결과 예상 요청 실패",
        content: `통신에러로 충전 결과 예상에 실패했습니다. 잠시 후 다시 시도해주세요`,
      },
      modal_case2: {
        title: "요청 실패",
        content: "충전 예상 수량을 확인해주세요",
      },
      modal_case3: {
        title: "요청 실패",
        content: "금액을 입력해주세요",
      },
      modal_case4: {
        title: "요청 실패",
        content: (params) => `${params.value_min} 이상의 금액을 입력해주세요`,
      },
      modal_case5: {
        title: "요청 실패",
        content: "화폐를 선택해주세요",
      },
      modal_case6: {
        title: "요청 실패",
        content: "지점을 등록해주세요",
      },
      modal_case7: {
        title: "요청 실패",
        content: "계좌를 선택해주세요",
      },
      modal_case8: {
        title: "요청 실패",
        content: "토큰을 선택해주세요",
      },
      modal_case9: {
        title: "요청 실패",
        content: "충전 예상 수량을 확인해주세요",
      },
      modal_case10: {
        title: "요청 실패",
        content: "충전될 토큰이 너무 적습니다",
      },
      modal_case11: {
        title: "요청 실패",
        content: "OTP 인증번호가 올바르지 않습니다. 다시 확인해주세요.",
      },
      modal_case12: {
        title: "충전 실패",
        content: "서버에러. 충전 요청에 실패했습니다",
      },
      modal_case13: {
        title: "충전 실패",
        content: "충전 요청 등록에 실패했습니다",
      },
      modal_case14: {
        title: "요청 실패",
        content:
          "지점의 계좌 정보 변동으로 인해 해당 지점으로의 충전이 불가능합니다. 잠시 후 다시 시도하거나, 지점을 다시 지정해주세요.",
      },
      modal_case15: {
        title: "요청 실패",
        content:
          "화폐를 다시 선택해주세요. 현재 지점에서 지원하지 않는 화폐입니다.",
      },
    },

    "filling/result": {
      title: "충전 신청 완료",
      money_amount: "신청 금액",
      token_amount: "충전 코인",
      exchanger: {
        title: "지점 정보",
        name: "지점명",
        bank: "은행",
        account: "계좌번호",
        btn_copy: "복사",
        info_text: "* 계좌로 입금완료 시 충전이 진행됩니다.",
      },
      user: {
        name: "예금주",
        account: "계좌번호",
      },
      btn_confirm: "확인",
    },

    //* exchange -------------------------------------------------
    exchange: {
      tabs: {
        title_token_to_money: "화폐 환전",
        title_token_to_token: "코인 환전",
      },
      btn_submit: "환전하기",
      btn_otp_submit: "환전",

      modal_case1: {
        title: "등록된 지점을 변경하시겠습니까?",
        content: "화폐 환전은 해당 국가의 지점에서 가능합니다.",
      },
      modal_case2: {
        title: "요청 실패",
        content: "금액을 입력해주세요.",
      },
      modal_case3: {
        title: "요청 실패",
        content: (params) => `${params.min_value} 이상의 금액을 입력해주세요.`,
      },
      modal_case4: {
        title: "요청 실패",
        content: "지점을 등록해주세요.",
      },
      modal_case5: {
        title: "요청 실패",
        content: "지점과 토큰의 국가가 일치하지 않습니다.",
      },
      modal_case6: {
        title: "요청 실패",
        content: "보유 토큰이 부족합니다",
      },
      modal_case7: {
        title: "요청 실패",
        content: "보유 토큰확인에 실패했습니다.",
      },
      modal_case8: {
        title: "요청 실패",
        content: "입금될 계좌를 선택해주세요.",
      },
      modal_case9: {
        title: "요청 실패",
        content: "금액을 입력해주세요.",
      },
      modal_case10: {
        title: "요청 실패",
        content: (params) => `${params.min_value} 이상의 금액을 입력해주세요.`,
      },
      modal_case11: {
        title: "요청 실패",
        content: "지점을 선택해주세요.",
      },
      modal_case12: {
        title: "요청 실패",
        content: "보유 토큰이 부족합니다",
      },
      modal_case13: {
        title: "요청 실패",
        content: "보유 토큰확인에 실패했습니다.",
      },
      // 'modal_case14' : {
      //   'title': '요청 실패',
      //   'content': '환전 받을 코인이 너무 적습니다.',
      // },
      modal_case15: {
        title: "요청 실패",
        content: "환전 결과 예상 확인 중 오류가 발생했습니다.",
      },
      modal_case16: {
        title: "요청 실패",
        content: "OTP 인증번호가 올바르지 않습니다. 다시 확인해주세요.",
      },
      modal_case17: {
        title: "요청 실패",
        content: "환전 요청 확인 중 에러가 발생했습니다.",
      },
      modal_case18: {
        title: "요청 실패",
        content: "통신에러가 발생했습니다.",
      },
      modal_case19: {
        title: "요청 실패",
        content: "환전 요청 확인 중 에러가 발생했습니다.",
      },
      modal_case20: {
        title: "요청 실패",
        content: "통신에러가 발생했습니다.",
      },
      modal_case21: {
        title: "잔액 부족",
        content: "현재 보유하신 토큰이 없습니다. 지금 바로 충전하시겠습니까?",
      },
      modal_case22: {
        title: "요청 실패",
        content: "토큰 및 화폐를 모두 지정해주세요.",
      },
      modal_case23: {
        title: "요청 실패",
        content: "토큰을 모두 지정해주세요.",
      },
      modal_case24: {
        title: "환전 결과 예상 요청 실패",
        content: `서버에러로 환전 결과 예상에 실패했습니다. 잠시 후 다시 시도해주세요`,
      },
      modal_case25: {
        title: "요청 실패",
        content: "환전 예상 수량을 확인해주세요.",
      },
      modal_case26: {
        title: "요청 실패",
        content:
          "지점의 계좌 정보 변동으로 인해 해당 지점에서의 환전이 불가능합니다. 잠시 후 다시 시도하거나, 지점을 다시 지정해주세요.",
      },
      modal_case27: {
        title: "요청 실패",
        content: (params) => `${params.max_value} 이하의 금액을 입력해주세요.`,
      },
    },

    "exchange/money_to_token": {
      commission_info_text1: (params) =>
        `※ 현재 같은종류 화폐 환전 수수료는 ${params.commission_rate}% 입니다.`,
      commission_info_text2: (params) =>
        `※ 현재 다른종류 화폐 환전 수수료는 ${params.commission_rate}% 입니다.`,
    },

    "exchange/token_to_money": {
      btn_enter_number: "입력",
      select_exchanger: {
        title: "지점 선택",
        not_selected: "미지정",
      },
      select_token: {
        title: "코인 선택",
        not_selected: "미지정",
      },
      select_exchange_type: {
        title: "입금 방식",
        by_offline: "지점 방식",
        by_bank_account: "계좌 이체",
      },
      select_bank_account: {
        title: "입금 계좌",
        not_selected: "계좌를 선택해주세요",
      },
      input_memo: {
        title: "메세지",
      },
      commission_info_text: (params) =>
        `※ 현재 화폐 환전 수수료는 ${params.commission_rate}% 입니다.`,
    },

    "exchange/token_to_token": {
      btn_enter_number: "입력",
      select_exchanger: {
        title: "지점 선택",
        not_selected: "미지정",
      },
      select_token: {
        title: "코인 선택",
        not_selected: "미지정",
      },
      result_caculating: {
        loading_text: "예상 결과를 계산 중입니다...",
        expected_token_to_add: "환전 예상 수량",
        expected_balance: "환전 후 예상 잔액",
        expected_commission: "예상 수수료",
        warning_message: (params) => {
          return `※ 환전 후 잔액은 현재(${params.date}) 서버 환율 기준 예상 금액으로 실제 환전 시의 환율에 따라 변동될 수 있습니다.`;
        },
      },
      input_memo: {
        title: "메세지",
      },
      commission_info_text: (params) =>
        `※ 현재 코인 환전 수수료는 ${params.commission_rate}% 입니다.`,
    },

    "exchange/result": {
      title: "환전 신청 완료",
      token_from: "신청 코인",
      commission_amount: "수수료",
      money_to: "환전 화폐",
      token_to: "환전 코인",
      exchanger: {
        title: "지점 정보",
        name: "지점명",
        bank: "은행",
        account: "계좌번호",
        btn_copy: "복사",
        info_text: "* 위 계좌로 입금완료 시 충전이 진행됩니다.",
      },
      user: {
        name: "예금주",
        account: "계좌번호",
      },
      btn_confirm: "확인",
    },

    //* wallet -------------------------------------------------
    wallet: {
      title: "지갑",
      qr_viewer: {
        title: "내 QR 코드",
        modal_detail: {
          title: "QR 코드",
          info_text: "내 QR코드를 보여주세요!",
          copied_message: "복사되었습니다",
          btn_copy_login_id: "아이디 복사",
          btn_copy_eos_account: "지갑주소 복사",
          btn_download_qr: "QR코드 저장",
        },
      },
      wallet_major: (params) => `${params.major_token_symbol} 보유 수량`,
      wallet_selected: (params) => `${params.selected_token_symbol} 보유 수량`,
    },

    "wallet/record": {
      title: "내역 조회",
      transfer_tab: "이체 내역",
      filling_tab: "충전 내역",
      exchange_tab: "환전 내역",
    },

    "wallet/record/transfer": {
      no_data_text: "이체 내역이 없습니다.",
      status: {
        pre_transfer: "승인 대기",
        on_transfering: "이체 중",
        ok: "완료",
        failed: "실패",
      },
      select: {
        all: "전체",
        to: "출금",
        from: "입금",
      },
      modal_detail: {
        title: "이체 내역",
        sender_id: "보내는 사람 ID",
        sender_id_exchanger: "보낸 지갑주소",
        receiver_id: "받는 사람 ID",
        receiver_exchanger: "받는 지갑주소",
        token_amount: "수량",
        log: "메세지",
        date: "이체 날짜",
        status: "상태",
      },
    },

    "wallet/record/filling": {
      no_data_text: "충전 내역이 없습니다.",
      status: {
        start: "승인 대기",
        exchangable: "거래 가능",
        pay_confirmed: "승인",
        ok: "완료",
        canceled: "취소",
        failed: "실패",
      },
      from_symbol: ({ currency_from }) => `${currency_from}로 충전`,
      modal_detail: {
        title: "충전 내역",
        exchanger_name: "지점",
        exchanger_bank_account: "계좌",
        money_amount: "신청금액",
        token_amount: "수량",
        date: "이체 날짜",
        status: "상태",
        reason: "사유",
      },
    },

    "wallet/record/exchange": {
      no_data_text: "환전 내역이 없습니다.",
      status: {
        start: "승인 대기",
        exchangable: "거래 가능",
        pay_confirmed: "승인",
        ok: "완료",
        canceled: "취소",
        failed: "실패",
      },
      select: {
        money: "코인-화폐 환전",
        token: "코인-코인 환전",
      },
      from_symbol: ({ token_from }) => `${token_from}로 환전`,
      modal_t2m_detail: {
        title: "환전 내역",
        exchanger_name: "지점",
        from_token: "요청 수량",
        to_money: "환전 금액",
        commission: "수수료",
        date: "이체 날짜",
        status: "상태",
        reason: "사유",
      },
      modal_t2t_detail: {
        title: "환전 내역",
        exchanger_name: "지점",
        from_token: "요청 수량",
        to_token: "환전 수량",
        commission: "수수료",
        date: "이체 날짜",
        status: "상태",
        reason: "사유",
      },
    },

    //* all ----------------------------------------------------
    all: {
      title: "전체",
      profile_section: {
        title: "프로필",
        profile: "프로필",
      },
      service_section: {
        title: "서비스",
        wallet: "내 지갑",
        coupon: "상품권",
        history: "거래 내역",
        exchanger: "지점 찾기",
        exchange_rate_info: "환율 정보",
        verification: "인증 및 보안",
        setting: "설정",
      },
    },

    "all/notice": {
      title: "공지사항",
      load_more: "더보기",
    },

    "all/faq": {
      title: "무엇을 도와드릴까요?",
      subtitle: "자주 묻는 질문",
    },

    "all/profile": {
      title: "프로필",
      user_id: "사용자 ID",
      email: "이메일",
      new_email_placeholder: "새 이메일을 입력해주세요",
      password: "비밀번호 수정",
      password_condition: "특수문자, 영문, 숫자를 포함한 8자리 이상",
      change_password_guide1: "1. 설정하신 이메일로 임시코드를 보내주세요",
      btn_send_code: "보내기",
      change_password_guide2: "2. 수령한 임시코드와 새 비밀번호를 입력하세요",
      code_for_new_password_placeholder: "임시코드를 입력해주세요",
      new_password_placeholder: "새 비밀번호를 입력해주세요",
      country: "국가",
      // 'otp_status': 'OTP 인증상태',
      // 'otp_btn_verified': '인증완료',
      // 'otp_btn_verify': '인증하기',
      export_private_key: "개인키 내보내기",
      export_keystore: "Keystore 내보내기",

      btn_modify: "수정",
      btn_replace: "변경",
      btn_cancel: "취소",
      btn_logout: "로그아웃",

      modal_case1: {
        title: "수정 실패",
        content: "이메일 형식을 확인해주세요.",
      },
      modal_case2: {
        title: "변경 되었습니다.",
      },
      modal_case3: {
        title: "수정 실패",
        content: "통신에러. 잠시 후 다시 시도해주세요.",
      },
      modal_case5: {
        title: "수정 실패",
        content: "8-30자의 영문, 숫자, 특수문자를 혼합해주세요.",
      },
      modal_case6: {
        title: "변경 되었습니다.",
      },
      modal_case7: {
        title: "수정 실패",
        content: "통신에러. 잠시 후 다시 시도해주세요.",
      },
      modal_case8: {
        title: "전송 요청 완료",
        content: "메일에서 코드를 확인해주세요.",
      },
      modal_case9: {
        title: "전송 요청 실패",
        content: "서버 에러. 잠시 후 다시 시도해주세요.",
      },
      modal_case10: {
        title: "수정 실패",
        content: "이미 존재하는 이메일입니다.",
      },
      modal_case11: {
        title: "변경 실패",
        content: "국가를 지정해주세요.",
      },
      modal_case12: {
        title: "변경 실패",
        content: "잘못된 코드입니다.",
      },
    },

    "all/exchange_infomation": {
      title: "환율 정보",
      time_info: ({ date }) => `환율 기준 시간: ${date}`,
    },

    "all/verification": {
      title: "OTP 인증",
      info_message: "이체/충전/환전 시 OTP 인증번호를 입력하셔야 합니다.",
      btn_verified: "인증 완료",
      btn_start_verify: "OTP 인증",
      btn_verify: "인증 확인",
      btn_otp_submit: "입력",

      info_step0: "OTP 인증 완료 후 거래/등록을 하실 수 있습니다.",
      info_step1: "1. Google OTP를 다운로드 하세요.",
      btn_download: "다운받기",
      info_step2: "2. 아래 개인키를 복사하여 등록하세요.",
      btn_copy: "복사",
      info_step3: "구글 인증번호 6자리를 입력해주세요.",

      modal_case1: {
        title: "인증성공",
        content: "OTP 등록이 정상적으로 완료되었습니다.",
      },
      modal_case2: {
        title: "인증 실패",
        content: "인증번호를 입력해주세요.",
      },
      modal_case3: {
        title: "인증 실패",
        content: "잘못된 인증번호입니다. 다시 시도해주세요.",
      },
    },

    "all/location": {
      title: "환전 지점",
      no_exchanger: "지점을 등록하세요!",
      near_exchangers: "주변 지점 리스트",
      no_list_data: "데이터가 없습니다",
      no_list_data_location: "검색된 주변 지점이 없습니다",

      btn_find_way: "길찾기",
      btn_find_location: "위치찾기",
      btn_change_location: "변경",
      modal_case1: {
        title: "등록 중 통신오류가 발생했습니다.",
      },
      modal_case2: {
        title: "해당 지점 또는 지점의 보유 계좌가 없습니다.",
      },
      modal_case3: {
        title: "기능 제한.",
        content:
          "권한 제한으로 일부 기능이 작동하지 않습니다. 위치 권한을 확인해주세요.",
      },
    },

    "all/location/map": {
      title: "지점 위치 탐색",

      google_map: {
        current_position: "현재 위치",
        btn_register: "등록",
        btn_registered: "등록된 지점",
      },

      modal_case1: {
        title: "에러 발생",
        content:
          "해당하는 지점이 없거나 변동되었습니다. 재접속 후 다시 시도해주세요.",
      },
      modal_case2: {
        title: "지점 등록 실패",
        content: "통신에러가 발생했습니다. 잠시 후 다시 시도해주세요.",
      },
    },

    "all/location/input_qr": {
      guide_text: "QR코드를 스캔하거나, QR코드 이미지를 업로드 해보세요",
      reading_qr: "인식 중입니다...",

      modal_case1: {
        title: "입력 실패",
        content: "존재하지 않는 지점입니다.",
      },
      modal_case2: {
        title: "입력 실패",
        content: "통신 에러로 확인에 실패했습니다.",
      },
      modal_case3: {
        title: "작동 제한",
        content: "카메라 구동에 실패했습니다. 기기 및 권한을 확인해주세요.",
      },
    },

    "filling/commission": {
      commission_info_text1: (params) =>
        `※ 현재 같은종류 화폐 충전 수수료는 ${params.commission_rate}% 입니다.`,
      commission_info_text2: (params) =>
        `※ 현재 다른종류 화폐 충전 수수료는 ${params.commission_rate}% 입니다.`,
    },

    "coupon/commission": {
      commission_info_text1: (params) =>
        `※ 현재 같은종류 상품권 구매 수수료는 ${params.commission_rate}% 입니다.`,
      commission_info_text2: (params) =>
        `※ 현재 다른종류 상품권 구매 수수료는 ${params.commission_rate}% 입니다.`,
    },

    "all/setting": {
      title: "설정",
      notify_sound: "알림음",
      no_sound: "미설정",

      notify_transfer: "입/출금 알림",
      notify_filling_exchange: "충/환전 알림",
      notify_popup: "팝업 알림",
    },

    "all/coupon": {
      select_buy_type: {
        title: "구매 방식",
        by_currency: "화폐",
        by_crypto: "코인",
      },
      commission_coupon_text: (params) =>
        `※ 현재 상품권 구매 수수료는 ${params.commission_rate}% 입니다.`,
      result: {
        title: "상품권 신청완료",
        name: "신청 상품권",
        money_amount: "구입 금액",
        token_amount: "구입 상품권",
        info_text: "* 위 계좌로 입금완료시 상품권 구입이 진행됩니다.",
      },
      modal_detail: {
        title: "상품권종류",
        status: "상태",
        type: "구입종류",
        code: "상품권코드",
        remain: "남은금액",
        reason: "사유",
        date: "날짜",
      },
      record: {
        gift: "보낸 선물",
        receive: "받은 선물",
        gift_from: "보낸사람",
        gift_to: "받는사람",
        memo: "메세지",
        time: "시간",
        send_date: "보낸날짜",
        recv_date: "받은날짜",
        type: "유형",
        date: "날짜",
        no_data_text: "상품권이 없습니다.",
        recv: "선물받은 상품권",
        send: "선물한 상품권",
        buy: "구매한 상품권",
        status: {
          start: "승인 대기",
          exchangable: "거래 가능",
          pay_confirmed: "승인",
          ok: "완료",
          canceled: "취소",
          failed: "실패",
          closed: "쿠폰완료",
          clear: "사용완료",
        },
      },
      t_buy: "구매한상품권",
      t_send: "선물한상품권",
      t_recv: "받은상품권",
      t_pay: "상품권으로 결제",
      t_wallet: "지갑으로 보냄",
      to_wallet: "내지갑으로 보내기",
      title: "상품권",
      my: "내상품권",
      coupon_log: "상품권내역",
      type: "내역종류",
      exchange_bank: "계좌",
      my_buy: "구매",
      my_able: "사용가능",
      my_total: "전체내역",
      my_recv: "받은선물",
      my_send: "보낸선물",
      my_wallet: "지갑전환내역",
      my_pay: "결제내역",
      my_cancel: "취소내역",
      my_remain: "남은수량",
      pay_id: "결제한 아이디",
      buy: "구매하기",
      gift: "선물하기",
      gift_box: "선물함",
      pay: "결제하기",
      select_currency: "화폐선택",
      select_coupon: "상품권 선택",
      gift_to: "선물받을 페이앱ID",
      pay_account: "받는사람 지갑주소",
      pay_amount: "수량",
      total_amount: "총보유량",
      not_selected: "미지정",
      success: "완료되었습니다. 내 상품권내역에서 확인할수있습니다.",
      modal_case1: {
        title: "구매 실패",
        content: "상품권을 선택해주세요.",
      },
      result_caculating: {
        loading_text: "예상 금액을 계산 중입니다...",
        expected_price: "상품권금액",
        expected_fee: "수수료",
        expected_total_price: "전체 구입금액",
        warning_message: (params) => {
          return `※ 구매 후 잔액은 현재(${params.date}) 서버 환율 기준 예상 금액으로 실제 충전 시의 환율에 따라 변동될 수 있습니다.`;
        },
      },
      modal_case2: {
        title: "요청실패",
        content: "구입 예상 금액을 확인해주세요",
      },
      modal_case3: {
        title: "요청실패",
        content: "금액을 입력해주세요",
      },
      modal_case4: {
        title: "요청실패",
        content: (params) => `${params.value_min} 이상의 금액을 입력해주세요`,
      },
      modal_case5: {
        title: "요청실패",
        content: "화폐를 선택해주세요",
      },
      modal_case6: {
        title: "요청실패",
        content: "지점을 등록해주세요",
      },
      modal_case7: {
        title: "요청실패",
        content: "계좌를 선택해주세요",
      },
      modal_case8: {
        title: "요청실패",
        content: "상품권을 선택해주세요",
      },

      modal_case11: {
        title: "요청실패",
        content: "OTP 인증번호가 올바르지 않습니다. 다시 확인해주세요.",
      },
      modal_case12: {
        title: "요청실패",
        content: "서버에러. 구매 요청에 실패했습니다",
      },
      modal_case13: {
        title: "요청실패",
        content: "상품권구입 실패했습니다",
      },
      modal_case14: {
        title: "요청실패",
        content:
          "지점의 계좌 정보 변동으로 인해 해당 지점으로의 구입이 불가능합니다. 잠시 후 다시 시도하거나, 지점을 다시 지정해주세요.",
      },
      modal_case15: {
        title: "요청실패",
        content:
          "화폐를 다시 선택해주세요. 현재 지점에서 지원하지 않는 화폐입니다.",
      },
      modal_case16: {
        title: "요청실패",
        content: "선물받을 페이앱ID가 없거나 잘못되었습니다.",
      },
      modal_case17: {
        title: "요청실패",
        content: "결제할 상품권을 선택해주세요",
      },
      modal_case18: {
        title: "요청실패",
        content: "받을 대상의 아이디를 입력하세요.",
      },
      modal_case19: {
        title: "요청실패",
        content: "결제할 금액을 입력하세요.",
      },
      modal_case20: {
        title: "잔액 부족",
        content: "현재 선택한 코인 잔액이 부족합니다.",
      },
    },

    registration: {
      id: "아이디",
      password: "비밀번호",
      password_confirm: "비밀번호 확인",
      password_comment:
        "* 영문,숫자,특수문자를 혼합하여 8자리이상 비밀번호를 입력해주세요.",
      email: "이메일",
      country: "국가",

      check_box_terms: "이용약관 및 개인정보보호 정책에 동의합니다.",
      modal_title_terms: "이용약관 및 개인정보보호 정책",
      btn_term_modal_agree: "동의",
      btn_term_modal_cancel: "취소",

      btn_register: "회원가입",
      btn_ck: "중복확인",
      btn_login: "로그인",
      alert1: "사용가능한 아이디입니다.",
      alert2: "이미 사용중인 아이디입니다.",
      alert3: "아이디 중복확인을 해주세요.",
      error_case1: "일치하는 아이디 또는 패스워드가 없습니다.",
      error_case2: "회원등록에 실패했습니다. 관리자에게 문의해주세요.",
      error_case3: "통신 에러. 잠시 후 다시 시도해주세요.",
      error_case4: "아이디를 입력해주세요",
      error_case5: "비밀번호를 입력해주세요",
      error_case6: "비밀번호가 일치하지 않습니다",
      error_case7: "이메일을 다시 확인해주세요",
      error_case9: "8자 이상의 영문, 숫자, 특수문자를 혼합해주세요",
      error_case10: "국가를 지정해주세요",
      error_case11: "이미 존재하는 아이디입니다.",
      error_case12: "이미 존재하는 이메일입니다.",
      // 'error_case13': '아이디에는 영문과 숫자만 사용해주세요.',
      error_case14: "이용약관 및 개인정보보호 정책에 동의해주세요.",
    },

    login: {
      id: "아이디",
      password: "비밀번호",

      btn_register: "회원가입",
      btn_login: "로그인",
      btn_id_search: "ID",
      btn_pw_search: "비밀번호 찾기",

      error_case1: "아이디를 입력해주세요",
      error_case2: "비밀번호를 입력해주세요",
      error_case3: "일치하는 아이디 또는 패스워드가 없습니다.",
      error_case4: "통신오류. 잠시 후 다시 시도해주세요.",
    },

    id_search: {
      id: "아이디",
      email: "이메일",
      email_guide: "가입하신 이메일로 아이디를 보내드립니다",
      btn_id_search: "보내기",
      btn_login: "로그인",
      btn_register: "회원가입",

      modal_case1: {
        title: "전송 요청 완료",
        content: "메일에서 아이디를 확인해주세요.",
      },
      modal_case2: {
        title: "전송 요청 실패",
        content: "서버 에러. 잠시 후 다시 시도해주세요.",
      },
      modal_case3: {
        title: "전송 요청 실패",
        content: "이메일을 입력해주세요.",
      },
      modal_case4: {
        title: "전송 요청 실패",
        content: "올바른 이메일 형식이 아닙니다.",
      },
      modal_case5: {
        title: "확인",
        content: "사용가능한 아이디입니다.",
      },
      modal_case6: {
        title: "확인",
        content: "사용불가능한 아이디입니다.",
      },
    },

    etc: {
      alert1: "* 예금주와 계좌정보가 일치해야 합니다.",
      alert2: "금액이 소진되었습니다.",
    },
    pw_search: {
      guide1: "가입하신 ID와 이메일을 입력하고 '보내기' 버튼을 눌러주세요",
      guide2:
        "이메일로 받은 임시코드와 아이디, 새 비밀번호를 입력 후 '비밀번호 설정' 버튼을 눌러주세요",
      id: "아이디",
      email: "이메일",
      code_for_new_password: "임시코드",
      btn_send_code: "보내기",
      new_password: "새 비밀번호",
      btn_pw_search: "비밀번호 설정",
      btn_login: "로그인",
      btn_register: "회원가입",

      modal_case1: {
        title: "설정 실패",
        content: "8-30자의 영문, 숫자, 특수문자를 혼합해주세요.",
      },
      modal_case2: {
        title: "설정 되었습니다.",
      },
      modal_case3: {
        title: "설정 실패",
        content: "통신에러. 잠시 후 다시 시도해주세요.",
      },
      modal_case4: {
        title: "전송 요청 완료",
        content: "메일에서 코드를 확인해주세요.",
      },
      modal_case5: {
        title: "전송 요청 실패",
        content: "서버 에러. 잠시 후 다시 시도해주세요.",
      },
      modal_case6: {
        title: "전송 요청 실패",
        content: "아이디를 입력해주세요.",
      },
      modal_case7: {
        title: "전송 요청 실패",
        content: "이메일을 입력해주세요.",
      },
      modal_case8: {
        title: "전송 요청 실패",
        content: "올바른 이메일 형식을 입력해주세요.",
      },
      modal_case9: {
        title: "설정 실패",
        content: "임시코드를 입력해주세요.",
      },
      modal_case10: {
        title: "설정 실패",
        content: "비밀번호를 입력해주세요.",
      },
      modal_case11: {
        title: "설정 실패",
        content: "잘못된 코드입니다.",
      },
      modal_case12: {
        title: "실패",
        content: "존재하지않는 아이디나 이메일입니다.",
      },
    },
    more: {
      withdraw_out: "외부출금",
      withdraw_history: "외부출금 내역",
      withdraw_amount: "출금 수량",
      withdraw_address: "출금 주소",
      withdraw_memo: "출금 메모",
      withdraw_exchange: "코드를 입력하세요.",
      error1: "수량이 잘못되었습니다.",
      erroe2: "출금토큰이 잘못되었습니다.",
      error3: "12자리 메모를 입력하세요.",
    },
  },
};
