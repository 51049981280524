import React from 'react';

import img_splash from '../../images/splash/iPhone XS Copy 5.png';


class Intro extends React.Component {
  is_mounted_ = false;

  componentDidMount() {
    this.is_mounted_ = true;
    setTimeout(() => {
      if (this.is_mounted_ === true) {
        this.props.onClick();
      }
    }, 2500);
  }

  componentWillUnmount() { this.is_mounted_ = false; }


  render () {
    return (
      <div onClick={this.props.onClick} style={this.props.style}>
        <img alt="" src={img_splash} style={{ height: "100%", objectFit: "cover" }} />
      </div>
    );
  }
}

export default Intro;