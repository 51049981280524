import React from 'react';
import { Row, Col, Modal, Typography, Select } from 'antd';
import moment from 'moment';
import 'moment/locale/ko';
//* view components
import Loading from '../../../../snippets/Loading';
//* utils
import cache      from '../../../../utils/cache';
import common     from '../../../../utils/common';
import eos_wallet from '../../../../utils/eos_wallet';
import server_api from '../../../../utils/server_api';
//* styles
import '../../../../styles/infinity_lists.less';
//* resources
import lang from '../../../../lang/lang';




class FillingList extends React.PureComponent {
  no_data_messege = lang.get('wallet/record/exchange/no_data_text');
  status_localize = {
    START        : lang.get('wallet/record/exchange/status/start'),
    EXCHANGABLE  : lang.get('wallet/record/exchange/status/exchangable'),
    PAY_CONFIRMED: lang.get('wallet/record/exchange/status/pay_confirmed'),
    OK           : lang.get('wallet/record/exchange/status/ok'),
    CANCELED     : lang.get('wallet/record/exchange/status/canceled'),
    FAILED       : lang.get('wallet/record/exchange/status/failed'),
  }

  static defaultProps = {
    year : new Date().getFullYear(),
    month: new Date().getMonth()
  }

  is_mounted_ = false;
  state = {
    is_loading: false,
    data: [],
    data_codes: [],
    selected_filter: 'money',
    money_modal2Visible: false,
    token_modal2Visible: false,
    selected_item: {}
  };  
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    const data = await this._selectData(this.state.selected_filter, this.props.year, this.props.month);

    const data_codes = await this._cacheCodes();
    
    this.setstate({
      is_loading: false,
      data,
      data_codes,
     });
  }

  componentWillUnmount() { this.is_mounted_ = false; }
  
  async componentWillReceiveProps(nextProps) {
    const is_same_year  = this.props.year  === nextProps.year;
    const is_same_month = this.props.month === nextProps.month;
    if (is_same_year && is_same_month) {
      return;
    }
    
    this.setstate({ is_loading: true });

    const data = await this._selectData(this.state.selected_filter, nextProps.year, nextProps.month);

    this.setstate({
      is_loading: false,
      data
    });  
  }

  render() {
    return (
      <div style={{ display: "table", height: "100%", width: "100%" }}>
        <div style={{ display: "table-row" }}>
          <div style={{ display: "table-cell"}}>
            <div style={{ paddingTop: "1rem" }}>
              <Select defaultValue="all" value={this.state.selected_filter} style={{ width: "100%" }}
                onChange={async value => {
                  this.setstate({ is_loading: true })
                  this.setstate({
                    is_loading: false,
                    data: await this._selectData(value, this.props.year, this.props.month),
                    selected_filter: value,
                  })
                }} >
                <Select.Option value="money">{lang.get('wallet/record/exchange/select/money')}</Select.Option>
                <Select.Option value="token">{lang.get('wallet/record/exchange/select/token')}</Select.Option>
              </Select>
            </div>
          </div>
        </div>
        <div style={{ display: "table-row", height: "100%" }}>
          <div style={{ display: "table-cell", padding: "0.5rem 0" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div style={{ position: "absolute", top: "0", bottom: "0", left: "0", right: "0", overflow: "auto" }}>
                <Loading is_loading={this.state.is_loading} overall={false} style={{ height: "100%" }}>
                  {(this.state.selected_filter === 'money')
                    ? (<ToMoneyList data={this.state.data} data_codes={this.state.data_codes} onClick={this._hShowMoneyModal.bind(this)} no_data_messege={this.no_data_messege} status_localize={this.status_localize} />)
                    : (<ToTokenList data={this.state.data} data_codes={this.state.data_codes} onClick={this._hShowTokenModal.bind(this)} no_data_messege={this.no_data_messege} status_localize={this.status_localize} />)
                  }
                  {/* money modal */}
                  <Modal
                    title={ <div style={{ fontWeight: "700" }}>
                              <Typography.Text>{lang.get('wallet/record/exchange/modal_t2m_detail/title')}</Typography.Text>
                            </div>}
                    centered
                    closable={true}
                    visible={this.state.money_modal2Visible}
                    onOk={(e) => this.setstate({ money_modal2Visible: false })}
                    onCancel={(e) => this.setstate({ money_modal2Visible: false })}
                    footer={null}
                  >
                    <table style={{ color: "RGB(50, 50, 50)" }}>
                      <tbody>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>{lang.get('wallet/record/exchange/modal_t2m_detail/exchanger_name')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)" }}>{(this.state.selected_item.exchanger || {}).name || ''}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>{lang.get('wallet/record/exchange/modal_t2m_detail/from_token')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)" }}>{`${eos_wallet.formatCommaNumber(this.state.selected_item.token_amount || '0')} ${this.state.selected_item.token_symbol || ''}`}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>{lang.get('wallet/record/exchange/modal_t2m_detail/to_money')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)" }}>{`${eos_wallet.formatCommaNumber(this.state.selected_item.money_amount || '0')} ${this.state.selected_item.currency_symbol || ''}`}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>{lang.get('wallet/record/exchange/modal_t2m_detail/commission')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)" }}>{`${eos_wallet.formatCommaNumber(this.state.selected_item.commission_amount || '0')} ${this.state.selected_item.token_symbol || ''}`}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>{lang.get('wallet/record/exchange/modal_t2m_detail/date')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)" }}>{moment(this.state.selected_item.createdAt || 0).format('YY-MM-DD HH:mm:ss')}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>{lang.get('wallet/record/exchange/modal_t2m_detail/status')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)" }}>{this.status_localize[this.state.selected_item.status] || ''}</td>
                        </tr>
                        {(this.state.selected_item.status === 'FAILED' || this.state.selected_item.status === 'CANCELED')
                          ? (<tr>
                            <td style={{ paddingRight: "0.5rem " }}>{lang.get('wallet/record/exchange/modal_t2m_detail/reason')}</td>
                            <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)" }}>{this.state.selected_item.cancel_log || ''}</td>
                          </tr>)
                          : null
                        }
                      </tbody>
                    </table>
                  </Modal>
                  {/* token modal */}
                  <Modal
                    title={ <div style={{ fontWeight: "700" }}>
                              <Typography.Text>{lang.get('wallet/record/exchange/modal_t2t_detail/title')}</Typography.Text>
                            </div>}
                    centered
                    closable={true}
                    visible={this.state.token_modal2Visible}
                    onOk={(e) => this.setstate({ token_modal2Visible: false })}
                    onCancel={(e) => this.setstate({ token_modal2Visible: false })}
                    footer={null}
                  >
                    <table style={{ color: "RGB(50, 50, 50)" }}>
                      <tbody>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>{lang.get('wallet/record/exchange/modal_t2t_detail/exchanger_name')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)" }}>{(this.state.selected_item.exchanger || {}).name}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>{lang.get('wallet/record/exchange/modal_t2t_detail/from_token')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)" }}>{`${eos_wallet.formatCommaNumber(this.state.selected_item.from_token_amount)} ${this.state.selected_item.from_token_symbol}`}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>{lang.get('wallet/record/exchange/modal_t2t_detail/to_token')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)" }}>{`${eos_wallet.formatCommaNumber(this.state.selected_item.to_token_amount)} ${this.state.selected_item.to_token_symbol}`}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>{lang.get('wallet/record/exchange/modal_t2t_detail/commission')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)" }}>{`${eos_wallet.formatCommaNumber(this.state.selected_item.commission_amount)} ${this.state.selected_item.from_token_symbol}`}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>{lang.get('wallet/record/exchange/modal_t2t_detail/date')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)" }}>{moment(this.state.selected_item.createdAt).format('YY-MM-DD HH:mm:ss')}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: "0.5rem " }}>{lang.get('wallet/record/exchange/modal_t2t_detail/status')}</td>
                          <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)" }}>{this.status_localize[this.state.selected_item.status]}</td>
                        </tr>
                        {(this.state.selected_item.status === 'FAILED' || this.state.selected_item.status === 'CANCELED')
                          ? (<tr>
                            <td style={{ paddingRight: "0.5rem " }}>{lang.get('wallet/record/exchange/modal_t2t_detail/reason')}</td>
                            <td style={{ paddingLeft: "0.5rem", borderLeft: "1px solid RGB(230, 230, 230)" }}>{this.state.selected_item.cancel_log}</td>
                          </tr>)
                          : null
                        }
                      </tbody>
                    </table>
                  </Modal>
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async _hShowMoneyModal (e) {
    let selected_item = (this.state.data).filter(datum => String(datum.id) === String(e.target.dataset.id));

    const currency_symbol = ((this.state.data_codes).filter(code => code.token_symbol === selected_item[0].token_symbol))[0].currency_symbol;
    selected_item[0].currency_symbol = currency_symbol;

    if (selected_item.length >= 1)
      this.setstate({
        is_loading: false,
        money_modal2Visible: true,
        selected_item: selected_item[0]
      });    
  }

  async _hShowTokenModal (e) {
    let selected_item = (this.state.data).filter(datum => String(datum.id) === String(e.target.dataset.id));

    if (selected_item.length >= 1)
      this.setstate({
        is_loading: false,
        token_modal2Visible: true,
        selected_item: selected_item[0]
      });    
  }


  //* transactions
  async _selectData (value, year, month) {
    const getMoneyData = async () => {
      try {
        const data_money = (await server_api.fetchTokenToMoneys({
          where: {
            createdAt: {
              $gte : new Date(year, month),
              $lt: new Date(year, month + 1)
            },
            user_id: common.get().id
          },
          attributes: [
            'cancel_log',
            'commission_amount',
            // 'commission_amount_for_owner',
            // 'commission_rate',
            // 'commission_rate_for_owner',
            'createdAt',
            'exchange_type',
            // 'exchanger_bankaccount_id',
            'exchanger_id',
            'id',
            // 'log',
            'money_amount',
            // 'sealedAt',
            'status',
            'token_amount',
            'token_symbol',
            // 'user_bankaccount_id',
            // 'user_id',
          ],
          include: [
            // { association: 'user' },
            { association: 'exchanger', attributes: ['name'] },
            // { association: 'user_bankaccount' },
            // { association: 'exchanger_bankaccount' }
          ]
        })).items;

        return data_money;
      }
      catch (e) {
        if (process.env.NODE_ENV === 'development') {
          return console.error(e);
        }
        return [];
      }
    }

    const getTokenData = async () => {
      try {
        const data_token = (await server_api.fetchTokenToTokens({
          where: {
            createdAt: {
              $gte : new Date(year, month),
              $lt: new Date(year, month + 1)
            },
            user_id: common.get().id
          },
          attributes: [
            'cancel_log',
            'commission_amount',
            // 'commission_amount_for_owner',
            // 'commission_rate',
            // 'commission_rate_for_owner',
            'createdAt',
            'exchanger_id',
            'from_token_amount',
            'from_token_symbol',
            'id',
            // 'log',
            // 'sealedAt',
            'status',
            'to_token_amount',
            'to_token_symbol',
            // 'updatedAt',
            // 'user_id',
          ],
          include: [
            // { association: 'user' },
            { association: 'exchanger', attributes: ['name'] },
            // { association: 'user_bankaccount' },
            // { association: 'exchanger_bankaccount' }
          ]
        })).items;

        return data_token;
      }
      catch (e) {
        if (process.env.NODE_ENV === 'development') {
          return console.error(e);
        }
        return [];
      }
    }

    let money_data;
    let token_data;
    switch(value) {
      case 'money':
        money_data = await getMoneyData();
        return money_data.reverse();
      case 'token':
        token_data = await getTokenData();
        return token_data.reverse();
      default:
        return [];
    }
  }


  async _cacheCodes () {
    try {
      const res_items = (await cache.codes.get()).items;

      return res_items
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        return console.error(e);
      }
      return [];
    }
  }
}


const ToMoneyList = (props) => {
  if (props.data.length === 0) {
    return (
      <li>
        <div style={{ textAlign: "center", marginTop: "1rem" }}>{props.no_data_messege}</div>
      </li>
    )
  }

  let lis = [];
  let current_date = '';
  props.data.forEach((datum, i) => {
      const is_date_changed = current_date !== new Date(datum.createdAt).getDate();
      if (is_date_changed) {
        current_date = new Date(datum.createdAt).getDate();
        lis.push(<li key={`date_${i}`}><div style={{ marginTop: "1rem", fontWeight: "700", color: "RGB(58, 99, 173)" }}>
          {moment(datum.createdAt).format('MM.DD (dd)')}
        </div></li>);
      }

      const currency_symbol = ((props.data_codes).filter(code => code.token_symbol === datum.token_symbol))[0].currency_symbol;

      lis.push(<li key={i} style={(i !== 0 && !is_date_changed) ? { borderTop: "1px dotted rgb(230, 230, 230)" } : {}}>
        <div style={{ padding: "0.2rem 0", color: "RGB(50, 50, 50)", cursor: "pointer" }} onClick={props.onClick} data-id={datum.id}>
          <div style={{ pointerEvents: "none" }}>
            <Row type="flex" justify="space-between" style={{ width: "100%" }}>
              <Col span={8}>
                <div>
                  <div style={{ fontWeight: "700" }}>{datum.exchanger.name}</div>
                  <div style={{ color: "RGB(166, 166, 166)", fontSize: "0.8rem" }}>
                    {`${(new Date(datum.createdAt)).toLocaleTimeString([], {
                      "hour12": false,
                      "hour": "2-digit",
                      "minute": "2-digit"
                    })}, ${props.status_localize[datum.status]}`}
                  </div>
                </div>
              </Col>
              <Col span={16}>
                <div style={{ textAlign: "right" }}>
                  <div>
                    <span style={{ fontWeight: "700", color: "red" }}>{eos_wallet.formatCommaNumber(datum.money_amount)}</span>
                    <span style={{ fontSize: "0.8rem", paddingLeft: "0.3rem" }}>{currency_symbol}</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </li>)
    }  
  );

  return lis;
}


const ToTokenList = (props) => {
  if (props.data.length === 0) {
    return (
      <li>
        <div style={{ textAlign: "center", marginTop: "1rem" }}>{props.no_data_messege}</div>
      </li>
    )
  }

  let lis = [];
  let current_date = '';
  props.data.forEach((datum, i) => {
      const is_date_changed = current_date !== new Date(datum.createdAt).getDate();
      if (is_date_changed) {
        current_date = new Date(datum.createdAt).getDate();
        lis.push(<li key={`date_${i}`}><div style={{ marginTop: "1rem", fontWeight: "700", color: "RGB(58, 99, 173)" }}>
          {moment(datum.createdAt).format('MM.DD (dd)')}
        </div></li>);
      }

      lis.push(<li key={i} style={(i !== 0 && !is_date_changed) ? { borderTop: "1px dotted rgb(230, 230, 230)" } : {}}>
        <div style={{ padding: "0.2rem 0", color: "RGB(50, 50, 50)", cursor: "pointer" }} onClick={props.onClick} data-id={datum.id}>
          <div style={{ pointerEvents: "none" }}>
            <Row type="flex" justify="space-between" style={{ width: "100%" }}>
              <Col span={8}>
                <div>
                  <div style={{ fontWeight: "700" }}>{datum.exchanger.name}</div>
                  <div style={{ color: "RGB(166, 166, 166)", fontSize: "0.8rem" }}>
                    {`${(new Date(datum.createdAt)).toLocaleTimeString([], {
                      "hour12": false,
                      "hour": "2-digit",
                      "minute": "2-digit"
                    })}, ${props.status_localize[datum.status]}`}
                  </div>
                </div>
              </Col>
              <Col span={16}>
                <div style={{ textAlign: "right" }}>
                  <div>
                    <span style={{ fontSize: "1rem", fontWeight: "700", color: "red" }}>{eos_wallet.formatCommaNumber(datum.to_token_amount)}</span>
                    <span style={{ fontSize: "0.8rem", paddingLeft: "0.3rem" }}>{datum.to_token_symbol}</span>
                  </div>
                  <div>
                    <span style={{ fontSize: "0.75rem", fontWeight: "700", color: "rgb(150, 150, 150)" }}>{eos_wallet.formatCommaNumber(datum.from_token_amount)}</span>
                    <span style={{ fontSize: "0.75rem", color: "rgb(150, 150, 150)", paddingLeft: "0.3rem" }}>{lang.get('wallet/record/exchange/from_symbol', { token_from: datum.from_token_symbol })}</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </li>)
    }  
  );

  return lis;
}


export default FillingList;