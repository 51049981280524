import React from "react";
import PropTypes from "prop-types";
import { withRouter, Switch, Route } from "react-router-dom";
import { Layout, Modal } from "antd";
//* routes
import TransferResult from "./views/content/transfer/Result";
import InputQRKey from "./views/content/transfer/InputQRKey";
import SelectWallet from "./views/content/transfer/SelectWallet";
import Transfer from "./views/content/Transfer";
import FillingResult from "./views/content/filling/Result";
import Filling from "./views/content/Filling";
import ExchangeResult from "./views/content/exchange/Result";
import Exchange from "./views/content/Exchange";
import Exchange2 from "./views/content/all/Exchange2";
import History from "./views/content/all/History";
import WalletRecord from "./views/content/wallet/Record";
import Wallet from "./views/content/Wallet";
import AllSetting from "./views/content/all/Setting";
import AllVerification from "./views/content/all/Verification";
import AllExchangeInformation from "./views/content/all/ExchangeInformation";
import AllLocationMap from "./views/content/all/location/LocationMap";
import AllLocationQR from "./views/content/all/location/InputQRLocation";
import AllLocation from "./views/content/all/Location";
import AllProfile from "./views/content/all/Profile";
import AllFaQ from "./views/content/all/Faq";
import AllNotice from "./views/content/all/Notice";
import All from "./views/content/All";
import Intro from "./views/content/Intro";
import Login from "./views/content/Login";
import Register from "./views/content/Registration";
import IDSearch from "./views/content/IDSearch";
import PWSearch from "./views/content/PWSearch";
import Navigation from "./views/footer/Navigation";
//* utils
import common from "./utils/common";
import checkers from "./utils/checkers";
import native_util from "./utils/native_util";
import storage_util from "./utils/storage_util";
import server_api from "./utils/server_api";

class App extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }; // for setting withRouter

  /* local constants */
  is_mounted_ = false;
  should_intro_play_ = false;
  no_footer_paths_ = [
    "/intro",
    "/login",
    "/registration",
    "/idsearch",
    "/pwsearch",
  ];
  guest_allowed_paths_ = [
    "/intro",
    "/login",
    "/registration",
    "/idsearch",
    "/pwsearch",
  ];
  state = {
    has_footer: true,
    current_menu: "transfer",
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      has_footer: checkers.notInThesePaths(this.no_footer_paths_),
      current_menu: window.location.pathname.split("/")[1],
    };

    this._routeIntro();
    this._manageDirectAccess();
  }
  setstate = (partial_state) =>
    this.is_mounted_ === true && this.setState(partial_state);

  _routeIntro() {
    this.should_intro_play_ = this._hasNoRecentTrail(60);
    this._setRecentTrail();
    if (this.should_intro_play_ === true)
      return this.props.history.push("/intro");
  }

  _hasNoRecentTrail(minutes_max) {
    const now = new Date();
    const last_visited_time_local = sessionStorage.getItem("footprint");

    if (!last_visited_time_local) {
      return true;
    } else {
      const last_visited_time = new Date(last_visited_time_local);
      const minutes_passed = (now - last_visited_time) / (1000 * 60);
      return minutes_passed > minutes_max;
    }
  }

  _setRecentTrail() {
    sessionStorage.setItem("footprint", new Date());
  }

  async _manageDirectAccess() {
    if (
      this.should_intro_play_ === false &&
      checkers.isInThesePaths(["/intro"])
    )
      return this.props.history.push("/");

    const is_logged_in = await common.isLoggedIn();
    if (
      is_logged_in === false &&
      checkers.notInThesePaths(this.guest_allowed_paths_)
    )
      return this.props.history.push("/login");
  }

  componentDidMount() {
    this.is_mounted_ = true;

    if (process.env.NODE_ENV === "development") {
      console.log("app is mounted");
    }

    if (native_util.hasNativeApi()) {
      window.__nativeInit = native_util.init.bind(native_util);

      window.__nativeInitOnFirstLoad = async () => {
        // save push_options
        const user = (await server_api.fetchUsers({ where: { id: common.id } }))
          .items[0];
        storage_util.saveToLocal("push_options", {
          push_deposit_withdrawal: user.need_transfer_noti,
          push_filling_exchange: user.need_exchange_noti,
          push_popup: user.need_popup_noti,
        });

        // update fcm token
        if ((await common.isLoggedIn()) === true) {
          const fcm_token = await native_util.getFcmToken();
          try {
            await server_api.updateUser(common.id, { fcm_token });
          } catch (e) {
            if (process.env.NODE_ENV === "development") {
              console.error(e);
            }
          }
        }
      };

      window.__showModal = (title, content) => {
        Modal.info({
          title,
          content,
          centered: true,
        });
      };
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this._onLocationChanged();
    }
  }

  async _onLocationChanged() {
    this._manageDirectAccess();
    this._setRecentTrail();

    native_util.hideKeyboard();

    console.log(window.location.pathname.split("/")[1]);
    this.setstate({
      has_footer: checkers.notInThesePaths(this.no_footer_paths_),
      current_menu: window.location.pathname.split("/")[1],
    });
  }

  componentWillUnmount() {
    this.is_mounted_ = false;
  }

  render() {
    const footer_height = "70px";

    return (
      <Layout
        ref="testref"
        style={{
          height: "100%",
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Layout.Content
          style={{
            position: "relative",
            height: `calc(100% - ${footer_height})`,
          }}
        >
          <Switch>
            <Route exact path="/" component={Transfer} />

            <Route exact path="/transfer/result" component={TransferResult} />
            <Route
              exact
              path="/transfer/selectwallet/inputqrkey"
              component={InputQRKey}
            />
            <Route
              exact
              path="/transfer/selectwallet"
              component={SelectWallet}
            />
            <Route path="/transfer" component={Transfer} />

            <Route exact path="/filling/result" component={FillingResult} />
            <Route path="/filling" component={Filling} />

            <Route exact path="/exchange/result" component={ExchangeResult} />
            <Route path="/exchange" component={Exchange} />

            <Route path="/exchange2" component={Exchange2} />
            <Route path="/history" component={History} />

            <Route exact path="/record" component={WalletRecord} />
            <Route path="/wallet" component={Wallet} />

            <Route exact path="/all/setting" component={AllSetting} />
            <Route exact path="/all/verification" component={AllVerification} />
            <Route
              exact
              path="/all/location/inputqrlocation"
              component={AllLocationQR}
            />
            <Route exact path="/all/location/map" component={AllLocationMap} />
            <Route exact path="/all/location" component={AllLocation} />
            <Route
              exact
              path="/all/exchangeinformation"
              component={AllExchangeInformation}
            />
            <Route exact path="/all/profile" component={AllProfile} />
            <Route exact path="/all/faq" component={AllFaQ} />
            <Route exact path="/all/notice" component={AllNotice} />
            <Route path="/all" component={All} />

            <Route exact path="/intro" component={this.BindedIntro} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/registration" component={Register} />
            <Route exact path="/idsearch" component={IDSearch} />
            <Route exact path="/pwsearch" component={PWSearch} />
          </Switch>
        </Layout.Content>

        {this.state.has_footer ? (
          <Layout.Footer
            style={{ height: footer_height, padding: "0", zIndex: "1" }}
          >
            <Navigation
              current_menu={this.state.current_menu}
              onMenuClick={this._hMenuClick.bind(this)}
            />
          </Layout.Footer>
        ) : null}
      </Layout>
    );
  }

  BindedIntro = () => {
    return (
      <Intro
        onClick={async (e) => {
          this.should_intro_play = false;
          await common.isLoggedIn();
          this.props.history.push("/");
        }}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "RGB(44, 80, 187)",
          zIndex: "999",
        }}
      />
    );
  };

  _hMenuClick = (e) => {
    // console.log('click ', e);
    this.setstate({
      current_menu: e.key,
    });
  };
}

export default withRouter(App);
