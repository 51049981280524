import React from "react";
import { Input, Button, Icon, Modal } from "antd";
//* view components
import Loading from "../../snippets/Loading";
//* utils
import checkers from "../../utils/checkers";
import eos_wallet from "../../utils/eos_wallet";
import server_api from "../../utils/server_api";
//* resources
import lang from "../../lang/lang";
import logo from "../../images/logos/LogoIcon@3x.png";

class IDSearch extends React.Component {
  is_mounted_ = false;
  state = {
    is_loading: false,
    email: "",
    error_message: "",
    focused_offsetTop: void 0,
  };

  constructor(props) {
    super(props);
    // this.state = {
    //   ...this.state,
    // }

    this._onResize = this._onResize.bind(this);
  }
  setstate = (partial_state) =>
    this.is_mounted_ === true && this.setState(partial_state);

  componentDidMount() {
    this.is_mounted_ = true;
    window.addEventListener("resize", this._onResize);
  }
  componentWillUnmount() {
    this.is_mounted_ = false;
    window.removeEventListener("resize", this._onResize);
  }
  _onResize() {
    if (checkers.is_mobile === false) {
      return;
    }
    if (!this.state.focused_offsetTop) {
      return;
    }

    if (this.is_mounted_)
      document.getElementById(
        "scrollbox"
      ).scrollTop = this.state.focused_offsetTop;
  }

  render() {
    return (
      <Loading
        is_loading={this.state.is_loading}
        overall={true}
        style={{ width: "100%", height: "100%", display: "table" }}
      >
        <div style={{ display: "table-row", height: "100%" }}>
          <div style={{ display: "table-cell", position: "relative" }}>
            <div
              id="scrollbox"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                overflow: "auto",
              }}
            >
              <div style={{ height: "10%" }} />
              <div
                style={{
                  width: "80%",
                  margin: "0 auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={logo}
                  alt=""
                  style={{
                    width: "2.2rem",
                    maxWidth: "99px",
                    height: "auto",
                    maxHeight: "99px",
                  }}
                />
                <span
                  style={{
                    fontSize: "2.9rem",
                    marginLeft: "0.5rem",
                    color: "rgb(130, 130, 130)",
                  }}
                >
                  GGP Wallet
                </span>
              </div>
              <div
                style={{
                  width: "80%",
                  margin: "0 auto",
                  fontSize: "1.4rem",
                  fontWeight: "700",
                }}
              >
                ID
              </div>
              <div style={{ height: "20%" }} />
              <div
                style={{ width: "70%", minWidth: "288px", margin: "0 auto" }}
              >
                <NyobInput
                  type="email"
                  labelText={lang.get("id_search/email")}
                  maxLength={255}
                  onChangeInput={(e) =>
                    this.setstate({ email: e.target.value })
                  }
                  setOffset={(v) => this.setstate({ focused_offsetTop: v })}
                  suffix={
                    <Icon
                      type="mail"
                      style={{ color: "rgba(0,0,0,.45)", fontSize: "1.5rem" }}
                    />
                  }
                />
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "0.75rem",
                    color: "rgb(125, 125, 125)",
                    textAlign: "center",
                  }}
                >
                  {lang.get("id_search/email_guide")}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "table-row" }}>
          <div style={{ display: "table-cell", padding: "0 1rem 0" }}>
            <div>
              <Button
                size="large"
                type="link"
                onClick={(e) => this._hSearchID(e)}
                style={{
                  width: "100%",
                  color: "#fff",
                  background: "rgb(64 118 212)",
                  border: "1px solid rgb(64 118 212)",
                }}
              >
                {lang.get("id_search/btn_id_search")}
              </Button>
            </div>

            <div
              style={{
                padding: "0.5rem 0.2rem 0",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Button
                  style={{ padding: "0" }}
                  type="link"
                  onClick={(e) => this.props.history.push("/login")}
                >
                  {lang.get("id_search/btn_login")}
                </Button>
              </div>
              <div>
                <Button
                  style={{ padding: "0" }}
                  type="link"
                  onClick={(e) => this.props.history.push("/registration")}
                >
                  {lang.get("id_search/btn_register")}
                </Button>
              </div>
            </div>

            <div style={{ paddingTop: "10%" }} />
          </div>
        </div>
      </Loading>
    );
  }

  async _hSearchID(e) {
    if (this.state.is_loading === true) {
      return;
    }

    if (this.state.email.trim() === "") {
      Modal.error({
        title: lang.get("id_search/modal_case3/title"),
        content: lang.get("id_search/modal_case3/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return;
    }

    const regExp_email = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    if (!regExp_email.test(this.state.email.trim())) {
      Modal.error({
        title: lang.get("id_search/modal_case4/title"),
        content: lang.get("id_search/modal_case4/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return;
    }

    this.setstate({ is_loading: true });

    try {
      await server_api.sendEmailOfLoginId({
        email: this.state.email,
      });

      Modal.success({
        title: lang.get("id_search/modal_case1/title"),
        content: lang.get("id_search/modal_case1/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setstate({ is_loading: false });
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      if (
        e &&
        e.result &&
        e.result.message &&
        e.result.message.includes("no user")
      ) {
        Modal.error({
          title: lang.get("pw_search/modal_case12/title"),
          content: lang.get("pw_search/modal_case12/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });
      } else {
        Modal.error({
          title: lang.get("id_search/modal_case2/title"),
          content: lang.get("id_search/modal_case2/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });
      }
      return this.setstate({ is_loading: false });
    }
  }
}

const NyobInput = ({
  labelText = "label",
  type = "text",
  onChangeInput = (e) => console.log("not binded"),
  maxLength = 255,
  suffix,
  setOffset = () => console.log("not binded"),
}) => {
  let style = {};
  const default_style = {
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderRadius: "0",
    boxShadow: "none",
  };
  if (suffix) style = Object.assign({}, default_style);

  return (
    <div style={{ position: "relative", marginTop: "0.5rem" }}>
      <label
        style={{
          marginLeft: "0.7rem",
          marginBottom: "0",
          color: "rgb(191, 191, 191)",
        }}
      >
        {labelText}
      </label>
      <Input
        type={type}
        style={style}
        maxLength={maxLength}
        onChange={(e) => onChangeInput(e)}
        defaultValue=""
        onFocus={(e) => {
          if (checkers.is_mobile === false) {
            return;
          }
          setOffset(eos_wallet.cumulativeOffset(e.target).top);
        }}
        onBlur={(e) => {
          if (checkers.is_mobile === false) {
            return;
          }
          setOffset(void 0);
        }}
      />
      <div style={{ position: "absolute", top: "50%", right: "2%" }}>
        {suffix}
      </div>
    </div>
  );
};

export default IDSearch;
