import { Keys } from 'numeric-keyboard/dist/numeric_keyboard.react';

export default {
  OTP :[
    [{ key: Keys.ONE },   { key: Keys.TWO },   { key: Keys.THREE } ],
    [{ key: Keys.FOUR },  { key: Keys.FIVE },  { key: Keys.SIX },  ],
    [{ key: Keys.SEVEN }, { key: Keys.EIGHT }, { key: Keys.NINE } ],
    [{ key: Keys.DEL },   { key: Keys.ZERO },  { key: Keys.ENTER },  ],
  ],
  value :[
    [{ key: Keys.ONE },   { key: Keys.TWO },   { key: Keys.THREE }, { key: Keys.DEL, rowspan: 2 } ],
    [{ key: Keys.FOUR },  { key: Keys.FIVE },  { key: Keys.SIX },  ],
    [{ key: Keys.SEVEN }, { key: Keys.EIGHT }, { key: Keys.NINE }, { key: Keys.ENTER, rowspan: 2 } ],
    [{ key: Keys.BLANK },   { key: Keys.ZERO },  { key: Keys.DOT },  ],
  ],
      
} 

// { key: Keys.ENTER, rowspan: 2}