class MultiLanguage {
  static countrycode_lang_map_ = {
    KR: "kr",
    EN: "en",
    // 'CN': 'zh',
  };

  constructor(lang = "en", default_lang = "en") {
    this.lang_ = lang; // ISO 639-1
    this.default_lang_ = default_lang; // ISO 639-1

    this.lang_path_present_map_ = {};
    this.lang_path_present_map_[default_lang] = {};
  }

  getLang() {
    return this.lang_;
  }

  setLang(lang) {
    this.lang_ = lang;
  }

  static getLangFromCountryCode(country_code) {
    return (
      MultiLanguage.countrycode_lang_map_[country_code] || this.default_lang_
    );
  }

  addLangDic(lang_dic, name_for_err) {
    try {
      for (const lang in lang_dic) {
        const dic = lang_dic[lang];

        // make path present map
        const path_present_map = {};
        this._makePathPresentMap(path_present_map, dic, [], name_for_err);

        // add
        if (!this.lang_path_present_map_[lang])
          this.lang_path_present_map_[lang] = {};
        this.lang_path_present_map_[lang] = Object.assign(
          {},
          this.lang_path_present_map_[lang],
          path_present_map
        );
      }
    } catch (e) {
      if (process.env.NODE_ENV === "development")
        console.log(`throwed from ${name_for_err}`);

      throw e;
    }
  }

  get(path, params = {}) {
    const path_present_map =
      this.lang_path_present_map_[this.lang_] ||
      this.lang_path_present_map_[this.default_lang_];

    const present = path_present_map[path];
    if (present === undefined || present === null) {
      console.log(`###not in lang err for "${path}"###`);
      return "###NOT_IN_LANG_ERR###";
    }

    if (this._isFunction(present)) return present(params);
    else return present;
  }

  _makePathPresentMap(path_present_map, dic, prefixes, name_for_err) {
    for (const key in dic) {
      const val = dic[key];
      if (typeof val === "object")
        this._makePathPresentMap(
          path_present_map,
          val,
          prefixes.concat([key]),
          name_for_err
        );
      else path_present_map[prefixes.concat([key]).join("/")] = val;
    }
  }

  _isFunction(functionToCheck) {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === "[object Function]"
    );
  }
}

export default MultiLanguage;
