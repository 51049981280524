import React from "react";
import { Button, Typography, Icon, Input, Drawer } from "antd";
//* view components
import { NumericKeyboard } from "numeric-keyboard/dist/numeric_keyboard.react";
import NumericKeyboardLayout from "../../../snippets/NumericKeyboardLayout";
import NumericInput from "../../../snippets/NumericInput";
import BankAccountDrawer from "../../../snippets/drawer/BankAccountsDrawer";
import TokenAndBalanceList from "../../../snippets/drawer_contents/TokenAndBalanceList";
//* utils
import checkers from "../../../utils/checkers";
import eos_wallet from "../../../utils/eos_wallet";
//* resources
import lang from "../../../lang/lang";
//* global variants
import config from "../../../config";

// const status_localize = {
//   KR: {
//     'START': '승인 대기',
//     'EXCHANGABLE': '거래 가능',
//     'PAY_CONFIRMED': '승인',
//     'OK': '완료',
//     'CANCELED': '취소',
//     'FAILED': '실패'
//   }
// }

class Token2MoenyTab extends React.Component {
  static defaultProps = {};

  is_mounted_ = false;
  state = {
    is_loading: false,
    // data: [],
    visible_keyboard_drawer: false,
  };
  setstate = (partial_state) =>
    this.is_mounted_ === true && this.setState(partial_state);

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });

    this.setstate({
      is_loading: false,
    });
  }

  componentWillUnmount() {
    this.is_mounted_ = false;
  }

  // async componentWillReceiveProps(nextProps) {
  //   const is_same_year  = this.props.year  === nextProps.year;
  // }
  // shouldComponentUpdate (nextProps, nextState) {
  //   return true;
  // }

  render() {
    return (
      <div>
        {/* input ***********************/}
        <div>
          <div
            style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          >
            <div style={{ flex: "1 1 0px" }}>
              <NumericInput
                className="numeric-input-borderless"
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
                size="large"
                value={eos_wallet.formatCommaNumber(
                  this.props.value_to_exchange,
                  false
                )}
                value_max={this.props.value_max}
                onChange={this.props.onChangeValueToExchange}
                onFocus={(e) => {
                  if (checkers.is_mobile === false) {
                    if (process.env.NODE_ENV === "development")
                      console.log("pc");
                    return;
                  }
                  if (process.env.NODE_ENV === "development")
                    console.log("mobile");
                  this.setstate({ visible_keyboard_drawer: true });
                }}
                maxLength={13}
                limit_decimal_point={this.props.limit_decimal_point}
                placeholder=""
                comma="true"
              />
              <Drawer
                placement="bottom"
                closable={false}
                onClose={(e) => {
                  let value_to_exchange = this.props.value_to_exchange;
                  if (value_to_exchange[value_to_exchange.length - 1] === ".")
                    value_to_exchange = value_to_exchange.substring(
                      0,
                      value_to_exchange.length - 1
                    );
                  this.props.onChangeValueToExchange(value_to_exchange);
                  this.setstate({ visible_keyboard_drawer: false });
                }}
                visible={this.state.visible_keyboard_drawer}
                height="bottom"
              >
                {/* <div style={{ paddingBottom: "0.25rem" }}>
                  <Row type="flex" justify="center" gutter={2}>
                    <Col span={5}><Button type="link" style={{ color: '#777', width: "100%", cursor: "pointer", textAlign: "center", padding: "0" }} onClick={e => this.props._hAddValue(100000)}>{lang.get('add_value/add100thousand')}</Button></Col>
                    <Col span={5}><Button type="link" style={{ color: '#777', width: "100%", cursor: "pointer", textAlign: "center", padding: "0" }} onClick={e => this.props._hAddValue(50000)}>{lang.get('add_value/add50thousand')}</Button></Col>
                    <Col span={5}><Button type="link" style={{ color: '#777', width: "100%", cursor: "pointer", textAlign: "center", padding: "0" }} onClick={e => this.props._hAddValue(10000)}>{lang.get('add_value/add10thousand')}</Button></Col>
                    <Col span={5}><Button type="link" style={{ color: '#777', width: "100%", cursor: "pointer", textAlign: "center", padding: "0" }} onClick={e => this.props.onChangeValueToExchange('0')}>{lang.get('add_value/clear')}</Button></Col>
                  </Row>
                </div> */}
                <NumericKeyboard
                  layout={NumericKeyboardLayout.value}
                  entertext={lang.get(
                    "exchange/token_to_money/btn_enter_number"
                  )}
                  onPress={(value) => {
                    if (value === "enter") {
                      this.setstate({ visible_keyboard_drawer: false });
                    }
                    this.props.onPressExchangeValue(value);
                  }}
                />
              </Drawer>
            </div>
            <div
              style={{
                position: "absolute",
                right: "0.5rem",
                // flex: "0 1 auto",
                fontSize: "1rem",
                fontWeight: "700",
                textAlign: "left",
                paddingRight: "0.5rem",
                background: "#fff",
              }}
            >
              <Typography.Text>{this.props.token_symbol}</Typography.Text>
            </div>
          </div>

          {/* {(checkers.is_mobile === false)
            ? ( <div style={{ padding: "0.5rem 0.5rem 1rem"}}>
                  <Row type="flex" justify="end">
                    <Col>
                      <div>
                        <Button onClick={e => this.props._hAddValue(100000)} style={{ marginRight: "5px" }}>{lang.get('add_value/add100thousand')}</Button>
                        <Button onClick={e => this.props._hAddValue(50000)} style={{ marginRight: "5px" }}>{lang.get('add_value/add50thousand')}</Button>
                        <Button onClick={e => this.props._hAddValue(10000)} style={{ marginRight: "5px" }}>{lang.get('add_value/add10thousand')}</Button>
                        <Button onClick={e => this.props.onChangeValueToExchange('0')}>{lang.get('add_value/clear')}</Button>
                      </div>
                    </Col>
                  </Row>
                </div>
            ) : null
          } */}
        </div>

        <div style={{ padding: "0.5rem" }}>
          {/* input exchange location */}
          <div
            style={{
              borderRadius: "0.6em",
              padding: "1.5rem 1rem",
              margin: "0.5rem auto 0",
              boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "1rem",
              fontWeight: "700",
              color: "RGB(191, 191, 191)",
            }}
          >
            <div>
              <Typography.Text>
                {lang.get("exchange/token_to_money/select_exchanger/title")}
              </Typography.Text>
            </div>
            {this.props.selected_exchanger.id && !this.props.is_loading ? (
              <div>
                <Button
                  type="link"
                  onClick={this.props.onClickRedirectToSelectExchanger}
                  style={{ padding: "0", fontSize: "1rem", fontWeight: "700" }}
                >
                  <Typography.Text>
                    {this.props.selected_exchanger.name}
                  </Typography.Text>
                  <Icon
                    type="unordered-list"
                    style={{ paddingLeft: "0.5rem", color: "rgb(2, 122, 255)" }}
                  />
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  type="link"
                  onClick={this.props.onClickRedirectToSelectExchanger}
                  style={{ padding: "0", fontSize: "1rem", fontWeight: "700" }}
                >
                  <Typography.Text>
                    {lang.get(
                      "exchange/token_to_money/select_exchanger/not_selected"
                    )}
                  </Typography.Text>
                  <Icon
                    type="unordered-list"
                    style={{ paddingLeft: "0.5rem", color: "rgb(2, 122, 255)" }}
                  />
                </Button>
              </div>
            )}
          </div>

          <div
            style={{
              borderRadius: "0.6em",
              padding: "1.5rem 1rem",
              margin: "0.5rem auto 0",
              boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
            }}
          >
            <div
              style={{
                fontSize: "1rem",
                fontWeight: "700",
                color: "RGB(191, 191, 191)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Typography.Text>
                  {lang.get("exchange/token_to_money/select_token/title")}
                </Typography.Text>
              </div>
              {this.props.token_symbol ? (
                <div>
                  <Typography.Text>{`${this.props.token_symbol} → ${this.props.currency_symbol}`}</Typography.Text>
                  <Button
                    type="link"
                    onClick={(e) =>
                      this.setstate({ visible_token_list_drawer: true })
                    }
                    style={{ padding: "0" }}
                  >
                    <Icon
                      type="unordered-list"
                      style={{
                        paddingLeft: "0.5rem",
                        color: "rgb(2, 122, 255)",
                      }}
                    />
                  </Button>
                </div>
              ) : (
                <div>
                  {lang.get(
                    "exchange/token_to_money/select_token/not_selected"
                  )}
                  <Button
                    type="link"
                    onClick={(e) =>
                      this.setstate({ visible_token_list_drawer: true })
                    }
                    style={{ padding: "0" }}
                  >
                    <Icon
                      type="unordered-list"
                      style={{
                        paddingLeft: "0.5rem",
                        color: "rgb(2, 122, 255)",
                      }}
                    />
                  </Button>
                </div>
              )}
            </div>
            <Drawer
              title={
                <div style={{ fontWeight: "700" }}>
                  <Typography.Text>
                    {lang.get("drawer/token_and_balance_list/title")}
                  </Typography.Text>
                </div>
              }
              placement="bottom"
              closable={true}
              onClose={(e) =>
                this.setstate({ visible_token_list_drawer: false })
              }
              visible={this.state.visible_token_list_drawer}
              height="bottom"
              bodyStyle={{ paddingTop: "0" }}
            >
              <div
                style={{
                  height: "100%",
                  maxWidth: "480px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <div
                  style={{ position: "relative", height: "calc(50vh - 5rem)" }}
                >
                  <div
                    style={{
                      top: "0",
                      bottom: "0",
                      left: "0",
                      right: "0",
                      position: "absolute",
                      overflow: "auto",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <TokenAndBalanceList
                      data={this.props.user_accounts}
                      except_tokens={[config.major_token_symbol]}
                      onClickCurrency={(value) => {
                        this.setstate({ visible_token_list_drawer: false });
                        this.props.onClickToken(value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Drawer>

            <div
              style={{
                color: "RGB(191, 191, 191)",
                marginTop: "0.5rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: "1rem", fontWeight: "700" }}>
                <Typography.Text>
                  {lang.get(
                    "exchange/token_to_money/select_exchange_type/title"
                  )}
                </Typography.Text>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "0.8rem",
                }}
              >
                <span
                  style={
                    this.props.exchange_type === "BY_OFFLINE_EXCHANGER"
                      ? {
                          display: "inline-block",
                          verticalAlign: "middle",
                          cursor: "pointer",
                          userSelect: "none",
                          padding: "0.2rem 0.4rem",
                          borderRadius: "4px",
                          color: "#fff",
                          background: "rgb(51, 129, 252)",
                        }
                      : {
                          display: "inline-block",
                          verticalAlign: "middle",
                          cursor: "pointer",
                          userSelect: "none",
                          padding: "0.2rem 0.4rem",
                          borderRadius: "4px",
                          color: "rgb(85,89,99)",
                          background: "rgb(240,240,240)",
                        }
                  }
                  onClick={(e) =>
                    this.props.onClickExchangeType("BY_OFFLINE_EXCHANGER")
                  }
                >
                  {lang.get(
                    "exchange/token_to_money/select_exchange_type/by_offline"
                  )}
                </span>
                <span style={{ width: "0.5rem" }} />
                <span
                  style={
                    this.props.exchange_type === "BY_BANK_ACCOUNT"
                      ? {
                          display: "inline-block",
                          verticalAlign: "middle",
                          cursor: "pointer",
                          userSelect: "none",
                          padding: "0.2rem 0.4rem",
                          borderRadius: "4px",
                          color: "#fff",
                          background: "rgb(51, 129, 252)",
                        }
                      : {
                          display: "inline-block",
                          verticalAlign: "middle",
                          cursor: "pointer",
                          userSelect: "none",
                          padding: "0.2rem 0.4rem",
                          borderRadius: "4px",
                          color: "rgb(85,89,99)",
                          background: "rgb(240,240,240)",
                        }
                  }
                  onClick={(e) =>
                    this.props.onClickExchangeType("BY_BANK_ACCOUNT")
                  }
                >
                  {lang.get(
                    "exchange/token_to_money/select_exchange_type/by_bank_account"
                  )}
                </span>
              </div>
            </div>
          </div>

          {/* select bank_account ***********************/}
          {this.props.exchange_type !== "BY_BANK_ACCOUNT" ? null : (
            <div
              style={{
                borderRadius: "0.6em",
                padding: "1.5rem 1rem",
                margin: "0.5rem auto 0",
                boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <div
                    style={{ fontSize: "0.75rem", color: "rgb(117,117,117)" }}
                  >
                    {lang.get(
                      "exchange/token_to_money/select_bank_account/title"
                    )}
                  </div>
                  {this.props.bank_account.bank_account ? (
                    <div
                      style={{
                        fontWeight: "700",
                        marginTop: "0.2rem",
                        fontSize: "1rem",
                      }}
                    >
                      {this.props.bank_account.bank_account}
                    </div>
                  ) : (
                    <div
                      style={{
                        fontWeight: "700",
                        marginTop: "0.2rem",
                        fontSize: "1rem",
                      }}
                    >
                      {lang.get(
                        "exchange/token_to_money/select_bank_account/not_selected"
                      )}
                    </div>
                  )}
                </div>
                <div style={{ fontWeight: "700" }}>
                  {this.props.bank_account.bank_name}
                  <Button
                    type="link"
                    onClick={(e) =>
                      this.setstate({ visible_bank_account_list_drawer: true })
                    }
                    style={{ padding: "0" }}
                  >
                    <Icon
                      type="unordered-list"
                      style={{
                        paddingLeft: "0.5rem",
                        color: "rgb(2, 122, 255)",
                      }}
                    />
                  </Button>
                </div>
              </div>
              <BankAccountDrawer
                visible={this.state.visible_bank_account_list_drawer}
                onClose={(e) =>
                  this.setstate({ visible_bank_account_list_drawer: false })
                }
                onClickAccount={(value) => {
                  this.props.onClickAccount(value);
                  this.setstate({ visible_bank_account_list_drawer: false });
                }}
              />
            </div>
          )}

          <div
            style={{
              borderRadius: "0.6em",
              padding: "1.5rem 1rem",
              margin: "0.5rem auto 0",
              boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
            }}
          >
            <div>
              <Icon type="profile" />
              <span
                style={{ paddingLeft: "0.5rem", color: "rgb(135, 135, 135)" }}
              >
                {lang.get("exchange/token_to_money/input_memo/title")}
              </span>
            </div>
            <div style={{ marginTop: "0.4rem" }}>
              <Input.TextArea
                maxLength="255"
                autosize={{ minRows: 1, maxRows: 4 }}
                value={this.props.log}
                onChange={this.props.onChangeMemo}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>

          <div style={{ padding: "0.5rem 1rem", margin: "0.5rem auto 0" }}>
            {lang.get("exchange/token_to_money/commission_info_text", {
              commission_rate: this.props.commission_rate,
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Token2MoenyTab;
