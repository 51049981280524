import React from 'react';

import { List, message, Avatar, Button } from 'antd';
import reqwest from 'reqwest';

// import InfiniteScroll from 'react-infinite-scroller';
import img_exchange_location from '../../../../../images/exchange_location.png';
import '../../../../../styles/infinity_lists.less';
//* global variants
import config from '../../../../../config';


const fakeDataUrl = 'https://randomuser.me/api/?results=5&inc=name,gender,email,nat&noinfo';



class LocationList extends React.Component {
  static defaultProps = {
    data: [],
    current_location: void 0,
    onClickLocation: e => {}
  }

  is_mounted_ = false;
  state = {    
    raw_data: [],
    data: [],
    loading: false,
    hasMore: true,
  };
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));
  
  componentDidMount() {
    this.is_mounted_ = true;

    const raw_data = this.props.data;
    const current_location = this.props.current_location;
    const data = this._filterNear(raw_data, current_location);


    this.setstate({      
      data
    });

    // this.fetchData(res => {
    //   this.setstate({
    //     // data: res.results,
    //     data: raw_data,
    //   });
    // });
  }
  componentWillUnmount() { this.is_mounted_ = false; }

  componentWillReceiveProps(nextProps) {
    const raw_data = nextProps.data;
    const current_location = nextProps.current_location;
    const data = this._filterNear(raw_data, current_location);

    this.setstate({
      data
    });  
  }

  fetchData = callback => {
    reqwest({
      url: fakeDataUrl,
      type: 'json',
      method: 'get',
      contentType: 'application/json',
      success: res => {
        callback(res);
      },
    });
  };

  handleInfiniteOnLoad = () => {
    let { data } = this.state;
    this.setstate({
      loading: true,
    });
    if (data.length > 5) { //infinite loading 사용시, 최대치를 알아야 할 듯
      message.warning('List loaded all');
      this.setstate({
        hasMore: false,
        loading: false,
      });
      return;
    }
    this.fetchData(res => {
      data = data.concat(res.results);
      this.setstate({
        data,
        loading: false,
      });
    });
  };

  render() {
    return (
      // <InfiniteScroll
      //   initialLoad={false}
      //   pageStart={0}
      //   loadMore={this.handleInfiniteOnLoad}
      //   hasMore={!this.state.loading && this.state.hasMore}
      //   useWindow={false}
      // >
        <List
          dataSource={this.state.data}
          renderItem={location => (
            <List.Item key={location.id} style={{ padding: "0.5rem 0", border: "0" }}>
              <List.Item.Meta
                avatar={
                  <Avatar shape="square" src={img_exchange_location} />
                }
                title={<div>
                    <Button type="link" onClick={this.onClickLocation.bind(this)} data-location_id={location.id} style={{ color: "RGB(90, 90, 90)"}}>
                      {location.name}
                    </Button>
                  </div>}
                // description={item.email}
              />
            </List.Item>
          )}
        >
          {/* {this.state.loading && this.state.hasMore && (
            <div className="infinity-loading-container">
              <Spin />
            </div>
          )} */}
        </List>
      // </InfiniteScroll>
    );
  }

  onClickLocation (e) {
    const selected_location = (this.state.data).filter(location => String(location.id) === e.target.dataset.location_id);

    this.props.onClickLocation(selected_location[0]);
  }

  _filterNear (raw_data, current_location) {
    if (!current_location) {
      return raw_data
    }

    const data = raw_data.filter(datum => {
      const diff_x = Math.pow(Number(datum.longitude)-Number(current_location.lng), 2);
      const diff_y = Math.pow(Number(datum.latitude)-Number(current_location.lat), 2);
      
      return diff_x + diff_y < config.near_distance;
    });

    return data;
  }

}

export default LocationList;