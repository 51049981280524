import React from 'react';
import { Button, Typography, Icon, Row, Col, Drawer } from 'antd';
import moment from 'moment';
//* view components
import NyobKeypad from '../../../snippets/NyobKeypad';
import Loading from '../../../snippets/Loading';
import CurrencyList from '../../../snippets/drawer_contents/CurrencyList';
//* utils
import cache      from '../../../utils/cache'     ;
import common     from '../../../utils/common'    ;
import s3         from '../../../utils/s3'        ;
import server_api from '../../../utils/server_api';
import eos_wallet from '../../../utils/eos_wallet';
//* resources
import lang from '../../../lang/lang';
import img_no_country from '../../../images/no_country.png'





const keypad_layout_number = [
  [{ value: '1', content: '1' }, { value: '2', content: '2' }, { value: '3', content: '3' },],
  [{ value: '4', content: '4' }, { value: '5', content: '5' }, { value: '6', content: '6' },],
  [{ value: '7', content: '7' }, { value: '8', content: '8' }, { value: '9', content: '9' },],
  [{ value: '.', content: '.' }, { value: '0', content: '0'}, { value: 'del', content: (<Icon type="left" style={{ pointerEvents: 'none'}} />)}],
]




class ExchangeInformation extends React.Component {
  is_mounted_ = false;
  state = {
    is_loading: false,
    header_text: '',
    value_from: '0',
    value_to: '0',
    value_from_max: '999999999999',
    value_to_max  : '999999999999',
    limit_decimal_point_from: 0,
    limit_decimal_point_to: 0,
    currency_from: ``,
    currency_from_img: img_no_country,
    currency_to: ``,
    currency_to_img: img_no_country,
    rate: 0,
    date: '',
    visible_currency_from_list_drawer: false,
    visible_currency_to_list_drawer: false,
    visible_from_keyboard_drawer: false,
    selected_keyboard: '', // 'from' or 'to
    error_msg: ''
  }

  constructor(props) { super(props);
    // console.log(this.props.location.state);
    // if (!this.props.location.state)
    //   return this.props.history.push('/');

    // const { value_transferred } = this.props.location.state;    
    this.state = {
      ...this.state,
    }
  }
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    if (await common.isLoggedIn() === false) {
      return;
    }

    const data_codes = await this._cacheCodes();
    const { rate, date } = await this._calculateRates(data_codes[0].currency_symbol, data_codes[1].currency_symbol)
   
    const currency_from = data_codes[0].currency_symbol,
          currency_to   = data_codes[1].currency_symbol,
          limit_decimal_point_from = data_codes[0].limit_decimal_point,
          limit_decimal_point_to   = data_codes[1].limit_decimal_point;

    this.setstate({
      is_loading: false,
      header_text: await eos_wallet.getHeaderTokenText() || '',
      data_codes,
      currency_from,
      currency_to,
      limit_decimal_point_from,
      limit_decimal_point_to,
      currency_from_img: `${s3.server_address}/${s3.flag_image_path}/${data_codes[0].country_code}.png`,
      currency_to_img  : `${s3.server_address}/${s3.flag_image_path}/${data_codes[1].country_code}.png`,
      rate,
      date
    });
  }
  componentWillUnmount() { this.is_mounted_ = false; }

  render () {
    if (common.get().token === void 0) {
      return null;
    }

    return (
      <Loading is_loading={this.state.is_loading} overall={true} style={{ display: "table", tableLayout: "fixed", width: "100%", height: "100%" }}>

        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>
            {/** HEADER *****/}
            <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  onClick={e => this.props.history.push('/wallet')}
                  style={{
                      flex: "1 1 0px", borderRadius: "4px", padding: "0.2rem 1rem"
                    , display: "block", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"
                    , fontSize: "1rem", color: "#fff", background: "rgb(51, 129, 252)", cursor: 'pointer'
                  }} >
                  {this.state.header_text}&nbsp;
              </div>
              <div style={{ flex: "0 0 auto", padding: "0 0 0 0.5rem" }}>
                  <Button type="link" style={{ padding: "0", height: "2.5rem" }} onClick={e => this.props.history.push('/all')}>
                    <Icon type="close" style={{ fontSize: "1.6rem", color: "RGB(75, 75, 75)" }} />
                  </Button>
                </div>
              </div>
              <Typography.Title level={3} style={{ display: 'flex', alignItems: 'center', marginBottom: "0", marginTop: "1.5rem" }}>
                <Typography.Text>{lang.get('all/exchange_infomation/title')}</Typography.Text>
              </Typography.Title>
            </div>
            {/***** HEADER END **/}

          </div>
        </div>

        <div style={{ display: "table-row", height: "4%" }}></div>

        <div style={{ display: "table-row", height: "0" }}> {/* content */}
          <div style={{ display: "table-cell" }}>
            <div style={{ position: "relative" }}>
              <div style={{ padding: "0.5rem" }}>
                <div style={{ borderRadius: "0.6em", padding: "0.5rem 1rem", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)", fontSize: "1.1rem" }}>
                  <Row type="flex" justify="space-between" align="middle">
                    <Col span={8} style={{ cursor: "pointer" }} onClick={e => this.setstate({ visible_currency_from_list_drawer: true })}>
                      <div style={{ display: "flex", alignItems:"center", pointerEvents: "none"}}>
                        <div style={{ width: "50%" }}><img alt="" src={this.state.currency_from_img} onError={e => this.setstate({ currency_from_img: img_no_country })} style={{ width: "100%" }} /></div>
                        <div style={{ fontWeight: "700", paddingLeft: "0.3rem"}}>{this.state.currency_from}</div>
                        <div><Icon type="unordered-list" style={{ paddingLeft: "0.5rem", color: "rgb(2, 122, 255)" }} /></div>
                      </div>
                    </Col>
                    <Col span={16} style={{ fontWeight: "700", verticalAlign: "middle", textAlign: "right", paddingRight: "11px" }}>
                      {eos_wallet.formatCommaNumber(this.state.value_from, false)}
                    </Col>
                  </Row>

                  <div style={{ textAlign: "center", padding: "0.5rem" }}><Icon type="swap" style={{ fontSize: "0.8rem" }} /></div>

                  <Row type="flex" justify="space-between" align="middle">
                    <Col span={8} style={{ cursor: "pointer" }} onClick={e => this.setstate({ visible_currency_to_list_drawer: true })}>
                      <div style={{ display: "flex", alignItems:"center"}}>
                        <div style={{ width: "50%" }}><img alt="" src={this.state.currency_to_img} style={{ width: "100%" }} /></div>
                        <div style={{ fontWeight: "700", paddingLeft: "0.3rem"}}>{this.state.currency_to}</div>
                        <div><Icon type="unordered-list" style={{ paddingLeft: "0.5rem", color: "rgb(2, 122, 255)" }} /></div>
                      </div>
                    </Col>
                    <Col span={16}>
                      <div style={{ fontWeight: "700", verticalAlign: "middle", textAlign: "right", paddingRight: "11px" }}>
                        {eos_wallet.formatCommaNumber(this.state.value_to, false)}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              {(this.state.date)
                ? ( <div style={{ borderRadius: "4px", marginTop: "0.5rem", textAlign: "center", fontSize: "0.8rem", color: "rgb(150, 150, 150)"}}>
                      {lang.get('all/exchange_infomation/time_info', { date: moment(this.state.date).format('YYYY.MM.DD HH:mm:ss') })}
                    </div>
                  ) : null
              }
              



              {/* drawers */}
              <div style={{ marginTop: '1rem'}}>
                <Drawer
                  title={<div style={{ fontWeight: "700" }}><Typography.Text>{lang.get('drawer/currency_list/title')}</Typography.Text></div>}
                  placement="bottom"
                  closable={true}
                  onClose={e => this.setstate({
                    visible_currency_from_list_drawer: false,
                    error_msg: ''
                  })}
                  visible={this.state.visible_currency_from_list_drawer}
                  height="bottom"
                >      
                  <div style={{ height: "100%"}}>          
                    <div style={{ position: "relative", height: "calc(50vh - 5rem)"}}>
                      <div style={{ top: "0", bottom: "0", left: "0", right: "0", position: "absolute", overflow: "auto"}}>                        
                        <CurrencyList data={this.state.data_codes} onClickCurrency={async value => {
                          const selected_code = (this.state.data_codes).filter(data_code => data_code.currency_symbol === value)[0];
                          const country_code = selected_code.country_code;
                          const limit_decimal_point_from = selected_code.limit_decimal_point;
                          const { rate, date } = await this._calculateRates(value, this.state.currency_to);
                          
                          this.setstate({
                            is_loading: false,
                            value_from: '0',
                            value_to: '0',
                            currency_from: value,
                            limit_decimal_point_from,
                            currency_from_img: `${s3.server_address}/${s3.flag_image_path}/${country_code}.png`,
                            rate,
                            date,
                            visible_currency_from_list_drawer: false });
                        }} />
                      </div>
                    </div>
                  </div>
                </Drawer>
                <Drawer
                  title={<div style={{ fontWeight: "700" }}><Typography.Text>{lang.get('drawer/currency_list/title')}</Typography.Text></div>}
                  placement="bottom"
                  closable={true}
                  onClose={e => this.setstate({
                    visible_currency_to_list_drawer: false,
                    error_msg: ''
                  })}
                  visible={this.state.visible_currency_to_list_drawer}
                  height="bottom"
                >      
                  <div style={{ height: "100%"}}>          
                    <div style={{ position: "relative", height: "calc(50vh - 5rem)"}}>
                      <div style={{ top: "0", bottom: "0", left: "0", right: "0", position: "absolute", overflow: "auto"}}>                        
                        <CurrencyList data={this.state.data_codes} onClickCurrency={async value => {
                          const selected_code = (this.state.data_codes).filter(data_code => data_code.currency_symbol === value)[0];
                          const country_code = selected_code.country_code;
                          const limit_decimal_point_to = selected_code.limit_decimal_point;
                          const { rate, date } = await this._calculateRates(this.state.currency_from, value);
                          this.setstate({
                            is_loading: false,
                            value_to: this._calculateResult(this.state.value_from, rate, limit_decimal_point_to),
                            currency_to: value,
                            limit_decimal_point_to,
                            currency_to_img: `${s3.server_address}/${s3.flag_image_path}/${country_code}.png`,
                            rate,
                            date,
                            visible_currency_to_list_drawer: false });
                        }} />
                      </div>
                    </div>
                  </div>
                </Drawer>
              </div>
            </div>
          </div>
        </div>


        <div style={{ display: "table-row", height: "100%" }}>
          <div style={{ display: "table-cell" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div style={{ position: "absolute", top: "0", bottom: "0", left: "0", right: "0" }}>
                <NyobKeypad
                  data={keypad_layout_number}
                  tableStyle={{ width: "100%", minWidth: "288px", height: "100%", padding: "0 1rem", margin: "0 auto" }}
                  // tbodyStyle={{}}
                  // trStyle={{}}
                  tdStyle={{ fontSize: "1.5rem", fontWeight: "700", borderRadius: "8px" }}
                  onClickKey={this._hNyobKey.bind(this)}/>
              </div>
            </div>
          </div>
       </div>
    </Loading>)
  }        
  

  // _hNumericKeyboard (key) {
  //   let { value_from } = this.state;

  //   if (!value_from)
  //     value_from = '';

  //   switch (key) {
  //     case 'clear':
  //       return this.setstate({
  //         value_from: '0',
  //         value_to: '0',
  //       });
  //     case 'del':
  //       const value_sliced = value_from.slice(0, -1);
  //       return this.setstate({
  //         value_from: value_sliced,
  //         value_to: this._calculateResult(value_sliced, this.state.rate, this.state.limit_decimal_point)
  //       });
  //     default:
  //       if (value_from === '0') {
  //         value_from = '';
  //       }
  //       const new_value = value_from.concat(key);
  //       const value_to = this._calculateResult(new_value, this.state.rate, this.state.limit_decimal_point);
  //       if (Number(new_value) < Number(this.state.value_from_max) && Number(value_to) < Number(this.state.value_to_max)) {
  //         return this.setstate({
  //           value_from: new_value,
  //           value_to: value_to,
  //         });
  //       }
  //   }
  // } 




  _hNyobKey (key) {
    let { value_from } = this.state;

    if (!value_from)
      value_from = '';

    switch (key) {
      case '.':
        if (this.state.limit_decimal_point_from === 0) {
          return;
        }
        if (value_from === '') {
          return this.setstate({
            error_header_text: '',
            value_from: '0.'
          });
        }

        for (let i = 0; i < value_from.length; ++i) {
          if (value_from[i] === '.')
            return;
        }

        return this.setstate({
          error_header_text: '',
          value_from: value_from.concat(key)
        });
      case 'del':
        let sliced_value = value_from.slice(0, -1);
        if (sliced_value === '') {
          sliced_value = '0';
        }
        return this.setstate({
          error_header_text: '',
          value_from: sliced_value,
          value_to: this._calculateResult(sliced_value, this.state.rate) || '0'
        });
      default:
        if (value_from === '0')
          value_from = '';
        const new_value_from = value_from.concat(key);
        for (let i = new_value_from.length - 1, count = 0; i >= 0; --i) {
          ++count;

          if (new_value_from[i] === '.') {
            if (count - 1 > this.state.limit_decimal_point_from)
              return
          }

          if (count - 1 > this.state.limit_decimal_point_from) {
            break;
          }
        }

        const value_to = this._calculateResult(new_value_from, this.state.rate, this.state.limit_decimal_point_to);
        if (Number(new_value_from) < Number(this.state.value_from_max) && Number(value_to) < Number(this.state.value_to_max))
          return this.setstate({
            value_from: new_value_from,
            value_to: value_to,
          });
        break;
    }
  }


  async _calculateRates (currency_from, currency_to) {
    this.setstate({ is_loading: true });

    try {
      const res_rate = await server_api.readExchangeRates({
        base: currency_from,
        symbols: JSON.stringify([currency_to]),
      });

      return {
        rate: (res_rate.rates || {})[currency_to] || 1,
        date: res_rate.date || '',
      }
    } 
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      return {
        is_loading: false,
        rate: 1,
        date: ''
      }
    }
  }


  _calculateResult (value_from, rate, limit_decimal_point) {
    if (value_from === '0' || value_from === '') {
      return '0';
    }

    if (rate === 0 || rate === void 0) {
      return '0';
    }

    const converted_value = Number(value_from) * rate;
    const rounded_value = converted_value.toFixed(limit_decimal_point);

    return String(rounded_value);
  }


  async _cacheCodes () {
    try {
      const res_codes_items = (await cache.codes.get()).items;
    
      const data_codes = res_codes_items.filter(datum => datum.currency_symbol);

      return data_codes;
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        return console.error(e);
      }
      return [];
    }
  }
}

export default ExchangeInformation;