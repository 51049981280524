module.exports = {
  'kr': {
    'title': 'HIIK 페이',

    'bottom_bar': {
      'transfer': '이체',
      'filling': '충전',
      'exchange': '환전',
      'wallet': '지갑',
      'all': '전체',
    },




    'modal': {
      'ok_text': '네',
      'cancel_text': '아니오',
      'confirm_text': '확인',
    },




    'drawer/record_redirecter': {
      'title': '내역 조회하기',
      'transfer_list': '이체 내역 조회',
      'filling_list' : '충전 내역 조회',
      'exchange_list': '환전 내역 조회',
    },

    'drawer/token_and_balance_list': {
      'title': '토큰 선택',
    },

    'drawer/currency_list': {
      'title': '화폐 선택',
    },

    'drawer/token_list': {
      'title': '토큰 선택',
    },

    'drawer/input_otp': {
      'title': 'OTP 입력',
    },

    'drawer/bank_accounts': {
      'title': '계좌 선택',
      'btn_add_account': '+ 계좌 추가',
      'btn_delete_account': '삭제',
      'pop_delete_account': {
        'content': '계좌를 삭제하시겠습니까?',
        'ok_text': '네',
        'cancel_text': '아니오'
      },

      'drawer/add_account': {
        'title': '계좌 추가',
        'select_country_title': '국가 :',
        'select_country_option_all': '전체',
        'select_bank_title': '은행',
        'input_account_placeholder': '계좌번호',
        'input_owner_placeholder': '예금주',
        'input_alias_placeholder': '별명',
        'btn_add': '추가',
      },
      'no_bank_list_message': '해당 은행 목록이 없습니다',
      'modal_case1' : {
        'title': '계좌 추가 실패',
        'content': '은행을 선택해주세요.',
      },
      'modal_case2' : {
        'title': '계좌 추가 실패',
        'content': '계좌번호를 입력해주세요.',
      },
      'modal_case3' : {
        'title': '계좌 추가 실패',
        'content': '정상적인 계좌번호를 입력해주세요.',
      },
      'modal_case4' : {
        'title': '계좌 추가 실패',
        'content': '예금주를 입력해주세요.',
      },
      'modal_case5' : {
        'title': '계좌 추가 실패',
        'content': '별명을 입력해주세요.',
      },
      'modal_case6' : {
        'title': '계좌 추가 실패',
        'content': '서버 통신 에러. 관리자에게 문의해주세요.',
      },
      'modal_case7' : {
        'title': '계좌 삭제 실패',
        'content': '통신에러. 잠시 후 다시 시도해주세요.',
      },
    },


    'add_value' : {
      'add100thousand' : '+10만',
      'add50thousand'  : '+5만',
      'add10thousand'  : '+1만',
      'clear': '초기화',
    },
  },
};