import React from "react";

class TermsKR extends React.Component {
  render() {
    return (
      <div style={{ fontSize: "0.75rem" }}>
        <p className="c4">
          <span className="c11">
            GGP WALLET LTD. &#51060;&#50857;&#50557;&#44288;
          </span>
        </p>
        <p className="c0" id="h.gjdgxs">
          <span className="c2"></span>
        </p>
        <p className="c4">
          <span className="c1">&#51228;1&#51109; &#52509;&#52825;</span>
        </p>
        <p className="c4">
          <span className="c8">&#51228;1&#51312; (&#47785;&#51201;)</span>
        </p>
        <p className="c4">
          <span className="c8">
            &#51060; &#50557;&#44288;&#51008; GGP WALLET LTD.&#44032;
            &#51228;&#44277;&#54616;&#45716; &#44288;&#47144; &#51228;&#48152;
            &#49436;&#48708;&#49828; (&#51060;&#54616;
            &quot;&#49436;&#48708;&#49828;&quot;)&#51032;
            &#51060;&#50857;&#51312;&#44148; &#48143; &#51208;&#52264;&#50640;
            &#44288;&#54620; &#54924;&#49324;&#50752; &#54924;&#50896;
            &#44036;&#51032; &#44428;&#47532; &#51032;&#47924; &#48143;
            &#52293;&#51076;&#49324;&#54637;, &#44592;&#53440;
            &#54596;&#50836;&#54620; &#49324;&#54637;&#51012;
            &#44508;&#51221;&#54632;&#51012; &#47785;&#51201;&#51004;&#47196;
            &#54620;&#45796;.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c4">
          <span className="c8">
            &#51228;2&#51312; (&#50557;&#44288;&#51032; &#47749;&#49884;,
            &#49444;&#47749;&#44284; &#44060;&#51221;)
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            1) &#51060; &#50557;&#44288;&#51032; &#45236;&#50857;&#51008;
            &#54924;&#49324;&#51032; &#49436;&#48708;&#49828;
            &#54924;&#50896;&#44032;&#51077; &#44288;&#47144;
            &#49324;&#51060;&#53944;&#50640;
            &#44172;&#49884;&#54616;&#44144;&#45208; &#44592;&#53440;&#51032;
            &#48169;&#48277;&#51004;&#47196;
            &#49324;&#50857;&#51088;&#50640;&#44172;
            &#44277;&#51648;&#54616;&#44256;, &#51060;&#50857;&#51088;&#44032;
            &#54924;&#50896;&#51004;&#47196;
            &#44032;&#51077;&#54616;&#47732;&#49436; &#51060;
            &#50557;&#44288;&#50640;
            &#46041;&#51032;&#54632;&#51004;&#47196;&#50024;
            &#54952;&#47141;&#51060; &#48156;&#49373;&#54620;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            2) &#54924;&#49324;&#44032; &#50557;&#44288;&#51012;
            &#44060;&#51221;&#54624; &#44221;&#50864;&#50640;&#45716;
            &#51201;&#50857;&#51068;&#51088; &#48143;
            &#44060;&#51221;&#49324;&#50976;&#47484;
            &#47749;&#49884;&#54616;&#50668;
            &#54788;&#54665;&#50557;&#44288;&#44284; &#54632;&#44760;
            &#54924;&#49324; &#49324;&#51060;&#53944;&#51032;
            &#52488;&#44592;&#54868;&#47732;&#51060;&#45208;
            &#44277;&#51648;&#49324;&#54637;&#50640; &#44536;
            &#51201;&#50857;&#51068;&#51088; 7&#51068;
            &#51060;&#51204;&#48512;&#53552; &#51201;&#50857;&#51068;&#51088;
            &#51204;&#51068;&#44620;&#51648; &#44277;&#51648;&#54620;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            3) &#54924;&#49324;&#44032; &#51204;&#54637;&#50640;
            &#46384;&#46972; &#44060;&#51221;&#50557;&#44288;&#51012;
            &#44277;&#51648; &#53685;&#51648;&#54616;&#47732;&#49436;
            &#54924;&#50896;&#50640;&#44172; 7&#51068;&#44036;&#51032;
            &#44592;&#44036; &#45236;&#50640;
            &#51032;&#49324;&#54364;&#49884;&#47484; &#54616;&#51648;
            &#50506;&#51004;&#47732; &#51032;&#49324;&#54364;&#49884;&#44032;
            &#54364;&#47749;&#46108; &#44163;&#51004;&#47196;
            &#48376;&#45796;&#45716; &#46907;&#51012;
            &#47749;&#54869;&#54616;&#44172; &#44256;&#51648; &#46608;&#45716;
            &#53685;&#51648;&#54616;&#50688;&#51020;&#50640;&#46020;
            &#54924;&#50896;&#51060; &#47749;&#54869;&#54616;&#44172;
            &#44144;&#48512;&#51032; &#51032;&#49324;&#54364;&#49884;&#47484;
            &#54616;&#51648; &#50500;&#45768;&#54620; &#44221;&#50864;
            &#54924;&#50896;&#51060; &#44060;&#51221;&#50557;&#44288;&#50640;
            &#46041;&#51032;&#54620; &#44163;&#51004;&#47196; &#48376;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            4) &#54924;&#50896;&#51060; &#44060;&#51221;&#50557;&#44288;&#51032;
            &#51201;&#50857;&#50640; &#46041;&#51032;&#54616;&#51648;
            &#50506;&#45716;&#45796;&#45716; &#51032;&#49324;&#47484;
            &#54364;&#47749;&#54620; &#44221;&#50864; &#54924;&#49324;&#45716;
            &#44060;&#51221; &#50557;&#44288;&#51032; &#45236;&#50857;&#51012;
            &#51201;&#50857;&#54624; &#49688; &#50630;&#51004;&#47728;, &#51060;
            &#44221;&#50864; &#54924;&#50896;&#51008;
            &#51060;&#50857;&#44228;&#50557;&#51012; &#54644;&#51648;&#54624;
            &#49688; &#51080;&#45796;. &#45796;&#47564;, &#44592;&#51316;
            &#50557;&#44288;&#51012; &#51201;&#50857;&#54624; &#49688;
            &#50630;&#45716; &#53945;&#48324;&#54620; &#49324;&#51221;&#51060;
            &#51080;&#45716; &#44221;&#50864;&#50640; &#54924;&#49324;&#45716;
            &#51060;&#50857;&#44228;&#50557;&#51012; &#54644;&#51648;&#54624;
            &#49688; &#51080;&#45796;.
          </span>
        </p>
        <p className="c0">
          <span className="c5"></span>
        </p>
        <p className="c4">
          <span className="c8">
            &#51228;3&#51312; (&#50857;&#50612;&#51032; &#51221;&#51032;)
          </span>
        </p>
        <p className="c4">
          <span className="c8">
            1) &#51060; &#50557;&#44288;&#50640;&#49436;
            &#49324;&#50857;&#54616;&#45716; &#50857;&#50612;&#51032;
            &#51221;&#51032;&#45716; &#45796;&#51020;&#44284; &#44057;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            &#9312; &#54924;&#50896;: &#51060; &#50557;&#44288;&#51012;
            &#49849;&#51064;&#54616;&#44256;
            &#54924;&#50896;&#44032;&#51077;&#51012; &#54616;&#50668;
            &#54924;&#49324;&#50752;
            &#49436;&#48708;&#49828;&#51060;&#50857;&#44228;&#50557;&#51012;
            &#52404;&#44208;&#54620; &#51088;&#47484; &#46907;&#54620;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            &#9313; ID: &#54924;&#50896;&#51012; &#44396;&#48516;
            &#54869;&#51064;&#54616;&#44592; &#50948;&#54644;
            &#54924;&#50896;&#51060; &#47928;&#51088;&#50752;
            &#49707;&#51088;&#51032; &#51312;&#54633;&#51004;&#47196;
            &#49444;&#51221;&#54616;&#44256; &#54924;&#49324;&#44032;
            &#49849;&#51064;&#54620;&#45796;.
          </span>
        </p>
        <p className="c4 c10">
          <span className="c8">
            &#9314; &#54856;&#54168;&#51060;&#51648;: &#54924;&#50896;&#51060;
            &#54924;&#49324;&#51032; &#49436;&#48708;&#49828;&#47484;
            &#51060;&#50857;&#54616;&#44172; &#54616;&#44592;
            &#50948;&#54616;&#50668; &#54924;&#49324;&#44032;
            &#51228;&#44277;&#54616;&#45716;
            &#50937;&#49324;&#51060;&#53944;&#47484; &#47568;&#54620;&#45796;.
          </span>
        </p>
        <p className="c4 c10">
          <span className="c6">
            &#9315; &#48708;&#48128;&#48264;&#54840;: &#54924;&#50896;&#51032;
            &#46041;&#51068;&#49457; &#54869;&#51064;&#44284;
            &#54924;&#50896;&#51221;&#48372;&#51032; &#48372;&#54840;&#47484;
            &#50948;&#54616;&#50668; &#54924;&#50896;&#51060;
            &#54924;&#49324;&#44032; &#49849;&#51064;&#54620;
            &#47928;&#51088;&#50752; &#49707;&#51088;&#51032;
            &#51312;&#54633;&#45824;&#47196; &#49444;&#51221;&#54620;
            &#44163;&#51012; &#47568;&#54620;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c6">
            &#9316; &#51648;&#44049;: HIM &#53076;&#51064;&#51012;
            &#48372;&#44288;&#54624; &#49688; &#51080;&#45716; &#54635;
            &#51648;&#44049; &#51593; &#51648;&#44049; &#51452;&#49548;&#47484;
            &#46907;&#54620;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c6">
            &#9317; &#51648;&#44049;&#49373;&#49457;: HIM
            &#50516;&#54840;&#54868;&#54224; &#54635; &#51648;&#44049;&#51012;
            &#51593; &#51648;&#44049;&#51452;&#49548;&#47484;
            &#49373;&#49457;&#54632;&#51012; &#46907;&#54620;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c6">
            &#9318; &#54872;&#50984;
            &#44228;&#49328;&#44592;:&#44397;&#44032;&#54868;&#54224;
            &#53076;&#51064;&#51004;&#47196; &#49464;&#44228;&#51032;
            &#48277;&#51221;&#54868;&#54224;&#47484;, &#49464;&#44228;&#51032;
            &#48277;&#51221;&#54868;&#54224;&#47196;
            &#44397;&#44032;&#54868;&#54224; &#54872;&#50984;&#51012;
            &#44228;&#49328;&#54624; &#49688; &#51080;&#45716;
            &#44228;&#49328;&#44592;&#47484; &#46907;&#54620;&#45796;.
          </span>
        </p>
        <p className="c4">
          <span className="c8">
            &#50948; &#49324;&#54637;&#50640;&#49436;
            &#51221;&#51032;&#46104;&#51648; &#50506;&#51008; &#51060;
            &#50557;&#44288;&#49345;&#51032; &#50857;&#50612;&#51032;
            &#51032;&#48120;&#45716; &#51068;&#48152;&#51201;&#51064;
            &#44144;&#47000;&#44288;&#54665;&#50640; &#51032;&#54620;&#45796;
          </span>
          <span className="c5">.</span>
        </p>
        <p className="c0">
          <span className="c5"></span>
        </p>
        <p className="c4">
          <span className="c1">
            &#51228;2&#51109; &#49436;&#48708;&#49828; &#51060;&#50857;
            &#49888;&#52397; &#48143; &#49849;&#51064;
            (&#54924;&#50896;&#44032;&#51077; &#48143; &#53448;&#53748;)
          </span>
        </p>
        <p className="c4">
          <span className="c8">
            &#51228;4&#51312; (&#51060;&#50857;&#44228;&#50557;&#51032;
            &#49457;&#47549;)
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            1) &#51060;&#50857;&#51088;&#45716; &#54924;&#49324;&#44032;
            &#51221;&#54620; &#44032;&#51077; &#50577;&#49885;&#50640;
            &#46384;&#46972; &#54924;&#50896;&#51221;&#48372;&#47484;
            &#44592;&#51077;&#54620; &#54980; &#51060; &#50557;&#44288;&#50640;
            &#46041;&#51032;&#54620;&#45796;&#45716;
            &#51032;&#49324;&#54364;&#49884;&#47484;
            &#54632;&#51004;&#47196;&#50024;
            &#54924;&#50896;&#44032;&#51077;&#51012;
            &#49888;&#52397;&#54620;&#45796;.
          </span>
        </p>
        <p className="c4">
          <span className="c8">
            2) &#54924;&#50896;&#44032;&#51077;&#51008; &#54924;&#49324;&#51032;
            &#49849;&#51064;&#51060; &#54924;&#50896;&#50640;&#44172;
            &#51204;&#45804;&#46108; &#49884;&#51216;&#51004;&#47196;
            &#54620;&#45796;.
          </span>
        </p>
        <p className="c4">
          <span className="c8">
            3) &#51060;&#50857; &#44228;&#50557;&#51008; &#54924;&#50896;ID
            &#45800;&#50948;&#47196; &#52404;&#44208;&#54620;&#45796;.
            &#51060;&#50857;&#44228;&#50557;&#51060;
            &#49457;&#47549;&#46104;&#47732;,
            &#51060;&#50857;&#49888;&#52397;&#51088;&#45716;
            &#54924;&#50896;&#51004;&#47196; &#46321;&#47197;&#46108;&#45796;.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c4">
          <span className="c8">
            &#51228;5&#51312; (&#51060;&#50857;&#49888;&#52397;)
          </span>
        </p>
        <p className="c4">
          <span className="c8">
            1) &#51060;&#50857;&#49888;&#52397;&#51008; &#54924;&#49324;
            &#49548;&#51221;&#51032; &#44032;&#51077;&#49888;&#52397;
            &#50577;&#49885;&#50640;&#49436; &#50836;&#44396;&#54616;&#45716;
            &#49324;&#54637;&#51012; &#44592;&#47197;&#54616;&#50668;
            &#49888;&#52397;&#54620;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            2) &#49324;&#49892;&#44284; &#45796;&#47480; &#51221;&#48372;,
            &#44144;&#51667; &#51221;&#48372;&#47484;
            &#44592;&#51077;&#54616;&#44144;&#45208; &#52628;&#54980;
            &#44536;&#47084;&#54620; &#51221;&#48372;&#51076;&#51060;
            &#48157;&#54784;&#51656; &#44221;&#50864; &#54924;&#49324;&#45716;
            &#49436;&#48708;&#49828; &#51060;&#50857;&#51012; &#51068;&#49884;
            &#51221;&#51648;&#54616;&#44144;&#45208;
            &#50689;&#44396;&#51221;&#51648; &#48143; &#51060;&#50857;
            &#44228;&#50557;&#51012; &#54644;&#51648;&#54624; &#49688;
            &#51080;&#45796;. &#51060;&#47196; &#51064;&#54616;&#50668;
            &#54924;&#49324; &#46608;&#45716; &#51228;3&#51088;&#50640;&#44172;
            &#48156;&#49373;&#54620; &#49552;&#54644;&#45716; &#54644;&#45817;
            &#54924;&#50896;&#50640;&#44172; &#47784;&#46304;
            &#52293;&#51076;&#51060; &#51080;&#45716; &#44163;&#51004;&#47196;
            &#44036;&#51452;&#54620;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            3) &#54924;&#49324;&#45716; &#54924;&#50896;&#50640;&#44172;
            &#54924;&#49324;&#51032;
            &#44288;&#47144;&#49436;&#48708;&#49828;&#50640; &#45824;&#54620;
            &#45796;&#50577;&#54616;&#44256; &#50976;&#51061;&#54620;
            &#51221;&#48372;&#47484; E-mail&#51012; &#53685;&#54616;&#50668;
            &#51228;&#44277;&#54624; &#49688; &#51080;&#45796;.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c4">
          <span className="c8">
            &#51228;6&#51312; (&#54924;&#50896;&#51221;&#48372;
            &#49324;&#50857;&#50640; &#45824;&#54620; &#46041;&#51032;)
          </span>
        </p>
        <p className="c4">
          <span className="c8">
            1) &#54924;&#49324;&#45716; &#54924;&#50896;&#51032;
            &#44060;&#51064;&#51221;&#48372;&#47484; &#48376;
            &#51060;&#50857;&#44228;&#50557;&#51032; &#51060;&#54665;&#44284;
            &#48376; &#51060;&#50857;&#44228;&#50557;&#49345;&#51032;
            &#49436;&#48708;&#49828;&#51228;&#44277;&#51012; &#50948;&#54620;
            &#47785;&#51201;&#51004;&#47196; &#51060;&#50857;&#54620;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            2) &#54924;&#50896;&#51060; &#54924;&#49324; &#48143;
            &#54924;&#49324;&#50752; &#51228;&#55092;&#54620;
            &#49436;&#48708;&#49828;&#46308;&#51012;
            &#54200;&#47532;&#54616;&#44172; &#51060;&#50857;&#54624; &#49688;
            &#51080;&#46020;&#47197; &#54924;&#50896; &#51221;&#48372;&#45716;
            &#54924;&#49324;&#50752; &#51228;&#55092;&#54620;
            &#50629;&#52404;&#50640; &#51228;&#44277;&#46112; &#49688;
            &#51080;&#45796;. &#45800;, &#54924;&#49324;&#45716;
            &#54924;&#50896; &#51221;&#48372; &#51228;&#44277;
            &#51060;&#51204;&#50640; &#51228;&#55092; &#50629;&#52404;,
            &#51228;&#44277; &#47785;&#51201;, &#51228;&#44277;&#54624;
            &#54924;&#50896; &#51221;&#48372;&#51032; &#45236;&#50857;
            &#46321;&#51012; &#49324;&#51204;&#50640;
            &#44277;&#51648;&#54616;&#44256; &#54924;&#50896;&#51032;
            &#46041;&#51032;&#47484; &#50619;&#50612;&#50556; &#54620;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            3) &#54924;&#50896;&#51060;
            &#51060;&#50857;&#49888;&#52397;&#49436;&#50640;
            &#54924;&#50896;&#51221;&#48372;&#47484;
            &#44592;&#51116;&#54616;&#44256;, &#54924;&#49324;&#50640; &#48376;
            &#50557;&#44288; &#48143; &#44060;&#51064;&#51221;&#48372;
            &#52376;&#47532;&#48169;&#52840;&#50640;
            &#46041;&#51032;&#54616;&#45716; &#44163;&#51008;
            &#54924;&#49324;&#44032; &#48376; &#50557;&#44288;&#50640;
            &#46384;&#46972; &#51060;&#50857;&#49888;&#52397;&#49436;&#50640;
            &#44592;&#51116;&#46108; &#54924;&#50896;&#51221;&#48372;&#47484;
            &#49688;&#51665;, &#51060;&#50857; &#48143;
            &#51228;&#44277;&#54616;&#45716; &#44163;&#50640;
            &#46041;&#51032;&#54616;&#45716; &#44163;&#51004;&#47196;
            &#44036;&#51452;&#46108;&#45796;.
          </span>
        </p>
        <p className="c4">
          <span className="c8">
            &#51228;7&#51312; (&#51060;&#50857;&#44228;&#50557;&#51032;
            &#51473;&#51648; &#48143; &#54644;&#51648;)
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            1) &#54924;&#49324;&#45716; &#51060;&#50857;&#51228;&#54620;&#51012;
            &#54616;&#44256;&#51088; &#54616;&#45716;
            &#44221;&#50864;&#50640;&#45716; &#44536; &#49324;&#50976;,
            &#51068;&#49884; &#48143; &#44592;&#44036;&#51012;
            &#51221;&#54616;&#50668; E-mail&#47484;
            &#51060;&#50857;&#54616;&#50668; &#54644;&#45817; &#54924;&#50896;
            &#46608;&#45716; &#45824;&#47532;&#51064;&#50640;&#44172;
            &#53685;&#51648;&#54620;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            2) &#45796;&#47564;, &#54924;&#49324;&#44032;
            &#44596;&#44553;&#54616;&#44172; &#51060;&#50857;&#51012;
            &#51473;&#51648;&#54644;&#50556; &#54624; &#54596;&#50836;&#44032;
            &#51080;&#45796;&#44256; &#51064;&#51221;&#54616;&#45716;
            &#44221;&#50864;&#50640;&#45716; &#51204;&#54637;&#51032;
            &#44284;&#51221; &#50630;&#51060; &#49436;&#48708;&#49828;
            &#51060;&#50857;&#51012; &#51228;&#54620;&#54624; &#49688;
            &#51080;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            3) &#49436;&#48708;&#49828; &#51060;&#50857;&#51473;&#51648;&#47484;
            &#53685;&#51648; &#48155;&#51008; &#54924;&#50896; &#46608;&#45716;
            &#44536; &#45824;&#47532;&#51064;&#51008;
            &#51060;&#50857;&#51473;&#51648;&#50640; &#45824;&#54616;&#50668;
            &#51060;&#51032;&#44032; &#51080;&#51012; &#44221;&#50864;
            &#51060;&#51032; &#49888;&#52397;&#51012; &#54624; &#49688;
            &#51080;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            4) &#54924;&#49324;&#45716; &#51060;&#50857;&#51473;&#51648;
            &#44592;&#44036; &#51473;&#50640; &#44536;
            &#51060;&#50857;&#51473;&#51648; &#49324;&#50976;&#44032;
            &#54644;&#44208;&#46108; &#44163;&#51060; &#54869;&#51064;&#46108;
            &#44221;&#50864;&#50640; &#54620;&#54616;&#50668;
            &#51060;&#50857;&#51473;&#51648; &#51312;&#52824;&#47484;
            &#51593;&#49884; &#54644;&#51228;&#54620;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            5) &#54924;&#49324;&#44032; &#49436;&#48708;&#49828;
            &#51060;&#50857;&#51012; &#51473;&#51648; &#46608;&#45716;
            &#51228;&#54620;&#49884;&#53416; &#54980; &#46041;&#51068;&#54620;
            &#54665;&#50948;&#44032; 2&#54924; &#51060;&#49345;
            &#48152;&#48373;&#46104;&#44144;&#45208; 30&#51068;
            &#51060;&#45236;&#50640; &#44536; &#49324;&#50976;&#44032;
            &#49884;&#51221;&#46104;&#51648; &#50500;&#45768;&#54616;&#45716;
            &#44221;&#50864;, &#46608;&#45716; &#51228;2&#54637;&#51032;
            &#50948;&#48152;&#54665;&#50948;&#44032; &#51080;&#45716;
            &#44221;&#50864;, &#54924;&#49324;&#45716;
            &#51060;&#50857;&#44228;&#50557;&#51012; &#54644;&#51648;&#54624;
            &#49688; &#51080;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            6) &#54924;&#49324;&#44032; &#51060;&#50857;&#44228;&#50557;&#51012;
            &#54644;&#51648;&#54616;&#45716; &#44221;&#50864;&#50640;&#45716;
            &#54924;&#50896;&#46321;&#47197;&#51012;
            &#47568;&#49548;&#54620;&#45796;. &#54924;&#49324;&#45716; &#51060;
            &#44221;&#50864; &#54924;&#50896;&#50640;&#44172; &#51060;&#47484;
            &#53685;&#51648;&#54616;&#44256;, &#54924;&#50896;&#46321;&#47197;
            &#47568;&#49548; &#51204;&#50640; &#49548;&#47749;&#54624;
            &#44592;&#54924;&#47484; &#48512;&#50668;&#54620;&#45796;.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c4">
          <span className="c8">
            &#51228;8&#51312; (&#54924;&#50896;&#51221;&#48372;&#51032;
            &#48320;&#44221;)
          </span>
        </p>
        <p className="c4 c13">
          <span className="c8">
            1) &#54924;&#50896;&#51008; &#44060;&#51064;&#51221;&#48372;
            &#49688;&#51221;&#54868;&#47732;&#51012; &#53685;&#54616;&#50668;
            &#50616;&#51228;&#46304;&#51648; &#48376;&#51064;&#51032;
            &#44060;&#51064;&#51221;&#48372;&#47484;
            &#50676;&#46988;&#54616;&#44256; &#49688;&#51221;&#54624; &#49688;
            &#51080;&#45796;.{" "}
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c4">
          <span className="c1">
            &#51228;3&#51109; &#54924;&#50896;&#51032; &#51032;&#47924;
          </span>
        </p>
        <p className="c4">
          <span className="c8">
            &#51228;9&#51312; (&#54924;&#50896; &#50500;&#51060;&#46356;&#50752;
            &#48708;&#48128;&#48264;&#54840; &#44288;&#47532;&#50640;
            &#45824;&#54620; &#54924;&#50896;&#51032; &#51032;&#47924;)
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            1) &#50500;&#51060;&#46356;&#50752;
            &#48708;&#48128;&#48264;&#54840;&#50640; &#44288;&#54620;
            &#47784;&#46304; &#44288;&#47532;&#52293;&#51076;&#51008;
            &#54924;&#50896;&#50640;&#44172; &#51080;&#45796;.
            &#54924;&#50896;&#50640;&#44172; &#48512;&#50668;&#46108;
            &#50500;&#51060;&#46356;&#50752;
            &#48708;&#48128;&#48264;&#54840;&#51032;
            &#44288;&#47532;&#49548;&#54848;,
            &#48512;&#51221;&#49324;&#50857;&#50640; &#51032;&#54616;&#50668;
            &#48156;&#49373;&#54616;&#45716; &#47784;&#46304;
            &#44208;&#44284;&#50640; &#45824;&#54620; &#52293;&#51076;&#51008;
            &#54924;&#50896;&#50640;&#44172; &#51080;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            2) &#54924;&#50896;&#51008; &#51088;&#49888;&#51032;
            &#50500;&#51060;&#46356;&#44032; &#48512;&#51221;&#54616;&#44172;
            &#49324;&#50857;&#46108; &#49324;&#49892;&#51012; &#50508;&#44172;
            &#46108; &#44221;&#50864; &#48152;&#46300;&#49884;
            &#54924;&#49324;&#50640; &#44536; &#49324;&#49892;&#51012;
            &#53685;&#51648;&#54616;&#44256; &#54924;&#49324;&#51032;
            &#50504;&#45236;&#50640; &#46384;&#46972;&#50556; &#54620;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            3) &#51228;2&#54637;&#51032; &#44221;&#50864;&#50640;
            &#54644;&#45817; &#54924;&#50896;&#51060; &#54924;&#49324;&#50640;
            &#44536; &#49324;&#49892;&#51012; &#53685;&#51648;&#54616;&#51648;
            &#50506;&#44144;&#45208;, &#53685;&#51648;&#54620;
            &#44221;&#50864;&#50640;&#46020; &#54924;&#49324;&#51032;
            &#50504;&#45236;&#50640; &#46384;&#47476;&#51648; &#50506;&#50500;
            &#48156;&#49373;&#54620; &#48520;&#51060;&#51061;&#50640;
            &#45824;&#54616;&#50668; &#54924;&#49324;&#45716;
            &#52293;&#51076;&#51648;&#51648; &#50506;&#45716;&#45796;.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c4">
          <span className="c8">
            &#51228;10&#51312; (&#51221;&#48372;&#51032; &#51228;&#44277;)
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            1) &#54924;&#49324;&#45716; &#54924;&#50896;&#51060;
            &#49436;&#48708;&#49828; &#51060;&#50857; &#51473;
            &#54596;&#50836;&#44032; &#51080;&#45796;&#44256;
            &#51064;&#51221;&#46104;&#45716; &#45796;&#51020;
            &#44033;&#54840;&#51032; &#54616;&#45208;&#50752; &#44057;&#51008;
            &#49436;&#48708;&#49828; &#51221;&#48372;&#50640;
            &#45824;&#54644;&#49436; &#51204;&#51088;&#50864;&#54200;&#51032;
            &#48169;&#48277;&#51004;&#47196; &#54924;&#50896;&#50640;&#44172;
            &#51228;&#44277;&#54624; &#49688; &#51080;&#51004;&#47728;
            &#54924;&#50896;&#51008; &#50896;&#52824; &#50506;&#51012;
            &#44221;&#50864; &#44032;&#51077;&#49888;&#52397;
            &#47700;&#45684;&#50752;
            &#54924;&#50896;&#51221;&#48372;&#49688;&#51221;
            &#47700;&#45684;&#50640;&#49436;
            &#51221;&#48372;&#49688;&#49888;&#44144;&#48512;&#47484; &#54624;
            &#49688; &#51080;&#45796;.
          </span>
        </p>
        <p className="c16">
          <span className="c8">
            &#9312; &#51060;&#48292;&#53944; &#48143;
            &#54665;&#49324;&#44288;&#47144; &#46321;&#51032;
            &#49436;&#48708;&#49828;
          </span>
        </p>
        <p className="c16">
          <span className="c8">
            &#9313; &#44592;&#53440; &#54924;&#49324;&#44032;
            &#49688;&#49884;&#47196; &#44208;&#51221;&#54616;&#50668;
            &#54924;&#50896;&#50640;&#44172; &#51228;&#44277;&#54616;&#45716;
            &#49436;&#48708;&#49828;
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c4">
          <span className="c1">
            &#51228;4&#51109; &#49436;&#48708;&#49828; &#51060;&#50857;
            &#52509;&#52825;
          </span>
        </p>
        <p className="c4">
          <span className="c8">
            &#51228;11&#51312; (&#49436;&#48708;&#49828;&#51032;
            &#51333;&#47448;)
          </span>
        </p>
        <p className="c4">
          <span className="c8">
            1) &#54924;&#49324;&#44032; &#51228;&#44277;&#54616;&#45716;
            &#49436;&#48708;&#49828;&#51032; &#51333;&#47448;&#45716;
            &#54924;&#49324;&#51032; &#49324;&#51221;&#50640;
            &#51032;&#54616;&#50668; &#49688;&#49884;&#47196;
            &#48320;&#44221;&#46112; &#49688; &#51080;&#45796;.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c4">
          <span className="c8">
            &#51228;12&#51312; (&#49436;&#48708;&#49828;
            &#45236;&#50857;&#51032; &#44277;&#51648; &#48143; &#48320;&#44221;)
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            1) &#54924;&#49324;&#45716; &#49436;&#48708;&#49828;&#51032;
            &#51333;&#47448;&#50640; &#46384;&#46972; &#44033;
            &#49436;&#48708;&#49828;&#51032; &#53945;&#49457;, &#51208;&#52264;
            &#48143; &#48169;&#48277;&#50640; &#45824;&#54620;
            &#49324;&#54637;&#51012; &#49436;&#48708;&#49828;
            &#54868;&#47732;&#51012; &#53685;&#54616;&#50668;
            &#44277;&#51648;&#54616;&#47728;, &#54924;&#50896;&#51008;
            &#54924;&#49324;&#44032; &#44277;&#51648;&#54620; &#44033;
            &#49436;&#48708;&#49828;&#50640; &#44288;&#54620;
            &#49324;&#54637;&#51012; &#51060;&#54644;&#54616;&#44256;
            &#49436;&#48708;&#49828;&#47484;
            &#51060;&#50857;&#54616;&#50668;&#50556; &#54620;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            2) &#54924;&#49324;&#45716; &#49436;&#48708;&#49828;
            &#45236;&#50857;&#51060; &#48320;&#44221;&#46104;&#45716;
            &#44221;&#50864;, &#54924;&#50896;&#51032; &#46321;&#47197;&#46108;
            &#51204;&#51088;&#50864;&#54200; &#51452;&#49548; &#48143;
            &#54924;&#49324;&#51032; &#44277;&#51648;&#49324;&#54637;
            &#54168;&#51060;&#51648;&#47484; &#53685;&#54616;&#50668;
            &#54924;&#50896;&#46308;&#50640;&#44172; &#53685;&#51648;&#54624;
            &#49688; &#51080;&#51004;&#47728;, &#54924;&#50896;&#51060;
            &#44277;&#51648; &#45236;&#50857;&#51012;
            &#51312;&#54924;&#54616;&#51648; &#50506;&#50500; &#51077;&#51008;
            &#49552;&#54644;&#50640; &#45824;&#54616;&#50668;&#45716;
            &#52293;&#51076;&#51648;&#51648; &#50506;&#45716;&#45796;.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c4">
          <span className="c8">
            &#51228;13&#51312; (&#49436;&#48708;&#49828;&#51032;
            &#50976;&#51648; &#48143; &#51473;&#51648;)
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            1) &#49436;&#48708;&#49828;&#51032; &#51060;&#50857;&#51008;
            &#54924;&#49324;&#51032; &#50629;&#47924;&#49345; &#46608;&#45716;
            &#44592;&#49696;&#49345; &#53945;&#48324;&#54620;
            &#47928;&#51228;&#44032; &#50630;&#45716; &#54620;
            &#50672;&#51473;&#47924;&#55092; 1&#51068;
            24&#49884;&#44036;&#51012; &#50896;&#52825;&#51004;&#47196;
            &#54620;&#45796;. &#45796;&#47564; &#51221;&#44592; &#51216;&#44160;
            &#46321;&#51032; &#47928;&#51228;&#47196; &#54924;&#49324;&#44032;
            &#51648;&#51221;&#54620; &#44592;&#44036;&#51008;
            &#44536;&#47084;&#54616;&#51648; &#50506;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            2) &#54924;&#49324;&#45716; &#49436;&#48708;&#49828;&#47484;
            &#51068;&#51221;&#48276;&#50948;&#47196;
            &#48516;&#54624;&#54616;&#50668; &#44033; &#48276;&#50948;
            &#48324;&#47196; &#51060;&#50857;&#44032;&#45733;
            &#49884;&#44036;&#51012; &#48324;&#46020;&#47196; &#51221;&#54624;
            &#49688; &#51080;&#45796;. &#51060; &#44221;&#50864; &#44536;
            &#45236;&#50857;&#51012; &#49324;&#51204;&#50640;
            &#44277;&#51648;&#54620;&#45796;.
          </span>
        </p>
        <p className="c4">
          <span className="c8">
            3) &#54924;&#49324;&#45716; &#45796;&#51020; &#44033;
            &#54840;&#51032; &#54616;&#45208;&#50640;
            &#54644;&#45817;&#54616;&#45716; &#44221;&#50864;
            &#49436;&#48708;&#49828; &#51228;&#44277;&#51012;
            &#51473;&#51648;&#54624; &#49688; &#51080;&#45796;.
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            &#9312; &#49436;&#48708;&#49828;&#50857; &#49444;&#48708;&#51032;
            &#48372;&#49688; &#46321; &#44277;&#49324;&#47196; &#51064;&#54620;
            &#48512;&#46301;&#51060;&#54620; &#44221;&#50864;
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            &#9313;
            &#51204;&#44592;&#53685;&#49888;&#49324;&#50629;&#48277;&#50640;
            &#44508;&#51221;&#46108;
            &#44592;&#44036;&#53685;&#49888;&#49324;&#50629;&#51088;&#44032;
            &#51204;&#44592;&#53685;&#49888; &#49436;&#48708;&#49828;&#47484;
            &#51473;&#51648;&#54664;&#51012; &#44221;&#50864;
          </span>
        </p>
        <p className="c4 c15">
          <span className="c8">
            &#9314; &#54924;&#49324;&#44032; &#51649;&#51217;
            &#51228;&#44277;&#54616;&#45716; &#49436;&#48708;&#49828;&#44032;
            &#50500;&#45772; &#51228;&#55092;&#50629;&#52404; &#46321;&#51032;
            &#51228;3&#51088;&#47484; &#51060;&#50857;&#54616;&#50668;
            &#51228;&#44277;&#54616;&#45716; &#49436;&#48708;&#49828;&#51032;
            &#44221;&#50864; &#51228;&#55092;&#50629;&#52404; &#46321;&#51032;
            &#51228;3&#51088;&#44032; &#49436;&#48708;&#49828;&#47484;
            &#51473;&#51648;&#54664;&#51012; &#44221;&#50864;
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            &#9315; &#44592;&#53440; &#48520;&#44032;&#54637;&#47141;&#51201;
            &#49324;&#50976;&#44032; &#51080;&#45716; &#44221;&#50864;
          </span>
        </p>
        <p className="c3">
          <span className="c8">
            4) &#54924;&#49324;&#45716;
            &#44397;&#44032;&#48708;&#49345;&#49324;&#53468;, &#51221;&#51204;,
            &#49436;&#48708;&#49828; &#49444;&#48708;&#51032; &#51109;&#50528;
            &#46608;&#45716; &#49436;&#48708;&#49828; &#51060;&#50857;&#51032;
            &#54253;&#51452; &#46321;&#51004;&#47196;
            &#51221;&#49345;&#51201;&#51064; &#49436;&#48708;&#49828;
            &#51060;&#50857;&#50640; &#51648;&#51109;&#51060; &#51080;&#45716;
            &#46412;&#50640;&#45716; &#49436;&#48708;&#49828;&#51032;
            &#51204;&#48512; &#46608;&#45716; &#51068;&#48512;&#47484;
            &#51228;&#54620;&#54616;&#44144;&#45208; &#51221;&#51648;&#54624;
            &#49688; &#51080;&#45796;.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c4">
          <span className="c1">&#48512;&#52825;</span>
        </p>
        <p className="c4">
          <span className="c8">
            &#51060; &#50557;&#44288;&#51008; 2019&#45380; 09&#50900;
            01&#51068;&#48512;&#53552; &#51201;&#50857;&#46108;&#45796;.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c0">
          <span className="c5"></span>
        </p>
        <p className="c17">
          <span className="c7"></span>
        </p>
      </div>
    );
  }
}

export default TermsKR;
