import common from "../utils/common";
import MultiLanguage from "./modules/MultiLanguage";

const browser_lang_interpreter = {
  "en-US": "en",
  "ko-KR": "kr",
};

let default_lang;

if (common.get().country_code) {
  default_lang = MultiLanguage.getLangFromCountryCode(
    common.get().country_code
  );
} else {
  // var browser_lang = navigator.language || navigator.userLanguage;
  // default_lang = browser_lang_interpreter[browser_lang] || "en";

  // TODO only kr now...
  default_lang = "en";
}

const lang = new MultiLanguage(default_lang, "en");

lang.addLangDic(require("./dics/en"), "en");
lang.addLangDic(require("./dics/en_common"), "en");
lang.addLangDic(require("./dics/en_country_codes"), "en");

lang.addLangDic(require("./dics/kr"), "kr");
lang.addLangDic(require("./dics/kr_common"), "kr");
lang.addLangDic(require("./dics/kr_country_codes"), "kr");

export { MultiLanguage };
export default lang;
