import React from "react";

import common from "../../../utils/common";
import eos_wallet from "../../../utils/eos_wallet";
import storage_util from "../../../utils/storage_util";
import lang from "../../../lang/lang";
import {
  Button,
  Typography,
  Icon,
  Input,
  Select,
  Drawer,
  Row,
  Col,
} from "antd";
import checkers from "../../../utils/checkers";
// import native_util from '../../../utils/native_util';
import server_api from "../../../utils/server_api";
import moment from "moment";
import "moment/locale/ko";
import cache from "../../../utils/cache";
import config from "../../../config";

class Exchange2 extends React.Component {
  is_mounted_ = false;
  state = {
    inputTokenAmount: 0,
    inputToken: "HIM",
    outputTokenAmount: 0,
    outputToken: "외부지갑주소",
    selectList: ["HIM", "GGP", "GGPG"],
    selectList2: ["HIM", "GGP", "GGPG", "외부지갑주소"],
    memo: "",
    toAddress: "",
    list: [],
    data_account: [],
    isLoading: false,
    global_variants: null,
  };

  constructor(props) {
    super(props);
    // console.log(this.props.location.state);
    // if (!this.props.location.state)
    //   return this.props.history.push('/');

    // const { value_transferred } = this.props.location.state;
    this.state = Object.assign(
      {},
      this.state,
      storage_util.loadFromLocal("push_options")
    );

    // console.log(storage_util.loadFromLocal('push_options'));
    // console.log(this.state);
  }
  setstate = (partial_state) =>
    this.is_mounted_ === true && this.setState(partial_state);

  /* server transactions */
  async _fetchAccount() {
    let res_accounts = [];
    try {
      res_accounts =
        (await server_api.readUsersAccount({}, { type: "array" })) || [];
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.error(e);
    }

    let res_accounts_value_not_zero = [];
    let major_token_obj = void 0;
    for (let v of res_accounts) {
      if (v.name === config.major_token_symbol) {
        major_token_obj = v;
        continue;
      }
      // if (v.value === 0) {
      //   continue;
      // }
      res_accounts_value_not_zero.push(v);
    }

    if (major_token_obj === void 0) {
      res_accounts_value_not_zero.unshift({
        name: config.major_token_symbol,
        value: 0,
      });
    } else {
      res_accounts_value_not_zero.unshift(major_token_obj);
    }

    let balance = 0;
    for (let v of res_accounts_value_not_zero) {
      balance += v.value;
    }

    // if (this.is_mounted_ && balance === 0) {
    //   const modal = Modal.confirm({
    //     title: lang.get('transfer/modal_case4/title'),
    //     content: lang.get('transfer/modal_case4/content'),
    //     okText: lang.get('modal/ok_text'),
    //     cancelText: lang.get('modal/cancel_text'),
    //     onOk: e => {
    //       modal.destroy();
    //       this.props.history.push('/filling');
    //     },
    //     onCancel() {

    //     },
    //     centered: true
    //   });
    // }

    return res_accounts_value_not_zero;
  }

  async componentDidMount() {
    this.is_mounted_ = true;
    let data_account = await this._fetchAccount();
    console.log(data_account);

    this.setstate({ data_account, is_loading: true });
    if ((await common.isLoggedIn()) === false) {
      return;
    }
    let global_variantsAll = await this._cacheGlobalALL();

    // this._fetchRequestList();

    this.setstate({
      is_loading: false,
      global_variants: global_variantsAll,

      header_text: (await eos_wallet.getHeaderTokenText()) || "",
    });
    // 알림 체크
  }
  componentWillUnmount() {
    this.is_mounted_ = false;
  }
  async _cacheGlobalALL() {
    try {
      const res_global_vars_items = (await cache.global_vars.get()).items;

      return res_global_vars_items;
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      return {};
    }
  }
  _rTokenOptions() {
    let options = [];

    options = this.state.selectList.map((token, i) => (
      <Select.Option key={i} value={token}>
        {token}
      </Select.Option>
    ));

    return options;
  }

  _rTokenOptionsOutPut() {
    let options = [];

    options = this.state.selectList2.map((token, i) => (
      <Select.Option key={i} value={token}>
        {token}
      </Select.Option>
    ));

    return options;
  }

  async _fetchRequest() {
    if (this.state.isLoading) return;
    const amount = +this.state.inputTokenAmount;

    if (!amount || amount <= 0) {
      return alert(lang.get("more/error1"));
    }

    if (this.state.inputToken === this.state.outputToken) {
      return alert(lang.get("more/error2"));
    }

    // if (
    //   this.state.outputToken.includes("외부지갑") &&
    //   this.state.memo.length !== 12
    // ) {
    //   return alert(lang.get("more/error3"));
    // }
    const common_data = common.get();

    const ableAmount =
      +common_data.lock_amount - +common_data.lock_transfer_amount;
    if (ableAmount < amount) {
      return alert("You can't transfer more than " + ableAmount);
    }

    try {
      this.setstate({ isLoading: true });
      const params = {
        fromCurrency: this.state.inputToken,
        amount: amount,
        toAddress: this.state.toAddress,
        memo: this.state.memo,
        toCurrency: this.state.outputToken.includes("지갑")
          ? "WALLET"
          : this.state.outputToken,
      };

      const r = await server_api.convertCoin(params);
      console.log(r);
      if (r) {
        this.setState({
          inputTokenAmount: 0,
          inputToken: "HIM",
          outputTokenAmount: 0,
          outputToken: "HIM",
          memo: "",
        });
        // this._fetchRequestList();
        alert("Success");
        this.setstate({ isLoading: false });
        return;
      }

      return r;
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      console.log(e.result.message);
      this.setstate({ isLoading: false });
      alert(e.result.message);
      return void 0;
    }
  }

  // //   amount: "0"
  // // createdAt: "2021-07-14T03:38:25.000Z"
  // // id: 7
  // // memo: ""
  // // state: "REQUEST"
  // // targetToken: "HIM"
  // // token: "HIM"
  // // updatedAt: "2021-07-14T03:38:25.000Z"
  // // user_id: 12
  // async _fetchRequestList() {
  //   try {
  //     const r = (await server_api.getUsersCoinExchanges()).items;

  //     let l = [];
  //     r.forEach((e) => {
  //       let ee = "요청";
  //       if (e.state === "SENDED") {
  //         ee = "지급완료";
  //       } else if (e.state === "FAIL") {
  //         ee = "실패";
  //       }
  //       l.push(
  //         <div
  //           style={{
  //             padding: "0.5rem 1rem 0",
  //             display: "table",
  //             width: "100%",
  //             borderBottom: "1px solid gray",
  //           }}
  //         >
  //           <div style={{ display: "table-cell", width: "30%" }}>{ee}</div>
  //           <div style={{ display: "table-cell", width: "20%" }}>
  //             {e.amount}
  //           </div>
  //           <div style={{ display: "table-cell", width: "30%" }}>
  //             {e.token + " => " + e.targetToken + " " + e.memo}
  //           </div>
  //           <div style={{ display: "table-cell", width: "20%" }}>
  //             {moment(e.updatedAt || 0).format("YY-MM-DD HH:mm:ss")}
  //           </div>
  //         </div>
  //       );
  //     });
  //     this.setState({ list: l });
  //   } catch (e) {
  //     if (process.env.NODE_ENV === "development") {
  //       console.error(e);
  //     }
  //     return void 0;
  //   }
  // }
  _checkLock() {
    console.log(this.state);
    const accounts = this.state.data_account;
    const account = accounts.filter(
      (i) => i.name.toUpperCase() === this.state.token
    )[0];

    let ableAmount = 0;
    if (account && account.is_lock) {
      if (+account.lock_amount - +account.lock_transfer_amount <= 0) {
        return true;
      }
    }

    if (ableAmount === -1) {
    }
    if (this.state.global_variants === null) {
      return true;
    }

    const g = this.state.global_variants.filter(
      (item) => item.name.toUpperCase() === this.state.inputToken.toUpperCase()
    )[0];

    if (g.value === "open") {
      return false;
    }
    // return true;
  }
  render() {
    if (common.get().token === void 0) {
      return null;
    }

    return (
      <div style={{ display: "table", width: "100%", height: "100%" }}>
        {/* Header */}
        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>
            {/** HEADER *****/}
            <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  onClick={(e) => this.props.history.push("/wallet")}
                  style={{
                    flex: "1 1 0px",
                    borderRadius: "4px",
                    padding: "0.2rem 1rem",
                    display: "block",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontSize: "1rem",
                    color: "#fff",
                    background: "rgb(51, 129, 252)",
                    cursor: "pointer",
                  }}
                >
                  {this.state.header_text}&nbsp;
                </div>
                <div style={{ flex: "0 0 auto", padding: "0 0 0 0.5rem" }}>
                  <Button
                    type="link"
                    style={{ padding: "0", height: "2.5rem" }}
                    onClick={(e) => this.props.history.push("/all")}
                  >
                    <Icon
                      type="close"
                      style={{ fontSize: "1.6rem", color: "#000" }}
                    />
                  </Button>
                </div>
              </div>

              <Typography.Title
                level={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0",
                  marginTop: "1.5rem",
                }}
              >
                <Typography.Text>
                  {" "}
                  {lang.get("more/withdraw_out")}
                </Typography.Text>
              </Typography.Title>
            </div>
            {/***** HEADER END **/}
          </div>
        </div>

        <div style={{ display: "table-row" }}>
          {" "}
          {/* content */}
          <div style={{ display: "table-cell" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div
                style={{
                  top: "0",
                  bottom: "0",
                  left: "0",
                  right: "0",
                  position: "absolute",
                  overflow: "auto",
                }}
              >
                <div style={{ padding: "1rem 0.5rem" }}>
                  <div
                    style={{
                      borderRadius: "0.6em",
                      padding: "1rem 1rem",
                      margin: "0.5rem auto 0",
                      boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                      fontSize: "1rem",
                      fontWeight: "700",
                    }}
                  >
                    <div> {lang.get("more/withdraw_amount")}</div>
                    <div style={{ height: "30px" }}></div>
                    <Input
                      type="number"
                      value={this.state.inputTokenAmount}
                      onChange={(e) => {
                        this.setstate({ inputTokenAmount: e.target.value });
                      }}
                      onFocus={(e) => {
                        if (checkers.is_mobile === false) {
                          if (process.env.NODE_ENV === "development")
                            console.log("pc");
                          return;
                        }
                      }}
                    />
                    <div style={{ height: "10px" }}></div>
                    <Select
                      id="country_selecter"
                      value={this.state.inputToken}
                      style={{ width: "100%" }}
                      onChange={(v) => this.setstate({ inputToken: v })}
                    >
                      {this._rTokenOptions()}
                    </Select>
                    <div style={{ height: "10px" }}></div>
                    {this.state.outputToken === "외부지갑주소" ? (
                      <Input
                        value={this.state.toAddress}
                        placeholder={
                          this.state.outputToken === "외부지갑주소"
                            ? lang.get("more/withdraw_address")
                            : "Memo"
                        }
                        onChange={(e) => {
                          this.setstate({ toAddress: e.target.value });
                        }}
                        onFocus={(e) => {
                          if (checkers.is_mobile === false) {
                            if (process.env.NODE_ENV === "development")
                              console.log("pc");
                            return;
                          }
                        }}
                      />
                    ) : (
                      <></>
                    )}

                    <div style={{ height: "10px" }}></div>

                    <Input
                      value={this.state.memo}
                      placeholder={
                        this.state.outputToken === "외부지갑주소"
                          ? lang.get("more/withdraw_memo")
                          : "Memo"
                      }
                      onChange={(e) => {
                        this.setstate({ memo: e.target.value });
                      }}
                      onFocus={(e) => {
                        if (checkers.is_mobile === false) {
                          if (process.env.NODE_ENV === "development")
                            console.log("pc");
                          return;
                        }
                      }}
                    />
                  </div>

                  {/* <div
                    style={{
                      borderRadius: "0.6em",
                      padding: "1rem 1rem",
                      margin: "0.5rem auto 0",
                      boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                      fontSize: "1rem",
                      fontWeight: "700",
                    }}
                  >
                    <div>환전받을 코인</div>

                    <div style={{ height: "10px" }}></div>
                    <Select
                      id="country_selecter"
                      value={this.state.outputToken}
                      style={{ width: "100%" }}
                      onChange={(v) => {
                        this.setstate({ outputToken: v });
                        this.setstate({ memo: "" });
                      }}
                    >
                      {this._rTokenOptionsOutPut()}
                    </Select>
                  </div> */}
                  <Button
                    type="primary"
                    onClick={(e) => {
                      if (this._checkLock() === true) return alert("LOCK");
                      this._fetchRequest();
                    }}
                    style={{
                      width: "100%",
                      padding: "0 3rem",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      marginTop: "40px",
                    }}
                  >
                    {this._checkLock() === true
                      ? "LOCK"
                      : this.state.isLoading
                      ? "Withdrawing"
                      : "Withdraw"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  savePushOptionsToLocalStorage(new_state) {
    storage_util.saveToLocal(
      "push_options",
      Object.assign(
        {
          push_deposit_withdrawal: this.state.push_deposit_withdrawal,
          push_filling_exchange: this.state.push_filling_exchange,
          push_popup: this.state.push_popup,
        },
        new_state
      )
    );

    this.setState(new_state);

    // update
    const name_noti_map = {
      push_deposit_withdrawal: "need_transfer_noti",
      push_filling_exchange: "need_exchange_noti",
      push_popup: "need_popup_noti",
    };
    const params = {};
    for (const name in new_state) params[name_noti_map[name]] = new_state[name];

    (async () => {
      try {
        await server_api.updateUser(common.id, params);
      } catch (e) {
        if (process.env.NODE_ENV === "development") {
          console.error(e);
        }
      }
    })();
  }
}

export default Exchange2;
