import server_api from "./server_api";

class CachedData {
  constructor(life_time, initial_data, fetchDataFunc, params = void 0) {
    this.life_time = life_time;
    this.data = initial_data;
    this.validated_time = 0;
    this.fetchDataFunc = fetchDataFunc;
    this.is_fetching = false;
    this.params = params;
  }

  isDirty() {
    const now = Date.now();

    return now - this.validated_time > this.life_time;
  }

  dirty() {
    this.validated_time = 0;
  }

  getCashed() {
    return this.data;
  }

  async get() {
    if (this.isDirty()) {
      // wait for async
      while (this.is_fetching) {
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
      this.is_fetching = true;

      //fetch
      try {
        this.data = await this.fetchDataFunc(this.params);
      } catch (e) {
        this.is_fetching = false;
        throw e;
      }

      // end
      this.validated_time = Date.now();

      this.is_fetching = false;
      return this.data;
    } else {
      return this.data;
    }
  }
}

export default {
  codes: new CachedData(2 * 60 * 1000, [], server_api.fetchTokens),
  global_vars: new CachedData(2 * 60 * 1000, [], server_api.fetchGlobalVars),
};
