import React from 'react';
import { Button, Input, Drawer, Select, Typography, Modal } from 'antd';

import BankAccountList from '../drawer_contents/BankAccountList';
import Loading from '../Loading';
//* utils
import common     from '../../utils/common';
import eos_wallet from '../../utils/eos_wallet';
import server_api from '../../utils/server_api';
//* resources
import lang from '../../lang/lang';




class BankAccountsDrawer extends React.Component {
  static defaultProps = {
    visible: false,
  }

  is_mounted_ = false;
  state = {
    is_loading: false,
    is_submitting: false,
    data_banks_of_country_selected: [],
    data_codes: [],
    data_users_bank_list: [],
    account: '',
    bank_account: '',
    bank_new_account: '',
    bank_new_alias: '',
    bank_new_owner: '',
    bank_new_selected: '',
    visible_bank_account_create_drawer: false,
    error_msg: '',
  }

  constructor(props) { super(props);
    // console.log(this.props.location.state);
    // if (!this.props.location.state)
    //   return this.props.history.push('/');
        
    this.state = {
      ...this.state,
    }
  }
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });

    let data_banks = await this._fetchBanks();
    
    this.setstate({
      is_loading: false,
      data_banks,      
      data_banks_of_country_selected: data_banks,
      bank_new_selected: (data_banks[0] || {}).id,
      data_users_bank_list: await this._refreshBankList(),
      data_codes: (await server_api.fetchTokens()).items
    })    
  }
  componentWillUnmount() {
    this.is_mounted_ = false;
  }

  render () {
    return (
      <Drawer
        title={<div style={{ fontSize: "1rem", fontWeight: "700" }}><Typography.Text>{lang.get('drawer/bank_accounts/title')}</Typography.Text></div>}
        placement="bottom"
        closable={true}
        onClose={e => {
          this.props.onClose(e);
          this.setstate({
            account: '',
            error_msg: ''
          })
        }}
        visible={this.props.visible}
        height="bottom"
        bodyStyle={{ position: "relative", padding: "0" }}
      >
        <Loading is_loading={this.state.is_loading || this.state.is_submitting } overall={true} style={{ height: "100%", padding: "0.5rem" }}>
          <div style={{ position: "relative", height: "calc(50vh - 5rem)" }}>
            <div style={{ top: "0", bottom: "0", left: "0", right: "0", position: "absolute", overflow: "auto" }}>
              <BankAccountList data={this.state.data_users_bank_list} onClickAccount={value => this.props.onClickAccount(value)} onClickDelete={this._hDeleteAccount.bind(this)}/>
            </div>
          </div>
          <div style={{ marginTop: "0.5rem" }}>
            <Button onClick={async (e) => {
              this.setstate({
                visible_bank_account_list_drawer: false,
                visible_bank_account_create_drawer: true
              });
            }}>
              {lang.get('drawer/bank_accounts/btn_add_account')}
            </Button>
            <Drawer
              title={<div style={{ fontSize: "1rem", fontWeight: "700" }}><Typography.Text>{lang.get('drawer/bank_accounts/drawer/add_account/title')}</Typography.Text></div>}
              placement="bottom"
              closable={true}
              onClose={e => this.setstate({
                visible_bank_account_list_drawer: true,
                visible_bank_account_create_drawer: false,
                account: '',
                error_msg: ''
              })}
              visible={this.state.visible_bank_account_create_drawer}
              height="bottom"
            >
              <div>
                <label htmlFor="country_selecter">{lang.get('drawer/bank_accounts/drawer/add_account/select_country_title')}</label>
                <Select
                  id="country_selecter"
                  defaultValue={lang.get('drawer/bank_accounts/drawer/add_account/select_country_option_all')}
                  style={{ width: "100%" }}
                  onChange={v => this._hSelectCountryOfBank(v)}
                >
                  <Select.Option key="country_all">{lang.get('drawer/bank_accounts/drawer/add_account/select_country_option_all')}</Select.Option>
                  {this._rCountryOptions()}
                </Select>
              </div>
              <div style={{ marginTop: "0.5rem" }}>
                <label htmlFor="bank_selecter">{lang.get('drawer/bank_accounts/drawer/add_account/select_bank_title')}</label>
                <Select
                  id="bank_selecter"
                  style={{ width: "100%" }}
                  value={this.state.bank_new_selected}
                  onChange={v => this.setstate({ bank_new_selected: v })}
                >
                  {this._rNewBankOptions()}
                </Select>
              </div>
              <div style={{ marginTop: "0.5rem" }}>
                <Input placeholder={lang.get('drawer/bank_accounts/drawer/add_account/input_account_placeholder')} onChange={e => this.setstate({ bank_new_account: (e.target.value).trim() })} />
              </div>
              <div style={{ marginTop: "0.5rem" }}>
                <Input placeholder={lang.get('drawer/bank_accounts/drawer/add_account/input_owner_placeholder')} onChange={e => this.setstate({ bank_new_owner: e.target.value })} />
              </div>
              <div style={{ marginTop: "0.5rem" }}>
                <Input placeholder={lang.get('drawer/bank_accounts/drawer/add_account/input_alias_placeholder')} onChange={e => this.setstate({ bank_new_alias: e.target.value })} />
              </div>
              <div style={{ marginTop: "0.5rem" }}>
                <Button type="primary" style={{ width: "100%" }} onClick={this._hAddNewBank.bind(this)}>{lang.get('drawer/bank_accounts/drawer/add_account/btn_add')}</Button>
              </div>
            </Drawer>
          </div>
        </Loading>
      </Drawer>
    );
  }

  _rCountryOptions () {
    if (this.state.data_codes.length === 0) {
      return null;
    }

    const list = (this.state.data_codes).reduce((p, c, i, a) => {
      if (c.country_code === '') {
        return p
      }
      else {
        p.push(<Select.Option key={c.country_code}>{lang.get(`country_codes/${c.country_code}`)}</Select.Option>)
      }
      return p;
    }, []);

    return list;
  }

  _rNewBankOptions () {
    const { data_banks_of_country_selected } = this.state;
    if (data_banks_of_country_selected.length === 0) {
      return <Select.Option key="-1">{lang.get('drawer/bank_accounts/no_bank_list_message')}</Select.Option>
    }

    return data_banks_of_country_selected.map(bank => (<Select.Option key={bank.name} value={bank.id}>{bank.name}</Select.Option>));
  }


  async _hAddNewBank (e) {
    const { bank_new_selected, bank_new_account, bank_new_owner, bank_new_alias } = this.state;

    if (bank_new_selected < 0 || typeof bank_new_selected !== 'number') {
      Modal.error({
        title: lang.get('drawer/bank_accounts/modal_case1/title'),
        content: lang.get('drawer/bank_accounts/modal_case1/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return;
    }

    
    if (eos_wallet.validate.checkBlank(bank_new_account)) {
      Modal.error({
        title: lang.get('drawer/bank_accounts/modal_case2/title'),
        content: lang.get('drawer/bank_accounts/modal_case2/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return;
    }

    const regex_1 = /^[A-Za-z0-9-+]*$/;  
    if (!regex_1.test(bank_new_account)) {
      Modal.error({
        title: lang.get('drawer/bank_accounts/modal_case3/title'),
        content: lang.get('drawer/bank_accounts/modal_case3/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return;
    }

    if (eos_wallet.validate.checkBlank(bank_new_owner.trim())) {
      Modal.error({
        title: lang.get('drawer/bank_accounts/modal_case4/title'),
        content: lang.get('drawer/bank_accounts/modal_case4/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return;
    }

    if (eos_wallet.validate.checkBlank(bank_new_alias.trim())) {
      Modal.error({
        title: lang.get('drawer/bank_accounts/modal_case5/title'),
        content: lang.get('drawer/bank_accounts/modal_case5/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return;
    }

    this.setstate({
      is_submitting: true,
      visible_bank_account_create_drawer: false,
    });
    try {
      await server_api.createUserBankAccount({
        user_id     : common.get().id,
        bank_id: bank_new_selected,
        bank_account: bank_new_account,
        owner_name: bank_new_owner,
        alias: bank_new_alias,
        is_archived: 0
      });

      this.setstate({
        is_submitting: false,
        data_users_bank_list: await this._refreshBankList(),
        visible_bank_account_list_drawer: true,
        visible_bank_account_create_drawer: false,
        bank_new_account: '',
        bank_new_owner: '',
        bank_new_alias: '',
      });
    } catch (e) {
      Modal.error({
        title: lang.get('drawer/bank_accounts/modal_case6/title'),
        content: lang.get('drawer/bank_accounts/modal_case6/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return;
    }
  }




  _hSelectCountryOfBank (v) {
    const { data_banks } = this.state;

    if (v === 'country_all') {
      this.setstate({
        data_banks_of_country_selected: data_banks,
        bank_new_selected: (data_banks[0] || {}).id
      });
    }
    else {
      const filtered = data_banks.filter(bank => bank.country_code === v);      

      this.setstate({
        data_banks_of_country_selected: filtered,        
        bank_new_selected: (filtered[0] || {}).id || lang.get('drawer/bank_accounts/no_bank_list_message')
      });
    }
  }

  async _hDeleteAccount (account_id) {
    this.setstate({ is_loading: true })

    try{
      await server_api.deleteUserBankAccount(account_id);
    
      return this.setstate({      
        is_loading: false,
        data_users_bank_list: await this._refreshBankList()
      })
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }
      
      Modal.error({
        title: lang.get('drawer/bank_accounts/modal_case7/title'),
        content: lang.get('drawer/bank_accounts/modal_case7/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return this.setstate({ is_loading: false });
    }
  }

  async _fetchBanks() {
    try {
      const res_items = (await server_api.fetchBanks({
        where: {
          status: 'AVAILABLE'
        }
      })).items;

      return res_items;
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      return [];
    }
  }
  

  async _refreshBankList () {
    try {
      const res_items = (await server_api.fetchUserBankAccounts({
        where: {
          user_id: common.get().id,
        },
        include: [
          {
            association: 'bank',
            where: {
              status: 'AVAILABLE'
            }
          }
        ]
      })).items;

      const data_users_bank_list = res_items.map(item => ({
        id: item.id,
        alias: item.alias,
        bank_id: item.bank_id,
        bank_name: item.bank.name,
        bank_account: item.bank_account,
        owner_name: item.owner_name,
      }))

      return data_users_bank_list;
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      return [];
    }
  }
}

export default BankAccountsDrawer;