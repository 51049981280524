import React from 'react';
import { Row, Col, Button, Input } from 'antd';


class InputOtpPc extends React.Component {
  static defaultProps = {

  }

  state = {

  }
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });

    this.setstate({
      is_loading: false,
     });
  }

  componentWillUnmount() { this.is_mounted_ = false; }
  
  async componentWillReceiveProps(nextProps) {
    // const is_same_year  = this.props.year  === nextProps.year;
  }

  render () {
    return (
      <div>
        <div>
          <Input          
            type="password"
            inputMode="numeric"
            style={{ width: "100%" }}
            value={this.props.password}
            maxLength={6}
            onChange={this._hInputChange.bind(this)} />                      
        </div>
        <div style={{ marginTop: "0.5rem" }}>
          <Button size="large" type="primary" style={{ width: "100%" }} onClick={this.props.onSubmit}>{this.props.button_text}</Button>
        </div>
      </div>
    );
  }

  _rPassword () {
    let pw_box = [];
    let { password } = this.props;
    const pw_length = (password || '').length;

    // const svg_circle =(
    //   <svg height="100" width="100">
    //     <circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" />
    //   </svg>)

    for(let i = 0; i < 6; ++i) {
      if (i < pw_length)
        pw_box.push(
          <Col key={i} span={4} style={{ textAlign: "center" }}>
            ●
          </Col>
        )
      else
        pw_box.push(
          <Col key={i} span={4} style={{ textAlign: "center", color: "RGBA(192, 192, 192, 0.5)"}}>
            ●
          </Col>
        )
    }

    return (
      <Row style={{ width: "14rem", borderRadius: "4px" }}>
        {pw_box}
      </Row>);    
  }


  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    if (value.charAt(value.length - 1) === '.' || value === '-') {
      if (this.props.comma) {
        onChange(value.slice(0, -1));
      }

      onChange(value);
    }

    if (onBlur) {
      onBlur();
    }
  }

  _hInputChange(e) {
    let value = e.target.value;

    const regex =  /^\d+$/
    if (regex.test(value) === true || value === '') {
      this.props.onChange(value);
    }
  }
}

export default InputOtpPc;