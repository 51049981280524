import React from 'react';
import { Button, Typography, Icon, Tabs, DatePicker } from 'antd';
import moment from 'moment';
import query_string from 'query-string';

//* view components
import Loading from '../../../snippets/Loading'
import TransferList from './record/TransferList';
import FillingList  from './record/FillingList';
import ExchangeList from './record/ExchangeList';
//* utils
import common     from '../../../utils/common';
import checkers   from '../../../utils/checkers';
import eos_wallet from '../../../utils/eos_wallet';
//* styles
import './record.less';
//* resources
import lang from '../../../lang/lang';





const monthFormat = 'YYYY. MM ';

class Records extends React.Component {
  is_mounted_ = false;
  state = {
    header_text: '',
    tab_key: "1",
    mouse_x_start: void 0,
    mouse_time_start: void 0,
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  }

  constructor(props) { super(props);
    // console.log(this.props.location.state);
    // if (!this.props.location.state)
    //   return this.props.history.push('/');

    this.state = {
      ...this.state,
      tab_key: ((this.props.location || {}).state || {}).tab_key || "1"
    }

    this._slide = this._slide.bind(this);
  }
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    if (await common.isLoggedIn() === false) {
      return;
    }
    
    this.setstate({
      is_loading: false,
      header_text: await eos_wallet.getHeaderTokenText() || '',
    })
  }
  componentWillUnmount() { this.is_mounted_ = false; }

  render () {
    if (common.get().token === void 0) {
      return null;
    }

    return (
      <Loading is_loading={this.state.is_loading} overall={true} style={{ display: "table", tableLayout: "fixed", width: "100%", height: "100%" }}>
        {/* Header */}
        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>

            {/** HEADER *****/}
            <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  onClick={e => this.props.history.push('/wallet')}
                  style={{
                      flex: "1 1 0px", borderRadius: "4px", padding: "0.2rem 1rem"
                    , display: "block", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"
                    , fontSize: "1rem", color: "#fff", background: "rgb(51, 129, 252)", cursor: 'pointer'
                  }} >
                  {this.state.header_text}&nbsp;
              </div>
                <div style={{ flex: "0 0 auto", padding: "0 0 0 0.5rem" }}>
                  {(query_string.parse(this.props.location.search).return)
                    ? ( <Button type="link" onClick={e => this.props.history.push({
                          pathname: query_string.parse(this.props.location.search).return,
                          state: {
                            ...this.props.location.state
                          }
                        })} style={{ padding: "0", height: "2.5rem" }}>
                          <Icon type="close" style={{ fontSize: "1.6rem", color: "rgb(75, 75, 75)" }} />
                        </Button>
                      )
                    : ( <Button type="link" style={{ paddingLeft: "0", pointerEvents: "none", height: "2.5rem" }}>
                          <Icon type="close" style={{ fontSize: "1.6rem", color: "rgb(75, 75, 75, 0)" }} />
                        </Button>
                      )
                  }
                </div>
              </div>
              <Typography.Title level={3} style={{ display: 'flex', alignItems: 'center', marginBottom: "0", marginTop: "1.5rem" }}>
                <Typography.Text>{lang.get('wallet/record/title')}</Typography.Text>
              </Typography.Title>
            </div>
            {/***** HEADER END **/}

          </div>
        </div>

        <div style={{ display: "table-row", height: "2%" }} />

        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell", padding: "0rem 0rem"}}>
           
            <div id="record-date-picker" style={{ marginTop: "1rem", padding: "0 0.5rem",
              display: "flex", justifyContent: "center", alignItems: "center",
              borderTop: "1px solid RGB(47, 85, 151)", borderBottom: "1px solid RGB(47, 85, 151)" }}>
              <DatePicker.MonthPicker value={moment(`${this.state.year}/${this.state.month + 1}`, monthFormat)} format={monthFormat}
                style={{ textAlign: "center", width: "280px" }} placeholder="" suffixIcon={<div></div>}
                onChange={(date, dateString) => this.setstate({ year: new Date(date._d).getFullYear(), month: new Date(date._d).getMonth() })} allowClear={false}
                monthCellContentRender={(date, locale) => {
                  return <div className="ant-calendar-month-panel-month">{date._d.getMonth() + 1}</div>
                }} />
            </div>
          </div>
        </div>


        <div style={{ display: "table-row" }}> {/* content */}
          <div style={{ display: "table-cell" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div id="record-tab-container" style={{ position: "absolute", top: "0", bottom: "0", left: "0", right: "0" }}
               onMouseDown={e => this.setstate({ pointer_x_start: e.clientX, pointer_time_start: new Date() })} onMouseLeave={this._slide} onMouseUp={this._slide}
               onTouchStart={e =>this.setstate({ pointer_x_start: e.changedTouches[0].clientX, pointer_time_start: new Date() })} onTouchEnd={this._slide}>
                <Tabs activeKey={this.state.tab_key} renderTabBar={() => (<div />)}>
                  <Tabs.TabPane tab="Tab 1" key="1">
                    <div style={{ padding: "0 1rem", userSelect: "none", height: "100%" }}>
                      <TransferList year={this.state.year} month={this.state.month} />
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Tab 2" key="2">
                    <div style={{ padding: "0 1rem", userSelect: "none", height: "100%" }}>
                      <FillingList year={this.state.year} month={this.state.month} />
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Tab 3" key="3">
                    <div style={{ padding: "0 1rem", userSelect: "none", height: "100%" }}>
                      <ExchangeList year={this.state.year} month={this.state.month} />
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Loading>
    )
  }        



  _slide (e) {
    const { pointer_x_start, pointer_time_start, tab_key } = this.state
    if (pointer_x_start === void 0)
      return;

    // console.log('width', document.documentElement.clientWidth);
    // console.log('height', document.documentElement.clientHeight);

    if (e.clientX === void 0 && e.changedTouches[0].clientX === void 0)
      return;

    const offset = (e.clientX || e.changedTouches[0].clientX) - pointer_x_start;

    const duration = new Date() - pointer_time_start;
    const speed = offset/duration;

    const trigger_speed = (checkers.is_mobile === false)? 1.3: 0.8;


    if (speed < (trigger_speed * -1))
      return this.setstate({
        tab_key: String(Math.min(3, (Number(tab_key) + 1))),
        pointer_x_start: void 0,
        pointer_time_start: void 0
      });

    if (speed > trigger_speed)
      return this.setstate({
        tab_key: String(Math.max(1, (Number(tab_key) - 1))),
        pointer_x_start: void 0,
        pointer_time_start: void 0
      })

    return this.setstate({
      pointer_x_start: void 0,
      pointer_time_start: void 0
    });
  }
}

export default Records;