import React from 'react';
import {  Button, Typography, Icon, Row, Col, Drawer, Modal, Tabs } from 'antd';
import Big from 'big.js';
import query_string from 'query-string';
//* tabs
import Token2MoneyTab  from './exchange/Token2MoneyTab';
import Token2TokenTab  from './exchange/Token2TokenTab';
//* view components
import Loading from '../../snippets/Loading';
import { NumericKeyboard } from 'numeric-keyboard/dist/numeric_keyboard.react';
import NumericKeyboardLayout from '../../snippets/NumericKeyboardLayout';
import InputOtpPc from '../../snippets/InputOtpPc';
//* utils
import cache        from '../../utils/cache';
import checkers     from '../../utils/checkers';
import common       from '../../utils/common';
import eos_wallet   from '../../utils/eos_wallet';
import server_api   from '../../utils/server_api';
import state_util   from '../../utils/state_util';
import storage_util from '../../utils/storage_util';
//* resources
import lang from '../../lang/lang';




class Exchange extends React.Component {
  is_mounted_ = false;
  state = {
    is_loading: false,
    is_result_caculating: false,
    is_submitting: false,
    exchange_tab_key: '1',
    bank_account: {},
    global_vars: {
      token_to_money_commission_rate: { },
      token_to_money_min_max_amount : { },
      token_to_token_commission_rate: { },
      token_to_token_min_max_amount : { },
    },
    limit_decimal_point_t2m: 0,
    limit_decimal_point_t2t: 0,
    value_to_exchange: '0',
    currency_symbol: ``,
    token_symbol: ``,
    token_from_symbol: ``,
    token_to_symbol: ``,
    country_code: '',
    user_accounts: [],
    selected_exchanger: {},
    exchange_type: 'BY_BANK_ACCOUNT',
    log: '',
    password: '',
    visible_input_otp_drawer: false,
    visible_input_otp_pc_modal: false,

    token_to_add: void 0,
    commission: void 0,
    result: void 0,
  }

  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    if (await common.isLoggedIn() === false) {
      return;
    }

    let stored_state = storage_util.loadFromSession(state_util.ENUM.exchange_state, void 0);
    if (stored_state.exchange_tab_key === '2') {
      this.setstate({ exchange_tab_key: '2' });
    }
    
    /* data_country_codes */
    const data_codes        = await this._cacheCodes();
    const selected_exchanger = await this._fetchExchangersRegistered();
    
    /* bind country codes for token_to_money */
    let { currency_symbol, token_symbol, country_code } = this.state;
    if ((selected_exchanger || {}).country_code) {
      const exchanger_codes = (data_codes.filter(datum => datum.country_code === selected_exchanger.country_code))[0];

      country_code    = exchanger_codes.country_code
      currency_symbol = exchanger_codes.currency_symbol;
      token_symbol    = exchanger_codes.token_symbol
    }
    const limit_decimal_point_t2m = (data_codes.filter(datum => datum.country_code === country_code)[0] || {}).limit_decimal_point || 0;


    /* bind country codes for token_to_token */
    const users_country_data = data_codes.filter(datum => datum.country_code === common.get().country_code)[0] || {};
    const token_from_symbol = users_country_data.token_symbol || '';
    const limit_decimal_point_t2t = users_country_data.limit_decimal_point || 0;
    
    const user_accounts = await this._fetchBalance();

    const global_vars = await this._cacheGlobalVars();


    let local_saved_bank = storage_util.loadFromLocal('user_last_bank', void 0);
    let bank_account = {};
    if (local_saved_bank) {
      bank_account = await this._fetchLocalBankAccount(local_saved_bank.id);
    }


    // let stored_state = storage_util.loadFromSession(state_util.ENUM.exchange_state, void 0);    
    state_util.clearEveryStates();
    stored_state = this._validateStateDecimal(stored_state, limit_decimal_point_t2m);

    this.setstate({      
      is_loading: false,
      header_text: await eos_wallet.getHeaderTokenText() || '',
      data_codes,
      currency_symbol,
      token_symbol,
      token_from_symbol,
      country_code,
      user_accounts,
      global_vars,
      limit_decimal_point_t2m,
      limit_decimal_point_t2t,
      selected_exchanger,

      bank_account,
      ...stored_state,
    });

    if (this.state.exchange_tab_key === '2')
      await this._previewResultT2T();
  }
  componentWillUnmount() { this.is_mounted_ = false; }



  render () {
    if (common.get().token === void 0) {
      return null;
    }
    
    return (
      <Loading is_loading={this.state.is_loading || this.state.is_submitting} overall={true} style={{ display: "table", tableLayout: "fixed", width: "100%", height: "100%" }}>
        {/* Header */}
        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>

             {/** HEADER *****/}
             <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  onClick={e => this.props.history.push('/wallet')}
                  style={{
                    flex: "1 1 0px", borderRadius: "4px", padding: "0.2rem 1rem"
                  , display: "block", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"
                  , fontSize: "1rem", color: "#fff", background: "rgb(51, 129, 252)", cursor: 'pointer'
                }} >
                {this.state.header_text}&nbsp;
              </div>
              <div style={{ flex: "0 0 auto", padding: "0 0 0 0.5rem"}}>
                  <Button type="link" style={{ padding: "0", height: "2.5rem" }}
                    onClick={e => this.setstate({ visible_redirect_drawer: true }) }>
                    <Icon type="plus" style={{ fontSize: "1.5rem", color: "RGB(75, 75, 75)" }} />
                  </Button>
                  <Drawer
                    title={<div style={{ fontWeight: "700" }}><Typography.Text>{lang.get('drawer/record_redirecter/title')}</Typography.Text></div>}
                    placement="bottom"
                    closable={true}
                    onClose={e => this.setstate({ visible_redirect_drawer: false })}
                    visible={this.state.visible_redirect_drawer}
                    height="bottom"
                    bodyStyle={{ padding: "0.5rem" }}>
                    <div style={{ textAlign: "left"}}>
                      <Button type="link" size="large" style={{ width: "100%", textAlign: "left"}} onClick={e => this._redirectTo('/wallet/record', { tab_key: '1' })}>
                        <Icon type="profile" /> <Typography.Text>{lang.get('drawer/record_redirecter/transfer_list')}</Typography.Text>
                      </Button>
                      <Button type="link" size="large" style={{ width: "100%", textAlign: "left"}} onClick={e => this._redirectTo('/wallet/record', { tab_key: '2' })}>
                        <Icon type="profile" /> <Typography.Text>{lang.get('drawer/record_redirecter/filling_list')}</Typography.Text>
                      </Button>
                      <Button type="link" size="large" style={{ width: "100%", textAlign: "left"}} onClick={e => this._redirectTo('/wallet/record', { tab_key: '3' })}>
                        <Icon type="profile" /> <Typography.Text>{lang.get('drawer/record_redirecter/exchange_list')}</Typography.Text>
                      </Button>
                    </div>
                  </Drawer>
                </div>
              </div>
            </div>
            {/***** HEADER END **/}
          </div>
        </div>

        <div style={{ display: "table-row", height: `6%`}}>
        </div>

        <div style={{ display: "table-row"}}> {/* content */}
          <div style={{ display: "table-cell", height: "100%" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div style={{ top: "0", bottom: "0", left: "0", right: "0", position: "absolute", overflow: "auto"}}>
                <Tabs size="large" tabBarStyle={{ display: "flex", justifyContent: "center", color: "rgb(150,150,150)" }} activeKey={this.state.exchange_tab_key} 
                  onChange={key => {
                    let optional_params = {};
                    if (key === '1') {
                      optional_params = {
                        token_to_symbol: ``,
                      };
                    } else if (key === '2') {
                      optional_params = {};
                    }

                    this.setstate(Object.assign({}, {
                      exchange_tab_key: key,
                      value_to_exchange: '0',
                      exchange_type: 'BY_BANK_ACCOUNT',
                      log: '',
                    }, optional_params));
                  }}>
                  <Tabs.TabPane tab={<div style={{ fontSize: "1.2rem", fontWeight: "700" }}>{lang.get('exchange/tabs/title_token_to_money')}</div>} key="1">
                    <Token2MoneyTab
                      value_to_exchange={this.state.value_to_exchange}
                      value_max={this.state.global_vars.token_to_money_min_max_amount.value2}
                      limit_decimal_point={this.state.limit_decimal_point_t2m}
                      onChangeValueToExchange={value => this.setstate({ value_to_exchange: eos_wallet.unFormatCommaNumber(value) })}
                      onPressExchangeValue={this._hNumericKeyboard.bind(this)}
                      _hAddValue={this._hAddValue.bind(this)}
                      selected_exchanger={this.state.selected_exchanger}
                      onClickRedirectToSelectExchanger={e => this._redirectTo("/all/location")}
                      user_accounts={this.state.user_accounts}
                      onClickToken={this._hChangeTokenSymbol.bind(this)}
                      currency_symbol={this.state.currency_symbol}
                      token_symbol={this.state.token_symbol}
                      exchange_type={this.state.exchange_type}
                      onChangeExchangeType={this._hSelectExchangeType.bind(this)}
                      onClickExchangeType={this._hSelectExchangeType.bind(this)}
                      bank_account={this.state.bank_account}
                      onClickAccount={value => this.setstate({ bank_account: value, })}
                      log={this.state.log}
                      onChangeMemo={e => this.setstate({ log: e.target.value })}
                      commission_rate={String(Number(this.state.global_vars.token_to_money_commission_rate.value || 0)*100)}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={<div style={{ fontSize: "1.2rem", fontWeight: "700" }}>{lang.get('exchange/tabs/title_token_to_token')}</div>} key="2">
                    <Token2TokenTab
                      value_to_exchange={this.state.value_to_exchange}
                      value_max={this.state.global_vars.token_to_token_min_max_amount.value2}
                      limit_decimal_point={this.state.limit_decimal_point_t2t}
                      onChangeValueToExchange={value => { this.setstate({ value_to_exchange: value })}}
                      onPressExchangeValue={this._hNumericKeyboard.bind(this)}
                      _hAddValue={this._hAddValue.bind(this)}
                      selected_exchanger={this.state.selected_exchanger}
                      onClickRedirectToSelectExchanger={e => this._redirectTo("/all/location")}
                      user_accounts={this.state.user_accounts}
                      onClickFromToken={value => {
                        const default_params = {
                          value_to_exchange: '0',
                          limit_decimal_point_t2t: (((this.state.data_codes).filter(datum => datum.token_symbol === value))[0] || {}).limit_decimal_point || 0,
                          token_from_symbol: value
                        };
                        let conditional_params = {};
                        if (this.state.token_to_symbol === value) {
                          conditional_params = { token_to_symbol: '' };
                        }
                        this.setState(Object.assign({}, default_params, conditional_params));
                      }}
                      onClickToToken={value => this.setState({ token_to_symbol: value })}
                      token_from_symbol={this.state.token_from_symbol}
                      token_to_symbol={this.state.token_to_symbol}
                      log={this.state.log}
                      onChangeMemo={e => this.setstate({ log: e.target.value })}
                      commission_rate={String(Number(this.state.global_vars.token_to_token_commission_rate.value || 0)*100)}
                      previewResult={this._previewResultT2T.bind(this)}
                      token_to_add={this.state.token_to_add}
                      commission={this.state.commission}
                      result={this.state.result}
                      is_result_caculating={this.state.is_result_caculating}
                      redirectTo={this._redirectTo.bind(this)}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>       


        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell", padding: "1rem 1rem"}}>
            <div>
              <Button size="large" type="danger" style={{ width: "100%", fontWeight: "700", color: "#fff", background: "rgb(255, 92, 92)" }} onClick={this._hPreSubmit.bind(this)}>
              {lang.get('exchange/btn_submit')}
              </Button>

              <Drawer
                title={<div style={{ fontWeight: "700" }}><Typography.Text>{lang.get('drawer/input_otp/title')}</Typography.Text></div>}
                placement="bottom"
                closable={true}
                onClose={e => this.setstate({
                  visible_input_otp_drawer: false,
                  password: '',
                  error_msg: ''
                })}
                visible={this.state.visible_input_otp_drawer}
                height="bottom"
              >
                <div style={{ height: "100%"}}>
                  <div style={{ display: "flex", justifyContent: "center", padding: "0 0 1rem" }}>
                    {this._rPassword()}
                  </div>
                  <NumericKeyboard layout={NumericKeyboardLayout.OTP} onPress={this._hOTPKeyboard.bind(this)} entertext={lang.get('exchange/btn_otp_submit')} />
                </div>
              </Drawer>
              <Modal
                  title={<div style={{ fontWeight: "700" }}><Typography.Text>{lang.get('drawer/input_otp/title')}</Typography.Text></div>}
                  footer={null}
                  visible={this.state.visible_input_otp_pc_modal}
                  onCancel={e => this.setstate({ visible_input_otp_pc_modal: false })}
                  width="320px"
                  centered
                >
                <InputOtpPc
                  password={this.state.password}
                  onChange={v => this.setstate({ password: v })}
                  button_text={lang.get('exchange/btn_otp_submit')}
                  onSubmit={this._hSubmit.bind(this)} />
              </Modal>
            </div>
          </div>
        </div>
    </Loading>)
  }        

  _rPassword () {
    let pw_box = [];
    let { password } = this.state;
    const pw_length = (password || '').length;

    for(let i = 0; i < 6; ++i) {
      if (i < pw_length)
        pw_box.push(
          <Col key={i} span={4} style={{ textAlign: "center" }}>
            ●
          </Col>
        )
      else
        pw_box.push(
          <Col key={i} span={4} style={{ textAlign: "center", color: "RGBA(192, 192, 192, 0.5)"}}>
            ●
          </Col>
        )
    }

    return (
      <Row style={{ width: "12rem", borderRadius: "4px" }}>
        {pw_box}
      </Row>);    
  }

  // _hSelectExchangeType (e) {
  //   if (e.target.value === 'BY_BANK_ACCOUNT') {
  //     this.setstate({ exchange_type: e.target.value })
  //   }
  //   else if (e.target.value === 'BY_OFFLINE_EXCHANGER') {
  //     this.setstate({ 
  //       bank_account: {},
  //       exchange_type: e.target.value
  //     })
  //   }
  //   else {
  //     console.log('unexpected radio value added')
  //   }
  // }

  _hSelectExchangeType (value) {
    if (value === 'BY_BANK_ACCOUNT') {
      this.setstate({ exchange_type: value })
    }
    else if (value === 'BY_OFFLINE_EXCHANGER') {
      this.setstate({ 
        exchange_type: value
      })
    }
    else {
      console.error('unexpected radio value added')
    }
  }

  _hAddValue(number_to_add) {
    let new_number = new Big(this.state.value_to_filling).plus(number_to_add);
    let max_number = (this.state.exchange_tab_key === '1') ? Number(this.state.global_vars.token_to_money_min_max_amount.value2) : Number(this.state.global_vars.token_to_token_min_max_amount.value2)
    if (new_number > max_number) { // maximum for devices with width: 320px
      new_number = max_number;
    }
    this.setstate({ value_to_exchange: String(new_number) });
  }


  _hNumericKeyboard (key) {
    let { value_to_exchange } = this.state;
    
    if (!value_to_exchange)
      value_to_exchange = '';

    switch (key) {
      case 'esc' :
        return this.setstate({
          error_msg: '',
          visible_keyboard_drawer: false
        });
      case 'del' :
        let new_value_del = value_to_exchange.slice(0, -1);
        if (new_value_del === '')
          new_value_del = '0';
        return this.setstate({          
          error_msg: '',
          value_to_exchange: new_value_del
        });
      case 'enter' :
        if (value_to_exchange[value_to_exchange.length - 1] === '.')
          value_to_exchange = value_to_exchange.substring(0, value_to_exchange.length - 1);
        this.setstate({
          value_to_exchange,
          error_msg: '',
        });

        if (this.state.exchange_tab_key === '2')
          this._previewResultT2T();

        return;
      case '.' :
          if (value_to_exchange === '') {
            return this.setstate({
              error_msg: '',
              value_to_exchange: '0.'
            });
          }
  
          for (let i = 0; i < value_to_exchange.length; ++i) {
            if (value_to_exchange[i] === '.')
              return;
          }
  
          return this.setstate({
            error_msg: '',
            value_to_exchange: value_to_exchange.concat(key)
          });

      default :
        if (value_to_exchange === '0') {
          value_to_exchange = '';
        }
        let limit_decimal_point;
        switch (this.state.exchange_tab_key) {
          case '1':
            limit_decimal_point = this.state.limit_decimal_point_t2m;
            break;
          case '2':
            limit_decimal_point = this.state.limit_decimal_point_t2t;
            break;
          default:
            console.error('exception case');
            break;
        }
        const new_value_default = value_to_exchange.concat(key);
        for (let i = new_value_default.length - 1, count = 0; i >= 0; --i) {
          ++count;

          if (new_value_default[i] === '.') {
            if (count - 1 > limit_decimal_point)
              return;
          }

          if (count - 1 > 8) { //! EOS MAXIMUM DECIMAL = 4 (2019.8)
            break;
          }
        }


        if (this.state.exchange_tab_key === '1') {
          if (Number(new_value_default) < Number(this.state.global_vars.token_to_money_min_max_amount.value2 || '0'))
            return this.setstate({            
              error_msg: '',
              value_to_exchange: new_value_default
            });
          else
            return this.setstate({            
              error_msg: '',
              value_to_exchange: this.state.global_vars.token_to_money_min_max_amount.value2 || '0'
            });
        }
        else {
          if (Number(new_value_default) < Number(this.state.global_vars.token_to_token_min_max_amount.value2 || '0'))
            return this.setstate({            
              error_msg: '',
              value_to_exchange: new_value_default
            });
          else
            return this.setstate({            
              error_msg: '',
              value_to_exchange: this.state.global_vars.token_to_token_min_max_amount.value2 || '0'
            });
        }
    }
  }





  _hChangeTokenSymbol (token_info) {
    const { selected_exchanger, exchange_tab_key } = this.state;
    if (exchange_tab_key === '1' && selected_exchanger.id) {
      if (selected_exchanger.country_code !== token_info.country_code) {
        const modal = Modal.confirm({
          title: lang.get('exchange/modal_case1/title'),
          content: lang.get('exchange/modal_case1/content'),
          okText: lang.get('modal/ok_text'),
          cancelText: lang.get('modal/cancel_text'),
          onOk: e => {
            modal.destroy();
            this._redirectTo('/all/location');
          },
          onCancel() {
            
          },
          centered: true
        });
        return;
      }
    }

    if (exchange_tab_key === '1') {
      this.setstate({
        token_symbol: token_info.token_symbol,
        currency_symbol: token_info.currency_symbol,
        country_code: token_info.country_code,
      });
    }
    else if (exchange_tab_key === '2') {
      this.setstate({
        token_symbol: token_info.token_symbol,
      });
    }
    
  }


  async _hChangeTokenToSymbol (token_info) {
    this.setstate({
      token_to_symbol: token_info.token_symbol,
    });
  }
 


  async _hOTPKeyboard (key) {
    let { password } = this.state;
    
    if (!password)
      password = '';

    switch (key) {
      case 'esc' :
        return this.setstate({
          error_msg: '',
          password: '',
          visible_input_otp_drawer: false
        });
      case 'del' :
        return this.setstate({          
          error_msg: '',
          password: password.slice(0, -1)
        });
      case 'enter' :
        await this._hSubmit();
        break;
      default :
        if (password.length < 6)
          return this.setstate({            
            error_msg: '',
            password: password.concat(key)
          });
    }
  }


  //* transactions
  // 화폐환전시까지 적용할 경우
  // async _previewResult () {
  //   switch (this.state.exchange_tab_key) {
  //     case '1':
  //       await this._previewResultT2M();
  //       break;
  //     case '2':
  //       await this._previewResultT2T();
  //       break;
  //   }

  // }


  // async _previewResultT2M () {    
  //   if (this.state.is_result_caculating === true) {
  //     return;
  //   }

  //   this.setstate({ is_result_caculating: true });

    
  //   let has_error = false;

  //   const { value_to_exchange, token_symbol, global_vars } = this.state;

  //   if (value_to_exchange === '' || value_to_exchange === '0') {
  //     has_error = true;
  //   }
  //   if (!token_symbol) {
  //     has_error = true;
  //   }
  //   if (!global_vars.token_to_money_commission_rate.value) {
  //     has_error = true;
  //   }

  //   if (has_error === true) {
  //     return this.setstate({
  //       is_result_caculating: false,
  //       token_to_add: void 0,
  //       result: void 0,
  //     });
  //   }
    
  //   const balance_of_token = (this.state.user_accounts).reduce((p, c, i, a) => {
  //     if (c.name === this.state.token_symbol) {
  //       return p = c.value;
  //     }
  //     return p
  //   }, 0);

    
  //   let token_to_add;
  //   try {
  //     const params = {
  //       token_symbol,
  //       token_amount: value_to_exchange,
  //       commission_rate: this.state.global_vars.token_to_money_commission_rate.value // get from global_vars
  //     }

  //     token_to_add = (await server_api.readExchangeRatesTokenToMoney(params)).items;

  //     console.log('test_token_to_add', token_to_add);
  //     console.log('balance_of_token', balance_of_token);
  //   }
  //   catch (e) {
  //     if (process.env.NODE_ENV === 'development') {
  //       console.error(e);
  //     }

  //     Modal.error({
  //       title: '환전 결과 예상 요청 실패',
  //       content: `통신에러로 환전 결과 예상에 실패했습니다. 잠시 후 다시 시도해주세요`,
  //       okText: lang.get('modal/confirm_text'),
  //       centered: true
  //     });

  //     return this.setstate({
  //       is_result_caculating: false,
  //       token_to_add: void 0,
  //       result: void 0
  //     });
  //   }


  //   return this.setstate({
  //     is_result_caculating: false,
  //     token_to_add: String(Number(token_to_add[0])),
  //     result: String(Big(balance_of_token).minus(token_to_add[0])),
  //   });
  // }


  async _previewResultT2T () {
    // if (this.state.is_result_caculating === true) {
    //   return;
    // }

    this.setstate({ is_result_caculating: true });


    let has_error = false;

    const { value_to_exchange, token_from_symbol, token_to_symbol, global_vars } = this.state;

    if (value_to_exchange === '' || value_to_exchange === '0') {
      has_error = true;
    }
    if (!token_from_symbol) {
      has_error = true;
    }    
    if (!token_to_symbol) {
      has_error = true;
    }
    if (!global_vars.token_to_token_commission_rate.value) {
      has_error = true;
    }

    if (has_error === true) {
      return this.setstate({
        is_result_caculating: false,
        token_to_add: void 0,
        result: void 0,
      });
    }

    const balance_of_token = (this.state.user_accounts).reduce((p, c, i, a) => {
      if (c.name === this.state.token_to_symbol) {
        return p = c.value;
      }
      return p
    }, 0);


    let res_token_to_token_items;
    try {
      const params = {
        from_amount: value_to_exchange,
        from_token_symbol: token_from_symbol,
        to_token_symbol: token_to_symbol,
        commission_rate: this.state.global_vars.token_to_token_commission_rate.value,// get from global_vars
      }

      res_token_to_token_items = (await server_api.readExchangeRatesTokenToToken(params)).items;
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      Modal.error({
        title: lang.get('exchange/modal_case24/title'),
        content: lang.get('exchange/modal_case24/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });

      return this.setstate({
        is_result_caculating: false,
        token_to_add: void 0,
        commission: void 0,
        result: void 0
      });
    }


    return this.setstate({
      is_result_caculating: false,
      token_to_add: String(Number(res_token_to_token_items[0])),
      commission: String(Number(res_token_to_token_items[1])),
      result: String(Big(balance_of_token).plus(res_token_to_token_items[0])),
    });
  }



  _hPreSubmit() {
    if (this.state.exchange_tab_key === '1') {
      return this._hPreSubmitToken2Money();
    }
    else if (this.state.exchange_tab_key === '2') {
      return this._hPreSubmitToken2Token();
    }
  }


  async _hPreSubmitToken2Money () {
    const { value_to_exchange } = this.state;

    this.setstate({ is_submitting: true });
    const is_blank = eos_wallet.validate.checkBlank(this.state.value_to_exchange);
    if (is_blank) {
      Modal.error({
        title: lang.get('exchange/modal_case2/title'),
        content: lang.get('exchange/modal_case2/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return this.setstate({ is_submitting: false });
    }

    if (this.state.token_symbol === '' || this.state.currency_symbol === '') {
      Modal.error({
        title: lang.get('exchange/modal_case22/title'),
        content: lang.get('exchange/modal_case22/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return this.setstate({ is_submitting: false });
    }

    if (Number(value_to_exchange) < Number(this.state.global_vars.token_to_money_min_max_amount.value)) {
      Modal.error({
        title: lang.get('exchange/modal_case3/title'),
        content: lang.get('exchange/modal_case3/content', { min_value: this.state.global_vars.token_to_money_min_max_amount.value }),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return this.setstate({ is_submitting: false });
    }

    if (!this.state.selected_exchanger.id) {
      Modal.error({
        title: lang.get('exchange/modal_case4/title'),
        content: lang.get('exchange/modal_case4/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return this.setstate({ is_submitting: false });
    }

    if (this.state.selected_exchanger.country_code !== this.state.country_code) {
      Modal.error({
        title: lang.get('exchange/modal_case5/title'),
        content: lang.get('exchange/modal_case5/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return this.setstate({ is_submitting: false });
    }

    try {      
      const res_balance = await server_api.readUsersAccount({}, {type: 'object'});
      if (Number(this.state.value_to_exchange) > res_balance[this.state.token_symbol] || 0) {
        Modal.error({
          title: lang.get('exchange/modal_case6/title'),
          content: lang.get('exchange/modal_case6/content'),
          okText: lang.get('modal/confirm_text'),
          centered: true
        });
        return this.setstate({ is_submitting: false });
      }
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      Modal.error({
        title: lang.get('exchange/modal_case7/title'),
        content: lang.get('exchange/modal_case7/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return this.setstate({ is_submitting: false });
    }

    if (this.state.exchange_type === 'BY_BANK_ACCOUNT' && !this.state.bank_account.id) {
      Modal.error({
        title: lang.get('exchange/modal_case8/title'),
        content: lang.get('exchange/modal_case8/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return this.setstate({ is_submitting: false });
    }

    //* Success! go to OTP-input and then _hSubmit()
    if (checkers.is_mobile === true)
      this.setstate({ visible_input_otp_drawer: true, is_submitting: false });
    else
      this.setstate({ visible_input_otp_pc_modal: true, is_submitting: false });
  }




  async _hPreSubmitToken2Token () {
    if (this.state.is_result_caculating === true) {
      Modal.error({
        title: lang.get('exchange/modal_case25/title'),
        content: lang.get('exchange/modal_case25/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });

      return;
    }


    const { value_to_exchange } = this.state;
    this.setstate({ is_submitting: true });
    const is_blank = eos_wallet.validate.checkBlank(this.state.value_to_exchange);
    if (is_blank) {
      Modal.error({
        title: lang.get('exchange/modal_case9/title'),
        content: lang.get('exchange/modal_case9/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return this.setstate({ is_submitting: false });
    }
    if (this.state.token_from_symbol === '' || this.state.token_to_symbol === '') {
      Modal.error({
        title: lang.get('exchange/modal_case23/title'),
        content: lang.get('exchange/modal_case23/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return this.setstate({ is_submitting: false });
    }


    if (Number(value_to_exchange) < Number(this.state.global_vars.token_to_token_min_max_amount.value)) {
      Modal.error({
        title: lang.get('exchange/modal_case10/title'),
        content: lang.get('exchange/modal_case10/content', { min_value: this.state.global_vars.token_to_token_min_max_amount.value }),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return this.setstate({ is_submitting: false });
    }

    if (!this.state.selected_exchanger.id) {
      Modal.error({
        title: lang.get('exchange/modal_case11/title'),
        content: lang.get('exchange/modal_case11/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return this.setstate({ is_submitting: false });
    }

    try {      
      const res_balance = await server_api.readUsersAccount({}, {type: 'object'});
      if (Number(this.state.value_to_exchange) > res_balance[this.state.token_from_symbol] || 0) {
        Modal.error({
          title: lang.get('exchange/modal_case12/title'),
          content: lang.get('exchange/modal_case12/content'),
          okText: lang.get('modal/confirm_text'),
          centered: true
        });
        return this.setstate({ is_submitting: false });
      }
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      Modal.error({
        title: lang.get('exchange/modal_case13/title'),
        content: lang.get('exchange/modal_case13/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return this.setstate({ is_submitting: false });
    }

    // let res_expect_result;
    // try {
    //   res_expect_result = (await server_api.readExchangeRatesTokenToToken({
    //     from_token_symbol: this.state.token_from_symbol,
    //     from_amount      : this.state.value_to_exchange,
    //     to_token_symbol  : this.state.token_to_symbol,
    //     commission_rate  : this.state.global_vars.token_to_token_commission_rate.value, // get from global_vars
    //   })).items[0];

    //   if (Number(res_expect_result) < 1) {
    //     Modal.error({
    //       title: lang.get('exchange/modal_case14/title'),
    //       content: lang.get('exchange/modal_case14/content'),
    //       okText: lang.get('modal/confirm_text'),
    //       centered: true
    //     });
    //     return this.setstate({ is_submitting: false });
    //   }
    // }
    // catch (e) {
    //   if (process.env.NODE_ENV === 'development') {
    //     console.error(e);
    //   }

    //   Modal.error({
    //     title: lang.get('exchange/modal_case15/title'),
    //     content: lang.get('exchange/modal_case15/content'),
    //     okText: lang.get('modal/confirm_text'),
    //     centered: true
    //   });
    //   return this.setstate({ is_submitting: false });
    // }


    //* Success! go to OTP-input and then _hSubmit()
    if (checkers.is_mobile === true)
      this.setstate({
        visible_input_otp_drawer: true,
        is_submitting: false });
    else
      this.setstate({
        visible_input_otp_pc_modal: true,
        is_submitting: false });
  }





  _hSubmit () {
    if (this.state.is_submitting === true || this.state.is_loading === true)
      return;
    
    this.setstate({
      is_submitting: true,
      visible_input_otp_drawer: false,
      visible_input_otp_pc_modal: false,
    });


    if (this.state.exchange_tab_key === '1') {
      return this._hSubmitToken2Money();
    }
    else if (this.state.exchange_tab_key === '2') {
      return this._hSubmitToken2Token();
    }
  }
  
  async _hSubmitToken2Money() {    
    try {
      let params = {
        user_id                 : common.get().id,
        exchanger_id            : this.state.selected_exchanger.id,
        exchange_type           : this.state.exchange_type,
        // user_bankaccount_id     : { type: Sequelize.INTEGER, allowNull: true, },
        exchanger_bankaccount_id: this.state.selected_exchanger.bankaccounts[0].id,
        token_symbol            : this.state.token_symbol,
        token_amount            : this.state.value_to_exchange,
        // money_amount            : { type: Sequelize.STRING(30), allowNull: false, },
        status                  : 'START',
        log                     : this.state.log,
        otp_code                : this.state.password,
      }
      if (this.state.exchange_type === 'BY_BANK_ACCOUNT' && this.state.bank_account.id) {
        params.user_bankaccount_id = this.state.bank_account.id;
      } else if (this.state.exchange_type === 'BY_OFFLINE_EXCHANGER') {
        params.user_bankaccount_id = null;
      }

      const res_exchanger = (await server_api.readExchangersSafe({
        where: {
          id: this.state.selected_exchanger.id,
          is_archived: false,
        },
        attributes: ['id', 'name'],
        include: [{
          association: 'bankaccounts',
          where: {
            is_archived: false,
          },
          required: true,
          attributes: ['id', 'bank_account'],
          include: [{ association: 'bank', attributes: ['name'] }]
        }]
        // attributes: ['name']
      })).items[0];

      if (((res_exchanger || {}).bankaccounts || []).length === 0) {
        Modal.error({
          title: lang.get('exchanger/modal_case26/title'),
          content: lang.get('exchanger/modal_case26/content'),
          okText: lang.get('modal/confirm_text'),
          centered: true
        });
        return this.setstate({
          password: '',
          error_msg: '',
          is_submitting: false
        });
      }      
      
      const res_item = (await server_api.createUsersTokenToMoney(params)).items[0];

      if (res_item.id) {
        // await new Promise(resolve => setTimeout(resolve, 100)); // OTP drawer 클릭시 '전체' 메뉴 클릭되는 문제 방지용 코드. 재발시 사용해 볼 것        
        storage_util.saveToLocal('user_last_bank', { id: this.state.bank_account.id });

        let res_user_bankaccount = {};
        if (res_item.exchange_type === "BY_BANK_ACCOUNT") {
          res_user_bankaccount = (await server_api.fetchUserBankAccounts({
            where: {
              id: res_item.user_bankaccount_id
            },
            attributes: ['id', 'bank_account', 'owner_name'],
            include: [
              {
                association: 'bank',
                attributes: ['id', 'name'],
                where: {
                  status: 'AVAILABLE'
                }                
              }
            ]
          })).items[0];
        }

        storage_util.saveToSession(state_util.ENUM.exchange_state, {
          commission_amount : res_item.commission_amount,
          commission_rate   : res_item.commission_rate,
          token_amount      : res_item.token_amount,
          token_symbol      : res_item.token_symbol,
          money_amount      : res_item.money_amount,
          money_symbol      : this.state.currency_symbol, //! res 값에서 받아오지 않음 주의
          exchange_type     : res_item.exchange_type,
          exchanger         : res_exchanger,
          user_bankaccount  : res_user_bankaccount,
          its_result: true,
          result_type: 'token_to_money',
        });

        return this.props.history.push({
          pathname: '/exchange/result',
          search: query_string.stringify({ type: 'tokentomoney'}),
        });
      } else {
        Modal.error({
          title: lang.get('exchange/modal_case17/title'),
          content: lang.get('exchange/modal_case17/content'),
          okText: lang.get('modal/confirm_text'),
          centered: true
        });
        return this.setstate({
          password: '',
          error_msg: '',
          is_submitting: false
        });
      }
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      if ((e.result || {}).message === 'opt-code not matched. please try again.') {
        Modal.error({
          title: lang.get('exchange/modal_case16/title'),
          content: lang.get('exchange/modal_case16/content'),
          okText: lang.get('modal/confirm_text'),
          centered: true
        });
        return this.setstate({
          password: '',
          error_msg: '',
          is_submitting: false
        })
      }


      Modal.error({
        title: lang.get('exchange/modal_case18/title'),
        content: lang.get('exchange/modal_case18/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return this.setstate({
        password: '',
        error_msg: '',
        is_submitting: false
      });
    }
  }

  
  async _hSubmitToken2Token() {
    try {
      let params = {
        user_id           : common.get().id,
        exchanger_id      : this.state.selected_exchanger.id,
        from_token_symbol : this.state.token_from_symbol,
        from_token_amount : this.state.value_to_exchange,
        to_token_symbol   : this.state.token_to_symbol,
        status            : 'START',
        log               : this.state.log,
        otp_code          : this.state.password,
      }

      const res_item = (await server_api.createUsersTokenToToken(params)).items[0];

      if (res_item.id) {
        // await new Promise(resolve => setTimeout(resolve, 100));

        const res_exchanger = (await server_api.readExchangersSafe({
          where: {
            id: res_item.exchanger_id,
            is_archived: false,
          },
          attributes: ['name']
        })).items[0];

        storage_util.saveToSession(state_util.ENUM.exchange_state, {
          commission_amount : res_item.commission_amount,
          commission_rate   : res_item.commission_rate,
          exchanger         : res_exchanger,
          from_token_amount : res_item.from_token_amount,
          from_token_symbol : res_item.from_token_symbol,
          log               : res_item.log,
          to_token_amount   : res_item.to_token_amount,
          to_token_symbol   : res_item.to_token_symbol,
          its_result: true,
          result_type: 'token_to_token',
        });

        return this.props.history.push({
          pathname: '/exchange/result',
          search: query_string.stringify({ type: 'tokentotoken'}),
        });
      } else {        
        Modal.error({
          title: lang.get('exchange/modal_case19/title'),
          content: lang.get('exchange/modal_case19/content'),
          okText: lang.get('modal/confirm_text'),
          centered: true
        });
        return this.setstate({
          password: '',
          error_msg: '',
          is_submitting: false
        });
      }
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      if ((e.result || {}).message === 'opt-code not matched. please try again.') {
        Modal.error({
          title: lang.get('exchange/modal_case16/title'),
          content: lang.get('exchange/modal_case16/content'),
          okText: lang.get('modal/confirm_text'),
          centered: true
        });
        return this.setstate({
          password: '',
          error_msg: '',
          is_submitting: false
        })
      }


      Modal.error({
        title: lang.get('exchange/modal_case20/title'),
        content: lang.get('exchange/modal_case20/content'),
        okText: lang.get('modal/confirm_text'),
        centered: true
      });
      return this.setstate({
        password: '',
        error_msg: '',
        is_submitting: false
      });
    }
  }



    
  async _cacheCodes () {
    try {
      const res_items = (await cache.codes.get()).items;

      return res_items;
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      return [];
    }
  }


  async _fetchExchangersRegistered () {    
    try {
      const res_user_item = (await server_api.fetchUsers({
        where: {
          id: common.get().id
        },
        attributes: ['registered_exchanger_id'],
        include: [
          { association: 'registered_exchanger', attributes: ['id', 'name', 'country_code'], include: [
            { association: 'bankaccounts', attributes: ['id', 'bank_id', 'bank_account'], include: [
              { association: 'bank', attributes: ['id', 'name'] }]
          }]
        }]
      })).items[0];

      if (!res_user_item.registered_exchanger) {
        return {}        
      }

      else {
        return res_user_item.registered_exchanger;
      }
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development')
        console.error(e);

      return {}
    }
  }



  
  async _fetchBalance () {
    let res_accounts = [];
    try {
      res_accounts = await server_api.readUsersAccount({}, { type: 'array' }) || [];
    }
    catch(e) {
      if (process.env.NODE_ENV === 'development')
        console.error(e);
    }

    let res_accounts_value_not_zero = [];
    for(let v of res_accounts) {
      if (v.value === 0) {
        continue;
      }
      res_accounts_value_not_zero.push(v);
    }
    if (this.is_mounted_ && res_accounts_value_not_zero.length === 0) {
      const modal = Modal.confirm({
        title: lang.get('exchange/modal_case21/title'),
        content: lang.get('exchange/modal_case21/content'),
        okText: lang.get('modal/ok_text'),
        cancelText: lang.get('modal/cancel_text'),
        onOk: e => {
          modal.destroy();
          this.props.history.push('/filling');
        },
        onCancel() {
          
        },
        centered: true
      });
    }


    return res_accounts_value_not_zero;
  }




  async _cacheGlobalVars() {
    try {
      const res_global_vars_items = (await cache.global_vars.get()).items;
      const selected_arr_keys = ["token_to_money_min_max_amount", "token_to_token_min_max_amount", "token_to_money_commission_rate", "token_to_token_commission_rate" ];
      let global_variants = {};
      for (let v of res_global_vars_items) {
        if (selected_arr_keys.indexOf(v.name) >= 0) {
          global_variants[v.name] = {
            value: v.value,
            value2: v.value2,
          }
        }
      }
      
      return global_variants;
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      return {};
    }
  }


  async _fetchLocalBankAccount (bank_account_id) {
    if (bank_account_id === void 0) {
      return {};
    }

    try {
      const res_item = (await server_api.fetchUserBankAccounts({
        where: {
          id: bank_account_id,
          user_id: common.get().id,          
        },
        include: [
          {
            association: 'bank',
            where: {
              status: 'AVAILABLE'
            }
          }
        ]
      })).items[0];

      if (res_item) {
        const data_user_bank = {
          id: res_item.id,
          alias: res_item.alias,
          bank_id: res_item.bank_id,
          bank_name: res_item.bank.name,
          bank_account: res_item.bank_account,
          owner_name: res_item.owner_name,
        }
  
        return data_user_bank;
      }
      else {
        return {};
      }
    }
    catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }
      
      return {};
    }
  }



  //* utils */
  _validateStateDecimal (stored_state, limit_decimal_point_t2m) {
    if (stored_state === void 0) {
      return {};
    }

    if (stored_state.value_to_exchange === void 0 || (stored_state.value_to_exchange || '').indexOf('.') < 0)
      return stored_state;

    let limit_decimal_point;
    if (stored_state.exchange_tab_key === '1')
      limit_decimal_point = limit_decimal_point_t2m || 0;
    else if (stored_state.exchange_tab_key === '2')
      limit_decimal_point = stored_state.limit_decimal_point_t2t || 0;

    stored_state.value_to_exchange = String(parseFloat(stored_state.value_to_exchange || '0').toFixed(limit_decimal_point));

    return stored_state;
  }


  _redirectTo(pathname, params = {}) {
    const { exchange_tab_key } = this.state;

    if (exchange_tab_key === '1') {
      storage_util.saveToSession(state_util.ENUM.exchange_state, {
        exchange_tab_key  : this.state.exchange_tab_key,
        value_to_exchange : this.state.value_to_exchange,
        exchange_type     : this.state.exchange_type,
        bank_account      : this.state.bank_account,
        log               : this.state.log,
      });      
    } else if (exchange_tab_key === '2') {
      storage_util.saveToSession(state_util.ENUM.exchange_state, {
        exchange_tab_key  : this.state.exchange_tab_key,
        value_to_exchange : this.state.value_to_exchange,
        token_from_symbol : this.state.token_from_symbol,
        limit_decimal_point_t2t: this.state.limit_decimal_point_t2t,
        token_to_symbol   : this.state.token_to_symbol,
        log               : this.state.log,
      });
    }

    return this.props.history.push({
      pathname: pathname,
      search: `?${query_string.stringify({ return: this.props.location.pathname })}`,
      state: params,
    });
  }
}

export default Exchange;