


function saveToSession(name, obj) {
	if (typeof obj !== 'object') {
		return console.error('The second argument should be object.');
	}

	sessionStorage.setItem(name, JSON.stringify(obj));
}


function loadFromSession (name, default_obj = {}, needToRemove = false) {
	const obj_str = sessionStorage.getItem(name);
	if (obj_str === null) {
		return default_obj;
	}
	
	if (needToRemove === true) {
		sessionStorage.removeItem(name);
	}
	
	return JSON.parse(obj_str);
}

function saveToLocal(name, obj) {
	if (typeof obj !== 'object') {
		return console.error('The second argument should be object.');
	}

	localStorage.setItem(name, JSON.stringify(obj));
}


function loadFromLocal (name, default_obj = {}, needToRemove = false) {
	const obj_str = localStorage.getItem(name);
	if (obj_str === null)
		return default_obj;
	

	if (needToRemove === true) {
		localStorage.removeItem(needToRemove)
	}
	
	return JSON.parse(obj_str);
}



export default {
	saveToSession,
	loadFromSession,
	saveToLocal,
	loadFromLocal,
}