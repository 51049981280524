//* modules
import React from "react";
import { Button, Typography, Icon, Drawer, Modal } from "antd";
import query_string from "query-string";
//* view components
import NyobKeypad from "../../snippets/NyobKeypad";
import TokenAndBalanceList from "../../snippets/drawer_contents/TokenAndBalanceList";
import Loading from "../../snippets/Loading";
//* utils
import cache from "../../utils/cache";
import common from "../../utils/common";
import eos_wallet from "../../utils/eos_wallet";
import server_api from "../../utils/server_api";
import state_util from "../../utils/state_util";
import storage_util from "../../utils/storage_util";
//* resources
import lang from "../../lang/lang";
//* global variants
import config from "../../config";
import { createLogicalAnd } from "typescript";

//* constant variants
const keypad_layout_number = [
  [
    { value: "1", content: "1" },
    { value: "2", content: "2" },
    { value: "3", content: "3" },
  ],
  [
    { value: "4", content: "4" },
    { value: "5", content: "5" },
    { value: "6", content: "6" },
  ],
  [
    { value: "7", content: "7" },
    { value: "8", content: "8" },
    { value: "9", content: "9" },
  ],
  [
    { value: ".", content: "." },
    { value: "0", content: "0" },
    {
      value: "del",
      content: <Icon type="left" style={{ pointerEvents: "none" }} />,
    },
  ],
];

class Transfer extends React.Component {
  is_mounted_ = false;
  state = {
    is_loading: false,
    is_submitting: false,
    data_account: [
      // {name: 'KRWH', value: '300'}
    ],
    header_text: "",
    value_to_transfer: "0",
    token: "HIM",
    visible_token_drawer: false,
    visible_keyboard_drawer: false,
    value_min: "0",
    value_max: "1000000",
    limit_decimal_point: 4,
    error_msg: "",
    error_header_text: "",
    global_variants: null,
  };

  setstate = (partial_state) =>
    this.is_mounted_ === true && this.setState(partial_state);

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    if ((await common.isLoggedIn()) === false) {
      return;
    }

    let data_codes = await this._cacheCodes();
    // let { token, limit_decimal_point } =
    //   await this._getCountryTokenAndLimitDecimalPoint(data_codes);
    let data_account = await this._fetchAccount();
    let global_variants = await this._cacheGlobalVars();
    let global_variantsAll = await this._cacheGlobalALL();

    // console.log(global_variantsAll);
    let stored_state = storage_util.loadFromSession(
      state_util.ENUM.transfer_state,
      void 0,
      true
    );
    state_util.clearEveryStates();
    stored_state = this._validateStateDecimal(stored_state, data_codes);

    this.setstate({
      is_loading: false,
      header_text: (await eos_wallet.getHeaderTokenText()) || "",
      data_account,
      data_codes,
      // token,
      // limit_decimal_point,
      value_min: global_variants.value,
      value_max: global_variants.value2,
      global_variants: global_variantsAll,
      ...stored_state, // value_to_transfer, token, limit_decimal_point
    });
  }

  componentWillUnmount() {
    this.is_mounted_ = false;
  }

  render() {
    if (common.get().token === void 0) {
      return null;
    }
    return (
      <Loading
        is_loading={this.state.is_loading}
        overall={true}
        style={{
          display: "table",
          tableLayout: "fixed",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>
            {/** HEADER *****/}
            <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  onClick={(e) => this.props.history.push("/wallet")}
                  style={{
                    flex: "1 1 0px",
                    borderRadius: "4px",
                    padding: "0.2rem 1rem",
                    display: "block",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontSize: "1rem",
                    color: "#fff",
                    background: "rgb(51, 129, 252)",
                    cursor: "pointer",
                  }}
                >
                  {this.state.header_text}&nbsp;
                </div>
              </div>

              {this.state.error_header_text ? (
                <Typography.Title
                  level={3}
                  style={{
                    margin: "0",
                    marginTop: "1.5rem",
                    textAlign: "center",
                  }}
                >
                  <Typography.Text>
                    {this.state.error_header_text}
                  </Typography.Text>
                </Typography.Title>
              ) : (
                <Typography.Title
                  level={3}
                  style={{ margin: "0", marginTop: "1.5rem" }}
                >
                  <Typography.Text>
                    {lang.get("transfer/title")}
                  </Typography.Text>
                </Typography.Title>
              )}
            </div>
            {/***** HEADER END **/}
          </div>
        </div>

        <div style={{ display: "table-row" }}>
          <div style={{ display: "table-cell" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  bottom: "0",
                  left: "0",
                  right: "0",
                  overflow: "auto",
                }}
              >
                <div
                  style={{ display: "table", width: "100%", height: "100%" }}
                >
                  <div style={{ display: "table-row" }}>
                    <div style={{ display: "table-cell" }}>
                      <div
                        style={{
                          position: "relative",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexWrap: "wrap",
                          padding: "0 1rem",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "right",
                            fontSize: "2rem",
                            fontWeight: "700",
                            minHeight: "3rem",
                            pointerEvents: "none",
                            userSelect: "none",
                          }}
                        >
                          {this.state.value_to_transfer
                            ? eos_wallet.formatCommaNumber(
                                this.state.value_to_transfer,
                                false
                              )
                            : ""}
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: `${1}rem`,
                            transform: `translate( ${0}, ${-50}%)`,
                            padding: "4px 8px",
                            cursor: "pointer",
                            userSelect: "none",
                            color: "rgb(85, 89, 99)",
                            background: "rgb(240, 240, 240)",
                            borderRadius: "4px",
                          }}
                          onClick={(e) =>
                            this.setstate({ visible_token_list_drawer: true })
                          }
                        >
                          <Typography.Text>
                            {this.state.token || "-"}
                          </Typography.Text>
                        </div>
                        <Drawer
                          title={
                            <div style={{ fontWeight: "700" }}>
                              <Typography.Text>
                                {lang.get("transfer/title")}
                              </Typography.Text>
                            </div>
                          }
                          placement="bottom"
                          closable={true}
                          onClose={(e) =>
                            this.setstate({ visible_token_list_drawer: false })
                          }
                          visible={this.state.visible_token_list_drawer}
                          height="bottom"
                          bodyStyle={{ paddingTop: "0" }}
                        >
                          <div
                            style={{
                              height: "100%",
                              maxWidth: "480px",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                                height: "calc(50vh - 5rem)",
                              }}
                            >
                              <div
                                style={{
                                  top: "0",
                                  bottom: "0",
                                  left: "0",
                                  right: "0",
                                  position: "absolute",
                                  overflow: "auto",
                                  paddingTop: "0.5rem",
                                }}
                              >
                                <TokenAndBalanceList
                                  data={this.state.data_account}
                                  onClickCurrency={(value) =>
                                    this.setstate({
                                      value_to_transfer: "0",
                                      token: value,
                                      limit_decimal_point:
                                        (
                                          this.state.data_codes.filter(
                                            (datum) =>
                                              datum.token_symbol === value
                                          )[0] || {}
                                        ).limit_decimal_point || 0,
                                      visible_token_list_drawer: false,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </Drawer>
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "table-row" }}>
                    <div style={{ display: "table-cell" }}>
                      <div style={{ position: "relative", height: "100%" }}>
                        <NyobKeypad
                          data={keypad_layout_number}
                          tableStyle={{
                            width: "100%",
                            minWidth: "288px",
                            height: "100%",
                            minHeight: "230px",
                            maxHeight: "384px",
                            margin: "0 auto",
                          }}
                          // tbodyStyle={{}}
                          // trStyle={{}}
                          tdStyle={{
                            fontSize: "1.5rem",
                            fontWeight: "700",
                            borderRadius: "8px",
                          }}
                          onClickKey={this._hNyobKey.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>
            <div style={{ padding: "0.5rem 1rem 1rem" }}>
              <Button
                size="large"
                type="danger"
                style={{
                  width: "100%",
                  fontWeight: "700",
                  color: "#fff",
                  background: "rgb(255, 92, 92)",
                }}
                onClick={this._hSubmit.bind(this)}
              >
                {this._isLock() === true
                  ? "LOCK"
                  : lang.get("transfer/btn_submit")}
              </Button>
            </div>
          </div>
        </div>
      </Loading>
    );
  }

  _hNyobKey(key) {
    let { value_to_transfer } = this.state;

    if (value_to_transfer === undefined) {
      return window.location.reload();
    }
    if (!value_to_transfer) value_to_transfer = "";

    switch (key) {
      case ".":
        if (value_to_transfer === "") {
          return this.setstate({
            error_header_text: "",
            value_to_transfer: "0.",
          });
        }

        for (let i = 0; i < value_to_transfer.length; ++i) {
          if (value_to_transfer[i] === ".") return;
        }

        return this.setstate({
          error_header_text: "",
          value_to_transfer: value_to_transfer.concat(key),
        });
      case "esc":
        return this.setstate({
          visible_keyboard_drawer: false,
        });
      case "enter":
        this._hSubmit();
        break;
      case "del":
        return this.setstate({
          error_header_text: "",
          value_to_transfer: value_to_transfer.slice(0, -1),
        });
      default:
        if (value_to_transfer === "0") value_to_transfer = "";
        const new_value = value_to_transfer.concat(key);
        for (let i = new_value.length - 1, count = 0; i >= 0; --i) {
          ++count;

          if (new_value[i] === ".") {
            if (count - 1 > this.state.limit_decimal_point) return;
          }

          if (count - 1 > 8) {
            //! EOS MAXIMUM DECIMAL = 4 (2019.8)
            break;
          }
        }

        if (Number(new_value) <= Number(this.state.value_max))
          return this.setstate({
            error_header_text: "",
            value_to_transfer: new_value,
          });
        else {
          return this.setstate({
            error_header_text: "",
            value_to_transfer: this.state.value_max,
          });
        }
    }
  }

  async _hSubmit(e) {
    if (this._isLock() === true) return alert("LOCK");

    if (this.state.is_loading === true) {
      return;
    }
    this.setstate({ is_loading: true });

    let { value_to_transfer, token, limit_decimal_point, data_account } =
      this.state;

    if (value_to_transfer[value_to_transfer.length - 1] === ".")
      value_to_transfer = value_to_transfer.substring(
        0,
        value_to_transfer.length - 1
      );
    const accounts = this.state.data_account;
    const account = accounts.filter(
      (i) => i.name.toUpperCase() === this.state.token
    )[0];

    if (account.is_lock) {
      const ableAmount = +account.lock_amount - +account.lock_transfer_amount;
      if (ableAmount < +value_to_transfer) {
        this.setstate({
          is_loading: false,
        });
        return alert("You can't transfer more than " + ableAmount);
      }
    }

    if (eos_wallet.validate.checkBlank(value_to_transfer)) {
      // Modal.error({
      //   title: '이체 요청 실패',
      //   content: '금액을 입력해주세요.',
      //   centered: true
      // });
      return this.setstate({
        error_header_text: lang.get("transfer/error_header_text"),
        is_loading: false,
      });
    }
    if (token === "") {
      Modal.error({
        title: lang.get("transfer/modal_case6/title"),
        content: lang.get("transfer/modal_case6/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
    }

    if (Number(value_to_transfer) < Number(this.state.value_min)) {
      Modal.error({
        title: lang.get("transfer/modal_case1/title"),
        content: lang.get("transfer/modal_case1/content", {
          value: this.state.value_min,
        }),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setstate({ is_loading: false });
    }

    if (data_account.length === 0) {
      const modal = Modal.confirm({
        title: lang.get("transfer/modal_case2/title"),
        content: lang.get("transfer/modal_case2/content"),
        okText: lang.get("modal/ok_text"),
        cancelText: lang.get("modal/cancel_text"),
        onOk: (e) => {
          modal.destroy();
          this.props.history.push("/filling");
        },
        onCancel() {},
        centered: true,
      });
      return this.setstate({ is_loading: false });
    }

    const selected_account = data_account.filter(
      (account) => account.name === token
    )[0];
    const max_value_to_transfer = (selected_account || {}).value || "0";
    if (Number(value_to_transfer) > Number(max_value_to_transfer)) {
      Modal.error({
        title: lang.get("transfer/modal_case3/title"),
        content: lang.get("transfer/modal_case3/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setstate({ is_loading: false });
    }

    // try {
    //   let data_codes = await this._cacheCodes();

    //   // const current_country_code = data_codes.filter(
    //   //   (datum) => datum.token_symbol === token
    //   // )[0];

    //   // const has_no_token_symbol = current_country_code === void 0;
    //   const is_dirty_limit_decimal_point = 4;
    //   // limit_decimal_point !== current_country_code.limit_decimal_point;
    //   console.log(has_no_token_symbol);
    //   console.log(is_dirty_limit_decimal_point);
    //   // if (has_no_token_symbol || is_dirty_limit_decimal_point) {
    //   //   Modal.error({
    //   //     title: lang.get("transfer/modal_case5/title"),
    //   //     content: lang.get("transfer/modal_case5/content"),
    //   //     okText: lang.get("modal/confirm_text"),
    //   //     centered: true,
    //   //   });
    //   //   // return window.location.reload();
    //   // }
    // } catch (e) {
    //   if (process.env.NODE_ENV === "development") {
    //     console.error(e);
    //   }

    //   Modal.error({
    //     title: lang.get("transfer/modal_case5/title"),
    //     content: lang.get("transfer/modal_case5/content"),
    //     okText: lang.get("modal/confirm_text"),
    //     centered: true,
    //   });
    //   return this.setstate({ is_loading: false });
    // }

    storage_util.saveToSession(state_util.ENUM.transfer_state, {
      value_to_transfer,
      token,
    });

    return this.props.history.push({
      pathname: "/transfer/selectwallet",
      // search: '?query=abc',
      // hash: '#abc',
      // state: {}
    });
  }

  /* server transactions */
  async _fetchAccount() {
    let res_accounts = [];
    try {
      res_accounts =
        (await server_api.readUsersAccount({}, { type: "array" })) || [];
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.error(e);
    }

    let res_accounts_value_not_zero = [];
    let major_token_obj = void 0;
    for (let v of res_accounts) {
      if (v.name === config.major_token_symbol) {
        major_token_obj = v;
        continue;
      }
      // if (v.value === 0) {
      //   continue;
      // }
      res_accounts_value_not_zero.push(v);
    }

    if (major_token_obj === void 0) {
      res_accounts_value_not_zero.unshift({
        name: config.major_token_symbol,
        value: 0,
      });
    } else {
      res_accounts_value_not_zero.unshift(major_token_obj);
    }

    let balance = 0;
    for (let v of res_accounts_value_not_zero) {
      balance += v.value;
    }

    // if (this.is_mounted_ && balance === 0) {
    //   const modal = Modal.confirm({
    //     title: lang.get('transfer/modal_case4/title'),
    //     content: lang.get('transfer/modal_case4/content'),
    //     okText: lang.get('modal/ok_text'),
    //     cancelText: lang.get('modal/cancel_text'),
    //     onOk: e => {
    //       modal.destroy();
    //       this.props.history.push('/filling');
    //     },
    //     onCancel() {

    //     },
    //     centered: true
    //   });
    // }

    return res_accounts_value_not_zero;
  }

  async _getCountryTokenAndLimitDecimalPoint(data_codes) {
    return 4;
    const token_item = data_codes.filter(
      (datum) => datum.country_code === common.get().country_code
    )[0];

    // const country_token = (token_item || {}).token_symbol || '';
    // const limit_decimal_point = (token_item || {}).limit_decimal_point || 0;

    const country_token = "HIM";
    const limit_decimal_point = 2;

    return { token: country_token, limit_decimal_point };
  }

  async _cacheGlobalVars() {
    try {
      const res_global_vars_items = (await cache.global_vars.get()).items;

      const global_variants = res_global_vars_items.filter(
        (item) => item.name === "transfer_min_max_amount"
      )[0];

      return global_variants;
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      return {};
    }
  }

  async _cacheGlobalALL() {
    try {
      const res_global_vars_items = (await cache.global_vars.get()).items;

      return res_global_vars_items;
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      return {};
    }
  }
  _isLock() {
    // TODO: TEST CODE

    const accounts = this.state.data_account;
    const account = accounts.filter(
      (i) => i.name.toUpperCase() === this.state.token
    )[0];

    let ableAmount = 0;
    if (account && account.is_lock) {
      if (+account.lock_amount - +account.lock_transfer_amount <= 0) {
        ableAmount = -1;
      }
    }

    if (ableAmount === -1) {
      return true;
    }

    if (this.state.global_variants === null) {
      return false;
    }

    const g = this.state.global_variants.filter(
      (item) => item.name.toUpperCase() === this.state.token
    )[0];

    if (g.value === "open") {
      return false;
    }

    return true;
  }

  async _cacheCodes() {
    try {
      const res_items = (await cache.codes.get()).items;

      return res_items;
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        return console.error(e);
      }
      return [];
    }
  }

  //* utils */
  _validateStateDecimal(stored_state, data_codes) {
    if (stored_state === void 0) {
      return {};
    }

    if (
      stored_state.value_to_transfer === void 0 ||
      (stored_state.value_to_transfer || "").indexOf(".") < 0
    )
      return stored_state;

    let token = stored_state.token || "";

    let limit_decimal_point = 4;
    // if (token === "") {
    //   limit_decimal_point = 0;
    // } else {
    //   limit_decimal_point =
    //     (data_codes.filter((datum) => datum.token_symbol === token)[0] || {})
    //       .limit_decimal_point || 0;
    // }

    stored_state.value_to_transfer = String(
      parseFloat(stored_state.value_to_transfer || "0").toFixed(
        limit_decimal_point
      )
    );

    return stored_state;
  }

  _redirectTo(pathname, params = {}) {
    let { value_to_transfer, token, limit_decimal_point } = this.state;
    storage_util.saveToSession(state_util.ENUM.transfer_state, {
      value_to_transfer,
      token,
      limit_decimal_point,
    });

    this.props.history.push({
      pathname: pathname,
      search: `?${query_string.stringify({
        return: this.props.location.pathname,
      })}`,
      state: params,
    });
  }
}

export default Transfer;
