import React from "react";
import { Button, Typography, Icon } from "antd";
//* utils
import common from "../../utils/common";
import eos_wallet from "../../utils/eos_wallet";
//* resources
import lang from "../../lang/lang";
import en from "../../images/en.png";
import kr from "../../images/kr.png";
class All extends React.Component {
  is_mounted_ = false;
  state = {
    header_text: "",
  };

  constructor(props) {
    super(props);
    // console.log(this.props.location.state);
    // if (!this.props.location.state)
    //   return this.props.history.push('/');

    // const { value_transferred } = this.props.location.state;
    this.state = {
      ...this.state,
    };
  }
  setstate = (partial_state) =>
    this.is_mounted_ === true && this.setState(partial_state);

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    if ((await common.isLoggedIn()) === false) {
      return;
    }

    this.setstate({
      is_loading: false,
      header_text: (await eos_wallet.getHeaderTokenText()) || "",
    });
  }
  componentWillUnmount() {
    this.is_mounted_ = false;
  }

  render() {
    if (common.get().token === void 0) {
      return null;
    }

    return (
      <div
        style={{
          display: "table",
          tableLayout: "fixed",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>
            {/** HEADER *****/}
            <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  onClick={(e) => this.props.history.push("/wallet")}
                  style={{
                    flex: "1 1 0px",
                    borderRadius: "4px",
                    padding: "0.2rem 1rem",
                    display: "block",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontSize: "1rem",
                    color: "#fff",
                    background: "rgb(51, 129, 252)",
                    cursor: "pointer",
                  }}
                >
                  {this.state.header_text}&nbsp;
                </div>
                <div style={{ flex: "0 0 auto", padding: "0 0 0 0.5rem" }}>
                  <Button
                    key="to_faq"
                    type="link"
                    style={{ padding: "0", height: "2.5rem" }}
                    onClick={(e) =>
                      this.props.history.push({ pathname: "/all/faq" })
                    }
                  >
                    <Icon
                      type="bulb"
                      style={{ fontSize: "1.6rem", color: "rgb(80, 80, 80)" }}
                    />
                  </Button>
                </div>
                <div
                  style={{
                    flex: "0 0 auto",
                    paddingLeft: "0.25rem",
                    paddingRight: "0",
                  }}
                >
                  <Button
                    key="to_notice"
                    type="link"
                    style={{ padding: "0", height: "2.5rem" }}
                    onClick={(e) =>
                      this.props.history.push({ pathname: "/all/notice" })
                    }
                  >
                    <Icon
                      type="notification"
                      style={{ fontSize: "1.6rem", color: "rgb(80, 80, 80)" }}
                    />
                  </Button>
                </div>
              </div>

              <Typography.Title
                level={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0",
                  marginTop: "1.5rem",
                }}
              >
                <Typography.Text>{lang.get("all/title")}</Typography.Text>
              </Typography.Title>
            </div>
            {/***** HEADER END **/}
          </div>
        </div>

        <div style={{ display: "table-row", height: "2%" }} />

        <div style={{ display: "table-row" }}>
          {" "}
          {/* content */}
          <div style={{ display: "table-cell" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div
                style={{
                  top: "0",
                  bottom: "0",
                  left: "0",
                  right: "0",
                  position: "absolute",
                  overflow: "auto",
                }}
              >
                <div style={{ padding: "0.5rem" }}>
                  <div
                    style={{
                      fontWeight: "700",
                      borderRadius: "0.6em",
                      padding: "1.5rem 1rem",
                      margin: "0.5rem auto 0",
                      boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    }}
                  >
                    <div>
                      <Typography.Text>
                        {lang.get("all/profile_section/title")}
                      </Typography.Text>
                    </div>
                    <div style={{ marginTop: "0.6rem" }}>
                      <Button
                        type="link"
                        onClick={(e) => this.props.history.push("/all/profile")}
                        style={{
                          width: "100%",
                          padding: "0",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontSize: "1.1rem",
                          color: "rgb(150, 150, 150)",
                        }}
                      >
                        <div>
                          <Icon type="user" />
                          <Typography.Text style={{ paddingLeft: "0.5rem" }}>
                            {lang.get("all/profile_section/profile")}
                          </Typography.Text>
                        </div>
                        <div>
                          <Icon type="right" />
                        </div>
                      </Button>
                    </div>
                  </div>

                  <div
                    style={{
                      fontWeight: "700",
                      borderRadius: "0.6em",
                      padding: "1.5rem 1rem",
                      margin: "0.5rem auto 0",
                      boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    }}
                  >
                    <div>
                      <Typography.Text>
                        {lang.get("all/service_section/title")}
                      </Typography.Text>
                    </div>

                    <div style={{ marginTop: "0.6rem" }}>
                      <Button
                        type="link"
                        onClick={(e) => this.props.history.push("/wallet")}
                        style={{
                          width: "100%",
                          padding: "0",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontSize: "1.1rem",
                          color: "rgb(150, 150, 150)",
                        }}
                      >
                        <div>
                          <Icon type="wallet" />
                          <Typography.Text style={{ paddingLeft: "0.5rem" }}>
                            {lang.get("all/service_section/wallet")}
                          </Typography.Text>
                        </div>
                        <div>
                          <Icon type="right" />
                        </div>
                      </Button>
                    </div>

                    {/* <div style={{ marginTop: "0.6rem" }}>
                      <Button type="link" onClick={e => this.props.history.push('/all/exchangeinformation')}
                        style={{ width: "100%", padding: "0", display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: "1.1rem", color: "rgb(150, 150, 150)" }}
                      >
                        <div>
                          <Icon type="line-chart" /><Typography.Text style={{ paddingLeft: "0.5rem" }}>{lang.get('all/service_section/exchange_rate_info')}</Typography.Text>
                        </div>
                        <div>
                          <Icon type="right" />
                        </div>
                      </Button>
                    </div> */}

                    <div style={{ marginTop: "0.6rem" }}>
                      <Button
                        type="link"
                        onClick={(e) =>
                          this.props.history.push("/all/verification")
                        }
                        style={{
                          width: "100%",
                          padding: "0",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontSize: "1.1rem",
                          color: "rgb(150, 150, 150)",
                        }}
                      >
                        <div>
                          <Icon type="lock" />
                          <Typography.Text style={{ paddingLeft: "0.5rem" }}>
                            {lang.get("all/service_section/verification")}
                          </Typography.Text>
                        </div>
                        <div>
                          <Icon type="right" />
                        </div>
                      </Button>
                    </div>

                    <div style={{ marginTop: "0.6rem" }}>
                      <Button
                        type="link"
                        onClick={(e) => this.props.history.push("/all/setting")}
                        style={{
                          width: "100%",
                          padding: "0",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontSize: "1.1rem",
                          color: "rgb(150, 150, 150)",
                        }}
                      >
                        <div>
                          <Icon type="setting" />
                          <Typography.Text style={{ paddingLeft: "0.5rem" }}>
                            {lang.get("all/service_section/setting")}
                          </Typography.Text>
                        </div>
                        <div>
                          <Icon type="right" />
                        </div>
                      </Button>
                    </div>

                    <div style={{ marginTop: "0.6rem" }}>
                      <Button
                        type="link"
                        onClick={(e) => this.props.history.push("/exchange2")}
                        style={{
                          width: "100%",
                          padding: "0",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontSize: "1.1rem",
                          color: "rgb(150, 150, 150)",
                        }}
                      >
                        <div>
                          <Icon type="bank" />
                          <Typography.Text style={{ paddingLeft: "0.5rem" }}>
                            {lang.get("more/withdraw_out")}
                          </Typography.Text>
                        </div>
                        <div>
                          <Icon type="right" />
                        </div>
                      </Button>
                    </div>
                    <div style={{ marginTop: "0.6rem" }}>
                      <Button
                        type="link"
                        onClick={(e) => this.props.history.push("/history")}
                        style={{
                          width: "100%",
                          padding: "0",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontSize: "1.1rem",
                          color: "rgb(150, 150, 150)",
                        }}
                      >
                        <div>
                          <Icon type="history" />
                          <Typography.Text style={{ paddingLeft: "0.5rem" }}>
                            {lang.get("more/withdraw_history")}
                          </Typography.Text>
                        </div>
                        <div>
                          <Icon type="right" />
                        </div>
                      </Button>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100px",
                      marginTop: "50px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      onClick={() => {
                        lang.setLang("en");
                        common.updateLocalCountryCode("EN");
                        window.location.reload();
                      }}
                      style={{ width: "30px", cursor: "pointer" }}
                      src={en}
                    />
                    <img
                      onClick={() => {
                        lang.setLang("kr");
                        common.updateLocalCountryCode("KR");
                        window.location.reload();
                      }}
                      style={{ width: "30px", cursor: "pointer" }}
                      src={kr}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default All;
