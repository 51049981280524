import React from "react";
import {
  Button,
  Typography,
  Icon,
  Row,
  Col,
  Drawer,
  Input,
  Modal,
  Spin,
} from "antd";
import Big from "big.js";
import moment from "moment";
import query_string from "query-string";
import { NumericKeyboard } from "numeric-keyboard/dist/numeric_keyboard.react";
//* view components
import Loading from "../../snippets/Loading";
import NumericKeyboardLayout from "../../snippets/NumericKeyboardLayout";
import NumericInput from "../../snippets/NumericInput";
import InputOtpPc from "../../snippets/InputOtpPc";
import BankAccountDrawer from "../../snippets/drawer/BankAccountsDrawer";
import CurrencyList from "../../snippets/drawer_contents/CurrencyList";
import TokenList from "../../snippets/drawer_contents/TokenList";
//* functions
import cache from "../../utils/cache";
import checkers from "../../utils/checkers";
import common from "../../utils/common";
import eos_wallet from "../../utils/eos_wallet";
import server_api from "../../utils/server_api";
import state_util from "../../utils/state_util";
import storage_util from "../../utils/storage_util";
//* resources
import lang from "../../lang/lang";
//* styles
import "../../styles/numeric_keybord.less";

class Filling extends React.Component {
  is_mounted_ = false;
  state = {
    is_loading: false,
    is_result_caculating: false,
    is_submitting: false,
    value_to_filling: "0",
    value_min: "0",
    value_max: "1000000",
    limit_decimal_point: 0,
    currency: "",
    token: void 0,
    selected_exchanger: {},
    data_codes: [],
    bank_account: void 0,
    log: "",
    password: "",
    visible_keyboard_drawer: false,
    visible_currency_list_drawer: false,
    visible_token_list_drawer: false,
    visible_bank_account_list_drawer: false,
    visible_bank_account_create_drawer: false,
    visible_input_otp_drawer: false,
    visible_input_otp_pc_modal: false,
    error_msg: "",
    errors: {
      currency: false,
      bank_account: false,
      value_to_filling: false,
    },
  };

  setstate = (partial_state) =>
    this.is_mounted_ === true && this.setState(partial_state);

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    if ((await common.isLoggedIn()) === false) {
      return;
    }

    let data_codes = await this._cacheCodes();
    let { currency, limit_decimal_point } =
      this._fetchCountryCurrency(data_codes);
    let global_variants = await this._cacheGlobalVars();

    let local_saved_bank = storage_util.loadFromLocal("user_last_bank", void 0);
    let bank_account;
    if (local_saved_bank) {
      bank_account = await this._fetchLocalBankAccount(local_saved_bank.id);
    }

    let stored_state = storage_util.loadFromSession(
      state_util.ENUM.filling_state,
      void 0,
      true
    );
    state_util.clearEveryStates();
    stored_state = this._validateStateDecimal(stored_state, data_codes);

    this.setstate({
      is_loading: false,
      header_text: (await eos_wallet.getHeaderTokenText()) || "",
      data_codes,
      data_account: await this._fetchDataAccount(),
      currency,
      limit_decimal_point,
      selected_exchanger: await this._fetchExchangersRegistered(),
      value_min: global_variants.value || 0,
      value_max: global_variants.value2 || 0,

      bank_account,
      ...stored_state,
    });

    await this._previewResult();
  }
  componentWillUnmount() {
    this.is_mounted_ = false;
  }

  render() {
    if (common.get().token === void 0) {
      return null;
    }

    return (
      <Loading
        is_loading={this.state.is_loading || this.state.is_submitting}
        overall={true}
        style={{
          display: "table",
          tableLayout: "fixed",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>
            {/** HEADER *****/}
            <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  onClick={(e) => this.props.history.push("/wallet")}
                  style={{
                    flex: "1 1 0px",
                    borderRadius: "4px",
                    padding: "0.2rem 1rem",
                    display: "block",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontSize: "1rem",
                    color: "#fff",
                    background: "rgb(51, 129, 252)",
                    cursor: "pointer",
                  }}
                >
                  {this.state.header_text}&nbsp;
                </div>
                <div style={{ flex: "0 0 auto", padding: "0 0 0 0.5rem" }}>
                  <Button
                    type="link"
                    style={{ padding: "0", height: "2.5rem" }}
                    onClick={(e) =>
                      this.setstate({ visible_redirect_drawer: true })
                    }
                  >
                    <Icon
                      type="plus"
                      style={{ fontSize: "1.5rem", color: "RGB(75, 75, 75)" }}
                    />
                  </Button>
                  <Drawer
                    title={
                      <div style={{ fontWeight: "700" }}>
                        <Typography.Text>
                          {lang.get("drawer/record_redirecter/title")}
                        </Typography.Text>
                      </div>
                    }
                    placement="bottom"
                    closable={true}
                    onClose={(e) =>
                      this.setstate({ visible_redirect_drawer: false })
                    }
                    visible={this.state.visible_redirect_drawer}
                    height="bottom"
                    bodyStyle={{ padding: "0.5rem" }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <Button
                        type="link"
                        size="large"
                        style={{ width: "100%", textAlign: "left" }}
                        onClick={(e) =>
                          this._redirectTo("/wallet/record", { tab_key: "1" })
                        }
                      >
                        <Icon type="profile" />{" "}
                        <Typography.Text>
                          {lang.get("drawer/record_redirecter/transfer_list")}
                        </Typography.Text>
                      </Button>
                      <Button
                        type="link"
                        size="large"
                        style={{ width: "100%", textAlign: "left" }}
                        onClick={(e) =>
                          this._redirectTo("/wallet/record", { tab_key: "2" })
                        }
                      >
                        <Icon type="profile" />{" "}
                        <Typography.Text>
                          {lang.get("drawer/record_redirecter/filling_list")}
                        </Typography.Text>
                      </Button>
                      <Button
                        type="link"
                        size="large"
                        style={{ width: "100%", textAlign: "left" }}
                        onClick={(e) =>
                          this._redirectTo("/wallet/record", { tab_key: "3" })
                        }
                      >
                        <Icon type="profile" />{" "}
                        <Typography.Text>
                          {lang.get("drawer/record_redirecter/exchange_list")}
                        </Typography.Text>
                      </Button>
                    </div>
                  </Drawer>
                </div>
              </div>
              <Typography.Title
                level={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0",
                  marginTop: "1.5rem",
                }}
              >
                <Typography.Text>{lang.get("filling/title")}</Typography.Text>
              </Typography.Title>
            </div>
            {/***** HEADER END **/}
          </div>
        </div>

        <div style={{ display: "table-row", height: "4%" }} />

        <div style={{ display: "table-row" }}>
          {" "}
          {/* content */}
          <div style={{ display: "table-cell" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div
                style={{
                  top: "0",
                  bottom: "0",
                  left: "0",
                  right: "0",
                  position: "absolute",
                  overflow: "auto",
                }}
              >
                {/* input ***********************/}
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ flex: "1 1 0px" }}>
                      <NumericInput
                        className="numeric-input-borderless"
                        style={{
                          width: "100%",
                          textAlign: "center",
                        }}
                        size="large"
                        value={eos_wallet.formatCommaNumber(
                          this.state.value_to_filling,
                          false
                        )}
                        value_max={this.state.value_max}
                        onChange={(value) => {
                          this.setstate({
                            value_to_filling:
                              eos_wallet.unFormatCommaNumber(value),
                          });
                        }}
                        onFocus={(e) => {
                          if (checkers.is_mobile === false) {
                            return;
                          }
                          this.setstate({ visible_keyboard_drawer: true });
                        }}
                        onBlur={(e) => {
                          if (checkers.is_mobile === false) {
                            this._previewResult();
                            return;
                          }
                        }}
                        maxLength={15}
                        limit_decimal_point={this.state.limit_decimal_point}
                        placeholder=""
                        comma="true"
                      />
                      <Drawer
                        placement="bottom"
                        closable={false}
                        onClose={(e) => {
                          let value_to_filling = this.state.value_to_filling;
                          if (
                            value_to_filling[value_to_filling.length - 1] ===
                            "."
                          )
                            value_to_filling = value_to_filling.substring(
                              0,
                              value_to_filling.length - 1
                            );
                          this.setstate({
                            visible_keyboard_drawer: false,
                            value_to_filling,
                          });
                          this._previewResult();
                        }}
                        visible={this.state.visible_keyboard_drawer}
                        height="bottom"
                      >
                        {/* <div style={{ paddingBottom: "0.25rem" }}>
                          <Row type="flex" justify="center" gutter={2}>
                            <Col span={5}><Button type="link" style={{ color: '#777', width: "100%", cursor: "pointer", textAlign: "center", padding: "0" }} onClick={e => this._hAddValue(100000)}>{lang.get('add_value/add100thousand')}</Button></Col>
                            <Col span={5}><Button type="link" style={{ color: '#777', width: "100%", cursor: "pointer", textAlign: "center", padding: "0" }} onClick={e => this._hAddValue(50000)}>{lang.get('add_value/add50thousand')}</Button></Col>
                            <Col span={5}><Button type="link" style={{ color: '#777', width: "100%", cursor: "pointer", textAlign: "center", padding: "0" }} onClick={e => this._hAddValue(10000)}>{lang.get('add_value/add10thousand')}</Button></Col>
                            <Col span={5}><Button type="link" style={{ color: '#777', width: "100%", cursor: "pointer", textAlign: "center", padding: "0" }} onClick={e => this.setstate({ value_to_filling: '' })}>{lang.get('add_value/clear')}</Button></Col>
                          </Row>
                        </div> */}
                        <NumericKeyboard
                          layout={NumericKeyboardLayout.value}
                          onPress={this._hNumericKeyboard.bind(this)}
                          entertext={lang.get(
                            "filling/input_number/btn_enter_number"
                          )}
                        />
                      </Drawer>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        right: "0.5rem",
                        flex: "0 1 auto",
                        fontSize: "1rem",
                        fontWeight: "700",
                        textAlign: "left",
                      }}
                    >
                      <Typography.Text>{this.state.currency}</Typography.Text>
                    </div>
                  </div>

                  {/* {(checkers.is_mobile === false)
                    ? ( <div style={{ padding: "0.5rem 0.5rem 1rem"}}>
                          <Row type="flex" justify="end">
                            <Col>
                              <div>
                                <Button onClick={e => this._hAddValue(100000)} style={{ marginRight: "5px" }}>{lang.get('add_value/add100thousand')}</Button>
                                <Button onClick={e => this._hAddValue(50000)} style={{ marginRight: "5px" }}>{lang.get('add_value/add50thousand')}</Button>
                                <Button onClick={e => this._hAddValue(10000)} style={{ marginRight: "5px" }}>{lang.get('add_value/add10thousand')}</Button>
                                <Button onClick={e => this.setstate({ value_to_filling: ''})}>{lang.get('add_value/clear')}</Button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                    ) : null
                  } */}
                </div>

                <div style={{ padding: "0.5rem" }}>
                  <div
                    style={{
                      borderRadius: "0.6em",
                      padding: "1.5rem 1rem",
                      margin: "0.5rem auto 0",
                      boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "1rem",
                      fontWeight: "700",
                      color: "RGB(191, 191, 191)",
                    }}
                  >
                    <div>
                      <Typography.Text>
                        {lang.get("filling/select_exchanger/title")}
                      </Typography.Text>
                    </div>
                    {this.state.selected_exchanger.id &&
                    !this.state.is_loading ? (
                      <div>
                        <Button
                          type="link"
                          onClick={(e) => this._redirectTo("/all/location")}
                          style={{
                            padding: "0",
                            fontSize: "1rem",
                            fontWeight: "700",
                          }}
                        >
                          <Typography.Text>
                            {this.state.selected_exchanger.name}
                          </Typography.Text>
                          <Icon
                            type="unordered-list"
                            style={{
                              paddingLeft: "0.5rem",
                              color: "rgb(2, 122, 255)",
                            }}
                          />
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Button
                          type="link"
                          onClick={(e) => this._redirectTo("/all/location")}
                          style={{
                            padding: "0",
                            fontSize: "1rem",
                            fontWeight: "700",
                          }}
                        >
                          <Typography.Text>
                            {lang.get("filling/select_exchanger/not_selected")}
                          </Typography.Text>
                          <Icon
                            type="unordered-list"
                            style={{
                              paddingLeft: "0.5rem",
                              color: "rgb(2, 122, 255)",
                            }}
                          />
                        </Button>
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      borderRadius: "0.6em",
                      padding: "1.5rem 1rem",
                      margin: "0.5rem auto 0",
                      boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "1rem",
                        fontWeight: "700",
                        color: "RGB(191, 191, 191)",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <Typography.Text>
                          {lang.get(
                            "filling/select_currency_token/title_currency"
                          )}
                        </Typography.Text>
                      </div>
                      <div>
                        <Typography.Text>
                          {lang.get(
                            "filling/select_currency_token/title_token"
                          )}
                        </Typography.Text>
                      </div>
                    </div>
                    <div
                      style={{
                        color: "RGB(191, 191, 191)",
                        marginTop: "0.5rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ fontSize: "1rem", fontWeight: "700" }}>
                        <Button
                          type="link"
                          onClick={(e) =>
                            this.setstate({
                              visible_currency_list_drawer: true,
                            })
                          }
                          style={{ padding: "0" }}
                        >
                          <Icon
                            type="unordered-list"
                            style={{
                              paddingRight: "0.5rem",
                              color: "rgb(2, 122, 255)",
                            }}
                          />
                        </Button>
                        <Typography.Text>
                          {this.state.currency
                            ? this.state.currency
                            : lang.get(
                                "filling/select_currency_token/not_selected"
                              )}
                        </Typography.Text>
                        <Drawer
                          title={
                            <div style={{ fontWeight: "700" }}>
                              <Typography.Text>
                                {lang.get("drawer/currency_list/title")}
                              </Typography.Text>
                            </div>
                          }
                          placement="bottom"
                          closable={true}
                          onClose={(e) =>
                            this.setstate({
                              visible_currency_list_drawer: false,
                            })
                          }
                          visible={this.state.visible_currency_list_drawer}
                          height="bottom"
                          bodyStyle={{ paddingTop: "0" }}
                        >
                          <div style={{ height: "100%" }}>
                            <div
                              style={{
                                position: "relative",
                                height: "calc(50vh - 5rem)",
                              }}
                            >
                              <div
                                style={{
                                  top: "0",
                                  bottom: "0",
                                  left: "0",
                                  right: "0",
                                  position: "absolute",
                                  overflow: "auto",
                                }}
                              >
                                <CurrencyList
                                  data={this.state.data_codes}
                                  onClickCurrency={async (value) => {
                                    await this.setstate({
                                      value_to_filling: "0",
                                      currency: value,
                                      limit_decimal_point:
                                        (
                                          this.state.data_codes.filter(
                                            (code) =>
                                              code.currency_symbol === value
                                          )[0] || {}
                                        ).limit_decimal_point || 0,
                                      visible_currency_list_drawer: false,
                                    });
                                    await this._previewResult();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Drawer>
                      </div>
                      <div style={{ fontSize: "1rem", fontWeight: "700" }}>
                        <Icon type="arrow-right" />
                      </div>
                      <div style={{ fontSize: "1rem", fontWeight: "700" }}>
                        <Typography.Text>
                          {this.state.token
                            ? this.state.token.token_symbol
                            : lang.get(
                                "filling/select_currency_token/not_selected"
                              )}
                        </Typography.Text>
                        <Button
                          type="link"
                          onClick={(e) =>
                            this.setstate({ visible_token_list_drawer: true })
                          }
                          style={{ padding: "0" }}
                        >
                          <Icon
                            type="unordered-list"
                            style={{
                              paddingLeft: "0.5rem",
                              color: "rgb(2, 122, 255)",
                            }}
                          />
                        </Button>
                        <Drawer
                          title={
                            <div style={{ fontWeight: "700" }}>
                              <Typography.Text>
                                {lang.get("drawer/token_list/title")}
                              </Typography.Text>
                            </div>
                          }
                          placement="bottom"
                          closable={true}
                          onClose={(e) =>
                            this.setstate({ visible_token_list_drawer: false })
                          }
                          visible={this.state.visible_token_list_drawer}
                          height="bottom"
                          bodyStyle={{ paddingTop: "0" }}
                        >
                          <div style={{ height: "100%" }}>
                            <div
                              style={{
                                position: "relative",
                                height: "calc(50vh - 5rem)",
                              }}
                            >
                              <div
                                style={{
                                  top: "0",
                                  bottom: "0",
                                  left: "0",
                                  right: "0",
                                  position: "absolute",
                                  overflow: "auto",
                                }}
                              >
                                <TokenList
                                  data={this.state.data_codes}
                                  onClickToken={async (value) => {
                                    await this.setstate({
                                      token: value,
                                      visible_token_list_drawer: false,
                                    });
                                    this._previewResult();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Drawer>
                      </div>
                    </div>
                  </div>

                  {this.state.is_result_caculating ? (
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100px",
                        borderRadius: "0.6em",
                        padding: "1.5rem 1rem",
                        margin: "0.5rem auto 0",
                        boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                        textAlign: "center",
                      }}
                    >
                      <Spin
                        tip={lang.get("filling/result_caculating/loading_text")}
                      />
                    </div>
                  ) : null}
                  {this.state.token_to_add &&
                  this.state.result &&
                  !this.state.is_result_caculating ? (
                    <div>
                      <div
                        style={{
                          borderRadius: "0.6em",
                          padding: "1.5rem 1rem",
                          margin: "0.5rem auto 0",
                          boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                        }}
                      >
                        <Row type="flex" justify="space-between" align="middle">
                          <Col span={20}>
                            <div style={{ fontWeight: "700" }}>
                              {lang.get(
                                "filling/result_caculating/expected_token_to_add"
                              )}
                            </div>
                            <div
                              style={{
                                fontSize: "1.2rem",
                                fontWeight: "700",
                                color: "red",
                              }}
                            >
                              {`${eos_wallet.formatCommaNumber(
                                this.state.token_to_add
                              )} `}
                              <span
                                style={{
                                  color: "RGB(150, 150, 150)",
                                  fontSize: "0.75rem",
                                }}
                              >
                                {this.state.token.token_symbol}
                              </span>
                            </div>
                            <div
                              style={{ fontWeight: "700", marginTop: "1rem" }}
                            >
                              {lang.get(
                                "filling/result_caculating/expected_balance"
                              )}
                            </div>
                            <div
                              style={{
                                fontSize: "1.2rem",
                                fontWeight: "700",
                                color: "red",
                              }}
                            >
                              {`${eos_wallet.formatCommaNumber(
                                this.state.result
                              )} `}
                              <span
                                style={{
                                  color: "RGB(150, 150, 150)",
                                  fontSize: "0.75rem",
                                }}
                              >
                                {this.state.token.token_symbol}
                              </span>
                            </div>
                          </Col>
                          <Col span={4}>
                            <div>
                              <Button
                                type="link"
                                onClick={(e) =>
                                  this.props.history.push({
                                    pathname: "/wallet/record",
                                    search: `?${query_string.stringify({
                                      return: "/filling",
                                    })}`,
                                    state: {
                                      tab_key: "2",
                                      // bank_account: this.state.bank_account,
                                      // currency: this.state.currency,
                                      // log: this.state.log,
                                      // token: this.state.token,
                                      // token_to_add: this.state.token_to_add,
                                      // result: this.state.result,
                                      // value_to_filling: this.state.value_to_filling,
                                    },
                                  })
                                }
                              >
                                <Icon
                                  type="right"
                                  style={{ color: "RGB(75, 75, 75)" }}
                                />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <div
                          style={{
                            fontSize: "0.8rem",
                            color: "red",
                            marginTop: "0.5rem",
                          }}
                        >
                          {lang.get(
                            "filling/result_caculating/warning_message",
                            {
                              date: moment(new Date()).format(
                                "YYYY.MM.DD HH:mm:ss"
                              ),
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div
                    style={{
                      borderRadius: "0.6em",
                      padding: "1.5rem 1rem",
                      margin: "0.5rem auto 0",
                      boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            fontSize: "0.75rem",
                            color: "rgb(117,117,117)",
                          }}
                        >
                          {lang.get("filling/select_bank_account/title")}
                        </div>
                        {this.state.bank_account ? (
                          <div
                            style={{
                              fontWeight: "700",
                              marginTop: "0.2rem",
                              fontSize: "1rem",
                            }}
                          >
                            {this.state.bank_account.bank_account}
                          </div>
                        ) : (
                          <div
                            style={{
                              fontWeight: "700",
                              marginTop: "0.2rem",
                              fontSize: "1rem",
                            }}
                          >
                            {lang.get(
                              "filling/select_bank_account/not_selected"
                            )}
                          </div>
                        )}
                      </div>
                      <div style={{ fontWeight: "700" }}>
                        {(this.state.bank_account || {}).bank_name}
                        <Button
                          type="link"
                          onClick={(e) =>
                            this.setstate({
                              visible_bank_account_list_drawer: true,
                            })
                          }
                          style={{ padding: "0" }}
                        >
                          <Icon
                            type="unordered-list"
                            style={{
                              paddingLeft: "0.5rem",
                              color: "rgb(2, 122, 255)",
                            }}
                          />
                        </Button>
                      </div>
                    </div>
                    <BankAccountDrawer
                      visible={this.state.visible_bank_account_list_drawer}
                      onClose={(e) =>
                        this.setstate({
                          visible_bank_account_list_drawer: false,
                        })
                      }
                      onClickAccount={(value) => {
                        this.setstate({
                          bank_account: value,
                          visible_bank_account_list_drawer: false,
                        });
                      }}
                    />
                  </div>

                  <div
                    style={{
                      borderRadius: "0.6em",
                      padding: "1.5rem 1rem",
                      margin: "0.5rem auto 0",
                      boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    }}
                  >
                    <div>
                      <Icon type="profile" />
                      <span
                        style={{
                          paddingLeft: "0.5rem",
                          color: "rgb(135, 135, 135)",
                        }}
                      >
                        {lang.get("filling/input_memo/title")}
                      </span>
                    </div>
                    <div style={{ marginTop: "0.4rem" }}>
                      <Input.TextArea
                        maxLength="255"
                        autosize={{ minRows: 1, maxRows: 4 }}
                        value={this.state.log}
                        onChange={(e) => this.setstate({ log: e.target.value })}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell", padding: "1rem" }}>
            <Button
              size="large"
              type="danger"
              style={{
                width: "100%",
                fontWeight: "700",
                color: "#fff",
                background: "rgb(255, 92, 92)",
              }}
              onClick={this._hPreSubmit.bind(this)}
            >
              {lang.get("filling/btn_submit")}
            </Button>
            <Drawer
              title={
                <div style={{ fontWeight: "700" }}>
                  <Typography.Text>
                    {lang.get("drawer/input_otp/title")}
                  </Typography.Text>
                </div>
              }
              placement="bottom"
              closable={true}
              onClose={(e) =>
                this.setstate({
                  visible_input_otp_drawer: false,
                  password: "",
                  error_msg: "",
                })
              }
              visible={this.state.visible_input_otp_drawer}
              height="bottom"
            >
              <div style={{ height: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "1rem 1rem 1.5rem",
                  }}
                >
                  {this._rPassword()}
                </div>
                <NumericKeyboard
                  layout={NumericKeyboardLayout.OTP}
                  onPress={this._hOTPKeyboard.bind(this)}
                  entertext={lang.get("filling/btn_otp_submit")}
                />
              </div>
            </Drawer>
            <Modal
              title={
                <div style={{ fontWeight: "700" }}>
                  <Typography.Text>
                    {lang.get("drawer/input_otp/title")}
                  </Typography.Text>
                </div>
              }
              footer={null}
              visible={this.state.visible_input_otp_pc_modal}
              onCancel={(e) =>
                this.setstate({ visible_input_otp_pc_modal: false })
              }
              width="320px"
              centered
            >
              <InputOtpPc
                password={this.state.password}
                onChange={(v) => this.setstate({ password: v })}
                button_text={lang.get("filling/btn_otp_submit")}
                onSubmit={this._submit.bind(this)}
              />
            </Modal>
          </div>
        </div>
      </Loading>
    );
  }

  _rPassword() {
    let pw_box = [];
    let { password } = this.state;
    const pw_length = (password || "").length;

    // const svg_circle =(
    //   <svg height="100" width="100">
    //     <circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" />
    //   </svg>)

    for (let i = 0; i < 6; ++i) {
      if (i < pw_length)
        pw_box.push(
          <Col key={i} span={4} style={{ textAlign: "center" }}>
            ●
          </Col>
        );
      else
        pw_box.push(
          <Col
            key={i}
            span={4}
            style={{ textAlign: "center", color: "RGBA(192, 192, 192, 0.5)" }}
          >
            ●
          </Col>
        );
    }

    return <Row style={{ width: "14rem", borderRadius: "4px" }}>{pw_box}</Row>;
  }

  _hAddValue(number_to_add) {
    let new_number = new Big(this.state.value_to_filling).plus(number_to_add);
    if (new_number > Number(this.state.value_max)) {
      // maximum for devices with width: 320px
      new_number = Number(this.state.value_max);
    }
    this.setstate({ value_to_filling: String(new_number) });
    this._previewResult();
  }

  async _hNumericKeyboard(key) {
    let { value_to_filling } = this.state;

    if (!value_to_filling) value_to_filling = "";

    switch (key) {
      case "esc":
        return this.setstate({
          error_msg: "",
          visible_keyboard_drawer: false,
        });
      case "del":
        let new_value_del = value_to_filling.slice(0, -1);
        if (new_value_del === "") new_value_del = "0";
        return this.setstate({
          error_msg: "",
          value_to_filling: new_value_del,
        });
      case "enter":
        if (value_to_filling[value_to_filling.length - 1] === ".")
          value_to_filling = value_to_filling.substring(
            0,
            value_to_filling.length - 1
          );
        this.setstate({
          error_msg: "",
          visible_keyboard_drawer: false,
          value_to_filling,
        });
        this._previewResult();
        return;
      case ".":
        if (value_to_filling === "") {
          return this.setstate({
            error_header_text: "",
            value_to_filling: "0.",
          });
        }

        for (let i = 0; i < value_to_filling.length; ++i) {
          if (value_to_filling[i] === ".") return;
        }

        return this.setstate({
          error_msg: "",
          value_to_filling: value_to_filling.concat(key),
        });
      default:
        if (value_to_filling === "0") value_to_filling = "";
        const new_value = value_to_filling.concat(key);
        for (let i = new_value.length - 1, count = 0; i >= 0; --i) {
          ++count;

          if (new_value[i] === ".") {
            if (count - 1 > this.state.limit_decimal_point) return;
          }

          if (count - 1 > 8) {
            //! EOS MAXIMUM DECIMAL = 4 (2019.8)
            break;
          }
        }

        if (Number(new_value) < Number(this.state.value_max))
          return this.setstate({
            error_msg: "",
            value_to_filling: new_value,
          });
        else
          return this.setstate({
            error_msg: "",
            value_to_filling: this.state.value_max,
          });
    }
  }

  async _hOTPKeyboard(key) {
    let { password } = this.state;

    if (!password) password = "";

    switch (key) {
      case "esc":
        return this.setstate({
          error_msg: "",
          password: "",
          visible_input_otp_drawer: false,
        });
      case "del":
        return this.setstate({
          error_msg: "",
          password: password.slice(0, -1),
        });
      case "enter":
        await this._submit();
        break;
      default:
        if (password.length < 6)
          return this.setstate({
            error_msg: "",
            password: password.concat(key),
          });
    }
  }

  //* server transaction
  async _previewResult() {
    this.setstate({ is_result_caculating: true });
    let errors = {
      currency: false,
      token: false,
      bank_account: false,
      value_to_filling: false,
    };
    let has_error = false;

    const { value_to_filling, currency, token } = this.state;

    if (value_to_filling === "" || value_to_filling === "0") {
      errors.value_to_filling = true;
      has_error = true;
    }
    if (currency === "") {
      errors.currency = true;
      has_error = true;
    }
    if (!token) {
      errors.token = true;
      has_error = true;
    }
    if (has_error === true) {
      return this.setstate({
        is_result_caculating: false,
        token_to_add: void 0,
        result: void 0,
        errors,
      });
    }

    const balance_of_token = this.state.data_account.reduce((p, c, i, a) => {
      if (c.name === this.state.token.token_symbol) {
        return (p = c.value);
      }
      return p;
    }, 0);

    let token_to_add;
    try {
      const params = {
        money_currency_symbol: currency,
        money_amount: value_to_filling,
        token_symbol: token.token_symbol,
      };

      token_to_add = (await server_api.readExchangeRatesMoneyToToken(params))
        .items;
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      Modal.error({
        title: lang.get("filling/modal_case1/title"),
        content: lang.get("filling/modal_case1/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setstate({
        is_result_caculating: false,
        token_to_add: void 0,
        result: void 0,
      });
    }

    return this.setstate({
      is_result_caculating: false,
      token_to_add: String(Number(token_to_add[0])),
      result: String(Big(balance_of_token).plus(token_to_add[0])),
      errors,
    });
  }

  async _cacheCodes() {
    try {
      const data_codes = (await cache.codes.get()).items;

      return data_codes;
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      return [];
    }
  }

  async _fetchDataAccount() {
    try {
      const res_users_account_items = await server_api.readUsersAccount(
        {},
        { type: "array" }
      );

      return res_users_account_items;
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      return [];
    }
  }

  async _fetchExchangersRegistered() {
    try {
      const res_user_item = (
        await server_api.fetchUsers({
          where: {
            id: common.get().id,
          },
          attributes: ["registered_exchanger_id"],
          include: [
            {
              association: "registered_exchanger",
              attributes: ["id", "name"],
              include: [
                {
                  association: "bankaccounts",
                  attributes: ["id", "bank_id", "bank_account"],
                  include: [
                    { association: "bank", attributes: ["id", "name"] },
                  ],
                },
              ],
            },
          ],
        })
      ).items[0];

      if (!res_user_item.registered_exchanger) {
        return {};
      }

      return res_user_item.registered_exchanger;
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.error(e);

      return {};
    }
  }

  _fetchCountryCurrency(data_codes) {
    const users_data_code = data_codes.filter(
      (datum) => datum.country_code === common.get().country_code
    )[0];

    const country_currency = (users_data_code || {}).currency_symbol || "";
    const limit_decimal_point =
      (users_data_code || {}).limit_decimal_point || 0;

    return { currency: country_currency, limit_decimal_point };
  }

  async _cacheGlobalVars() {
    try {
      const res_global_vars_items = (await cache.global_vars.get()).items;

      const global_variants = res_global_vars_items.filter(
        (item) => item.name === "money_to_token_min_max_amount"
      )[0];

      return global_variants;
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      return {};
    }
  }

  async _fetchLocalBankAccount(bank_account_id) {
    if (bank_account_id === void 0) {
      return void 0;
    }

    try {
      const res_item = (
        await server_api.fetchUserBankAccounts({
          where: {
            id: bank_account_id,
            user_id: common.get().id,
          },
          include: [
            {
              association: "bank",
              where: {
                status: "AVAILABLE",
              },
            },
          ],
        })
      ).items[0];

      if (res_item) {
        const data_user_bank = {
          id: res_item.id,
          alias: res_item.alias,
          bank_id: res_item.bank_id,
          bank_name: res_item.bank.name,
          bank_account: res_item.bank_account,
          owner_name: res_item.owner_name,
        };

        return data_user_bank;
      } else {
        return void 0;
      }
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      return void 0;
    }
  }

  async _hPreSubmit() {
    this.setstate({ is_submitting: true });
    const {
      value_to_filling,
      currency,
      bank_account,
      token,
      token_to_add,
      is_result_caculating,
    } = this.state;

    if (is_result_caculating === true) {
      Modal.error({
        title: lang.get("filling/modal_case2/title"),
        content: lang.get("filling/modal_case2/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setState({ is_submitting: false });
    }

    if (eos_wallet.validate.checkBlank(value_to_filling)) {
      Modal.error({
        title: lang.get("filling/modal_case3/title"),
        content: lang.get("filling/modal_case3/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setState({ is_submitting: false });
    }

    if (Number(value_to_filling) < Number(this.state.value_min)) {
      Modal.error({
        title: lang.get("filling/modal_case4/title"),
        content: lang.get("filling/modal_case4/content", {
          value_min: this.state.value_min,
        }),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setState({ is_submitting: false });
    }
    console.log("CHECK BLANK");
    if (eos_wallet.validate.checkBlank(currency)) {
      Modal.error({
        title: lang.get("filling/modal_case5/title"),
        content: lang.get("filling/modal_case5/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setState({ is_submitting: false });
    }

    if (!(this.state.selected_exchanger || {}).id) {
      Modal.error({
        title: lang.get("filling/modal_case6/title"),
        content: lang.get("filling/modal_case6/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setState({ is_submitting: false });
    }

    if (!bank_account) {
      Modal.error({
        title: lang.get("filling/modal_case7/title"),
        content: lang.get("filling/modal_case7/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setState({ is_submitting: false });
    }

    if (!token) {
      Modal.error({
        title: lang.get("filling/modal_case8/title"),
        content: lang.get("filling/modal_case8/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setState({ is_submitting: false });
    }

    if (token_to_add === void 0) {
      Modal.error({
        title: lang.get("filling/modal_case9/title"),
        content: lang.get("filling/modal_case9/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setState({ is_submitting: false });
    }

    if (Number(token_to_add) === 0) {
      Modal.error({
        title: lang.get("filling/modal_case10/title"),
        content: lang.get("filling/modal_case10/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setState({ is_submitting: false });
    }

    //* Success!
    if (checkers.is_mobile === true)
      this.setstate({ visible_input_otp_drawer: true, is_submitting: false });
    else
      this.setstate({ visible_input_otp_pc_modal: true, is_submitting: false });
  }

  async _submit(e) {
    if (this.state.is_loading === true) {
      return;
    }

    try {
      this.setstate({
        is_loading: true,
        visible_input_otp_drawer: false,
        visible_input_otp_pc_modal: false,
      });

      const res_exchanger_item = (
        await server_api.readExchangersSafe({
          where: {
            id: this.state.selected_exchanger.id,
            is_archived: false,
          },
          attributes: ["id", "name"],
          include: [
            {
              association: "bankaccounts",
              where: {
                is_archived: false,
              },
              required: true,
              attributes: ["id", "bank_account"],
              include: [{ association: "bank", attributes: ["name"] }],
            },
          ],
        })
      ).items[0];

      if (((res_exchanger_item || {}).bankaccounts || []).length === 0) {
        Modal.error({
          title: lang.get("filling/modal_case14/title"),
          content: lang.get("filling/modal_case14/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });
        return this.setstate({
          is_loading: false,
          password: "",
        });
      }

      const params = {
        exchanger_id: res_exchanger_item.id,
        user_bankaccount_id: this.state.bank_account.id,
        exchanger_bankaccount_id: res_exchanger_item.bankaccounts[0].id,
        money_currency_symbol: this.state.currency,
        money_amount: this.state.value_to_filling,
        token_symbol: this.state.token.token_symbol,
        log: this.state.log,
        otp_code: this.state.password,
      };

      const res_items = (await server_api.createUsersMoneyToToken(params))
        .items[0];

      if (res_items) {
        storage_util.saveToLocal("user_last_bank", {
          id: this.state.bank_account.id,
        });

        storage_util.saveToSession(state_util.ENUM.filling_state, {
          money_amount: res_items.money_amount,
          money_currency_symbol: res_items.money_currency_symbol,
          token_symbol: res_items.token_symbol,
          token_amount: res_items.token_amount,
          exchange_location: {
            name: res_exchanger_item.name,
            bank: res_exchanger_item.bankaccounts[0].bank.name,
            account: res_exchanger_item.bankaccounts[0].bank_account,
          },
          exchanger_id: res_items.exchanger_id,
          exchanger_bankaccount_id: res_items.exchanger_bankaccount_id,
          user_bankaccount_id: res_items.user_bankaccount_id,
          user_bankaccount: {
            owner_name: this.state.bank_account.owner_name,
            bank_name: this.state.bank_account.bank_name,
            bank_account: this.state.bank_account.bank_account,
          },
          its_result: true,
        });

        return this.props.history.push({
          pathname: "/filling/result",
        });
      } else {
        if (process.env.NODE_ENV === "development") {
          console.error("not created");
        }

        Modal.error({
          title: lang.get("filling/modal_case13/title"),
          content: lang.get("filling/modal_case13/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });
        return this.setstate({
          is_loading: false,
          password: "",
          system_error_msg: e.result,
        });
      }
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      if (
        (e.result || {}).message === "opt-code not matched. please try again."
      ) {
        Modal.error({
          title: lang.get("filling/modal_case11/title"),
          content: lang.get("filling/modal_case11/content"),
          okText: lang.get("modal/confirm_text"),
          centered: true,
        });
        return this.setstate({
          is_loading: false,
          password: "",
        });
      }

      Modal.error({
        title: lang.get("filling/modal_case12/title"),
        content: lang.get("filling/modal_case12/content"),
        okText: lang.get("modal/confirm_text"),
        centered: true,
      });
      return this.setstate({
        is_loading: false,
        password: "",
        system_error_msg: e.result,
      });
    }
  }

  //* utils */
  _validateStateDecimal(stored_state, data_codes) {
    if (stored_state === void 0) {
      return {};
    }

    if (
      stored_state.value_to_filling === void 0 ||
      (stored_state.value_to_filling || "").indexOf(".") < 0
    )
      return stored_state;

    let currency = stored_state.currency || "";

    let limit_decimal_point;
    if (currency === "") {
      limit_decimal_point = 0;
    } else {
      limit_decimal_point =
        (
          data_codes.filter((datum) => datum.currency_symbol === currency)[0] ||
          {}
        ).limit_decimal_point || 0;
    }

    stored_state.value_to_filling = String(
      parseFloat(stored_state.value_to_filling || "0").toFixed(
        limit_decimal_point
      )
    );

    return stored_state;
  }

  _redirectTo(pathname, params = {}) {
    let {
      bank_account,
      currency,
      log,
      token,
      token_to_add,
      result,
      value_to_filling,
    } = this.state;
    storage_util.saveToSession(state_util.ENUM.filling_state, {
      value_to_filling,
      currency,
      token,
      bank_account,
      token_to_add,
      result,
      log,
    });

    return this.props.history.push({
      pathname: pathname,
      search: `?${query_string.stringify({
        return: this.props.location.pathname,
      })}`,
      state: params,
    });
  }
}

export default Filling;
