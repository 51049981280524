import React from 'react';

import common       from '../../../utils/common';
import eos_wallet   from '../../../utils/eos_wallet';
import storage_util from '../../../utils/storage_util';
import lang from '../../../lang/lang';

import { Button, Typography, Icon, Switch } from 'antd';
// import native_util from '../../../utils/native_util';
import server_api from '../../../utils/server_api';



class Setting extends React.Component {
  is_mounted_ = false;
  state = {
    is_loading: false,
    push_deposit_withdrawal: true,
    push_filling_exchange: true,
    push_popup: true,
    lock_password: true
  }

  constructor(props) { super(props);
    // console.log(this.props.location.state);
    // if (!this.props.location.state)
    //   return this.props.history.push('/');

    // const { value_transferred } = this.props.location.state;    
    this.state = Object.assign({}, this.state, storage_util.loadFromLocal('push_options'));
    
    // console.log(storage_util.loadFromLocal('push_options'));
    // console.log(this.state);
  }
  setstate = partial_state => (this.is_mounted_ === true && this.setState(partial_state));

  async componentDidMount() {
    this.is_mounted_ = true;    
    this.setstate({ is_loading: true });
    if (await common.isLoggedIn() === false) {
      return;
    }

    this.setstate({
      is_loading: false,      
      header_text: await eos_wallet.getHeaderTokenText() || '',
    });
    // 알림 체크
  }
  componentWillUnmount() { this.is_mounted_ = false; }

  render () {
    if (common.get().token === void 0) {
      return null;
    }

    return (
      <div style={{ display: "table", width: "100%", height: "100%" }}>
        {/* Header */}
        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>

            {/** HEADER *****/}
            <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  onClick={e => this.props.history.push('/wallet')}
                  style={{
                      flex: "1 1 0px", borderRadius: "4px", padding: "0.2rem 1rem"
                    , display: "block", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"
                    , fontSize: "1rem", color: "#fff", background: "rgb(51, 129, 252)", cursor: 'pointer'
                  }} >
                  {this.state.header_text}&nbsp;
                </div>  
                <div style={{ flex: "0 0 auto", padding: "0 0 0 0.5rem" }}>
                  <Button type="link" style={{ padding: "0", height: "2.5rem" }} onClick={e => this.props.history.push('/all')}>
                    <Icon type="close" style={{ fontSize: "1.6rem", color: "#000" }} />
                  </Button>
                </div>
              </div>

              <Typography.Title level={3} style={{ display: 'flex', alignItems: 'center', marginBottom: "0", marginTop: "1.5rem" }}>
                <Typography.Text>{lang.get('all/setting/title')}</Typography.Text>
              </Typography.Title>
            </div>
            {/***** HEADER END **/}

          </div>
        </div>


        <div style={{ display: "table-row" }}> {/* content */}
          <div style={{ display: "table-cell" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div style={{ top: "0", bottom: "0", left: "0", right: "0", position: "absolute", overflow: "auto"}}>
                <div style={{ padding: "1rem 0.5rem" }}>
                  <div style={{
                    borderRadius: "0.6em", padding: "1rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    fontSize: "1rem", fontWeight: "700",
                  }}>
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: "1 1 0px" }}>
                        <div>{lang.get('all/setting/notify_transfer')}</div>
                      </div>
                      <div style={{ flex: "0 0 auto" }}>
                        <div>
                          <Switch
                            defaultChecked={this.state.push_deposit_withdrawal}
                            onChange={checked => {
                              this.savePushOptionsToLocalStorage({ push_deposit_withdrawal: checked});
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div style={{ display: "flex", marginTop: "1rem" }}>
                      <div style={{ flex: "1 1 0px" }}>
                        <div>{lang.get('all/setting/notify_sound')} <span style={{ paddingLeft: "0.5rem", color: "RGB(192, 205, 236)" }}>{lang.get('all/setting/no_sound')}</span></div>
                      </div>
                      <div style={{ flex: "0 0 auto" }}>
                        <div><Icon type="right" /></div>
                      </div>
                    </div> */}
                  </div>

                  <div style={{
                    borderRadius: "0.6em", padding: "1rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    fontSize: "1rem", fontWeight: "700",
                  }}>
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: "1 1 0px" }}>
                        <div>{lang.get('all/setting/notify_filling_exchange')}</div>
                      </div>
                      <div style={{ flex: "0 0 auto" }}>
                        <div>
                          <Switch
                            defaultChecked={this.state.push_filling_exchange}
                            onChange={checked => {
                              this.savePushOptionsToLocalStorage({ push_filling_exchange: checked});
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div style={{ display: "flex", marginTop: "0.5rem" }}>
                      <div style={{ flex: "1 1 0px" }}>
                        <div>{lang.get('all/setting/notify_sound')} <span style={{ paddingLeft: "0.5rem", color: "RGB(192, 205, 236)" }}>{lang.get('all/setting/no_sound')}</span></div>
                      </div>
                      <div style={{ flex: "0 0 auto" }}>
                        <div><Icon type="right" /></div>
                      </div>
                    </div> */}
                  </div>

                  <div style={{
                    borderRadius: "0.6em", padding: "1rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    fontSize: "1rem", fontWeight: "700",
                  }}>
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: "1 1 0px" }}>
                        <div>{lang.get('all/setting/notify_popup')}</div>
                      </div>
                      <div style={{ flex: "0 0 auto" }}>
                        <div>
                          <Switch
                            defaultChecked={this.state.push_popup}
                            onChange={checked => {
                              this.savePushOptionsToLocalStorage({ push_popup: checked});
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div style={{ display: "flex", marginTop: "0.5rem" }}>
                      <div style={{ flex: "1 1 0px" }}>
                        <div>{lang.get('all/setting/notify_sound')} <span style={{ paddingLeft: "0.5rem", color: "RGB(192, 205, 236)" }}>{lang.get('all/setting/no_sound')}</span></div>
                      </div>
                      <div style={{ flex: "0 0 auto" }}>
                        <div><Icon type="right" /></div>
                      </div>
                    </div> */}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
    </div>)
  }

  savePushOptionsToLocalStorage (new_state) {
    storage_util.saveToLocal(
      'push_options',
      Object.assign({
        push_deposit_withdrawal: this.state.push_deposit_withdrawal,
        push_filling_exchange  : this.state.push_filling_exchange,
        push_popup             : this.state.push_popup,
      }, new_state),
    );

    this.setState(new_state);


    // update
    const name_noti_map = {
      push_deposit_withdrawal: 'need_transfer_noti',
      push_filling_exchange  : 'need_exchange_noti',
      push_popup             : 'need_popup_noti',
    };
    const params = {};
    for (const name in new_state)
      params[name_noti_map[name]] = new_state[name];

    (async () => {
      try {
        await server_api.updateUser(common.id, params);
      }catch(e) {
        if (process.env.NODE_ENV === 'development') {
          console.error(e);
        }
      }
    })();
    
  }

}

export default Setting;