import React from "react";
import {
  Button,
  Typography,
  Icon,
  Row,
  Col,
  Drawer,
  Modal,
  Select,
} from "antd";
import QRCode from "qrcode";
import query_string from "query-string";
import * as clipboard from "clipboard-polyfill/build/clipboard-polyfill.promise";
//* view components
import Loading from "../../snippets/Loading";
import TokenAndBalanceList from "../../snippets/drawer_contents/TokenAndBalanceList";
//* utils
import cache from "../../utils/cache";
import common from "../../utils/common";
import eos_wallet from "../../utils/eos_wallet";
import native_util from "../../utils/native_util";
import server_api from "../../utils/server_api";
//* resources
import lang from "../../lang/lang";
import qr_loading from "../../images/loading/qr_loading.png";
//* global variants
import config from "../../config";

class Wallet extends React.Component {
  is_mounted_ = false;
  state = {
    header_text: "",
    qr_image: qr_loading,
    login_id: "login_id (undefined)",
    visible_currency_drawer: false,
    visible_qr_modal: false,
    visible_token_list_drawer: false,
    user_login_id: "",
    user_eos_account: "",
    qr_modal_message: "",
    local_token_info: void 0,
    user_accounts: [],
    major_token: {
      name: config.major_token_symbol,
      value: 0,
    },
    selected_token: void 0,
  };

  setstate = (partial_state) =>
    this.is_mounted_ === true && this.setState(partial_state);

  async componentDidMount() {
    this.is_mounted_ = true;
    this.setstate({ is_loading: true });
    if ((await common.isLoggedIn()) === false) {
      return;
    }

    const qr = await this._getQrWithText(common.get().login_id);

    // let local_token_info = await this._cacheLocalTokenInfo();
    let user_accounts = await this._fetchUsersAccount();
    let user_eos_account = await this._fetchUsersEOSAccount();

    // let major_token = user_accounts.filter(account => account.name === this.state.major_token.name)[0] || this.state.major_token;

    // let selected_token = user_accounts.filter(token => token.name === local_token_info.token_symbol)[0] || { name: local_token_info.token_symbol, value: 0 };

    this.setstate({
      is_loading: false,
      header_text: (await eos_wallet.getHeaderTokenText()) || "",
      qr_image: qr,
      user_login_id: common.get().login_id,
      user_eos_account,
      // local_token_info,
      user_accounts,
      // selected_token,
      // major_token,
    });
  }
  componentWillUnmount() {
    this.is_mounted_ = false;
  }

  getAccountBalance() {
    let list = [];
    this.state.user_accounts.forEach((e) => {
      list.push(
        <div
          style={{
            borderRadius: "0.6em",
            padding: "0.5rem 1rem",
            margin: "0.5rem auto 0",
            boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
          }}
        >
          <Row type="flex" justify="space-between" align="middle">
            <Col span={24}>
              <div>
                {lang.get("wallet/wallet_major", {
                  major_token_symbol: e.name,
                })}
              </div>
              <div style={{ fontSize: "1.1rem", fontWeight: "700" }}>
                {`${eos_wallet.formatCommaNumber(e.value)} ${e.name}`}
              </div>
            </Col>
          </Row>
        </div>
      );
    });
    return list;
  }

  render() {
    if (common.get().token === void 0) {
      return null;
    }

    return (
      <Loading
        is_loading={this.state.is_loading}
        overall={true}
        style={{
          display: "table",
          tableLayout: "fixed",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ display: "table-row", height: "0" }}>
          <div style={{ display: "table-cell" }}>
            {/** HEADER *****/}
            <div style={{ padding: "0.5rem 1rem 0" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  onClick={(e) => this.props.history.push("/wallet")}
                  style={{
                    flex: "1 1 0px",
                    borderRadius: "4px",
                    padding: "0.2rem 1rem",
                    display: "block",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontSize: "1rem",
                    color: "#fff",
                    background: "rgb(51, 129, 252)",
                    cursor: "pointer",
                  }}
                >
                  {this.state.header_text}&nbsp;
                </div>
              </div>

              <Typography.Title
                level={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0",
                  marginTop: "1.5rem",
                }}
              >
                <Typography.Text>{lang.get("wallet/title")}</Typography.Text>
              </Typography.Title>
            </div>
            {/***** HEADER END **/}
          </div>
        </div>

        <div style={{ display: "table-row", height: "3%" }}></div>

        <div style={{ display: "table-row" }}>
          {" "}
          {/* content */}
          <div style={{ display: "table-cell", padding: "0 0 0.5rem" }}>
            <div style={{ position: "relative", height: "100%" }}>
              <div
                style={{
                  top: "0",
                  bottom: "0",
                  left: "0",
                  right: "0",
                  position: "absolute",
                  overflow: "auto",
                }}
              >
                <div style={{ padding: "0.5rem" }}>
                  <div
                    style={{
                      borderRadius: "0.6em",
                      padding: "0.5rem 1rem",
                      margin: "0.5rem auto 0",
                      boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)",
                    }}
                  >
                    <div style={{ fontSize: "1rem", fontWeight: "700" }}>
                      <Typography.Text>
                        {lang.get("wallet/qr_viewer/title")}
                      </Typography.Text>
                    </div>
                    <div
                      style={{ cursor: "pointer", marginTop: "1rem" }}
                      onClick={(e) => this.setstate({ visible_qr_modal: true })}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          alt=""
                          src={this.state.qr_image}
                          style={{
                            width: "50%",
                            minWidth: "128px",
                            maxWidth: "512px",
                            height: "50%",
                            minHeight: "128px",
                            maxHeight: "512px",
                          }}
                        />
                      </div>
                      {this.state.user_login_id ? (
                        <div
                          style={{ textAlign: "center", padding: "0.5rem 0 0" }}
                        >
                          {`ID: ${this.state.user_login_id}`}
                        </div>
                      ) : null}

                      {this.state.user_eos_account ? (
                        <div
                          style={{ textAlign: "center", padding: "0.1rem 0" }}
                        >
                          {`Wallet address: ${this.state.user_eos_account}`}
                        </div>
                      ) : null}
                    </div>
                    <Modal
                      title={
                        <div style={{ fontWeight: "700" }}>
                          {lang.get("wallet/qr_viewer/modal_detail/title")}
                        </div>
                      }
                      centered
                      visible={this.state.visible_qr_modal}
                      onCancel={() =>
                        this.setstate({ visible_qr_modal: false })
                      }
                      footer={null}
                      bodyStyle={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                      }}
                    >
                      <div style={{ fontSize: "0.8rem", alignSelf: "start" }}>
                        {lang.get("wallet/qr_viewer/modal_detail/info_text")}
                      </div>
                      <div style={{ textAlign: "center", marginTop: "1rem" }}>
                        <img
                          alt=""
                          src={this.state.qr_image}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div
                        style={{ textAlign: "center", padding: "0.5rem 0 0" }}
                      >
                        {`ID: ${this.state.user_login_id}`}
                      </div>
                      <div style={{ textAlign: "center", padding: "0.1rem 0" }}>
                        {`Wallet address: ${this.state.user_eos_account}`}
                      </div>
                      <Row
                        type="flex"
                        justify="center"
                        align="middle"
                        gutter={8}
                        style={{ marginTop: "0.5rem", width: "100%" }}
                      >
                        <Col span={8}>
                          <Button
                            type="primary"
                            onClick={(e) =>
                              this._hCopyText(this.state.user_login_id)
                            }
                            style={{
                              width: "100%",
                              padding: "0 0.5rem",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {lang.get(
                              "wallet/qr_viewer/modal_detail/btn_copy_login_id"
                            )}
                          </Button>
                        </Col>
                        <Col span={8}>
                          <Button
                            type="primary"
                            onClick={(e) =>
                              this._hCopyText(this.state.user_eos_account)
                            }
                            style={{
                              width: "100%",
                              padding: "0 0.5rem",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {lang.get(
                              "wallet/qr_viewer/modal_detail/btn_copy_eos_account"
                            )}
                          </Button>
                        </Col>
                        <Col span={8}>
                          <a
                            id="qr_image"
                            href={this.state.qr_image}
                            download="Hiik_QR.png"
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            <Button
                              type="primary"
                              style={{
                                width: "100%",
                                padding: "0 0.5rem",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                              onClick={() => {
                                // document.getElementsById('qr_image').click();
                                native_util.downloadImage(this.state.qr_image);
                              }}
                            >
                              {lang.get(
                                "wallet/qr_viewer/modal_detail/btn_download_qr"
                              )}
                            </Button>
                          </a>
                        </Col>
                      </Row>
                      {this.state.qr_modal_message ? (
                        <div style={{ padding: "0.5rem 0" }}>
                          {this.state.qr_modal_message}
                        </div>
                      ) : null}
                    </Modal>
                  </div>

                  {this.getAccountBalance()}

                  {/* {(this.state.selected_token)
                    ? ( <div style={{
                          borderRadius: "0.6em", padding: "0.5rem 1rem", margin: "0.5rem auto 0", boxShadow: "0 0 0.5rem 0 rgb(200, 200, 200)" }}>
                          <Row type="flex" justify="space-between" align="middle" style={{ width: "100%" }}>
                            <Col span={21}>
                              <div>{lang.get('wallet/wallet_selected', { selected_token_symbol: this.state.selected_token.name })}</div>
                              <div style={{ fontSize: "1.1rem", fontWeight: "700" }}>
                                {`${eos_wallet.formatCommaNumber(this.state.selected_token.value)} ${this.state.selected_token.name}`}
                              </div>
                            </Col>
                            <Col>
                              <div>
                                <Button type="link" style={{ padding: "0" }} onClick={e => this.setstate({ visible_token_list_drawer: true })}>
                                  <Icon type="unordered-list" style={{ paddingLeft: "0.5rem", color: "rgb(2, 122, 255)" }} />
                                </Button>
                                <Drawer
                                  title={<div style={{ fontWeight: "700" }}><Typography.Text>{lang.get('drawer/token_and_balance_list/title')}</Typography.Text></div>}
                                  placement="bottom"
                                  closable={true}
                                  onClose={e => this.setstate({ visible_token_list_drawer: false})}
                                  visible={this.state.visible_token_list_drawer}
                                  height="bottom"
                                  bodyStyle={{ paddingTop: "0" }}
                                >
                                  <div style={{ height: "100%", maxWidth: "480px", marginLeft: "auto", marginRight: "auto" }}>        
                                    <div style={{ position: "relative", height: "calc(50vh - 5rem)"}}>
                                      <div style={{ top: "0", bottom: "0", left: "0", right: "0", position: "absolute",  overflow: "auto", paddingTop: "0.5rem"}}>
                                        <TokenAndBalanceList data={this.state.user_accounts} except_tokens={[config.major_token_symbol]}
                                          onClickCurrency={value => this.setstate({
                                            selected_token: (this.state.user_accounts).filter(account => account.name === value)[0],
                                            visible_token_list_drawer: false,
                                          })} />
                                      </div>
                                    </div>
                                  </div>
                                </Drawer>
                              </div>
                            </Col>
                          </Row>
                        </div> )
                    : null
                  } */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Loading>
    );
  }

  _rTokenOptions() {
    const { user_accounts } = this.state;
    let options = [];

    options = user_accounts.map((account, i) => (
      <Select.Option key={i} value={account.name}>
        {account.name}
      </Select.Option>
    ));

    return options;
  }

  async _hCopyText(text) {
    if (native_util.hasNativeApi()) await native_util.setClipboardText(text);
    else await clipboard.writeText(text);

    this.setstate({
      qr_modal_message: lang.get(
        "wallet/qr_viewer/modal_detail/copied_message"
      ),
    });
  }

  async _getQrWithText(text) {
    try {
      return await QRCode.toDataURL(text, { width: 800, margin: 0 });
    } catch (err) {
      console.error(err);
    }
  }

  //* transactions
  async _cacheLocalTokenInfo() {
    try {
      const res_items = (await cache.codes.get()).items;

      const local_token_info = res_items.filter(
        (item) => item.country_code === common.get().country_code
      )[0];

      return local_token_info;
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        return console.error(e);
      }
      return "";
    }
  }

  async _fetchUsersAccount() {
    try {
      let res_user_accounts_items = await server_api.readUsersAccount(
        {},
        { type: "array" }
      );
      console.log(res_user_accounts_items);

      // const user_accounts_zero_filtered = res_user_accounts_items.filter(
      //   (item) => item.value !== 0
      // );

      return res_user_accounts_items;
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        return console.error(e);
      }

      return [];
    }
  }

  async _fetchUsersEOSAccount() {
    try {
      let res_users_item = (
        await server_api.fetchUsers({
          where: {
            id: common.get().id,
          },
          attributes: ["eos_account"],
        })
      ).items[0];

      return res_users_item.eos_account;
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        return console.error(e);
      }

      return "";
    }
  }

  //* utils */
  _redirectTo(pathname, params = {}) {
    const default_params = {
      // tab_key: this.state.tab_key,
    };
    const combined_params = Object.assign({}, default_params, params);

    this.props.history.push({
      pathname: pathname,
      search: `?${query_string.stringify({
        return: this.props.location.pathname,
      })}`,
      state: combined_params,
    });
  }
}

export default Wallet;
